export interface InvoiceMetadata {
	id: string,
	fieldName: string,
	dataType: string,
	options: string,
	createdBy: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string | null,
	model: string;
	valuesJson: any;
	permissionsJson: string;
}

export interface Invoice {
	createdAt: string,
	createdBy: string,
	deletedAt: string,
	id: string,
	updatedAt: string,
	valuesJson: string
}

export interface Pagination {
	size: number,
	page: number,
	pages: number,
	total: number,
	elements: number,
	last: boolean
}

export enum InvoiceActionTypes {
	GET_METADATA_REQUEST = "@@invoice/GET_METADATA_REQUEST",
	GET_METADATA_SUCCESS = "@@invoice/GET_METADATA_SUCCESS",
	GET_METADATA_ERROR = "@@invoice/GET_METADATA_ERROR",
	GET_REQUEST = "@@invoice/GET_REQUEST",
	GET_SUCCESS = "@@invoice/GET_SUCCESS",
	GET_ERROR = "@@invoice/GET_ERROR",
	GET_ALL_REQUEST = "@@invoice/GET_ALL_REQUEST",
	GET_ALL_SUCCESS = "@@invoice/GET_ALL_SUCCESS",
	GET_ALL_ERROR = "@@invoice/GET_ALL_ERROR",
	PUT_REQUEST = "@@invoice/PUT_REQUEST",
	PUT_SUCCESS = "@@invoice/PUT_SUCCESS",
	PUT_ERROR = "@@invoice/PUT_ERROR",
	DELETE_REQUEST = "@@invoice/DELETE_REQUEST",
	DELETE_SUCCESS = "@@invoice/DELETE_SUCCESS",
	DELETE_ERROR = "@@invoice/DELETE_ERROR",
	POST_REQUEST = "@@invoice/POST_REQUEST",
	POST_SUCCESS = "@@invoice/POST_SUCCESS",
	POST_ERROR = "@@invoice/POST_ERROR",
	POST_STUDENT_INVOICES_REQUEST = "@@invoice/POST_STUDENT_INVOICES_REQUEST",
	POST_STUDENT_INVOICES_SUCCESS = "@@invoice/POST_STUDENT_INVOICES_SUCCESS",
	POST_STUDENT_INVOICES_ERROR = "@@invoice/POST_STUDENT_INVOICES_ERROR",


	
}

export interface InvoiceState {
	readonly list: Invoice[] | null;
	readonly details: Invoice | null;
	readonly pagination: Pagination | null;
	readonly metaDataFields: InvoiceMetadata[];
	readonly uploadedApplicationList: any;
	readonly hierarchysources: any;
	readonly loading: boolean;
	readonly dataLoading: boolean;
	readonly metaLoading: boolean;
}
