import { useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import MeetingAccount from "./innerComponents/MeetingAccount";
import MeetingEventType from "./innerComponents/MeetingEventType";
import MeetingsDisplay from "./innerComponents/MeetingsDisplay";
import Availability from "./innerComponents/Availability";

const MeetingsMenu = ({ t, avtarImage2 }: any) => {
    const [openFillColored1, setOpenFillColored1] = useState("1");
    const toggleFillColored1 = (id: any) => {
        if (openFillColored1 !== id) {
            setOpenFillColored1(id);
        }
    };

    return (
        <>
            <SimpleBar style={{maxHeight: "600px"}}>
            {/* <Accordion className="custom-accordionwithicon accordion-flush accordion-fill-primary" id="accordionFill2" open={openFillColored1} toggle={toggleFillColored1}>
                <AccordionItem>
                    <AccordionHeader targetId="1"><Label className="mb-0 fs-16">{t("userpreferences.account")}</Label></AccordionHeader>
                    <AccordionBody accordionId="1"> */}
                       <MeetingAccount />
                    {/* </AccordionBody>
                </AccordionItem>
            </Accordion> */}
            {/* <Accordion className="custom-accordionwithicon accordion-flush accordion-fill-primary" id="accordionFill2" open={openFillColored1} toggle={toggleFillColored1}>

                <AccordionItem>
                    <AccordionHeader targetId="2"><Label className="mb-0 fs-16">{t("userpreferences.event_types")}</Label></AccordionHeader>
                    <AccordionBody accordionId="2" className="mt-n3">
                        <MeetingEventType />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            <Accordion className="custom-accordionwithicon accordion-flush accordion-fill-primary" id="accordionFill2" open={openFillColored1} toggle={toggleFillColored1}>

                <AccordionItem>
                    <AccordionHeader targetId="4"><Label className="mb-0 fs-16">{t("userpreferences.availability")}</Label></AccordionHeader>
                    <AccordionBody accordionId="4">
                        <Availability />
                    </AccordionBody>
                </AccordionItem>
            </Accordion> */}
            {/* <Accordion className="custom-accordionwithicon accordion-flush accordion-fill-primary" id="accordionFill2" open={openFillColored1} toggle={toggleFillColored1}>

                <AccordionItem>
                    <AccordionHeader targetId="3"><Label className="mb-0 fs-16">Meetings</Label></AccordionHeader>
                    <AccordionBody accordionId="3" className="mt-0">
                        <MeetingsDisplay />
                    </AccordionBody>
                </AccordionItem>
            </Accordion> */}
            </SimpleBar>

        </>
    )
}
export default withTranslation()(MeetingsMenu);
