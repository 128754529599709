import { useEffect, useState } from "react";
import { Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { toast } from "react-toastify";
import { studentExternalApplication } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import FormSuccess from "./formSuccess";
import axios from "axios";
import config from "config";
import { ApplicationState } from "store";
const { DTS_API_STUDENT, AWS_API } = config.api;

const DTSFullForm = ({ dataFields, btn_text, data, md, lg, sm, xl, xs, BtnClassName,tenantId, isUpdate}: any) => {
	
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
	
	const [filterFields, setFilterFields] = useState<any>([]);

	useEffect(() => {
		let fields: any= [];
		dataFields &&
		dataFields.length &&
		dataFields.map((field: any) => {
			let item = field ? field : {};
			if (field) return fields.push(field);
		});
		setFilterFields(fields)
	}, [dataFields])
	

	const [formValues, setFormValues] = useState<any>({});
	const [fieldValues, SetFieldValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);
	const [registered, setRegistered] = useState(false)	
	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(null);

	const isFile = (file: any) => {
        return typeof file === 'object' && file !== null && 'size' in file && 'type' in file;
    }

    const SubmitUpload = async (selectedFiles: any, key: any) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : selectedFiles;
        if (file) {
                const formData = new FormData();
                formData.append('documentType', key);
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
						"tenantId": tenantId
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents/open`, formData, config)
                .then((res: any) => {                
                    if(res?.key) {
                        setFormValues({...formValues, [key]: res?.key })
					}                   
                })
        }
    }
	const onChange = async (option: any, key: any, field: any) => {  
        if(field.type === "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
        }       
        else if(isFile(option)) {
            const file = option && option.length ? option[0] : option;
            setFile(file)
            setFileKey(key)
            await SubmitUpload(file, key)
            // let name = option.name;
            // let newFormValues = {...formValues, [key]: option?.name}
            // setFormValues(newFormValues) 
            // // feldtoupdate = {[key]:  file}           
        }      
        else if(key === 'parent') {
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else if(key === 'source') {
            let newFormvalues = { ...formValues, [key]: option?.value || option, subagent: null }
            setFormValues(newFormvalues)
        }
        else if(key === 'subagent' && option !=null) {
            setFormValues({ ...formValues, [key]: option?.value || option, source: "c017ce92-15ae-47b1-9ab1-197895ce94d6" })
        }        
        else if(isNaN(Date.parse(option)) === false) {
            setFormValues({...formValues, [key]: option})
        }
        else if (typeof option === 'object') {            
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
        }
    };

	const btnAction = (formValues: any) => {
		setdisabled(true)
		let data = formValues;	
        if(formValues['subagent']) {
			let newData = { ...formValues, source: "c017ce92-15ae-47b1-9ab1-197895ce94d6" }
            setFormValues(newData)
			data = newData;
        }
		if(formValues['source'] && typeof formValues['source'] === 'object') {
			let newData = { ...formValues, source: formValues['source']?.value }
            setFormValues(newData)
			data = newData;
        }
        const handleSuccess = (body: any) => {
			// window.location.reload(); 
			let newValues: any = {}
			Object.keys(formValues).forEach(key => {
				if(data[key]) {
					newValues[key] = data[key];
				}
				else {
					newValues[key] ="";
				}
			  });
			  setFormValues(newValues);
			  setRegistered(true)
            const message = () => toast(`Entry Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
			setdisabled(false)
        }
        const handleError = () => {

        }
        dispatch(studentExternalApplication(tenantId, data, handleSuccess ,handleError))
    }
	
	const allMandatoryFieldsFilled = () => {
		for (const field of filterFields) {
			if (
				field &&
				field.validate &&
				field.validate.required &&
				!formValues[field.key]
			) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			SetFieldValues(data);
		}
	}, [data]);

	const formdisabled = Object.keys(formerror).length === 0;
	return (
		<div>
			{
				registered ?
				<FormSuccess />
			:

			<Form action="" className="d-flex flex-column justify-content-center h-100">
				<div className="live-preview hstack flex-wrap">
					{filterFields && filterFields.length
						? filterFields.map((field: any) => {
							if(field.hidden) return
							let statusoptions = null; 
							if(field?.process_flows && field.key == 'student_status'){
								const { statuses, status } = getAvailableStatuses(field?.process_flows, formValues[field.key], '', true);
								statusoptions= statuses
							}
							return (
								<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
									<FormBuilder
										placeholder={field.placeholder}
										name={field.key}
										onChange={(e: any) => onChange(e, field.key, field)}
										options={statusoptions || field.values && field.values.reverse() || []}
										label={field.label}
										type={field.type}
										value={formValues[field.key] ? formValues[field.key] : ""}
										defaultValue={formValues[field.key] ? formValues[field.key] : ""}
										resetValue={fieldValues[field.key]}
										isEditState={true}
										actionButtons={isUpdate}
										Editable={true}
										rest={field}
										invalid={true}
										formValues={formValues}
										dataFields={filterFields}
										setdisabled={setdisabled}
										setError={setError}
										formerror={formerror}
										// isTextract={true}
									/>
								</Col>
							);
						})
						: null}
				</div>
				<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
					<Button
						className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
						disabled={isUpdate ? false : !allMandatoryFieldsFilled() || disabled || !formdisabled}
						onClick={() => btnAction(formValues)}
					>
						{btn_text}
					</Button>
				</div>
			</Form>
			}
		</div>
	);

};

export default DTSFullForm;
