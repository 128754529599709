import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Configure from "./Configuration/Configure";
import Filters from "./Filter/Filters";

interface DataPanelProps {
    dataSources: any;
    chart: string;
    columns: any;
    filters: any;
    groupBy: any;
    value: any;
    compareBy: any;
    xAxis: any;
    yAxis: any;
    fields: any;
    setChart: (chart: string) => void
    moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onColumnUpdated: (newfilter: any) => void
    onColumnDelete: (deleteColumn: any) => void
    onFilterUpdated: (newfilter: any, condition: any) => void
    onFilterDelete: (deleteFilter: any, condition: any) => void
    setFilterCondition: (filterCondition: string) => void
    onModifyGroupBy: (property: any, dataSource: any) => void;
    onModifyValue: (property: any, dataSource: any) => void;
    onModifyCompareBy: (property: any, dataSource: any) => void;
    setCompareBy: (compareBy: any) => void;
    onModifyXAxis: (property: any, dataSource: any) => void;
    onModifyYAxis: (property: any, dataSource: any) => void;
    setXAxis: (xAxis: any) => void;
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const ControlPanel: React.FC<DataPanelProps> = ({
    t,
    dataSources,
    chart,
    columns,
    filters,
    groupBy,
    value,
    compareBy,
    xAxis,
    yAxis,
    fields,
    setChart,
    moveColumns,
    onFilterUpdated,
    onFilterDelete,
    setFilterCondition,
    onColumnUpdated,
    onColumnDelete,
    onModifyGroupBy,
    onModifyValue,
    onModifyCompareBy,
    setCompareBy,
    onModifyXAxis,
    onModifyYAxis,
    setXAxis,
    moveFields,
    onFieldUpdated,
    onFieldDelete
}) => {
    const [selectedTab, setSelectedTab] = useState("1")
    return (
        <div className="d-flex flex-column border-primary-subtle border-end">
            <div className="d-flex h-100  flex-column" style={{width: "300px"}}>
                <Nav tabs>
                    <NavItem className="w-50">
                        <NavLink
                            className={selectedTab === "1" ? "active cursor-pointer border-start-0" : "cursor-pointer"}
                            onClick={() => setSelectedTab("1")}
                        >
                            {t("reports.data_bar.configure")}
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50">
                        <NavLink
                            className={selectedTab === "2" ? "active cursor-pointer border-end-0" : "cursor-pointer"}
                            onClick={() => setSelectedTab("2")}
                        >
                            {t("reports.data_bar.filters")}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={selectedTab} className="overflow-y-scroll">
                    <TabPane tabId="1" className="h-100">
                        {
                            dataSources?.length ? <Configure
                                chart={chart}
                                columns={columns}
                                groupBy={groupBy}
                                value={value}
                                compareBy={compareBy}
                                xAxis={xAxis}
                                yAxis={yAxis}
                                fields={fields}
                                setChart={setChart}
                                moveColumns={moveColumns}
                                onColumnUpdated={onColumnUpdated}
                                onColumnDelete={onColumnDelete}
                                onModifyGroupBy={onModifyGroupBy}
                                onModifyValue={onModifyValue}
                                onModifyCompareBy={onModifyCompareBy}
                                setCompareBy={setCompareBy}
                                onModifyXAxis={onModifyXAxis}
                                onModifyYAxis={onModifyYAxis}
                                setXAxis={setXAxis}
                                moveFields={moveFields}
                                onFieldUpdated={onFieldUpdated}
                                onFieldDelete={onFieldDelete}
                            /> :
                            <div className="h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">{t('common.message.no_data_sources_selected')}</div>
                        }
                    </TabPane>
                    <TabPane tabId="2" className="h-100">
                        {
                            dataSources?.length ? <Filters
                                filters={filters}
                                onFilterUpdated={onFilterUpdated}
                                onFilterDelete={onFilterDelete}
                                setFilterCondition={setFilterCondition}
                            /> :
                            <div className="h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">{t('common.message.no_data_sources_selected')}</div>
                        }
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
};

export default withTranslation()(ControlPanel);
