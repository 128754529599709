import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalBody,  ModalHeader } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import googleicon from "assets/images/gmail.svg";
import outlook from "assets/images/outlook.svg";
import imap from "assets/images/imap.svg";
import Imap from "./Imap";
import GmailSync from "./GmailSync";

const SyncOptionModal = ({ t, modal, setModal }: any) => {
    const navigate = useNavigate();
    const [syncOption, setSyncOption] = useState<string | null>(null)
    const [isImapOpen, setIsImapOpen] = useState<boolean>(false)
    const [isGmail, setIsGmail] = useState<boolean>(false)
    const handleSyncOption = (syncOption: string) => {
        switch(syncOption) {
            case 'google':
                setSyncOption(syncOption);
                setIsGmail(true);
                break;
            case 'outlook':
                setSyncOption(syncOption);
                navigate('/user-preferences/connect/microsoft');
                break;
            default:
                setSyncOption(syncOption);
                setIsImapOpen(true);
                break;
        }
    }
    return (
        <>
            <Modal id="topmodal" isOpen={modal} modalClassName="zoomIn" centered>
                <ModalHeader className="pb-3" toggle={() => { setModal(false) }}>
                    <h5 className="modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_calendar")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-between gap-0 px-2">
                        <Button
                            color="primary"
                            outline={!(syncOption === 'google')}
                            onClick={() => handleSyncOption('google')}
                            className="w-25 align-items-center justify-content-center"
                        >
                                <img alt="Gmail" width="60" src={googleicon} />
                                <p className="text-nowrap text-truncate text-nowrap-ellipsis">{t("userpreferences.gmail")}</p>
                        </Button>
                        <Button
                            color="primary"
                            outline={!(syncOption === 'outlook')}
                            onClick={() => handleSyncOption("outlook")}
                            className="w-25 align-items-center justify-content-center"
                        >
                                <img alt="Outlook" width="60" src={outlook} />
                                <p className="text-nowrap text-truncate text-nowrap-ellipsis">{t("userpreferences.outlook")}</p>
                        </Button>
                        <Button
                            color="primary"
                            outline={!(syncOption === 'imap')}
                            onClick={() => handleSyncOption("imap")}
                            className="w-25 align-items-center justify-content-center"
                        >
                                <img alt="IMAP" width="60" src={imap} />
                                <p className="text-nowrap text-truncate text-nowrap-ellipsis">{t("userpreferences.other_accounts")}</p>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            <Imap
                isOpen={isImapOpen}
                setIsOpen={setIsImapOpen}
                setModal={setModal}
            />
            <GmailSync
                isOpen={isGmail}
                setIsOpen={setIsGmail}
                setModal={setModal}
            />
        </>
    )
}
export default withTranslation()(SyncOptionModal);