import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Form } from 'reactstrap';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

interface ForgotSuccessProps {
    onSubmit: (e: any) => void;
    inputs: any;
}

const ForgotSuccess: React.FC<ForgotSuccessProps> = ({ onSubmit, inputs }) => {
    document.title = "Create New Password | Zilter";
    
    return (
        <div className="auth-page-content">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/#" className="d-inline-block auth-logo">
                                                    <img src={logoLight} alt="" height="50" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    <FeatherIcon icon="check-circle" className="icon-xxl icon-dual-success" />
                                    <h5 className="text-secondary fw-bold mb-2 mt-4">Reset link sent.</h5>
                                </div>
                                <div className="text-center mt-2">
                                    <h5 className="text-secondary fw-bold mb-2 mt-4">Please check your email.</h5>
                                </div>
                                <div className="text-center mt-2">
                                    <h6 className="text-dark fw-bold mb-2 mt-4">{inputs.email}</h6>
                                </div>
                                <div className="mb-2">
                                    <Form>
                                        <div className="mt-4 text-center hstack justify-content-center align-items-center">
                                            <span className='fw-medium fs-14'>Didn't receive the email?&nbsp;</span><span className='fw-bold text-info text-decoration-underline fs-14 cursor-pointer' onClick={onSubmit}>Send again</span>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(ForgotSuccess);