import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Select from "react-select";
import { Button, ButtonGroup, Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, UncontrolledDropdown } from "reactstrap";
import { Action } from "redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { ApplicationState } from "store";
import { dashboardsGetRequest, dashboardGetRequest } from "store/report/action";
import Report from "pages/Reports/Common/Config/Report";
import dashboardLoading from "common/data/dashboardLoading";
import BreadCrumb from "Components/Common/BreadCrumb";
import { withTranslation } from "react-i18next";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface OptionsProps {
    label: string;
    value: string;
}
const Dashboard = ({t}: any) => {
    const [searchParams] = useSearchParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.report.dashboards_loading);
    const dashboards = useSelector((state: ApplicationState) => state.report.dashboards);
    const dashboard_loading = useSelector((state: ApplicationState) => state.report.dashboard_loading);
    const dashboard = useSelector((state: ApplicationState) => state.report.dashboard);
    const navigate = useNavigate();

    const [filteredDashboards, setFilteredDashboards] = useState<OptionsProps[] | []>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<OptionsProps | null>(null);

    useEffect(() => {
        dispatch(dashboardsGetRequest())
    }, [])

    useEffect(() => {
        if(dashboards?.length) {
            const filteredDashboards = dashboards.map(dashboard => {
                return { label: dashboard.name, value: dashboard.id }
            })
            setFilteredDashboards(filteredDashboards);
            const selectedDashboard = filteredDashboards.find(dashboard => dashboard.value === searchParams.get('id')) || filteredDashboards[0];
            setSelectedDashboard(selectedDashboard);
            dispatch(dashboardGetRequest(selectedDashboard?.value));
        }
    }, [dashboards])

    const onSelectDashboard = (selectedDashboard: OptionsProps) => {
        setSelectedDashboard(selectedDashboard);
        dispatch(dashboardGetRequest(selectedDashboard?.value));
    }

    const onCreate =  () => {
        navigate('/dashboards/create');
    }
    const onManage =  () => {
        navigate(`/dashboards`);
    }
    const onEdit =  () => {
        navigate(`/dashboards/edit/${selectedDashboard?.value}`);
    }
    const onViewReport =  (id: string) => {
        navigate(`/reports/${id}`);
    }
    const onEditReport =  (id: string) => {
        navigate(`/reports/edit/${id}`);
    }
    
    document.title="DTS | Zilter - Dashboard";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title={t("dashboard.breadcrumb.dashboard")} pageTitle={t("dashboard.breadcrumb.dashboard")} />
                    <Row>
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex align-items-center justify-content-between  bg-white rounded p-3 shadow">
                                <div className="d-flex" style={{width: '240px'}}>
                                    <Select
                                        placeholder={t("common.filter.search.select_dashboard")}
                                        className="fs-16 fw-medium w-100"
                                        value={selectedDashboard}
                                        onChange={(option : any) => onSelectDashboard(option)}
                                        options={filteredDashboards}
                                        name="choices-single-default"
                                    ></Select>
                                </div>
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        className="btn-label btn-soft-primary"
                                        onClick={onManage}
                                    >
                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                        {t("dashboard.manage_dashboards")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="btn-label"
                                        onClick={onCreate}
                                    >
                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                            {t("dashboard.create_dashboard")}
                                    </Button>
                                </div>
                            </div>
                            {loading || dashboard_loading ?
                                <ResponsiveGridLayout
                                    className="layout"
                                    layouts={{ lg: dashboardLoading.map(r => r.layout) }}
                                    breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
                                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                    rowHeight={50}
                                    isDraggable={false}
                                    isResizable={false}
                                >
                                    {dashboardLoading.map(report => (
                                        <div key={report.key} className="d-flex flex-column gap-2 bg-white rounded shadow p-2">
                                            <Report showResult={true} config={null} />
                                        </div>
                                    ))}
                                </ResponsiveGridLayout> :
                                dashboards?.length ?
                                dashboard?.dashboard_reports?.length ? <div className="d-flex flex-column gap-4 w-100 min-h-100">
                                    <ResponsiveGridLayout
                                        className="layout"
                                        layouts={{ lg: dashboard?.dashboard_reports?.map((r: any) => r?.layout?.layout) }}
                                        breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
                                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                        rowHeight={50}
                                        isDraggable={false}
                                        isResizable={false}
                                        compactType="vertical"
                                    >
                                        {dashboard?.dashboard_reports?.map((report: any) => (
                                            <div key={report?.layout?.key} className="d-flex flex-column gap-2 bg-white rounded shadow p-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="text-primary">{report?.layout?.title}</h5>
                                                        <ButtonGroup>
                                                            <UncontrolledDropdown direction="start">
                                                                <DropdownToggle tag="button" className="btn btn-primary btn-icon btn-ghost-primary" onMouseDown={ e => e.stopPropagation() }>
                                                                    <i className="cursor-pointer ri-more-2-fill fs-4"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdownmenu-primary" onMouseDown={ e => e.stopPropagation() }>
                                                                    <DropdownItem onClick={() => onViewReport(report?.report?.id)}>{t("common.table.view")}</DropdownItem>
                                                                    <DropdownItem onClick={() => onEditReport(report?.report?.id)}>{t("common.table.edit")}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </ButtonGroup>
                                                </div>
                                                <Report showResult={true} config={report?.report?.config} />
                                            </div>
                                        ))}
                                    </ResponsiveGridLayout>
                                </div> : <Card className="w-100">
                                    <CardBody className="d-flex flex-column gap-2 align-items-center p-5">
                                            <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-3" style={{ height: '300px'}}>
                                                <Label>{t("dashboard.no_dashboard_message")}</Label>
                                                <div>
                                                    <Button
                                                        color="primary"
                                                        className="btn-label"
                                                        onClick={onEdit}
                                                    >
                                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                                            {t("dashboard.add_reports")}
                                                    </Button>
                                                </div>
                                            </div>
                                    </CardBody>
                                </Card> :
                                <Card className="w-100">
                                    <CardBody className="d-flex flex-column gap-2 align-items-center p-5">
                                            <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-3" style={{ height: '300px'}}>
                                                <Label>{t("dashboard.no_dashboard_message")}</Label>
                                                <div>
                                                    <Button
                                                        color="primary"
                                                        className="btn-label"
                                                        onClick={onCreate}
                                                    >
                                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                                            {t("dashboard.create_dashboard")}
                                                    </Button>
                                                </div>
                                            </div>
                                    </CardBody>
                                </Card>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Dashboard);


