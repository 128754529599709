import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Review from "./User/Review";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userGetRequest } from 'store/user/action';
import { permissionsGetRequest, roleGetRequest, roleNameListRequest } from 'store/role/action';
import ProfileCompleteness from "./User/ProfileCompleteness";
import CompanyProfile from "./Settings/CompanyProfile";
import ProfileMenus from "./User/innerComponents/ProfileMenus";
import { withTranslation } from "react-i18next";

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string;
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean;
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    features: Feature[];
}

interface MenuItem {
    name: string;
    items: SubItem[];
}

interface CreateInputs {
    role: string;
    access: MenuItem[];
}

const UserProfile = ({ t }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth?.userProfile);
    const roleDetails: any = useSelector((state: ApplicationState) => state.role.roleDetails);
    const [accessData, setAccessData] = useState<any>(null);
    const [verticalTab, setverticalTab] = useState<any>("1");
    const [customNav, setcustomNav] = useState<any>("1");
    const [openBordered, setOpenBordered] = useState<string[]>([]);
    const [createInputs, setCreateInputs] = useState<CreateInputs>({
        role: "",
        access: [],
    });

    const toggleBordered = (id: string) => {
        if (openBordered.includes(id)) {
            setOpenBordered(openBordered.filter(item => item !== id));
        } else {
            setOpenBordered([...openBordered, id]);
        }
    };

    const customNavtoggle = (tab: any) => {
        if (customNav !== tab) {
            setcustomNav(tab);
        }
    };

    useEffect(() => {
        dispatch(userGetRequest(id))
        dispatch(roleNameListRequest())
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [])

    useEffect(() => {
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [roleDetails, user])

    useEffect(() => {
        if (user && user.role) {
            dispatch(userGetRequest(id));
            dispatch(roleGetRequest(user.role.id))
            dispatch(permissionsGetRequest());
        }
    }, [user])

    const toggleVertical = (tab: any) => {
        if (verticalTab !== tab) {
            setverticalTab(tab);
        }
    };

    return (
        <div className="">
            <Row className="mx-1 mb-3">
                <Col xxl={12} className="" style={{ padding: "1px" }}>
                    <Row>
                        <Col md={3} className="py-3">
                            <Nav pills className="flex-column" id="v-pills-tab">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleVertical("1");
                                        }}
                                        id="v-pills-home-tab"
                                    >
                                        {t("userpreferences.general")}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2");
                                        }}
                                        id="v-pills-profile-tab"
                                    >
                                        {t("userpreferences.access")}
                                    </NavLink>
                                </NavItem>
                                {
                                    user?.role?.title === 'Owner' ?
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    "mb-2": true,
                                                    active: verticalTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleVertical("3");
                                                }}
                                                id="v-pills-profile-tab"
                                            >
                                                {t("userpreferences.workspace_settings")}
                                            </NavLink>
                                        </NavItem>
                                        : null}
                            </Nav>
                        </Col>
                        <div className="border-round w-75">
                            <SimpleBar>
                                <TabContent
                                    activeTab={verticalTab}
                                    className="d-felx justify-content-center align-items-center text-muted ms-0 my-0"
                                    id="v-pills-tabContent"
                                >
                                    <TabPane tabId="1" id="v-pills-home">
                                        <SimpleBar>
                                            <ProfileMenus />
                                        </SimpleBar>
                                    </TabPane>
                                    <TabPane tabId="2" id="v-pills-profile">
                                        <Card>
                                            <CardBody>
                                                {user && (user.role.title === 'Owner' || user.role.title === 'owner') ? (
                                                    <>
                                                        <h4 className="">{t("userpreferences.access")}</h4>
                                                        <p>{t("userpreferences.owner_has_all")}</p>
                                                    </>
                                                ) :
                                                    <>
                                                        <Review createInputs={createInputs}
                                                            accessData={accessData} />
                                                    </>
                                                }
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId="3" id="v-pills-profile">
                                        <Card>
                                            <CardBody>
                                                <div>
                                                    <h4 className="">{t("userpreferences.settings")}</h4>
                                                </div>
                                                <Nav pills className="nav-customs nav-danger mb-3">
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "1" })} onClick={() => { customNavtoggle("1"); }} >
                                                            {t("userpreferences.company_profile")}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "2" })} onClick={() => { customNavtoggle("2"); }} >
                                                            {t("userpreferences.profile_completeness")}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={customNav} className="text-muted">
                                                    <TabPane tabId="1" id="border-navs-home">
                                                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                                            <CompanyProfile />
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="2" id="border-navs-profile">
                                                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                                            <ProfileCompleteness />
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </SimpleBar>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default withTranslation()(UserProfile);