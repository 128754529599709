import React, { useState } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import config from "../../../../config"
const { DTS_API_STUDENT } = config.api;

interface FProps {
    studentId: any;
    onUpdate: (url: string) => void;
}

const Upload: React.FC<FProps> = ({onUpdate, studentId}) => {
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [file, setFile] = useState<File | null>(null)

    const SubmitUpload = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
        setFile(file)
        if (file) {
                setUploading('started');
                const formData = new FormData();
                formData.append('documentType', 'Avatar');
                formData.append('studentId', studentId);
                formData.append('file', file);
                setUploading('started');
                const config = {
                    onUploadProgress: function (progressEvent: any) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        setPercentage(percentCompleted)
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {                    
                    if(res.key) {
                        setPercentage(100)
                        setUploading('completed')
                        setSelectedFiles([])
                        onUpdate(res.key)
                    }
                   
                })
        }
    }
    
    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New selectedFiles={selectedFiles} setselectedFiles={setSelectedFiles} SubmitUpload={SubmitUpload} />
            break;
        case 'started':
            component = <Uploading file={file} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload