import React, { useEffect, useState, FC, ChangeEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
} from "reactstrap";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountAddRequest } from "store/user/action";
import { toast } from "react-toastify";
import { getGoogleCalendarData } from "store/calendarSync/action";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";

interface Option {
  label: string;
  value: string;
}

interface GroupedOption {
  options: Option[];
}

interface SyncOptionModalProps extends WithTranslation {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setModal: (model: boolean) => void;
}

const secureConnectionOptions: GroupedOption[] = [
  {
    options: [
      { label: "SSL/TLS (recommended)", value: "ssl" },
      { label: "StartTLS", value: "starttls" },
      { label: "None", value: "none" },
    ],
  },
];

const providerOptions: GroupedOption[] = [
  {
    options: [
      { label: "Google", value: "google" },
      { label: "Others", value: "other" },
    ],
  },
];

type ConnectionType = "ssl" | "starttls" | "none";

const SyncOptionModal: FC<SyncOptionModalProps> = ({ t, isOpen, setIsOpen, setModal }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  // Inline the route params type to satisfy the constraint
  const { id } = useParams<{ id: string }>();

  const defaultSecurityOption: Option = secureConnectionOptions[0].options[0];

  // Form state with proper types
  const [imapSecurityOption, setImapSecurityOption] = useState<Option>(defaultSecurityOption);
  const [smtpSecurityOption, setSmtpSecurityOption] = useState<Option>(defaultSecurityOption);
  const [selectedProvider, setSelectedProvider] = useState<Option | null>(null);
  const [email, setEmail] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [imapServer, setImapServer] = useState<string>("");
  const [smtpServer, setSmtpServer] = useState<string>("");
  const [imapPort, setImapPort] = useState<string>("");
  const [smtpPort, setSmtpPort] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [guideLink, setGuideLink] = useState<string>("");
  const [selectedImapProtocol, setSelectedImapProtocol] = useState<ConnectionType>("ssl");
  const [selectedSmtpProtocol, setSelectedSmtpProtocol] = useState<ConnectionType>("ssl");
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [imapSsl, setImapSsl] = useState<boolean>(true);
  const [imapStartTls, setImapStartTls] = useState<boolean>(true);
  const [smtpSsl, setSmtpSsl] = useState<boolean>(true);
  const [smtpStartTls, setSmtpStartTls] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const googleDomains = ["gmail.com", "googlemail.com", "google.com"];
  const microsoftDomains = ["outlook.com", "hotmail.com", "live.com", "microsoft.com", "msn.com"];

  useEffect(() => {
    if (selectedProvider) {
      if (selectedProvider.value === "google") {
        setGuideLink("https://support.google.com/mail/answer/185833?hl=en");
      } else if (selectedProvider.value === "outlook") {
        setGuideLink(
          "https://support.microsoft.com/en-us/account-billing/how-to-get-and-use-app-passwords-5896ed9b-4263-e681-128a-a6f2979a7944"
        );
      } else {
        setGuideLink("https://www.fastmail.help/hc/en-us/articles/360058752854-App-passwords");
      }
    } else {
      setGuideLink("https://www.fastmail.help/hc/en-us/articles/360058752854-App-passwords");
    }
  }, [selectedProvider]);

  useEffect(() => {
    const valid =
      selectedProvider !== null &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      imapServer.trim() !== "" &&
      imapPort.trim() !== "" &&
      smtpServer.trim() !== "" &&
      smtpPort.trim() !== "";
    setIsFormValid(valid);
  }, [selectedProvider, email, password, imapServer, imapPort, smtpServer, smtpPort]);

  const togglePopover = (): void => setPopoverOpen((prev) => !prev);

  const togglePasswordVisibility = (): void => setShowPassword((prev) => !prev);

  const handleModalToggle = (): void => {
    setIsOpen(!isOpen);
    // Reset fields when toggling modal
    setSelectedProvider(null);
    setEmail("");
    setPassword("");
  };

  const validateEmail = (value: string): void => {
    setEmail(value);
    if (!value) {
      setDomain("");
      return;
    }
    const emailDomain = value.split("@")[1];
    if (!/\S+@\S+\.\S+/.test(value)) {
      setDomain("invalid");
    } else if (googleDomains.includes(emailDomain)) {
      setDomain("google");
    } else if (microsoftDomains.includes(emailDomain)) {
      setDomain("microsoft");
    } else {
      setDomain("other");
    }
  };

  const handleProviderSelect = (option: Option): void => {
    if (option) {
      setSelectedProvider(option);
      if (option.value === "google") {
        setImapServer("imap.gmail.com");
        setSmtpServer("smtp.gmail.com");
        setImapPort("993");
        setSmtpPort("465");
      } else if (option.value === "outlook") {
        setImapServer("outlook.office365.com");
        setSmtpServer("smtp.office365.com");
        setImapPort("993");
        setSmtpPort("587");
      } else if (option.value === "other") {
        setImapServer("");
        setSmtpServer("");
        setImapPort("");
        setSmtpPort("");
      }
    }
  };

  const handleConnectionType = (
    protocol: "imap" | "smtp",
    option: Option
  ): void => {
    if (!option) return;
    if (protocol === "imap") {
      setSelectedImapProtocol(option.value as ConnectionType);
      if (option.value === "ssl") {
        setImapSsl(true);
        setImapStartTls(false);
      } else if (option.value === "starttls") {
        setImapSsl(false);
        setImapStartTls(true);
      } else {
        setImapSsl(false);
        setImapStartTls(false);
      }
      setImapSecurityOption(option);
    } else if (protocol === "smtp") {
      setSelectedSmtpProtocol(option.value as ConnectionType);
      if (option.value === "ssl") {
        setSmtpSsl(true);
        setSmtpStartTls(false);
      } else if (option.value === "starttls") {
        setSmtpSsl(false);
        setSmtpStartTls(true);
      } else {
        setSmtpSsl(false);
        setSmtpStartTls(false);
      }
      setSmtpSecurityOption(option);
    }
  };

  const handleAddAccount = (): void => {
    const selectedInfo = {
      mail_settings: {
        provider: selectedProvider?.value || null,
        credentials: {
          username: email,
          password: password,
        },
        mail: {
          smtp: {
            host: smtpServer,
            port: smtpPort,
            auth: true,
            ...(selectedSmtpProtocol === "ssl" && { ssl: { enable: smtpSsl } }),
            ...(selectedSmtpProtocol === "starttls" && { starttls: { enable: smtpStartTls } }),
          },
          imap: {
            host: imapServer,
            port: imapPort,
            ...(selectedImapProtocol === "ssl" && { ssl: { enable: imapSsl } }),
            ...(selectedImapProtocol === "starttls" && { starttls: { enable: imapStartTls } }),
          },
        },
      },
    };

    const data = {
      attributes: [
        {
          key: "valuesJson",
          value: JSON.stringify(selectedInfo),
        },
      ],
    };

    const handleSuccess = (): void => {
      dispatch(getGoogleCalendarData());
      setIsOpen(false);
      setSelectedProvider(null);
      setEmail("");
      setPassword("");
      setModal(false);
    };

    const handleError = (body: any): void => {
      toast(`${body.status}`, {
        position: "top-center",
        hideProgressBar: true,
        className: "bg-danger text-white",
      });
    };

    dispatch(accountAddRequest(id, data, handleSuccess, handleError));
  };

  return (
    <Modal id="topmodal" isOpen={isOpen} modalClassName="zoomIn" centered>
      <ModalHeader className="pb-3" toggle={handleModalToggle}>
        <h5 className="modal-title">{t("userpreferences.add_other_account")}</h5>
      </ModalHeader>
      <ModalBody>
        <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
          <div className="p-1">
            <div>
              <Label className="mb-0 fw-bold">
                {t("userpreferences.credentials")}
              </Label>
            </div>
            <div className="mt-3">
              <Label className="fw-medium">
                {t("userpreferences.provider")} <span className="text-danger">*</span>
              </Label>
              <div className="form-icon right">
                <Select
                  value={selectedProvider}
                  onChange={handleProviderSelect}
                  options={providerOptions}
                  className="js-example-basic-single mb-0"
                  name="provider"
                />
              </div>
              <div>
                <Label className="mt-3 fw-medium">
                  {t("userpreferences.email")} <span className="text-danger">*</span>
                </Label>
                <div className="form-icon right">
                  <Input
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => validateEmail(e.target.value)}
                    type="email"
                    className="form-control form-control-icon"
                    placeholder={t("userpreferences.enter_email")}
                  />
                  <i className="ri-mail-unread-line"></i>
                </div>
                {domain === "invalid" && (
                  <Label className="text-danger">
                    {t("userpreferences.invalid_email_address")}
                  </Label>
                )}
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-start gap-2">
                  <div className="d-flex">
                    <Label htmlFor="passwordInput" className="form-label">
                      {t("userpreferences.password")}
                    </Label>
                    <div className="text-danger">*</div>
                  </div>
                  <div
                    id="infoPopover"
                    onClick={togglePopover}
                    style={{ display: "inline-block", cursor: "pointer" }}
                  >
                    {popoverOpen ? (
                      <i className="text-info ri-close-circle-line"></i>
                    ) : (
                      <i className="text-info ri-information-line"></i>
                    )}
                  </div>
                  <Popover isOpen={popoverOpen} target="infoPopover" placement="top">
                    <PopoverBody>
                      <div>
                        {t("userpreferences.imap_pop_info_message")}&nbsp;
                        <strong>
                          <a
                            style={{ color: "blue" }}
                            href={guideLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("userpreferences.click_here")}
                          </a>
                        </strong>
                        &nbsp;{t("userpreferences.for_guidelines")}
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
                <div className="form-icon right">
                  <Input
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-icon"
                    placeholder="**********"
                  />
                  <i
                    className={`ri-eye${showPassword ? "-off" : ""}-line`}
                    style={{ cursor: "pointer" }}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <Label className="mb-0 fw-bold">
                  {t("userpreferences.imap_configuration")}
                </Label>
              </div>
              <div className="mt-3 d-flex justify-content-between gap-1">
                <div style={{ width: "70%" }}>
                  <Label>
                    IMAP Incoming server <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon right">
                    <Input
                      value={imapServer}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setImapServer(e.target.value)}
                      type="text"
                      className="form-control form-control-icon"
                      placeholder={t("userpreferences.enter_hostname")}
                    />
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <Label>
                    {t("userpreferences.port")} <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon right">
                    <Input
                      value={imapPort}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setImapPort(e.target.value)}
                      type="text"
                      className="form-control form-control-icon"
                      placeholder={t("userpreferences.port")}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Label>{t("userpreferences.secure_connection_type")}</Label>
                <div className="form-icon right">
                  <Select
                    value={imapSecurityOption}
                    onChange={(option: Option) => handleConnectionType("imap", option)}
                    options={secureConnectionOptions}
                    className="js-example-basic-single mb-0"
                    name="imapSecurity"
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <Label className="mb-0 fw-bold">
                  {t("userpreferences.smtp_configuration")}
                </Label>
              </div>
              <div className="mt-3 d-flex justify-content-between gap-1">
                <div style={{ width: "70%" }}>
                  <Label>
                    {t("userpreferences.smtp_outgoing_server")} <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon right">
                    <Input
                      value={smtpServer}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setSmtpServer(e.target.value)}
                      type="text"
                      className="form-control form-control-icon"
                      placeholder={t("userpreferences.enter_hostname")}
                    />
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <Label>
                    {t("userpreferences.port")} <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon right">
                    <Input
                      value={smtpPort}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setSmtpPort(e.target.value)}
                      type="text"
                      className="form-control form-control-icon"
                      placeholder={t("userpreferences.port")}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Label>{t("userpreferences.secure_connection_type")}</Label>
                <div className="form-icon right">
                  <Select
                    value={smtpSecurityOption}
                    onChange={(option: Option) => handleConnectionType("smtp", option)}
                    options={secureConnectionOptions}
                    className="js-example-basic-single mb-0"
                    name="smtpSecurity"
                  />
                </div>
              </div>
            </div>
          </div>
        </SimpleBar>
      </ModalBody>
      <ModalFooter>
        <div className="mb-0 mt-3 align-items-center">
          <Button
            color="primary"
            className="btn btn-primary"
            onClick={handleAddAccount}
            disabled={!isFormValid || domain === "invalid"}
          >
            {t("userpreferences.add_account")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(SyncOptionModal);
