import { useState } from "react";
import {
    Card
} from "reactstrap";
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";
import { withTranslation } from "react-i18next";

const PreviewComponent = ({ EditInputs, fieldType, fieldJson, t }: any) => {
    const [value, setvalue] = useState<any>('Sample text')
    return (
        <div id="myDivparent">
           <div className="fs-16 fw-semibold">{t("common.offcanvas.preview")}</div>
            <Card className="m-3" id="myDivChild">
                <FormBuilder
                    label={EditInputs.label}
                    type={fieldType}
                    value={value}
                    placeholder={EditInputs.placeholder}
                    className="w-100 h-100 text-start"
                    onChange={(value: any) => {
                        setvalue(value)
                    }}
                    actionButtons={true}
                    defaultValue={t("common.offcanvas.sample_text")}
                    Editable={true}
                    rest={fieldJson}
                />
            </Card>
        </div>
    );
};

export default withTranslation()(PreviewComponent);
