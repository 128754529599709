import placeholderLogo from '../../../assets/images/dtsimg/logo/logo-placeholder.jpg';

const Logo = (props:any) => {
    const { tenant, } = props;
    return (
        <div className='hstack justify-content-center'>
            <div className='logo-container'>
                {
                    tenant?.logo ? 
                    <img src={tenant?.logo} alt={''}/>
                    :
                    <img src={placeholderLogo} alt={''}/>
                }
            </div>
        </div>
    );
};

export default Logo;