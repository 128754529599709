import {Reducer} from "redux";
import {RecommendActionTypes, RecommendState} from "./types";
export const initialState: RecommendState = {    
    errors: undefined,
    loading: false,
    details: {},
    list:[],
    pagination: null,
    formDetails: {}
};

const reducer: Reducer<RecommendState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case RecommendActionTypes.POST_CONFIRMED_COURSES_REQUEST: {
            return {...state, loading: true};
        }
        case RecommendActionTypes.POST_CONFIRMED_COURSES_SUCCESS: {
            return {...state, loading: false};
        }
        case RecommendActionTypes.POST_CONFIRMED_COURSES_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }  
        case RecommendActionTypes.POST_RECOMMEND_COURSES_REQUEST: {
            return {...state, loading: true};
        }
        case RecommendActionTypes.POST_RECOMMEND_COURSES_SUCCESS: {
            return {...state, loading: false};
        }
        case RecommendActionTypes.POST_RECOMMEND_COURSES_ERROR: {
            return {...state, loading: false, errors: action.payload};
        } 
         
        case RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_REQUEST: {
            return {...state, loading: true};
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_SUCCESS: {
            return {...state, loading: false, details: action.payload};
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        } 
        case RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_REQUEST: {
            return {...state, loading: true};
        }
        case RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_SUCCESS: {
            return {...state, loading: false, formDetails: action.payload};
        }
        case RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }  
        
        case RecommendActionTypes.GET_RECOMMENDATIONS_List_REQUEST: {
            return {...state, loading: true};
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_List_SUCCESS: {
            return { ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }};
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_List_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        
        case RecommendActionTypes.GET_RECOMMENDATIONS_REQUEST: {
            return { ...state, loading: true, list:[]  };
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case RecommendActionTypes.GET_RECOMMENDATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list:[]  };
        }               
        case RecommendActionTypes.PUT_RECOMMENDATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case RecommendActionTypes.PUT_RECOMMENDATIONS_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case RecommendActionTypes.PUT_RECOMMENDATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export {reducer as RecommendReducer};
