export interface ViewsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Views {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum ViewsActionTypes {
  GET_VIEWMETADATA_REQUEST = "@@views/GET_VIEWMETADATA_REQUEST",
  GET_VIEWMETADATA_SUCCESS = "@@views/GET_VIEWMETADATA_SUCCESS",
  GET_VIEWMETADATA_ERROR = "@@views/GET_VIEWMETADATA_ERROR",
  GET_VIEW_REQUEST = "@@views/GET_VIEW_REQUEST",
  GET_VIEW_SUCCESS = "@@views/GET_VIEW_SUCCESS",
  GET_VIEW_ERROR = "@@views/GET_VIEW_ERROR",
  GET_VIEWS_REQUEST = "@@views/GET_VIEWS_REQUEST",
  GET_VIEWS_SUCCESS = "@@views/GET_VIEWS_SUCCESS",
  GET_VIEWS_ERROR = "@@views/GET_VIEWS_ERROR",
  PUT_VIEW_REQUEST = "@@views/PUT_VIEW_REQUEST",
  PUT_VIEW_SUCCESS = "@@views/PUT_VIEW_SUCCESS",
  PUT_VIEW_ERROR = "@@views/PUT_VIEW_ERROR",
  DELETE_VIEW_REQUEST = "@@views/DELETE_VIEW_REQUEST",
  DELETE_VIEW_SUCCESS = "@@views/DELETE_VIEW_SUCCESS",
  DELETE_VIEW_ERROR = "@@views/DELETE_VIEW_ERROR",
  POST_VIEW_REQUEST = "@@views/POST_VIEW_REQUEST",
  POST_VIEW_SUCCESS = "@@views/POST_VIEW_SUCCESS",
  POST_VIEW_ERROR = "@@views/POST_VIEW_ERROR",  
  POST_FILTER_SUCCESS = "@@view/POST_FILTER_SUCCESS", 
  GET_FILTER_REQUEST = "@@view/GET_FILTER_REQUEST",
  GET_FILTER_SUCCESS = "@@view/GET_FILTER_SUCCESS",
  POST_STORE_VIEW_SUCCESS = "@@view/POST_STORE_VIEW_SUCCESS",
  GET_STORE_VIEW_REQUEST = "@@view/GET_STORE_VIEW_REQUEST",
  GET_STORE_VIEW_SUCCESS = "@@view/GET_STORE_VIEW_SUCCESS",
}

export interface ViewsState {
  readonly list: Views[] | null;
  readonly details: Views | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: ViewsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
  readonly storeFilters: any;
  readonly storeView: any;
}
