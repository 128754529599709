import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './optiontablecomponent';
import dataFields from './tablefields.json';
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";
import LoadOptions from './LoadOptions';
import { withTranslation } from 'react-i18next';

const DropdownSelectPreview = ({createInputs, setCreateInputs, setFieldJson, fieldJson, fieldType} : any) => {
    const [columns, setColumns] = useState<any>([])
    const [counter, setCounter] = useState<any>(1)
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [inFormCheckedRows, setInFormCheckedRows] = useState<any>({});
    const [actionoptions, setactionoptions] = useState<any>([])
    const [selectedsortOption, setselectedsortOption] = useState<any>({label: 'Custom', value: 'custom'})
    const [isLoadOptions, setisLoadOptions] = useState(false)
    const [rows, setRows] = useState<any>([
        {
            "id":1,
            "label": "",
            "value": "",
            "in_forms": true
        }
    ])
    const [pageSize, setPageSize] = useState<number>(30)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [Selectedcount, setselectedcount] = useState<number>(0)
    const [informTrue, setInformTrue] = useState<number>(0)

    const handleToggleInForms = (value: any) => { 
        let count = 0;
        if(value) {               
            setRows((prevState: any) =>
                prevState.map((item: any) => {
                    count = count+1;
                    return { ...item, in_forms: true }
                })
            );
            setInformTrue(count)
        }
        else {             
            setRows((prevState: any) =>
                prevState.map((item: any) => {
                    return { ...item, in_forms: false }
                })
            );
            setInformTrue(count)
            setInformTrue(0)
        }  
      };

      const handleToggleCheckbox = (value: any) => {   
        let newCheckedrows = checkedRows;   
        if(value) {            
            rows.map((row: any) => {
                newCheckedrows = {...newCheckedrows, [`${row?.id}`]: value }
            });
            setactionoptions(rows)
            setCheckedRows(newCheckedrows);
        }
        else {
            setactionoptions([])
            setCheckedRows({});
        }  
      };

    useEffect(() => {
        const columns: any = dataFields && dataFields.fields ? dataFields.fields.map((fields,index) =>{     
            if(fields.name == 'id') return {
                    Header: _.startCase(fields.name),
                    accessor: fields.name,
                    filterable: true,
                    isSortable:false,
                    disableFilters: true,
                    show: false,
            }
            else if(fields.type == 'text')  return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {                 
                    return <Input placeholder={fields.placeholder} value={cellProps.row.original[fields.name]} name={fields.name} onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.value)} />
                  }
            }     
            else if(fields.type == 'radio') return {
                Header: () => {
                    return (
                      <div>
                        <span>{_.startCase(fields.name)}</span>
                        <div className="form-check form-switch form-check-right align-items-center d-flex mt-1">
                            <Input className="form-check-input" name={fields.name} type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" defaultChecked={true} onChange={(e: any) => handleToggleInForms(e?.target?.checked)} />
                        </div>     
                      </div>
                    );
                  },
                accessor: fields.name,
                filterable: true,
                isSortable:true,
                disableFilters: true,
                show: true,
                className: 'text-center',
                Cell: (cellProps: any) => {
                    return <div className="form-check form-switch form-check-right align-items-center d-flex mt-1">
                    <Input className="form-check-input" name={fields.name} type="checkbox" role="switch" defaultChecked={cellProps.row.original.in_forms} checked={cellProps.row.original.in_forms} id="flexSwitchCheckRightDisabled" onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.checked)}/>
                </div>                  
                }
            }
        }) : []
        setColumns(columns)
    }, [dataFields]); 


    const handleCellValueChange = (cellprops: any, field: any, value: any) => {
        if(field == "label") {
            setRows((prevState: any) =>
            prevState.map((item: any) =>
                item.id === cellprops.id ? { ...item, [field]: value, value: value.toLowerCase().replace(/ /g, "_")} : item
            )
        );
        } 
        else {
            setRows((prevState: any) =>
                prevState.map((item: any) =>
                    item.id === cellprops.id ? { ...item, [field]: value} : item
                )
            );            
        } 
        
    };
    
     
    const addoption = () => {
        setCounter(counter + 1)
        let updatedrows = [...rows]
        const row = {
            "id":counter + 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        updatedrows.push(row)
        setRows(updatedrows);
    }

    const deleteAllOption = () => {
        setCounter(1)
        const row = {
            "id": 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        setRows([row]);
    }

    const deleteOption = () => {
        let newrows = rows;
        actionoptions && actionoptions.map((object: any) => {
            newrows = newrows.filter((item: any) => item.id !== object.id)  
        })
        setRows(newrows)
        setCheckedRows([])   
        setactionoptions([])            
    }

    
    const handleselect = (obj: any, value: boolean) => {
        if(value) {
            let newList = actionoptions;
            newList.push(obj)           
            setactionoptions(newList)            
            handleCheckboxChange(obj.id, true)
        }
        else {
            let newList = actionoptions.filter((item: any) => item.id !== obj.id);       
            setactionoptions(newList)            
            handleCheckboxChange(obj.id, false)
        }
    }

    
    const handleCheckboxChange = (id: any, value: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: value }));
    };


    useEffect(() => {
        let options= rows.sort((a: any, b: any) => a.id - b.id);
        setRows(options);
        setCreateInputs({...createInputs, options: options})
         let newfield = {...fieldJson, values: options}
        setFieldJson(newfield)  
        setInformTrue(rows.filter((item: any) => item?.in_forms)?.length)
    }, [rows]); 

    const onChanged = (value: any) => {
        setCreateInputs({ ...createInputs, isMulti: value.checked})
        let newfield = {...fieldJson, isMulti:  value.checked}
       setFieldJson(newfield)  
    }
    document.title="DTS | Zilter";

    const onLoad = (options: any) => {
        setRows(options)
        setCounter(options?.length)
    }

    return (
        <React.Fragment>
            {isLoadOptions && <LoadOptions 
                show={isLoadOptions}
                onCloseClick={() => setisLoadOptions(false)}
                onLoad={onLoad}
            />}
            <Row>
                <div className="hstack align-content-center gap-1 mb-1">
                    <Input className="mb-1 form-check-input ms-4" type="checkbox" checked={createInputs?.isMulti} name="isMulti" onClick={(e) => onChanged(e.target)}/>
                    <Label className="fs-16 mb-0 fw-medium  ms-5">Allow Multi Select</Label>
                </div>
            </Row>
            <Row className='mt-1'>
                <Col>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0">
                            <div  className={"students-table"}>
                                <TableContainer
                                    columns={columns}
                                    data={rows.sort((a: any, b: any) => a.id - b.id) || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    isPageSizeChange={false}
                                    isBordered={true}
                                    setIsMultiDeleteButton={setIsMultiDeleteButton}
                                    setselectedcount={setselectedcount}   
                                    handleselect={handleselect}
                                    checkedRows={checkedRows}
                                    handleToggleCheckbox={handleToggleCheckbox}
                                 
                                    
                                />
                            </div>
                            <div className="hstack gap-3 px-3">
                                <Link
                                    to="#"
                                    className="fw-bold text-info w-auto"
                                    onClick={() => addoption()}
                                >
                                    <FeatherIcon icon="plus" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} />
                                    Add option                  
                                </Link>
                                <Link
                                    to="#"
                                    className="fw-bold text-info w-auto"
                                    onClick={() => setisLoadOptions(true)}
                                >
                                    <FeatherIcon icon="list" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} />
                                    Load Options                  
                                </Link>
                                <Link
                                    to="#"
                                    className="fw-bold text-info w-auto"
                                    onClick={() => deleteAllOption()}
                                >
                                    <FeatherIcon icon="trash-2" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} /> 
                                     Clear all                   
                                </Link>
                                {
                                actionoptions && actionoptions.length ?
                                    <Link
                                        to="#"
                                        className="fw-bold text-info w-auto"
                                        onClick={() => deleteOption()}
                                    >
                                        <FeatherIcon icon="trash-2" className="mb-1 fw-bold text-info display-2" style={{width:14, height: 14}} /> 
                                        Delete options                   
                                    </Link> 
                                : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div id="myDivparent">
                        <div  className="fs-16 fw-semibold">Preview</div>
                        <Card className="m-3 vstack" id="myDivChild">
                            <FormBuilder                             
                                placeholder={createInputs.placeholder}
                                onChange={(option : any) => setselectedsortOption(option)}
                                options={rows}
                                label={createInputs.label}
                                type={fieldType}
                                value={selectedsortOption}
                                className="w-100 h-100 text-start"
                            />
                        </Card>
                    </div>
                </Col>
            </Row>               
        </React.Fragment>
    );
};
export default withTranslation()(DropdownSelectPreview);
