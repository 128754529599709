import { Button, Modal, ModalBody, ModalHeader, } from "reactstrap";

const DeleteViewModal = ({ props, show, onCloseClick, record, onDelete }: any) => {
    return (record && record.id ?
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>{props.t("views.delete_view")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <i className="ri-delete-bin-5-line display-5 text-danger"></i>
                    <h4 className="text-nowrap text-truncate text-nowrap-ellipsis mb-2 mt-2">{record.name}</h4>
                    <p className="text-muted fs-15 mb-4">{props.t("properties.delete_disclaimer1")} <h6 className="text-nowrap text-truncate text-nowrap-ellipsis">{record.name}</h6> {props.t("properties.delete_disclaimer2")}</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger" onClick={() => onDelete(record.id)}>
                            {props.t("properties.delete")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("common.offcanvas.import_cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default DeleteViewModal;