import {CGRActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const cgrSendRequest: AppThunk = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { auth: { token } } = getState();
    dispatch({ type: CGRActionTypes.POST_CALL_REQUEST });
    try {
      const response = await fetch(
        `${DTS_API_STUDENT}/career-guidance/send`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          body: JSON.stringify(data)
        }
      );
           
      const error = {
        208: {
            "message": "Report already send",
            "success": false
        },
        201: {
            "message": "Report sent Successfully",
            "success": true
        },
        200: {
            "message": "Report sent Successfully",
            "success": true
        }
    }
    await responseHandler(response, dispatch, CGRActionTypes.POST_CALL_SUCCESS, CGRActionTypes.POST_CALL_ERROR, error, handleSuccess, handleError);

    } catch (error) {
      dispatch({ type: CGRActionTypes.POST_CALL_ERROR, payload: error });
      handleError();
    }
  };
};