import React from "react";
import { Card, CardBody, CardHeader } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Common/ChartsDynamicColor";


const TalktimeChart = ({ talktime, nontalktime }: any) => {
    const dataColors = '["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning", "--vz-info"]'
    var chartStackedBar100Colors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Agent",
            data: [talktime.AGENT.TotalTimeMillis],
        },
        {
            name: "Student",
            data: [talktime.AGENT.TotalTimeMillis],
        },
        {
            name: "Non Talktime",
            data: [nontalktime],
        }
    ];

    const options = {
        chart: {
            stacked: !0,
            stackType: "100%",
            toolbar: {
                show: !1,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        title: {
            text: "",
            style: {
                fontWeight: 600,
            },
        },
        xaxis: {
            categories: ['Talktime'],
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val + "K";
                },
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
        colors: chartStackedBar100Colors,
    };
    return (
        <React.Fragment>
            <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Talktime Session</h4>
                </CardHeader>
                <CardBody className="p-0">
                    <ReactApexChart dir="ltr"
                        className="apex-charts"
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default TalktimeChart;