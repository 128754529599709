import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Col,
  Row,
  Label,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import googleicon from "../../../assets/images/googleicon.png";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { userUpdateRequest } from "store/user/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { disconnectGoogleAccount, getGoogleCalendarData } from "store/calendarSync/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import CircleBadge from "pages/StudentsDetails/CircleBadge";
import { toast } from "react-toastify";
import ConfirmSyncModal from "../Accounts/GmailSync";
import SyncOptionModal from "../Accounts/SyncOptionModal";
import { userGetRequest } from "store/auth/action";
import { permissionsGetRequest } from "store/role/action";
import Upload from "../Upload/Upload";
import { useParams } from "react-router";
import UserAvatar from "Components/Common/UserAvtar/UserAvatar";

const Profile = ({ t, avtarImage2 }: any) => {
  const { id } = useParams();
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const user = useSelector((state: ApplicationState) => state.auth.userProfile);
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync?.data);
  const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmModalOutlook, setConfirmModalOutlook] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<any>(null);
  const [modal, setModal] = useState(false);
  const [modalPicture, setModalPicture] = useState(false)
  const [userData, setUserData] = useState<any>(user);
  const [modalFile, setModalFile] = useState<boolean>(false);
  const [picture, setPicture] = useState<string>("")
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [pictureLoading, setPictureLoading] = useState<boolean>(false);
  const [data, setData] = useState(0)
  const [fileInfo, setFileInfo] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({});
  const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      dispatch(userGetRequest());
      dispatch(permissionsGetRequest());
    }
  }, [user.profilePic]);

  useEffect(() => {
    dispatch(getGoogleCalendarData())
  }, [])

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleUpdateInfo = () => {
    const attributes = [];

    if (userData.name !== user.name) {
      attributes.push({
        key: "name",
        value: userData.name
      });
    }
    if (userData.email !== user.email) {
      attributes.push({
        key: "email",
        value: userData.email
      });
    }
    if (userData.phoneNumber !== user.phoneNumber) {
      attributes.push({
        key: "phone_number",
        value: userData.phoneNumber
      });
    }

    // Only dispatch if there are attributes to update
    if (attributes.length > 0) {
      const data = {
        attributes: attributes
      };
      const handleSuccess = (body: any): void => {
        // const message = () => toast(`User information updated successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
        // message();
        dispatch(userGetRequest(id))
      };
      const handleError = (body: any): void => {
        const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        message();
      };
      dispatch(userUpdateRequest(id, data, handleSuccess, handleError));
    }
  };

  const handleDeleteProfilePic = () => {
    const data = {
      "attributes": [
        {
          "key": "profile_pic",
          "value": ""
        }
      ]
    }
    const handleSuccess = (body: any): void => {
      setDeleteModal(false)
      // toast(`Profile image removed successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      dispatch(userGetRequest(id))
    };
    const handleError = (body: any): void => {
      const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      message();
    };
    dispatch(userUpdateRequest(id, data, handleSuccess, handleError));
  };

  const handleGoogleAccountDisconnect = (email: any) => {
    const handleSuccess = (body: any): void => {
      dispatch(getGoogleCalendarData());
    };
    const handleError = (body: any): void => {
      const message = () =>
        toast(`${body.status}`, {
          position: "top-center",
          hideProgressBar: true,
          className: "bg-danger text-white",
        });
      message();
    };
    dispatch(disconnectGoogleAccount(email, handleSuccess, handleError));
  };

  useEffect(() => {
    if (googleResponse) setPicture(googleResponse[0]?.socialDataDTO?.userProfile?.picture)
  }, [googleResponse])

  const fileToggle = useCallback(() => {
    if (modalFile) {
      setModalFile(false);
    } else {
      setModalFile(true);
    }
  }, [modalFile]);

  const handleFileClicks = () => {
    setModalFile(!modalFile);
    fileToggle();
  };

  const toggle = () => setModal(!modal);

  const handleClick = (event: any) => {
    setTarget(event.target);
    setShow(!show);
    setModalPicture(!modalPicture);
  };

  return (
    <React.Fragment>
      <Modal backdrop="static" isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <i className="ri-delete-bin-line display-5 text-danger"></i>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>{t("common.modals.are_you_sure")}</h4>
              <p className="text-muted mx-4 mb-0">
                {t("users.are_you_sure_remove_profile_image")}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              {t("common.action_button.close")}
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => handleDeleteProfilePic()}
            >
              {t("common.modals.yes_remove")}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <ConfirmSyncModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
      // props={props}
      />
      <SyncOptionModal
        modal={modal}
        setModal={setModal}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        setConfirmModalOutlook={setConfirmModalOutlook}
      // props={props}
      />
      <Modal backdrop="static" id="createFileModal" isOpen={modalFile} toggle={() => { fileToggle(); setselectedFiles([]); }} modalClassName="zoomIn" centered tabIndex={1}>
        <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]); }} className="p-3 bg-primary-subtle">{t("userpreferences.upload_file")}</ModalHeader>
        <ModalBody>
          <Upload setPictureLoading={setPictureLoading} setData={setData} setDataPreviewModal={setDataPreviewModal} filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} fileInfo={fileInfo} setFileInfo={setFileInfo} />
        </ModalBody>
      </Modal>

      <div className="hstack mt-0 mb-2">
        <Row className="p-0 w-100 hstack gap-3 justify-items-center">
          {/* <Col lg={12} className="vstack justify-content-between"> */}
          <div className="vstack gap -1 justify-content-center">
            <p>{t("userpreferences.this_applies_across_zilter")}</p>
          </div>
          <div className="vstack gap-3 align-items-left">
            <Card className="mb-1 p-3 shadow-lg">
              <Label className="fw-bold fs-14">{t("userpreferences.profile_image")}</Label>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex position-relative gap-3">
                  <div>
                    <div className="profile-user position-relative d-inline-block mx-auto">
                      {user.profilePic ? (
                        <img
                          src={user?.profilePic}
                          className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                          alt="user-profile"
                          onClick={handleClick}
                          ref={setTarget}
                          style={{ cursor: 'pointer' }}
                        />
                      ) :
                        <UserAvatar className="rounded-circle avatar-lg img-thumbnail user-profile-image" img={user.profilePic} firstName={user.name} />
                      }
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xxs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i onClick={handleFileClicks} className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 text-muted">
                    <Label className="fs-16">{user?.name}</Label>
                    <div className="vstack gap-1 align-self-center flex-grow-1">
                      <small className="fs-14">{user?.email}</small>
                    </div>
                  </div>
                </div>
                {user.profilePic !== "" || user.profilePic !== null ? (
                  <div>
                    <Button
                      size="sm"
                      color="danger"
                      className="btn-danger"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="ri-delete-bin-2-line label-icon align-middle fs-16 me-2"></i>
                      {t("userpreferences.remove_profile_image")}
                    </Button>
                  </div>
                ) : null}
              </div>
            </Card>
            <Card className="p-3 mb-1 shadow-lg">
              <Row>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <FormBuilder
                    label={"Name"}
                    type={"textfield"}
                    value={userData?.name}
                    name={"name"}
                    placeholder={`Enter name`}
                    className="flex-grow-1 me-3 mb-3"
                    onChange={(value: any) => {

                      // setvalue(value)
                      setUserData({ ...userData, name: value })
                    }}
                    Editable={true}
                  />
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <FormBuilder
                    disabled
                    label={"Email"}
                    type={"textfield"}
                    value={userData?.email}
                    name={"last_name"}
                    placeholder={`Enter last name`}
                    className="flex-grow-1 me-3 mb-3"
                    Editable={false}
                  />
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6}>
                  <p>
                    <FormBuilder
                      disabled={!(user?.role?.title === 'Owner')}
                      label={"Main Phone"}
                      type={"textfield"}
                      defaultValue={userData?.phoneNumber}
                      onChange={(value: any) => {
                        setUserData({ ...userData, phoneNumber: value })
                      }}
                      value={userData?.phoneNumber}
                      name={"phone_number"}
                      placeholder={`Enter Main number`}
                      className="flex-grow-1 me-3 mb-3"
                      Editable={false}
                    />
                    <small className="text-muted">{t("userpreferences.this_phone_number_used_for_contact")}</small>
                  </p>
                </Col>
              </Row>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="w-50">
                  <Button className='align-self-end cursor-pointer' color="primary" onClick={handleUpdateInfo}>{t("userpreferences.update_info")}</Button>
                </div>
              </div>
            </Card>
            <Card className="p-3 shadow-lg">
              <div>
                {googleConnect?.length === 0 ? (
                  <div>

                    <div className="mt-3">
                      <div>
                        <Label className="fw-bold fs-14">{t("userpreferences.connected_accounts")}</Label>
                        <UncontrolledAlert color="danger" className="mb-xl-3">
                          <strong>
                            {t(
                              "profile.your_account_is_disconnected"
                            )}
                          </strong>
                          &nbsp;
                          <span>
                            {t(
                              "profile.connect_it_to_access_all_communication"
                            )}
                          </span>
                        </UncontrolledAlert>
                        <p>
                          {t(
                            "profile.connect_your_calendar_to_use_zilter_communication_services"
                          )}
                        </p>
                        <div>
                          <ListGroup>
                            <ListGroupItem>
                            <i className="ri-checkbox-circle-fill text-success me-2"></i>
                              {t(
                                "profile.send_emails_meetings_directly_from_zilter"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                            <i className="ri-checkbox-circle-fill text-success me-2"></i>
                              {t(
                                "profile.log_outgoing_emails_meetings_automatically"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                            <i className="ri-checkbox-circle-fill text-success me-2"></i>
                              {t(
                                "profile.sync_your_email_calendar_so_contacts_can_schedule_time_with_you"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {t(
                                  "profile.requires_email_sync"
                                )}
                              </p>
                            </ListGroupItem>
                            {/* <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {t(
                                "userpreferences.see_your_zilter_tasks_in_your_calendar"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {t(
                                  "userpreferences.requires_an_additional_step_with_task_sync_only_available_on_Sales_starter_and_up_plans"
                                )}
                              </p>
                            </ListGroupItem> */}
                          </ListGroup>
                        </div>
                        <div className="my-3">
                          <Button
                            onClick={() => setModal(true)}
                            color="primary"
                            className="bg-gradient"
                          >
                            {" "}
                            {t(
                              "profile.connect_your_account"
                            )}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Label className="fw-bold fs-14">{t("userpreferences.connected_accounts")}</Label>
                    {googleConnect?.map((item: { email: any }) => (
                      <div
                        key={item.email}
                        className="d-flex justify-content-between my-3 mx-0"
                        onMouseEnter={() => setIsHovered(item.email)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <div className="d-flex justify-content-start">
                          <div>
                            <img
                              className="avatar-sm"
                              src={googleicon}
                              alt="Google Icon"
                            />
                          </div>
                          <div className="text-muted">
                            <Label className="ms-1 mb-0 mt-1 fs-15 fw-medium">
                              <strong>{item.email}</strong>
                            </Label>
                            <div>
                              <span className="ms-1">
                                <CircleBadge />
                              </span>
                              {t("userpreferences.connected")}
                            </div>
                          </div>
                        </div>
                        {isHovered === item.email && (
                          <div>
                            <Button
                              onClick={() =>
                                handleGoogleAccountDisconnect(item.email)
                              }
                              className="btn-danger"
                            >
                              {t("userpreferences.disconnect")}
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Profile);
