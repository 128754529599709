import { useState } from "react";
import {
    Card,
    CardBody
} from "reactstrap";
import UsersTable from "./UsersTable";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";
import BreadCrumb from "Components/Common/BreadCrumb";

const UsersList = () => {
    const [show, setShow] = useState<boolean>(false); 

    return (
        <>
            
            <BreadCrumb title={"Users"} pageTitle={"Users"} icon={"ri-list-check"} />
            {show ? <ToastContainer /> : null}
            <Card>
                <CardBody className="">
                    <UsersTable />
                </CardBody>
            </Card>
        </>
    );
};
export default withTranslation()(UsersList);
