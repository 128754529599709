import React, { useEffect, useState } from 'react';
import { Container, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from 'store';
import Form from './FormRender';
import { formGetRequest, getMataData } from 'store/customform/action';
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ToastContainer } from 'react-toastify';
import { isJson } from 'utils';
import { studentGetRequest, studentUpdateRequest } from "store/student/action";

const FormRender = ({ setEvaluate, wizardSteps }: any) => {
    const { id } = useParams();
    document.title = "Evaluation Form";
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const formDetails = useSelector((state: ApplicationState) => state.customform.customformDetails);
    const details = useSelector((state: ApplicationState) => state.student.details);
    const loading = useSelector((state: ApplicationState) => state.student.loading);
    const metadata = useSelector((state: ApplicationState) => state.customform.metadata);
    const tenant = useSelector((state: ApplicationState) => state.auth.userProfile.tenant);

    const [formData, setFormData] = useState<any>({});
    const [title, setTile] = useState<any>("");
    const [description, setDescription] = useState<any>({});
    const [col, setColumn] = useState({});
    const [step, setStep] = useState(0);
    const [wizard, setWizard] = useState(wizardSteps[0]);
    const [submitting, setSubmitting] = useState(false);
    // New state to capture the student class from the very first submission.
    const [localStudentClass, setLocalStudentClass] = useState<string | null>(null);

    // Load metadata and initial form once when tenant is available.
    useEffect(() => {
        if (tenant?.id) {
            dispatch(getMataData(tenant?.id));
            dispatch(formGetRequest(tenant?.id, wizardSteps[0].id));
        }
    }, [tenant]);

    useEffect(() => {
        let fields: any = [];
        const newFormData = formDetails && formDetails.valuesJson && isJson(formDetails.valuesJson)
          ? JSON.parse(formDetails.valuesJson)
          : {};
        newFormData?.components?.forEach((component: any) => {
            metadata?.forEach((field: any) => {
                const item = field.valuesJson && isJson(field.valuesJson)
                  ? JSON.parse(field.valuesJson)
                  : {};
                const newField = { ...item, id: field.id };
                if (component.id === field.id) {
                    fields.push({ ...newField, ...component.extras });
                }
            });
        });
        setFormData(fields);
        setColumn(newFormData.column);
        setTile(newFormData.title);
        setDescription(newFormData.description);
    }, [formDetails, metadata]);

    // Helper function to compute wizard index from a student_class string.
    const getWizardIndex = (studentClass: string): number => {
        if (studentClass?.startsWith("yr_11_")) {
            return 0;
        } else if (studentClass?.startsWith("yr_12_") || studentClass?.startsWith("btech_1")) {
            return 1;
        } else if (studentClass?.startsWith("yr_13_") || studentClass?.startsWith("btech_2")) {
            return 2;
        }
        return 0;
    };

    const handleNext = (formValues: any) => {
        if (submitting) return; // prevent duplicate submissions
        setSubmitting(true);
        // Capture student_class on the very first submission.
        if (step === 0 && formValues?.student_class) {
            setLocalStudentClass(formValues.student_class);
        }
        // Use the locally captured student_class if available.
        const studentClassForIndex = localStudentClass || formValues?.student_class || "";
        const index = getWizardIndex(studentClassForIndex);

        const handleSuccess = (body: any) => {
            setSubmitting(false);
            if (step < wizardSteps.length - 1) {
                const newStep = step + 1;
                setStep(newStep);
                const currentWizard = wizardSteps[newStep];
                setWizard(currentWizard);
                // Use the same index for subsequent wizard step lookup.
                let wizard_id = Array.isArray(currentWizard.id)
                    ? currentWizard.id[index]
                    : currentWizard.id;
                dispatch(formGetRequest(tenant?.id, wizard_id));
            } else {
                dispatch(studentGetRequest(id));
                setEvaluate(false);
            }
        };
        dispatch(studentUpdateRequest(id, formValues, handleSuccess, () => { setSubmitting(false); }));
    };

    const handlePrevious = () => {
        if (step > 0) {
            const newStep = step - 1;
            setStep(newStep);
            const currentWizard = wizardSteps[newStep];
            setWizard(currentWizard);
            // Use the captured student class (if available) for computing index.
            const studentClassForIndex = localStudentClass || (((details?.valuesJson as any)?.student_class) || "");
            let index = getWizardIndex(studentClassForIndex);
            let wizard_id = Array.isArray(currentWizard.id)
                ? currentWizard.id[index]
                : currentWizard.id;
            dispatch(formGetRequest(tenant?.id, wizard_id));
        } else {
            setEvaluate(false);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <Container>
                <Row>
                    <div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
                        <Label>{title}</Label>
                    </div>
                </Row>
                {description?.body && (
                    <Row>
                        <div className='hstack justify-content-start m-3 border border-dark p-3 border-opacity-10'>
                            <div dangerouslySetInnerHTML={{ __html: description?.body }}></div>
                        </div>
                    </Row>
                )}
                <Form
                    wizard={wizard}
                    loading={loading}
                    sm={col}
                    lg={col}
                    md={col}
                    xs={col}
                    xl={col}
                    dataFields={formData}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    showbtn={true}
                    btn_text={'Update Task'}
                    data={details?.valuesJson}
                    formfor={'student'}
                    isEditAccess={true}
                />
            </Container>
        </React.Fragment>
    );
};

export default FormRender;
