import React from 'react';
import { ListGroupItem } from 'reactstrap';

export interface SideMenuItemProps {
	item: any; // Replace "any" with your MenuItem/SubItem interface as needed.
	level?: number;
	onSelect: (item: any) => void;
	selectedItemId: string | number | null;
}

/**
 * SideMenuItem Component
 * Renders an item (and its children recursively) for the left–side menu.
 */
const SideMenuItem: React.FC<SideMenuItemProps> = ({
	item,
	level = 0,
	onSelect,
	selectedItemId,
}) => {
	const hasChildren = item.children && item.children.length > 0;
	return (
		<>
			<ListGroupItem
				tag="button"
				action
				onClick={() => onSelect(item)}
				active={selectedItemId === item.id}
				style={{ paddingLeft: `${level * 20 + 10}px` }}
				className="border-0"
			>
				{item.name}
			</ListGroupItem>
			{hasChildren &&
				item.children.map((child: any) => (
					<SideMenuItem
						key={child.id}
						item={child}
						level={level + 1}
						onSelect={onSelect}
						selectedItemId={selectedItemId}
					/>
				))}
		</>
	);
};

export default SideMenuItem;
