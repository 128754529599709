import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationGr from "./locales/gr.json";
import translationIT from "./locales/it.json";
import translationRS from "./locales/ru.json";
import translationSP from "./locales/sp.json";
import translationENG_old from "./locales/en.json";
import student from "./locales/en/student.json";
import deal from "./locales/en/deal.json"
import common from "./locales/en/common.json";
import application from "./locales/en/application.json"
import institute from './locales/en/institute.json'
import course from './locales/en/course.json'
import invoice from './locales/en/invoice.json'
import source from './locales/en/source.json'
import subagent from './locales/en/subagent.json'
import channel from './locales/en/channel.json'
import field from './locales/en/field.json'
import role from './locales/en/role.json'
import company from './locales/en/company.json'
import product from './locales/en/product.json'
import template from './locales/en/template.json'
import report from './locales/en/report.json'
import auth from './locales/en/auth.json'
import user from './locales/en/user.json'
import bank from './locales/en/bank.json'
import form from './locales/en/form.json'
import dashboard from './locales/en/dashboard.json'
import payment from './locales/en/payment.json'
import dispute from './locales/en/dispute.json'
import contract from './locales/en/contract.json'
import profile from './locales/en/profile.json'
import team from './locales/en/team.json'
import recentimports from './locales/en/recentimports.json'
import translationCN from "./locales/ch.json";
import translationFR from "./locales/fr.json";

const translationENG = {
  ...translationENG_old,
  ...common,
  ...student,
  ...deal,
  ...application,
  ...institute,
  ...course,
  ...invoice,
  ...source,
  ...subagent,
  ...channel,
  ...field,
  ...role,
  ...company,
  ...product,
  ...template,
  ...report,
  ...auth,
  ...user,
  ...bank,
  ...form,
  ...recentimports,
  ...dashboard,
  ...payment,
  ...dispute,
  ...contract,
  ...team,
  ...profile
};

// const translationENG = Object.assign({}, translationENG_Common, translationENG_Student, translationENG_Admin);


// the translations
const resources = {
  gr: {
    translation: translationGr,
  },
  it: {
    translation: translationIT,
  },
  rs: {
    translation: translationRS,
  },
  sp: {
    translation: translationSP,
  },
  en: {
    translation: translationENG,
  },
  cn: {
    translation: translationCN,
  },
  fr: {
    translation: translationFR,
  },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    // fallbackLng: "en", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
