import { useEffect, useState } from "react";
import { Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";

const DTSFullForm = ({ wizard, dataFields, loading, handleNext, handlePrevious, data, md, lg, sm, xl, xs, BtnClassName, isUpdate}: any) => {
	const [formValues, setFormValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);
	const [fieldsValues, SetFieldValues] = useState<any>(null)

	const handlePrefieldData = (data: any) => {
		let newFormValues: any = {};
		const keys = Object.keys(data)
		dataFields && dataFields && dataFields.map((field: any) => {
			let item = field?.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
			keys && keys.length && keys.map((key: any) => {
				let key1 = key.toLowerCase();
				let key2 = item.key.toLowerCase();
				if(key1 == key2 || key1.includes(key2) || key2.includes(key1)) {
					newFormValues = {...newFormValues, [item.key]: data[key].toLowerCase()}
				}
			})
		})
		setFormValues(newFormValues)
	}

	const onChange = (option: any, key: any) => {
		if (key == "personal_information_document") {
			handlePrefieldData(option);
		}
		else if (typeof option == "object") {
			setFormValues({ ...formValues, [key]: option.value });
		} else setFormValues({ ...formValues, [key]: option });
	};
	
	const onSaveClick = () => {
		// btnAction(formValues, "single")
	}

	const allMandatoryFieldsFilled = () => {
		if(dataFields && dataFields.length) {
			for (const field of dataFields) {
				if (
					field &&
					field.validate &&
					field.validate.required &&
					!formValues[field.key]
				) {
					return false;
				}
			}
			return true;
		} else return false;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			SetFieldValues(data);
		}
	}, [isUpdate, data]);
	const formdisabled = Object.keys(formerror).length === 0;
	return (
		<Form action="" className="d-flex flex-column justify-content-end h-100">
			<div className="live-preview hstack flex-wrap">
				{dataFields?.length
					? dataFields.map((field: any, fieldKey: number) => {
						return (
							<Col key={fieldKey} xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<FormBuilder
									placeholder={field.placeholder}
									name={field.key}
									onChange={(e: any) => onChange(e, field.key)}
									options={(field.values && field.values.reverse()) || []}
									label={field.label}
									type={field.type}
									value={formValues[field.key] || ""}
									defaultValue={formValues[field.key]}
									resetValue={fieldsValues[field.key]}
									isEditState={true}
									actionButtons={isUpdate}
									Editable={true}
									rest={field}
									invalid={true}
									formValues={formValues}
									dataFields={dataFields}
									setdisabled={setdisabled}
									onSaveClick={onSaveClick}
									setError={setError}
									formerror={formerror}
									isTextract={true}
								/>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer d-flex bg-opacity-10 border-top p-3 justify-content-center gap-2">
				<Button
					color="danger"
					className={BtnClassName ? `btn btn-soft-danger ${BtnClassName}` : "btn btn-soft-danger"}
					disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || loading}
					onClick={() => handlePrevious(formValues)}
				>
					{wizard?.previous}
				</Button>
				<Button
					color="success"
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success"}
					disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || loading}
					onClick={() => handleNext(formValues)}
				>
					{wizard?.next}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;
