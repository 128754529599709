import { Reducer } from "redux";
import { TeamActionTypes, TeamState } from "./types";


export const initialState: TeamState =
{
    teams: null,
    team: null,
    pagination: null,
    loading: false
}
const reducer: Reducer<TeamState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TeamActionTypes.POST_REQUEST: {
            return { ...state, loading: true };
        }
        case TeamActionTypes.POST_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TeamActionTypes.POST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TeamActionTypes.GET_REQUEST: {
            return { ...state, loading: true };
        }
        case TeamActionTypes.GET_SUCCESS: {
            return {
                ...state,
                loading: false,
                team: action.payload
            };
        }
        case TeamActionTypes.GET_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TeamActionTypes.GET_ALL_REQUEST: {
            return { ...state, loading: true };
        }
        case TeamActionTypes.GET_ALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                teams: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    pageable: action.payload.pageable,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case TeamActionTypes.GET_ALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TeamActionTypes.PUT_REQUEST: {
            return { ...state, loading: true };
        }
        case TeamActionTypes.PUT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TeamActionTypes.PUT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        
        case TeamActionTypes.DELETE_REQUEST: {
            return { ...state, loading: true };
        }
        case TeamActionTypes.DELETE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TeamActionTypes.DELETE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as TeamReducer };
