import {
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
} from "reactstrap";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { withTranslation } from "react-i18next";

const AddView = ({ props, show, onCloseClick, dataFields, onCreate }: any) => {
    return (
        <Modal backdrop={'static'} id={'staticBackdrop'} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 Modal-header-dark d-flex flex-row justify-content-between align-items-center" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2">
                    {props.t("student.modals.add_new_entry")}
                </span>
            </ModalHeader>
            <ModalBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0">
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={dataFields} btnAction={onCreate} btn_text={props.t("common.action_button.add_entry")} formfor="students" />
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default withTranslation()(AddView);

