import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Accordion,
    AccordionBody,
    Button,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    UncontrolledDropdown
} from "reactstrap";
import AutoGrowTextarea from "Components/Common/AutoGrowTextarea/AutoGrowTextarea";
import AddFilter from "./AddFilter";
import { validateFilterString } from './filterUtil';
import HighlightedLogic from './HighlightedLogic'; 

// Interface for Filter
interface FilterProps {
    id: number;
    key: string;
    label: string | Date;
    type: string;
    value: string;
    condition: string;
    model: string;
    dataSource: any;
    optionLabel: string;
    values: any;
    options: any;
    dateType: any;
}

interface FiltersProps {
    filters: FilterProps[];
    onFilterUpdated: (newFilter: FilterProps, condition: any) => void;
    onFilterDelete: (deleteFilter: FilterProps, condition: any) => void
    setFilterCondition: (filterCondition: string) => void
    t: (key: string) => string;
}

const Filters: React.FC<FiltersProps> = ({ t, filters, onFilterUpdated, onFilterDelete, setFilterCondition }) => {
    const [condition, setCondition] = useState({value: 'AND', label: 'ALL of the filters below'});
    const [custom, setCustom] = useState('');
    const [customEdit, setCustomEdit] = useState(false);
    const [customError, setCustomError] = useState<string | null>(null);
    const [openDefault, setOpenDefault] = useState('');
    const toggleDefault = (id: any) => {
        if (openDefault !== id) {
            setOpenDefault(id);
        } else {
            setOpenDefault('');
        }
    };

    const setFilterUpdated = (newFilter: FilterProps) => {
        onFilterUpdated(newFilter, condition)
        onSetCondition(condition);
    }

    const setFilterDelete = (deleteFilter: FilterProps) => {
        onFilterDelete(deleteFilter, condition);
    
        if (condition.value === 'CUSTOM') {
            const [activeFilters] = getFiltersStatus();
            const result = activeFilters.map((item: any) => item.id).join(` ${condition.value} `);
            setCustomError(validateFilterString(custom, activeFilters.length));
            setCustom(result); // Keep user's input unchanged
            setFilterCondition(custom); // Keep previous value
        } else {
            onSetCondition(condition);
        }
    };
    

    const onSetCondition = (condition: any) => {
        const [activeFilters] = getFiltersStatus();
    
        const result = activeFilters.map((item: any) => item.id).join(` ${condition.value} `);
    
        setCondition(condition);
    
        if (condition.value === 'CUSTOM') {
            setCustomError(validateFilterString(custom, activeFilters.length));
            setCustom(custom);
            setFilterCondition(custom);
        } else {
            setCustom(result);
            setCustomError(null);
            setFilterCondition(result);
        }
    };    

    const onToggle = (selectedAccordionId: string, column: any) => {
      toggleDefault(selectedAccordionId)
    }

    const getFiltersStatus = () => {
        return filters.reduce<[FilterProps[], FilterProps[]]>(
            ([active, inactive], filter: FilterProps) => {
                if (
                    filter?.values?.length ||
                    filter?.value ||
                    filter?.dateType ||
                    filter?.condition === "IS_EMPTY" ||
                    filter?.condition === "IS_NOT_EMPTY"
                ) {
                    active.push(filter);
                } else {
                    inactive.push(filter);
                }
    
                // Sort both active and inactive filters by id before returning
                active.sort((a, b) => a?.id - b?.id);
                inactive.sort((a, b) => a?.id - b?.id);
    
                return [active, inactive];
            },
            [[], []]
        );
    };

    const [activeFilters, inActiveFilters] = getFiltersStatus();
    const onCustomChange = (value: string, maxFilter: number) => {
        setCustom(value)
        setFilterCondition(value)
        setCustomError(validateFilterString(value, maxFilter))
    }
    return (
        <div className="d-flex flex-column gap-3 h-100">
            <div className="d-flex flex-column gap-4 p-2 border-bottom">
                <Label className="m-0 fw-semibold fs-5">Active filters</Label>
                <div className='d-flex flex-column w-100 gap-1'>
                    <Label className="m-0 fw-semibold fs-6">Include data if it matches:</Label>
                    <ButtonGroup>
                        <UncontrolledDropdown className='w-100'>
                            <DropdownToggle
                                tag="button"
                                className="btn btn-soft-primary text-left d-flex align-items-center justify-content-between w-100"
                            >
                                {condition.label} <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdownmenu-primary">
                                <DropdownItem
                                    onClick={() => onSetCondition({value: 'AND', label: 'ALL of the filters below'})}
                                >
                                    ALL of the filters below
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => onSetCondition({value: 'OR', label: 'ANY of the filters below'})}
                                >
                                    ANY of the filters below
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => setCondition({value: 'CUSTOM', label: 'Custom filter rules'})}
                                >
                                    Custom filter rules
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                </div>
                {condition.value === 'CUSTOM' ? <div className='d-flex flex-column w-100 gap-1'>
                    <Label className="form-label m-0">Filter rules</Label>
                    {customEdit ? <div className='d-flex flex-column w-100 border p-2 gap-2 bg-white'>
                        <AutoGrowTextarea
                            value={custom}
                            onChange={(e: any) => onCustomChange(e.target.value, activeFilters.length)}
                        />
                        {customError ? <Label className="text-danger">{customError}</Label> : ''}
                        <div className='d-flex gap-2 justify-content-start w-100'>
                            <Button
                                onClick={() => setCustomEdit(false)}
                                color="primary"
                                disabled={!!customError}
                            >
                                Apply
                            </Button>
                            <Button color="light"
                                onClick={() => setCustomEdit(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>  : <HighlightedLogic logic={custom} onClick={() => setCustomEdit(true)} /> }
                </div> : null}
                <Accordion className={condition.value === 'CUSTOM' ? "d-flex flex-column gap-2 h-100" : "d-flex flex-column gap-5 h-100"} open={openDefault} toggle={toggleDefault}>
                    {activeFilters.map((filter: any, valueIndex: number) => {
                        const accordionId = `active_${valueIndex}`;
                        const isCustomCondition = valueIndex > 0 && condition.value !== 'CUSTOM';
                        const isValidDate = (date: any) => !isNaN(new Date(date).getTime());

                        const renderValuesLabel = () => {
                            if (!filter.valuesLabel || filter.valuesLabel.length === 0) return null;

                            return filter.valuesLabel.map((value: any, idx: number) => {
                                const isLastValue = idx === filter.valuesLabel.length - 1;
                                const separator = idx < filter.valuesLabel.length - 2 ? ', ' : ' ';
                                return (
                                    <span key={idx} className="fw-semibold">
                                        {isLastValue && filter.valuesLabel.length !== 1 ? `or ${value.label}` : value.label}
                                        {separator}
                                    </span>
                                );
                            });
                        };

                        const renderDateValue = () => {
                            if (filter.value && filter.high_value && isValidDate(filter.value) && isValidDate(filter.high_value)) {
                                return `${new Date(filter.value).toDateString()} and ${new Date(filter.high_value).toDateString()}`;
                            }
                            if (filter.dateType && filter.dateType.value !== 'specific_date') {
                                return filter.dateType.label;
                            }
                            if (filter.value && isValidDate(filter.value)) {
                                return new Date(filter.value).toDateString();
                            }
                            if (filter.value && filter.high_value) {
                                return `${filter.value} and ${filter.high_value}`;
                            }
                            if (filter.value) {
                                return filter.value;
                            }
                            return null;
                        };

                        return (
                            <div className="position-relative accordion-item border border-dark-subtle rounded-1" key={accordionId}>
                                {isCustomCondition && (
                                    <div className="position-absolute w-100 border-top d-flex align-items-center justify-content-center" style={{ top: '-24px' }}>
                                        <div className="position-absolute d-flex align-items-center justify-content-center rounded fw-bold bg-primary-subtle text-primary" style={{ width: '40px', height: '20px' }}>
                                            {condition.value}
                                        </div>
                                    </div>
                                )}
                                <div className="w-100 p-1 ps-2 m-0 bg-light">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="flex-shrink-0">
                                                <div className="task-handle bg-primary-subtle rounded text-primary px-2 py-1">{filter.id}</div>
                                            </div>
                                            <div>
                                                <span className="fw-semibold">{filter.keyLabel} </span>
                                                <span>{filter.conditionLabel} </span>
                                                {filter.condition !== "IS_EMPTY" && filter.condition !== "IS_NOT_EMPTY" && (
                                                    <>
                                                    {renderValuesLabel()}
                                                    <span className="fw-semibold">{renderDateValue()}</span>
                                                    </>
                                                )}
                                                <div className="text-muted">{filter.data_source?.label}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <Button
                                                size="sm"
                                                color="primary"
                                                className="btn btn-ghost-primary waves-effect waves-light p-1"
                                                onClick={() => onToggle(accordionId, filter)}
                                            >
                                                <i className="ri-edit-2-fill fs-5"></i>
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                className="btn btn-ghost-primary waves-effect waves-light p-1"
                                                onClick={() => setFilterDelete(filter)}
                                            >
                                                <i className="ri-close-line fs-5"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <AccordionBody accordionId={accordionId}>
                                    <div className="d-flex flex-column align-items-center justify-content-start gap-4">
                                        {accordionId === openDefault && (
                                            <AddFilter
                                                filter={filter}
                                                onFilterUpdated={setFilterUpdated}
                                                activeFilters={activeFilters}
                                                toggleDefault={toggleDefault}
                                            />
                                        )}
                                    </div>
                                </AccordionBody>
                            </div>
                        );
                    })}
                </Accordion>
            </div>
            <div className="d-flex flex-column gap-2 p-2 pb-5">
                <Label className="m-0 fw-semibold fs-5">Inactive filters</Label>
                <Accordion className="d-flex flex-column gap-1 h-100 pb-5" id="default-accordion-example" open={openDefault} toggle={toggleDefault}>
                    {inActiveFilters.map((filter: any, valueIndex: number) => {
                        const accordionId = `inactive_${valueIndex.toString()}`;
                        return (
                            <div className="accordion-item border border-dark-subtle rounded-1">
                                <h6 className="w-100 p-1 ps-2 m-0 bg-light">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center gap-2'>
                                            {filter?.label}
                                        </div>
                                        <div className="d-flex">
                                            <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1"  onClick={() => onToggle(accordionId, filter)}>
                                                <i className="ri-edit-2-fill fs-5"></i>
                                            </Button>
                                            <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1"  onClick={() => setFilterDelete(filter)}>
                                                <i className="ri-close-line fs-5"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </h6>
                                {/* <AccordionHeader targetId="1">How to create a group booking ?</AccordionHeader> */}
                                <AccordionBody accordionId={accordionId}>
                                    <div className='d-flex flex-column align-items-center justify-content-start gap-4'>
                                        {accordionId === openDefault && <AddFilter filter={filter} onFilterUpdated={setFilterUpdated} activeFilters={activeFilters} toggleDefault={toggleDefault} />}
                                    </div>
                                </AccordionBody>
                            </div>
                        )
                    })}
                </Accordion>
            </div>
        </div>
    )
};

export default withTranslation()(Filters);
