import React from 'react';
import { Row } from 'reactstrap';
import CommissionsListing from './Finance';

const Commissions = () => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>                        
            <Row>
                <CommissionsListing />
            </Row>
        </React.Fragment>
    );
};

export default Commissions;