export interface Team {
    id: string,
    name: string,
    parentTeam: Team,
    description: string,
    createdAt: string,
    updatedAt: string
    deletedAt: string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum TeamActionTypes {
  POST_REQUEST = "@@team/POST_REQUEST",
  POST_SUCCESS = "@@team/POST_SUCCESS",
  POST_ERROR = "@@team/POST_ERROR",
  GET_REQUEST = "@@team/GET_REQUEST",
  GET_SUCCESS = "@@team/GET_SUCCESS",
  GET_ERROR = "@@team/GET_ERROR",
  GET_ALL_REQUEST = "@@team/GET_ALL_REQUEST",
  GET_ALL_SUCCESS = "@@team/GET_ALL_SUCCESS",
  GET_ALL_ERROR = "@@team/GET_ALL_ERROR",
  PUT_REQUEST = "@@team/PUT_REQUEST",
  PUT_SUCCESS = "@@team/PUT_SUCCESS",
  PUT_ERROR = "@@team/PUT_ERROR",
  DELETE_REQUEST = "@@team/DELETE_REQUEST",
  DELETE_SUCCESS = "@@team/DELETE_SUCCESS",
  DELETE_ERROR = "@@team/DELETE_ERROR",
}

export interface TeamState {
    readonly teams: Team[] | null;
    readonly team: Team | null;
    readonly pagination: Pagination | null;
    readonly loading: boolean;
}
