import React from "react";
import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/dtsimg/logo/logo-placeholder.jpg";
import FormRender from "./FormRender";
import { isMobile } from "react-device-detect";
import Logo from "Components/Common/Logo/Logo";


const Preview = ({ show, onCloseClick, title, description, formField, cols, formValues, onCreate, btndisabled, tenant }: any) => {
   return (
        <Modal size="xl" backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                Preview
            </ModalHeader>
            <ModalBody className='p-5'>
                <div className="mt-1">
                    <React.Fragment>
                        <Container>
                        <Logo tenant={tenant} height={40}/>
                        {
                            isMobile ?
                            <Row>
                                <Col className='hstack justify-content-center'>
                                    <div className="horizontal-logo mb-2">
                                        <Link to="" className="logo logo-dark">
                                            <span className="logo-sm">
                                                <img src={tenant?.logo || logoLight} alt="" height="100" />
                                            </span>
                                            <span className="logo-lg">
                                                <img src={tenant?.logo || logoLight} alt="" height="100" />
                                            </span>
                                        </Link>
                                        <Link to="" className="logo logo-light">
                                            <span className="logo-sm">
                                                <img src={tenant?.logo || logoLight} alt="" height="100" />
                                            </span>
                                            <span className="logo-lg">
                                                <img src={tenant?.logo || logoLight} alt="" height="100" />
                                            </span>
                                        </Link>
                                    </div>  
                                </Col>
                            </Row>
                            : null
                        }
                            <Row>
                                <div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
                                    <Label>{title}</Label>
                                </div>
                            </Row>
                            {description?.body ? <Row>
                                <div className='hstack justify-content-start m-3 border border-dark p-3 border-opacity-10'>
                                    <div dangerouslySetInnerHTML={{ __html: description?.body}}></div>
                                </div>
                            </Row> : null}
                            <FormRender dataFields={formField || []} md={cols} lg={cols} xl={cols} BtnClassName={"w-auto justify-content-end"} btn_text={'Submit'} data={formValues} />
                        </Container>
                    </React.Fragment>
                </div>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Button onClick={onCreate} className="btn btn-primary w-md mb-3" disabled={btndisabled}>
                        Create Form
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    ) as unknown as JSX.Element;
};

export default Preview;