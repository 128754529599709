import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { isJson } from "utils";
import { createSelector, Action, ThunkDispatch } from "@reduxjs/toolkit";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { handleSelectValue } from "utils";
import moment from "moment";

interface ReducerState {
    [key: string]: any;
}

const Edit = ({ id, detailsGetAction, updateAction, reducerState, metaData, show, onClose, size = "w-25", setTriggerApi }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ["details", "loading"]);
    const derivedState: any = useSelector(reducer);
    const { details, loading } = derivedState;

    const [record, setRecord] = useState<any>();
    const [formValues, setFormValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(true);
    const [formerror, setError] = useState<any>([]);
    const [fieldsValues, SetFieldValues] = useState<any>({});
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        dispatch(detailsGetAction(id))
    }, [])

    useEffect(() => {
        let info = details?.valuesJson;
        setRecord(info)
    }, [details])


    useEffect(() => {
        let fields: any = [];
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            let newField = { ...item, id: field.id }
            fields.push({ ...newField })
        })
        setFormData(fields)
    }, [metaData])

    useEffect(() => {
        if (record) {
            setFormValues(record);
            SetFieldValues(record);
        }
    }, [record]);

    useEffect(() => {
        let newData: any = {};
        if (record) {
            formData && formData.length && formData.map((item: any, key: any) => {
                let rendervalue = record?.[item.key];
                if (item?.mergeColumns?.columns) {
                    let value = item?.mergeColumns?.columns
                    let values = value.split(",")
                    let newValue = "";
                    values?.length && values.map((item: any) => {
                        newValue = record?.[item] ? `${newValue ? newValue : ""} ${record?.[item]}${item.mergeColumns?.separator || " "}` : ""
                    })
                    rendervalue = newValue;
                }
                if (item.optionLabel) {
                    rendervalue = record?.[item.key]?.[item.optionLabel] ? { label: record?.[item.key]?.[item.optionLabel], value: record?.[item.key]?.["id"] } : null
                }
                else if (item.isMulti || item.type == "selectboxes") {
                    rendervalue = record?.[item.key];
                }
                else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
                    rendervalue = handleSelectValue(record?.[item.key], item.values);
                }
                else if (item.type == 'file') {
                    rendervalue = typeof record?.[item.key] !== 'object' ? record?.[item.key] : '';
                }
                else if (item.type == 'datetime') {
                    rendervalue = typeof record?.[item.key] !== 'object' ? moment(record?.[item.key]).toDate() : '';
                }
                newData[item.key] = rendervalue;
            })
            setFormValues(newData)
            SetFieldValues(newData)
        }
    }, [formData, record]);

    const onChange = (option: any, key: any) => {
        if (option) {
            if (typeof option == "object") {
                setFormValues({ ...formValues, [key]: option.value });
            } if(formValues[key]?.type === 'datetime') {
                setFormValues({ ...formValues, [key]: moment(option) });
            } else {
                setFormValues({ ...formValues, [key]: option });
            }
        }
    };

    const onSaveClick = () => {
        // btnAction(formValues, "single")
    }

    const allMandatoryFieldsFilled = () => {
        if (formData && formData.length) {
            for (const field of formData) {
                if (
                    field &&
                    field.validate &&
                    field.validate.required &&
                    !formValues[field.key]
                ) {
                    return false;
                }
            }
            return true;
        } else return false;
    };

    const onUpdate = () => {
        const handleSuccess = () => {
            setTriggerApi();
            onClose(true);
        }
        dispatch(updateAction(id, transformData(formValues, formData), handleSuccess))
    }

    const transformData = (data: Record<string, any>, metadata: any[]): Record<string, any> => {
        const output: Record<string, any> = {};

        // Loop over the keys in the data object
        Object.keys(data).forEach(key => {
            // Look up the corresponding metadata for the current key.
            const meta = metadata.find(m => m.key === key);
            if (!meta) {
                output[key] = data[key];
                return;
            }

            let value = data[key];

            switch (meta.type) {
                case "datasetselect":
                    // For datasetselect, extract inner "value" if available.
                    output[key] =
                        value && typeof value === "object" && "value" in value ? value.value : value;
                    break;
                case "select":
                    // For select fields, use metadata's options to find a matching value.
                    if (meta.values && Array.isArray(meta.values)) {
                        const dataStr = String(value).toLowerCase();
                        const match = meta.values.find((option: any) =>
                            (option.value && option.value.toLowerCase() === dataStr) ||
                            (option.label && option.label.toLowerCase() === dataStr)
                        );
                        output[key] = match ? match.value : dataStr;
                    } else {
                        output[key] = typeof value === "string" ? value.toLowerCase() : value;
                    }
                    break;
                default:
                    output[key] = value;
            }
        });
        return output;
    };

    const formdisabled = Object.keys(formerror).length === 0;
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            backdrop={false}
            toggle={onClose}
            className={size}
        >
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onClose}>
                <span className="m-0 me-2 text-light">
                    Edit Details
                </span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <div className="live-preview hstack flex-wrap">
                    {formData?.length
                        ? formData.map((field: any, fieldKey: number) => {
                            return (
                                <Col key={fieldKey} xl={12} lg={12} md={12} sm={12} xs={12} className="align-self-start">
                                    <FormBuilder
                                        placeholder={field.placeholder}
                                        name={field.key}
                                        onChange={(e: any) => onChange(e, field.key)}
                                        options={(field.values && field.values.reverse()) || []}
                                        label={field.label}
                                        type={field.type}
                                        value={formValues[field.key] || ""}
                                        defaultValue={formValues[field.key]}
                                        resetValue={fieldsValues[field.key]}
                                        isEditState={true}
                                        actionButtons={false}
                                        Editable={true}
                                        rest={field}
                                        invalid={true}
                                        formValues={formValues}
                                        dataFields={formData}
                                        setdisabled={setdisabled}
                                        onSaveClick={onSaveClick}
                                        setError={setError}
                                        formerror={formerror}
                                        isTextract={false}
                                    />
                                </Col>
                            );
                        })
                        : null}
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer d-flex bg-opacity-10 border-top p-3 justify-content-between gap-2">
                <Button
                    color="danger"
                    className={"btn btn-soft-danger"}
                    disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || loading}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="success"
                    className={"btn btn-success"}
                    disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || loading}
                    onClick={onUpdate}
                >
                    {loading ? "Updating..." : "Update"}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default Edit;


