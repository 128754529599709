import { Button, Col, Label, Row } from "reactstrap";
import TimeSlotCalendar from "./TimeSlotCalendar";
import SimpleBar from "simplebar-react";
import { useState } from "react";
import ConfirmSchedule from "./ConfirmSchedule";
import { useNavigate } from 'react-router-dom';

const BookSlot = ({tenId, eventTypeId}: any) => {

    const [isScheduleConfirm, setIsScheduleConfirm] = useState(false)

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      const onBack =  () => {
        setIsScheduleConfirm(false)
    }
    return (
        <>
            <div>
                {/* <SimpleBar> */}
                <Row>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3}>
                    {isScheduleConfirm && (
                        <div className="mb-3">
                            <Button
                            size="sm"
                                color="primary"
                                className="btn-label"
                                onClick={() => onBack()}
                            >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                Back
                            </Button>
                        </div>
                    )}
                        
                        <div className="d-flex flex-column">
                            <Label className="text-muted fs-16 fw-bold">Counsellors ame</Label>
                            <Label className="fs-20 fw-bold align-items-center"> Event type nname</Label>
                            <div className="d-flex align-items-start text-muted fs-16 fw-bold justify-content-start">
                                <i className="ri-time-line align-middle"></i>
                                <Label className="ms-2">
                                    Duration
                                </Label>
                            </div>
                            <div className="d-flex align-items-start justify-content-start text-muted fs-16 fw-bold">
                                <i className="ri-video-add-line align-middle fs-5"></i>
                                <Label className="ms-2">
                                    Web conferencing details provided upon confirmation.
                                </Label>
                            </div>

                        </div>
                    </Col>
                    <Col xxl={9} xl={9} lg={9} md={9} sm={9}>
                        {!isScheduleConfirm ? (
                            <TimeSlotCalendar isScheduleConfirm={isScheduleConfirm} setIsScheduleConfirm={setIsScheduleConfirm} />
                        ) : <ConfirmSchedule />}

                    </Col>
                </Row>
                {/* </SimpleBar> */}

            </div>
        </>
    )
}
export default BookSlot;