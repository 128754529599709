import { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";

const AddEntry = ({ props, show, onCloseClick, onSave, model }: any) => {
    const [groupName, setGroupName] = useState('')
    return (
        <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                <span className="m-0 me-2 text-white">{props.t("properties.create_a_new_property_group")} {model.toUpperCase()}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <p className="text-muted fs-15 mb-4">{props.t("properties.create_group_disclaimer")}</p>
                    <Input
                        type="text"
                        size={14}
                        className="search"
                        placeholder={props.t("common.modals.enter_group_name")}
                        onChange={(e) => setGroupName(e.target.value)}
                        value={groupName}
                    />
                    <div className="hstack gap-2 justify-content-center mt-3">
                        <Button className="btn btn-primary" onClick={() => onSave(groupName)} disabled={!groupName}>
                            {props.t("properties.save")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("common.offcanvas.import_cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default AddEntry;