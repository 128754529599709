import {
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { useState } from "react";
import SimpleBar from "simplebar-react";
import { isJson } from "utils";
import { ApplicationState } from "store";
import moment from "moment";

const AddEntry = ({ show, onCloseClick, dataFields, setTriggerApi, createAction, parent, parentId }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const student = useSelector((state: ApplicationState) => state.student.details);
    const [disabled, setDisabled] = useState<any>(false)
    const OnAddEntry = (formValues: any) => {
        setDisabled(true)
        let data: any = parent ? { ...formValues, [parent]: parentId, invitee_email: student?.id } : formValues;
        const handleSuccess = (body: any) => {
            onCloseClick()
            setDisabled(false)
            setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        const handleFailure = (body: any) => {
            setDisabled(false)
        }
        dispatch(createAction(data, handleSuccess, handleFailure))
    }
    dataFields = dataFields?.length && dataFields.filter((field: any) => {
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : { ...field.valuesJson }
        return item.key !== 'invitee_email';
    })
    // Define a function to handle field dependencies.
    const handleFieldDependencyChange = (option: any, key: any, field: any, currentFormValues: any) => {
        if(key === "meeting_type" && option) {
            return {
                ...currentFormValues,
                event_name: `${student?.valuesJson?.name} - ${option?.label}`
            };
        } else if (key === "start_time" && option) {
            // Create a moment instance from the option value
            const startTime = moment(option);
            // Add one hour
            const endTime = startTime.clone().add(1, 'hour');
            // Update the form values, format the date as needed
            return {
              ...currentFormValues,
              end_time: endTime.toDate()  // You can also use endTime.format() for a specific format
            };
        }
        return currentFormValues;
    };
    return (
        <Modal
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
        >

            <ModalHeader className="p-3 d-flex flex-row justify-content-between align-items-center" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2">
                    Create new event
                </span>
            </ModalHeader>
            <ModalBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0">
                        <SimpleBar style={{ maxHeight: 450 }}>
                            <div className="live-preview vstack gap-2">
                                <QuickForm
                                    dataFields={dataFields}
                                    btnAction={OnAddEntry}
                                    btn_text={'Create Event'}
                                    formfor="meetings"
                                    btndisabled={disabled}
                                    onFieldDependencyChange={handleFieldDependencyChange}
                                />
                            </div>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default AddEntry;

