import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Table } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { handleSelectValue, isJson } from "utils";
import { documentDownload } from "store/documents/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";


const StudentInfo = ({ record, fieldsMetaData }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const [metaData, setMetaData] = useState<{ [key: string]: any }>({});
	const [datafields, setdatafields] = useState<any>([])
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

	useEffect(() => {
		setMetaData(fieldsMetaData)
	}, [fieldsMetaData != undefined])
	

	const handleAccess = (permissionJson: any, fields: any) => {
		let userRole = userProfile?.role?.title;
		let roleid = userProfile?.role?.id;
		const permissions = permissionJson?.permissions;
		if (userRole === "Owner") return true
		else if (permissions) {
			switch (permissions) {
				case "Owner":
				case "owner": if (userRole === "Owner") return true;
				else return false;
				case "view": return true;
				case "view_edit": return true;
				case "role_based": const items = permissionJson.item || [];
					let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
					let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
					return isview && isview.length;
				default: return false;
			}

		}
	}
	useEffect(() => {
		if (metaData && metaData.length > 0) {
			let datafields: any = []
			metaData.map((fieldName: any) => {
				let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson) : {}
				let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : {}
				let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson, fields) : true;
				if (fields && isFieldviewaccess) datafields.push(fields)
			});
			setdatafields(datafields)
		}
	}, [metaData]);

	const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };  

	const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", key); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }

	return (
		<Table className="table table-borderless mb-0">
			{datafields && datafields.length && datafields.map((fields: any) => {
				let rendervalue = record?.[fields.key];
				if (fields?.mergeColumns?.columns) {
					let value = fields?.mergeColumns?.columns
					let values = value.split(",")
					let newValue = "";
					values?.length && values.map((item: any) => {
						newValue = record?.[item] ? `${newValue ? newValue : ""} ${record?.[item]}${item.mergeColumns?.separator || " "}` : ""
					})
					rendervalue = newValue;
				}   
				if(fields.tableRenderType == 'hyperlink' && record?.[fields.key] && record?.[fields.key] !== "NA") {
					rendervalue = <div className='ms-3'>
							<FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(record?.[fields.key])}/>
						</div>
				} 
				else if (fields.type === 'file' && record?.[fields.key] && record?.[fields.key] !== "NA") {
					rendervalue =  <div className='ms-3'>
						<FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(record?.[fields.key], `${fields.key}_${record?.id}`)} />
					</div>
				}                         
				else if(fields.tableRenderType == 'tag') {            
					let value = record?.[fields.key]
					let intakearray = value?.split(",")
					rendervalue = <div>
						{intakearray?.length && intakearray.map((item: any) => {                                          
							return <div className='ms-0'>
								<span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
							</div>
						})}
					</div>
				}   
				else if(fields.isMulti || fields.type == 'selectboxes') {            
					let value = record?.[fields.key]
					let arr = value && value.length && value.map((item:any) => {
						if(item?.value) return item.value
						else return item
					})
					rendervalue = <div>
						{arr?.length && arr.map((item: any) => {   
							let label = handleSelectValue(item, fields.values);                                
							return <div className='ms-0'>
								<span className="badge bg-success">{label}</span>
							</div>
						})}
					</div>
				}                                                 
				else if (fields.optionLabel) {
					rendervalue = record?.[fields.key]?.[fields.optionLabel] ? record?.[fields.key]?.[fields.optionLabel] : 'NA'
				}         
				else if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
					rendervalue = handleSelectValue(record?.[fields.key], fields.values);
				}				
				else if (fields.type === "texteditor") {
					rendervalue =  <span className="text-ellipsis"><div className='text-truncate-two-lines'  style={{maxWidth: 200, overflow:'hidden', maxHeight: 100}} dangerouslySetInnerHTML={{ __html: record?.[fields.key] }} /></span>
				}
				else if (fields.type === "textarea") {
					rendervalue = <span className="text-ellipsis"><span className='text-truncate-two-lines'  style={{maxWidth: 200, overflow:'hidden', maxHeight: 100}}>{record?.[fields.key]}</span></span>
				}
				return <tbody>
					<tr className="border border-0 border-bottom">
						<td className="fw-semibold">
							{fields.label}
						</td>
						<td className="overflow-hidden" style={{ maxWidth: 200, textWrap:'pretty' }}>
							<span className="teaser">{rendervalue}</span>
						</td>
					</tr>
				</tbody>
			})}
		</Table>
	);
};

export default StudentInfo;


