export const importModel = [
    {
        "title": "Institutes",
        "key": "instituteId",
        "model": "institutes",
        "description": "Upload the data Commission for Institutes",
        "svg": "ri-building-4-line"
    },
    {
        "title": "Channels",
        "key": "channelId",
        "model": "channels",
        "description": "Upload the data  Commission for Channels",
        "svg": "ri-book-open-line"
    },
    // {
    //     "title": "Subagents",
    //     "key": "subagentId",
    //     "model": "subagents",
    //     "description": "Upload the data Commission for Subagents",
    //     "svg": " ri-exchange-funds-fill"
    // }
]