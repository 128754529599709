import { useEffect, useState } from "react";
import {
    Label,
    FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { propertyListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import { isJson } from "utils";

interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
}

const DtsSelectInput: React.FC<DtsSelectInputProps> = ({ formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name, icon, iconPosition, errorText, actionButtons, readOnly, disabled }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionList = useSelector((state: ApplicationState) => state.auth.optionmetaData);
    const [options, setOptions] = useState([]);
    const [filterObject, setFilterObject] = useState<any>({
        pageSize: 100
    });
    const [errormsg, setErrormsg] = useState();
    
    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])

    useEffect(() => {
        if (rest && rest.model) {
            dispatch(propertyListGetRequest(rest.model, filterObject))
        }
    }, [rest])

    const handletypetext = (
        inputValue: string
    ) => {
        const filters = {...filterObject, search: inputValue}
        setFilterObject(filters)
        setTimeout(() => {
            dispatch(propertyListGetRequest(rest.model, filters));
        }, 1000);
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            return { label: valueJson?.label, value: valueJson?.key, valueJson: valueJson }
        })
        setOptions(options)
    };

    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            let valueJson =item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            return { label: valueJson?.label, value: valueJson?.key, valueJson: valueJson }
        })
        setOptions(options)
    }, [optionList])
    
    return (
        <div>
            {
                <div>
                    {label ? <Label htmlFor={name} className="form-label mt-1 fw-bold fs-16 form-label">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                    <Select {...rest} type={type} options={options || []} id={name} placeholder={placeholder} onChange={(selectedOption: any) => onChange(selectedOption)} readOnly={readOnly} disabled={disabled} onInputChange={(inputValue: any) => handletypetext(inputValue)}></Select>
                    {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                </div>
            }
        </div>
    );
};

export default DtsSelectInput;
