import {CalendarActionTypes, Calendar} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import * as msal from '@azure/msal-browser';
import { jwtDecode } from "jwt-decode";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const postGoogleCalendarSync: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const signUpMicrosoftRequest = () => {
    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/1b9e5340-f9f7-4130-8fe3-a87963878cf0",
            redirectUri: "http://localhost:3000"
        }
    };
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        dispatch({
            type: CalendarActionTypes.SIGNUP_MICROSOFT_REQUEST
        });
        try {
            const loginRequest = {
                scopes: [
                    "User.Read",
                    "Calendars.ReadWrite",
                    "Mail.ReadWrite"
                ]
            };
            
            const response = await msalInstance.loginPopup(loginRequest);
            
            if (response.accessToken) {
                const accessToken = jwtDecode(response.accessToken);
                
                const backendResponse = await fetch(`${DTS_API_DOMAIN}/accounts`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        code: accessToken,
                        scope: "email profile openid https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Read",
                        provider: 'microsoft',
                        isSignup: 'true'
                    })
                });

                const body = await backendResponse.json();
                const {response: response_1, body: body_1} = {response, body};
                if (!backendResponse.ok) {
                    dispatch({
                        type: CalendarActionTypes.SIGNUP_MICROSOFT_ERROR,
                        payload: body_1
                    });
                } else {
                    const userData: any = jwtDecode(body.token);
                    dispatch({
                        type: CalendarActionTypes.SIGNUP_MICROSOFT_SUCCESS,
                        payload: userData
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: CalendarActionTypes.SIGNUP_MICROSOFT_ERROR,
                payload: error
            });
            console.error("Login Error: ", error);
        }
    };
};

// export const microsoftSignup: AppThunk = (data, handleSuccess, handleError) => {
//     return async (dispatch: Dispatch, getState: any): Promise<void> => {
//         const { auth: { token } } = getState();
//         dispatch({
//             type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_REQUEST
//         });
//         const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 'token': token
//             },
//             body: JSON.stringify(data)
//         });
//         const body = await response.json();
//         const {response: response_1, body: body_1} = {response, body};
//         if (!response_1.ok) {
//             dispatch({
//                 type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_ERROR,
//                 payload: body_1
//             });
//             handleError(body_1);
//         } else {
//             dispatch({
//                 type: CalendarActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS,
//                 payload: body_1
//             });
//             handleSuccess(body_1);
//         }
//     };
// };

export const createGoogleCalendarEvent: AppThunk = (email, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/calendar/events/${email}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.POST_CREATEGOOGLEEVENT_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const getGoogleCalendarData: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            // handleSuccess(body_1);
        }
    };
};
export const getCalendarEvents: AppThunk = (emailId, provider, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/${provider}/calendar/events/${emailId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            // body: JSON.stringify(emailId)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.GET_GOOGLECALENDAREVENTS_SUCCESS,
                payload: body_1
            });
            // handleSuccess(body_1);
        }
    };
};

export const deleteGoogleEvent: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (email, eventId, handleSuccess) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();  
        dispatch({
            type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/calendar/events/${email}/${eventId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "token": token
            }
        });
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            // dispatch({
            //     type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_ERROR
            // });
            // handleError();
        } else {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDAREVENT_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};

export const disconnectGoogleAccount: ActionCreator<
ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: data
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: CalendarActionTypes.DELETE_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const eventTypeMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_EVENTTYPEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        headers.append('Properties-Model', "events");
        const searchQuery =   createQueryLink(filterObject)  
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, CalendarActionTypes.GET_EVENTTYPEMETADATA_SUCCESS, CalendarActionTypes.GET_EVENTTYPEMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const eventTypeCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { auth: { token } } = getState();
    dispatch({ type: CalendarActionTypes.POST_EVENT_TYPE_REQUEST });
    try {
      const response = await fetch(
        `${DTS_API_STUDENT}/models/events`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          body: JSON.stringify(data)
        }
      );
           
      const error = {
        208: {
            "message": "Event type already exist",
            "success": false
        },
        201: {
            "message": "Event type created Successfully",
            "success": true
        },
        200: {
            "message": "Event type created Successfully",
            "success": true
        }
    }
    await responseHandler(response, dispatch, CalendarActionTypes.POST_EVENT_TYPE_SUCCESS, CalendarActionTypes.POST_EVENT_TYPE_ERROR, error, handleSuccess, handleError);

    } catch (error) {
      dispatch({ type: CalendarActionTypes.POST_EVENT_TYPE_ERROR, payload: error });
      handleError();
    }
  };
};

export const eventTypeListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.GET_EVENT_TYPE_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/events?page=${page?page:0}&pageSize=${size?size:50}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CalendarActionTypes.GET_EVENT_TYPE_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CalendarActionTypes.GET_EVENT_TYPE_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const eventTypeDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CalendarActionTypes.DELETE_EVENT_TYPE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/events/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Event type deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Event type deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, CalendarActionTypes.DELETE_EVENT_TYPE_SUCCESS, CalendarActionTypes.DELETE_EVENT_TYPE_ERROR, error, handleSuccess, handleError);
       } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CalendarActionTypes.DELETE_EVENT_TYPE_ERROR
            });
            handleSuccess();
        }
    };
};