import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PropertiesListing from './Properties';
import { withTranslation } from 'react-i18next';

const Properties = (props: any) => {
	document.title = "DTS | Zilter - Fields";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("fields.breadcrumb.fields")} pageTitle={props.t("fields.breadcrumb.fields")} />
                    <Row>
                        <PropertiesListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Properties);