import { useEffect, useState } from "react";
import {
    Label,
    FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { propertyListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import { isJson } from "utils";

interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string, key?: any) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    model?: any;
}

const DtsSelectInput: React.FC<DtsSelectInputProps> = ({ model, formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name, icon, iconPosition, errorText, actionButtons, readOnly, disabled }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [defaultoption, setDefaultOption] = useState()
    const optionList = useSelector((state: ApplicationState) => state.auth.optionmetaData);
    const [options, setOptions] = useState([]);
    const [filterObject, setFilterObject] = useState<any>({});
    const [errormsg, setErrormsg] = useState();


    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
        if (rest && rest.model) {
            dispatch(propertyListGetRequest(rest.model, filterObject))
        }
    }, [rest?.model, rest?.validate?.required])

    const handletypetext = (
        inputValue: string
    ) => {
        const filterObject = {
            search: inputValue
        }
        setTimeout(() => {
            dispatch(propertyListGetRequest(rest.model, filterObject));
        }, 1000);
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            return { label: valueJson?.label, value: valueJson?.key, valueJson: valueJson }
        })
        setOptions(options)
    };

    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            return { label: valueJson?.label, value: valueJson?.key, valueJson: valueJson }
        })
        setOptions(options)
    }, [optionList])

    useEffect(() => {
        options && options.map((item: any) => {
            if (item.value === defaultValue) {
                setDefaultOption(item)
                onChange(item, "default")
            }
        })
    }, [options, defaultValue])

    useEffect(() => {
        let filters = {pageSize:200}
        dispatch(propertyListGetRequest(model?.value, filters))
    }, [model?.value])
    return (
        <div>
            {
                <div>
                    {label ? <Label htmlFor={name} className="mt-1 fw-bold fs-16 form-label">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                    <Select {...rest} type={type} options={options || []} value={defaultoption} defaultValue={defaultoption} id={name} placeholder={placeholder} onChange={(selectedOption: any) => onChange(selectedOption)} readOnly={readOnly} disabled={disabled} onInputChange={(inputValue: any) => handletypetext(inputValue)}></Select>
                    {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                </div>
            }
        </div>
    );
};

export default DtsSelectInput;
