import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Button, Input } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { isJson } from 'utils';
import EditColumnModal from './EditColumnModal';
import metaDataFields from './Fields.json'

const Listing = (props: any) => {
    const { selectedList, loading, renderCell, mainList, setSelectedList } = props;
    const [columns, setColumns] = useState<any>([])
    const [tempcolumns, setTempColumns] = useState<any>([])
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [visibleColumns, setvisibleColumns] = useState<any>([])
    const [editModal, setEditOpen] = useState<boolean>(false);
    const [metaData, setMetaData] = useState<any>([]);
    const [leftFields, setLeftFields] = useState<any>([]);
    const [search, setSearch] = useState<any>('');
    const [list, setList] = useState<any>('');
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    // Handle PageSize Change
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value, page: 0 })
    }

    useEffect(() => {
        setList(selectedList)
    }, [selectedList])



    // Update metadata fields
    useEffect(() => {
        setMetaData(metaDataFields);
    }, [metaDataFields]);

    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

 
    // Generate default view columns
    const defaultViewColumn = () => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ? JSON.parse(fieldName.valuesJson) : fieldName?.valuesJson ? fieldName?.valuesJson : {}
                let show = fields.key === "difference" || fields.key === "settlement_type" || fields.key === "settlement_comments" || fields.key === 'dispute_amount' || fields.key === 'dispute_note' || fields.key === 'attachment'
                let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        fieldid: fieldName.id,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: show,
                        isPrimary: fields.isPrimary,
                        Cell: (cellProps: any) => {
                            return renderCell(cellProps, fields);
                        }
                    }
                    columns.push(newColumn)
            });
        }
        return columns
    }

    useEffect(() => {
        let columns: any[] = [];
        columns = defaultViewColumn();
        const nameColumnIndex = columns.findIndex(column => column.isPrimary == true);
        if (nameColumnIndex !== -1) {
            const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
            columns.unshift(nameColumn); // Add it to the beginning
        }
        setvisibleColumns(columns)
        setColumns(columns)
        setTempColumns(columns)
        setLeftFields(columns)
    }, [metaData]);


    // Columns methods
    // Update visible columns    
    const onchange = (column: { accessor: string; }) => {
        let index = visibleColumns.findIndex((x: { accessor: string; }) => x.accessor === `${column.accessor}`);
        let newCols = visibleColumns
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
            }
        }
        setvisibleColumns(newCols)
    }

    // Move column
    const onClickmove = (column: { accessor: string; }) => {
        const newCols = [...columns]
        const index = columns.findIndex((obj: { accessor: string; }) => obj.accessor === column.accessor);
        if (column.accessor == 'created_date' || column.accessor == 'updated_date') {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => { return moment(cellProps.row.original.heading).format('DD MMM, YYYY'); }
            }
        }
        else {
            newCols[index] = {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
            }
        }
        setvisibleColumns(newCols)
    }

    // Toggle column visibility
    const toggleColumn = (columnAccessor: any) => {
        setTempColumns((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
        setLeftFields((prevColumns: any[]) => {
            const updatedColumns = prevColumns.map(column => {
                if (column.accessor === columnAccessor) {
                    return { ...column, show: !column.show };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    // Apply column changes
    const onApplyClick = () => {
        setColumns(tempcolumns)
        setLeftFields(tempcolumns)
        setEditOpen(false)
    }

    // Handle sort changes
    const handleSort = (columnName: any) => {
        setPagination({ ...pagination, page: 0 })
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
        }
    };

    // Deselect all columns
    const deSelectColumnall = () => {
        let tempcolumnsnew: any = []
        tempcolumnsnew = columns.map((column: any, index: number) => {
            if (column.accessor === "createdAt") return {
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            }
            else if (column.isPrimary) return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return renderCell(cellProps, column.valueJson);
                }
            }
            else return {
                Header: _.startCase(column.accessor),
                accessor: column.accessor,
                filterable: true,
                disableFilters: true,
                show: false,
                Cell: (cellProps: any) => {
                    return renderCell(cellProps, column.valueJson);
                }
            }
        })
        setColumns(tempcolumnsnew)
        setTempColumns(tempcolumnsnew)
        setLeftFields(tempcolumnsnew)
        setEditOpen(false)
    }

    // Change column index
    const onChangeIndex = (column: any, index: any) => {
        if (index !== -1) {
            let newTempcolumns: any = [...tempcolumns]
            // Remove the column from its current position
            const removedColumn = newTempcolumns.splice(newTempcolumns.indexOf(column), 1)[0];
            // Insert the column at the desired index
            newTempcolumns.splice(index, 0, removedColumn);
            setTempColumns(newTempcolumns);
        }
    }

    const checkExist = (item: any, value: string): boolean => {
        if (!item) return false;
    
        if (typeof item === "string") {
            return item.toLowerCase().includes(value);
        }
    
        if (typeof item === "object") {
            return Object.values(item).some((val) => checkExist(val, value));
        }
    
        return false;
    };
    
    useEffect(() => {
        if (search) {
            let value = search.replace(/\+/g, "").toLowerCase();    
            const updatedData = selectedList.filter((item: any) => checkExist(item, value));  
            setList(updatedData);
        } else {
            setList(selectedList);
        }
    }, [search]);

    document.title = `DTS | Zilter - Select Students in Create Invoice`;
    return (
        <React.Fragment>
            {editModal && <EditColumnModal
                show={editModal}
                onCloseClick={() => setEditOpen(false)}
                onApplyClick={onApplyClick}
                onchange={onchange}
                columns={columns}
                visibleColumns={visibleColumns}
                toggleColumn={toggleColumn}
                onClickmove={onClickmove}
                deSelectColumnall={deSelectColumnall}
                onChangeIndex={onChangeIndex}
                tempcolumns={tempcolumns}
                setTempColumns={setTempColumns}
                leftFields={leftFields}
                setLeftFields={setLeftFields}
            />}

            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                            <Row className='hstack gap-3'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <Input className='border rounded' placeholder="Search..." style={{ padding: '1.2rem 0.9rem' }} onChange={(e: any) => setSearch(e.target.value)} value={search}></Input>
                                        {search === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => setSearch("")}></i>}
                                    </div>
                                </Col>
                                
                                <Col>
                                    <div className='hstack gap-2 justify-content-end gap-2'>
                                        <div className='hstack gap-2'>
                                            <Button
                                                onClick={() => setEditOpen(true)}
                                                color="primary"
                                                className="btn-label btn-soft-primary">
                                                <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                                                {props.t("student.listing.action_button.edit_columns")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={[...list]}
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    trClass=""
                                    isPageSizeChange={true}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    customPageSize={pagination.size}
                                    onChangePageSize={onChangePageSize}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    dataLoading={loading}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Listing);


