import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from './Listing';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { isJson } from 'utils';
import { callCreateRequest, callDelete, callGetRequest, callListGetRequest, callsMetadataGetRequest } from 'store/calls/action';

const Calls = (props: any) => {
    const { details, mainModel, applicationId, studentId } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaData = useSelector((state: ApplicationState) => state.calls.metaDataFields);
    const [create, setCreate] = useState<boolean>(false);
    const [parentField, setParentField] = useState({})
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [modal_positionTop, setmodal_positionTop] = useState<boolean>(false);

    useEffect(() => {
        metaData && metaData.length && metaData.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if (field.key === "student") setParentField({ ...field, ...item })
        })
    }, [metaData])

    const { viewId, isCreateModal, setCreateOpen } = props;

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.tasks;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() === 'create') {
                            item.value === "" || item.value === "none" ? setCreate(false) : setCreate(true)
                        }
                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let filterObject = { PageSize: 500 }
        dispatch(callsMetadataGetRequest("calls", filterObject))
    }, [])

    return (
        <div>
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={callListGetRequest}
                deleteAction={callDelete}
                detailsGetAction={callGetRequest}
                metaDatagetRequest={callsMetadataGetRequest}
                createAction={callCreateRequest}
                model={"calls"}
                reducerState={"calls"}
                parentId={details?.id}
                parent={"student"}
                parentField={parentField}
                details={details}
                selectedTask={selectedTask}
                modal_positionTop={modal_positionTop}
                setmodal_positionTop={setmodal_positionTop}
                mainModel={mainModel}
                applicationId={applicationId}
                studentId={studentId}
            />
        </div>
    );
};
export default withTranslation()(Calls);


