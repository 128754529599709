import TableContainer from "pages/Properties/roles/TableComponent";
import { useEffect, useState } from "react";
import {  Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody,  ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import { isJson } from "utils";

const ViewAll = ({ show, onCloseClick, viewlist, setisDeleteOpen, setIsEditOpen, setRecord, setViewname, setViewId, setView, view, setFields}: any) => {
   const onHandleselect = (item: any) => {
    if(item?.fields && isJson(item?.fields)) {
        setFields(JSON.parse(item?.fields))
    }
   }
    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true} size="lg">
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>All About Views</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
            <div className="table-responsive">
                <SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
                    <table className="table align-middle position-relative table-nowrap">
                        <thead className="cursor-pointer table-active position-sticky">
                            <tr>
                                <th className="border-bottom-1 table-soft-primary  fw-bold" style={ {textAlign:'left' }}>
                                    View Name
                                </th>
                                <th  className="border-bottom-1 table-soft-primary  fw-bold">
                                    Location
                                </th>
                                <th  className="border-bottom-1 table-soft-primary  fw-bold">
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        <tbody id="task-list">
                            {viewlist?.length ? viewlist.map((item: any, key: any) => (
                                <tr key={key} style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                    <td style={{ verticalAlign: 'middle', textAlign:'left' }} className='align-items-center justify-content-between'>
                                        <div>
                                            <div>
                                                {item.name}{" "}{view?.id ===item?.id ? <Badge color="success">Currently Active</Badge> : "" }
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between'>
                                        <div>
                                            <div>
                                                {item.location}
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-center'>
                                        <ul className="hstack gap-1 justify-content-center ps-0">                                                    
                                                <li
                                                    onClick={() => {setIsEditOpen(true);setRecord(item);onCloseClick() }}
                                                    className="dropdown-item edit-item-btn fs-12 w-auto"
                                                    title="Edit"
                                                >
                                                    <button className="btn btn-sm btn-soft-secondary remove-list"
                                                    >
                                                        <i className="ri-pencil-fill"></i>{" "}
                                                    </button>{" "}
                                                </li>
                                                <li
                                                    className="dropdown-item remove-item-btn fs-12 w-auto"
                                                    title="Edit"
                                                    onClick={() => {setisDeleteOpen(true);setRecord(item);onCloseClick()}}
                                                >
                                                    <button className="btn btn-sm btn-soft-danger remove-list"
                                                    >
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button>  {" "}
                                                </li>
                                                {view?.id ===item?.id ?  null : <li
                                                    className="dropdown-item remove-item-btn fs-12 w-auto"
                                                    title="Apply"
                                                    onClick={() => {setViewId(item?.id);setView(item); onHandleselect(item); onCloseClick()}}
                                                >
                                                    <button className="btn btn-sm btn-soft-success remove-list"
                                                    >
                                                        <i className="ri-checkbox-circle-line"></i>
                                                    </button>  {" "}
                                                </li>}
                                        </ul>
                                    </td>
                                </tr>))
                                : null}
                        </tbody>
                    </table>                    
                    {viewlist?.length ? null : <div className="text-center w-100 fs-16 fw-semibold">No views to show</div>}
                </SimpleBar>
            </div>

            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ViewAll;