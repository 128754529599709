import React, { useEffect, useState } from "react";
import { Form, Col, Container, Label, Row } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from 'store';
import { formGetRequest, getMataData } from 'store/customform/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

const Feedback = ({}: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const details = useSelector((state: ApplicationState) => state.student.details);
    const loading = useSelector((state: ApplicationState) => state.student.loading);
    const metadata = useSelector((state: ApplicationState) => state.customform.metadata);
    const tenant = useSelector((state: ApplicationState) => state.auth.userProfile.tenant);
	const [formData, setFormData] = useState<any>({})
		const [formDetails, setFormDetails] = useState<any>({})
	const [title, setTile] = useState<any>("")
	const [description, setDescription] = useState<any>({})
	const [col, setColumn] = useState({})
	const [formValues, setFormValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);
	const [fieldsValues, SetFieldValues] = useState<any>(null)
	
	useEffect(() => {
		if (tenant?.id) {
			dispatch(getMataData(tenant?.id))
			const handleSuccess = (data: any) => {
				setFormDetails(data);
			}
			dispatch(formGetRequest(tenant?.id, 'f3766056-29ae-40c1-9eb5-acc89fec3964', handleSuccess))
		}
	}, [tenant])
	
	useEffect(() => {
		let fields: any = [];
		let newFormData = formDetails && formDetails.valuesJson && isJson(formDetails.valuesJson) ? JSON.parse(formDetails.valuesJson) : {}
		newFormData && newFormData?.components && newFormData?.components.length && newFormData.components.map((component: any) => {
			metadata && metadata.length && metadata.map((field: any) => {
				let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
				let newField = { ...item, id: field.id }
				if (component.id == field.id) {
					fields.push({ ...newField, ...component.extras })
				}
			})
		})
		setFormData(fields)
		setColumn(newFormData.column)
		setTile(newFormData.title)
		setDescription(newFormData.description)
	}, [formDetails, metadata])

	useEffect(() => {
		if (details?.valuesJson) {
			setFormValues(details?.valuesJson);
			SetFieldValues(details?.valuesJson);
		}
	}, [details]);
	return (
        <React.Fragment>
            <div className="bg-light border-light border-1 p-3">
				<div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
					<Label>{title}</Label>
				</div>
				<Form>
					<Row>
						{formData?.length
							? formData.map((field: any, fieldKey: number) => {
								return (
									<Col key={fieldKey} xl={col} lg={col} md={col} sm={col} xs={col} className="align-self-start">
										<FormBuilder
											placeholder={field.placeholder}
											name={field.key}
											onChange={(e: any) => console.log(e, field.key)}
											options={(field.values && field.values.reverse()) || []}
											label={field.label}
											type={field.type}
											value={formValues[field.key] || ""}
											defaultValue={formValues[field.key]}
											resetValue={fieldsValues[field.key]}
											isEditState={false}
											actionButtons={false}
											Editable={false}
											rest={field}
											invalid={true}
											formValues={formValues}
											dataFields={formData}
											setdisabled={setdisabled}
											disabled={true}
											onSaveClick={() => {}}
											setError={setError}
											formerror={formerror}
											isTextract={false}
											readOnly={true}
										/>
									</Col>
								);
							})
							: null}
					</Row>
				</Form>
            </div>
        </React.Fragment>
	);

};

export default Feedback;
