import { useEffect, useRef, useState } from "react";
import {
    Label,
    Input,
    Form,
    Button,
    FormFeedback,
    ButtonGroup,
    Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";

interface DateOptionProps {
    altInput?: boolean,
    altFormat?:string, // Human-Friendly Dates example altFormat: "F j, Y",  
    dateFormat?: string, // example "Y-m-d H:i"   or  "H:i"
    defaultDate?: string, // example ["2022-01-20"]
    disable?: string[] // example ["2022-01-30", "2022-02-21", "2022-03-08", new Date(2025, 4, 9)]
    minDate?: string,
    maxDate?: string,
    mode?: string // example mode: "multiple" or mode: "range"
    inline?: boolean,
    weekNumbers?: boolean,
    enableTime?: boolean,
    noCalendar?: boolean,
}

interface DtsDateInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    options?: DateOptionProps;
    onSaveClick?: any;
    setdisabled?: any;
    rest?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
    loading?: boolean;
}

const DtsDateInput: React.FC<DtsDateInputProps> = ({loading, resetvalue, rest, setdisabled, onSaveClick, defaultValue, label, type, value, placeholder, onChange, isEditable, name ,icon, iconPosition, errorText, actionButtons, readOnly, disabled, options}: any) => {
    const [hover, setHover] = useState<any>(false)
    const [date, setDate] = useState<any>(null);    
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [errormsg, setErrormsg] = useState();
    const [resetValue, setResetValue] = useState<any>(null)
    const [flatpickrOptions, setFlatpickeroption] = useState({   
            dateFormat:'M d, Y',  
          onOpen: () => {
            if (value !== '' && value !== undefined) {
              setDate(null);
              onChange('');
            }
          }
    });
    const onhandleChange = (value: any) => {
        let dateValue = moment(value).format('ll');
        onChange(value)
        validateInput(value);
    }
    useEffect(() => {
        onChange && onChange(resetvalue)
        if(resetvalue) {
            setResetValue(resetvalue)
            setDate(resetvalue)
        }
        else {
            setResetValue(null)            
            setDate(null)
        }
    }, [resetvalue])

    useEffect(() => {
        if(value) {
            let newValue = null
            if(rest?.widget?.enableTime) {
                newValue = moment(value).format('lll');
            }
            else {
                newValue = moment(value).format('ll');
            }
            setDate(newValue)
        }
    },[value])
    useEffect(() => {
        let newflatpickrOptions: any = {}
        if(rest && rest?.widget) {
            newflatpickrOptions = {...flatpickrOptions, ...rest.widget, dateFormat:'M d, Y'}
            setFlatpickeroption(newflatpickrOptions)
        }
        if(rest && rest.validate && rest.validate.dateAllowed) {
            if(rest.validate.dateAllowed == "futureonly") {
                let currentDate = moment().startOf('day').add(1, 'day').toDate();
                let newflatpickrOptions = {...flatpickrOptions, minDate: currentDate,  dateFormat:'M d, Y'}
                setFlatpickeroption(newflatpickrOptions)
            }
            else {
                if(rest.validate.dateAllowed == "pastonly") {
                    var date = moment().toDate();            
                    let newflatpickrOptions = {...flatpickrOptions, maxDate: date,   dateFormat:'M d, Y'}
                    setFlatpickeroption(newflatpickrOptions)
                }
            }            
        }
        if(rest && rest?.widget && rest?.widget?.enableTime) {
            newflatpickrOptions = {...flatpickrOptions, ...rest.widget,                 
                onReady() { this.showTimeInput = true },
                dateFormat:'M d, Y H:i K'}
            setFlatpickeroption(newflatpickrOptions)
        }
        // else {
        //     newflatpickrOptions = {...options, ...flatpickrOptions}
        //     setFlatpickeroption(newflatpickrOptions)
        // }
    }, [])
    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) { 
            const feedback: any = document.getElementById(`${name}`);
            if(feedback) feedback.classList.remove('d-none');
        }
    },[rest])
    
    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }
    
    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.minLength) {
            if(value.length < rest.validate.minLength) {
                let error: any =  `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.maxLength) {   
           if(value.length > rest.validate.maxLength) {
                let error: any =  `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined)
        setCheckdisabled(false)
        return true
    }
    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      };
    
    return (        
        <div className="pb-3"> 
            {
                actionButtons ?
                <div>
                    { hover ?
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-2">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                        <div className="d-flex border rounded-3 align-items-center">
                            <Flatpickr className="form-control border-0" defaultValue={defaultValue} id={name} placeholder={placeholder} value={value} 
                                onChange={(date:any, altdate: any, instance: any) => {
                                onhandleChange(date[0]); 
                                if(!rest?.widget?.enableTime) {
                                    instance.close()
                                }                      
                                }
                            }                            
                            onKeyDown={(event) => handleKeyDown(event)}
                            readOnly={readOnly} disabled={disabled} options={{...flatpickrOptions, enableTime: rest?.widget?.enableTime ? rest?.widget?.enableTime : false}}/>
                            <ButtonGroup>
                                <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue); setDate(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                <Button onClick={(e) => {onCheckClick(e)}} disabled={checkdisabled} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                            </ButtonGroup>
                        </div>   
                        {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>} 
                    </div>
                    : <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}              
                        <div className="hstack justify-content-between topbar-user">
                            <div className="fs-14 fw-medium form-control border-0 topbar-user text-nowrap text-truncate text-nowrap-ellipsis" >
                                {date ? rest?.widget?.enableTime ? moment(date).format('lll') : moment(date).format('ll') : null}
                            </div>
                            {
                                readOnly ? null
                                : <Button disabled={loading} onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary" id={`btn${name}`}> 
                                {loading ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-edit-2-fill" /> } 
                            </Button>
                            }
                        </div>
                    </div>
                    }
                </div>
            :            
                <div>
                    {label ? <Label htmlFor={name} className="form-label fw-bold fs-14  mb-1 text-nowrap text-truncate text-nowrap-ellipsis">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                    <Flatpickr className="form-control" style={{paddingLeft: 10}} id={name} placeholder={placeholder} defaultValue={defaultValue} value={value} 
                     onChange={(date:any, altdate: any, instance: any) => {
                        onhandleChange(date[0]);
                        if(!rest?.widget?.enableTime) {
                            instance.close()
                        }
                        }
                    } 
                    onKeyDown={(event) => handleKeyDown(event)}
                    readOnly={readOnly} disabled={disabled}  options={{...flatpickrOptions, enableTime: rest?.widget?.enableTime ? rest?.widget?.enableTime : false}} />
                    {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>} 
                    {/* {errorText ? <div className="invalid-feedback">{errorText}</div> : null} */}
                </div>
            }
        </div>
    );
};

export default DtsDateInput;
