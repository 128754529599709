import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Container } from "reactstrap";
import { postGoogleCalendarSync } from "store/calendarSync/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../config";

const { api } = config;
const url =  api.url;

const redirectUri = `${url}/user-preferences/connect/microsoft`;

// Utility function to generate a random string for code_verifier
function generateRandomString(length: number) {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let result = "";
    const cryptoObj = window.crypto;
    const randomValues = new Uint32Array(length);
    cryptoObj.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
        result += possible.charAt(randomValues[i] % possible.length);
    }
    return result;
}

// Utility function to generate the code_challenge from the code_verifier
async function generateCodeChallenge(code_verifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(code_verifier);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashString = hashArray.map(b => String.fromCharCode(b)).join("");
    return btoa(hashString)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

const OutlookSync = ({ t }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [isAccount, setIsAccount] = useState(false);

    // When the component mounts, check if we have been redirected back with an authorization code.
    useEffect(() => {
        const hash = location.hash; // e.g., "#code=..."
        const searchParams = new URLSearchParams(hash.replace(/^#/, ""));
        const authCode = searchParams.get("code");
        if (authCode) {
            // Retrieve the code_verifier from local storage
            const code_verifier = localStorage.getItem("pkce_code_verifier");
            // Clean up the stored code_verifier once retrieved.
            localStorage.removeItem("pkce_code_verifier");
            // Clear the hash so that this effect is not triggered again.
            navigate(location.pathname, { replace: true });
            setIsAccount(true);
            const data = {
                code: authCode,            // The authorization code from Microsoft
                code_verifier,             // Include the PKCE code_verifier for the backend token exchange
                scope: "User.Read Calendars.ReadWrite Mail.Send Mail.ReadWrite offline_access",
                provider: "microsoft",
                isSignup: "false",
            };
            dispatch(
                postGoogleCalendarSync(
                    data,
                    () => {
                        toast("Account Connected Successfully", {
                            position: "top-center",
                            hideProgressBar: true,
                            className: "bg-success text-white",
                        });
                        setTimeout(() => {
                            navigate(`/students`);
                        }, 2000);
                    },
                    () => {
                        // Handle error if needed
                    }
                )
            );
        }
    }, [location, navigate, dispatch]);

    // Handle login via manual redirect with PKCE parameters.
    const handleLogin = async () => {
        if (disabled) {
            console.log("Login is already in progress.");
            return;
        }
        setDisabled(true);
        try {
            // Generate the PKCE code_verifier
            const code_verifier = generateRandomString(128);
            // Generate the corresponding code_challenge
            const code_challenge = await generateCodeChallenge(code_verifier);
            // Store the verifier for use after the redirect
            localStorage.setItem("pkce_code_verifier", code_verifier);

            // Build the authorization URL with the required PKCE parameters
            const params = new URLSearchParams({
                client_id: "9dce3457-ea35-4f69-9c96-aecbda169c12",
                response_type: "code",
                redirect_uri: redirectUri,
                response_mode: "fragment",
                scope: "User.Read Calendars.ReadWrite Mail.Send Mail.ReadWrite offline_access",
                code_challenge: code_challenge,
                code_challenge_method: "S256",
                state: "someRandomState" // You should generate and validate this state value for CSRF protection
            });

            const authorizationUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`;
            // Redirect the user to Microsoft's authorization endpoint
            window.location.href = authorizationUrl;
        } catch (error) {
            console.error("Error during login:", error);
            setDisabled(false);
        }
    };

    if (isAccount) {
        return (
            <div className="page-content">
                <ToastContainer />
                <Container fluid>
                    <div style={{ textAlign: "center", padding: "2rem" }}>
                        <h1>Processing Microsoft Login...</h1>
                        <p>Please wait while we complete the connection.</p>
                    </div>
                </Container>
            </div>
        );
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div style={{ padding: "2rem", textAlign: "center" }}>
                    <h1>{t("userpreferences.connect_your_microsoft_account")}</h1>
                    <div style={{ margin: "2rem auto", maxWidth: "600px", textAlign: "left" }}>
                        <p>{t("userpreferences.confirm_modal_microsoft_para1")}</p>
                        <ul>
                            <li>
                                <strong>{t("userpreferences.microsoft_calendar")}</strong>
                                <p className="mx-3">{t("userpreferences.confirm_modal_microsoft_para2")}</p>
                            </li>
                        </ul>
                        <p>{t("userpreferences.confirm_modal_microsoft_para3")}</p>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para4")}
                            <span>
                                <strong>
                                    <Link to="#">{t("userpreferences.here")}</Link>
                                </strong>
                            </span>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_microsoft_para5")}
                            {t("userpreferences.confirm_modal_microsoft_para4")}
                            <span>
                                <strong>
                                    <Link to="#">{t("userpreferences.privacy_policy")}</Link>
                                </strong>
                            </span>
                        </p>
                    </div>
                    <Button color="success" disabled={disabled} onClick={handleLogin} style={{ marginRight: "1rem" }}>
                        {t("userpreferences.accept_connect_with_microsoft")}
                    </Button>
                    <Button color="danger" onClick={() => window.history.back()}>
                        {t("userpreferences.cancel")}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default withTranslation()(OutlookSync);
