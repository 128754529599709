import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Table } from 'reactstrap';
import { useNavigate } from 'react-router';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setTokenRequest } from 'store/auth/action';

// Define the type for Account
interface Account {
    token: string;
    tenant: {
        companyName: string;
        website: string;
    };
}

const MultipleSignin: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, any> = useDispatch();

    // Parse retrievedData as Account[] with a default empty array to handle null cases
    const retrievedData: Account[] = JSON.parse(localStorage.getItem("accountData") || "[]");

    const handleSignIn = (companyToken: string) => {
        if (companyToken) {
            localStorage.setItem('isMulti', JSON.stringify(true));
            dispatch(setTokenRequest(companyToken));
            navigate('/students');
        } else {
            navigate('/signup');
        }
    };

    return (
        <div className="p-5">
            <div className="d-flex justify-content-center">
                <Col xl={8} lg={8} sm={10}>
                    <h3 className="">Zilter Accounts</h3>
                </Col>
            </div>
            <div className="d-flex justify-content-center">
                <Col xl={8} lg={8} sm={10}>
                    <hr />
                </Col>
            </div>
            <div className="d-flex justify-content-center">
                <Col xl={8} lg={8} sm={10}>
                    <div className="border border-bottom-0 table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                {retrievedData.map(({ token, tenant }, index) => (
                                    <tr key={index}>
                                        <td
                                            className="text-primary fs-16 fw-medium"
                                            onClick={() => handleSignIn(token)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {tenant.companyName}
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="flex-grow-1">
                                                    <a href={tenant.website || "#"} target="_blank" rel="noopener noreferrer">
                                                        {tenant.website || "No Website"}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </div>
        </div>
    );
};

export default MultipleSignin;