import { useEffect, useState } from "react";
import { Form, Button, Col } from "reactstrap";
import { handleSelectValue, isJson } from "utils";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";

const DTSFullForm = ({ dataFields, btnAction, btn_text, data, md, lg, sm, xl, xs, BtnClassName, isUpdate }: any) => {
	const [fieldsValues, SetFieldValues] = useState<any>(null)

	const [formValues, setFormValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);
	const [filterFields, setFilterFields] = useState<any>([]);

	const onChange = (option: any, key: any, field: any) => {
		let feldtoupdate: any = null;
		if (field.type == "selectboxes" || field.isMulti) {
			setFormValues({ ...formValues, [key]: option })
			feldtoupdate = { [key]: option }
		}
		else if (isNaN(Date.parse(option)) === false) {
			setFormValues({ ...formValues, [key]: option })
			feldtoupdate = { [key]: option }
		}
		else if (option?.value) {
			setFormValues({ ...formValues, [key]: option?.value })
			feldtoupdate = { [key]: option?.value }
		}
		else {
			setFormValues({ ...formValues, [key]: option })
			feldtoupdate = { [key]: option }
		}
	};

	const onSaveClick = (key: any) => {
		btnAction({ [key]: formValues[key] }, "single")
	}

	const allMandatoryFieldsFilled = () => {
		for (const field of filterFields) {
			if (
				field &&
				field.validate &&
				field.validate.required &&
				!formValues[field.key]
			) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			SetFieldValues(data)
		}
	}, [isUpdate, data]);

	useEffect(() => {
		let newData: any = {};
		if (data) {
			let info = data?.valuesJson || data;
			filterFields && filterFields.length && filterFields.map((item: any, key: any) => {
				let rendervalue = info?.[item.key];
				if (item?.mergeColumns?.columns) {
					let value = item?.mergeColumns?.columns
					let values = value.split(",")
					let newValue = "";
					values?.length && values.map((item: any) => {
						newValue = info?.[item] ? `${newValue ? newValue : ""} ${info?.[item]}${item.mergeColumns?.separator || " "}` : ""
					})
					rendervalue = newValue;
				}
				if (item.optionLabel) {
					rendervalue = info?.[item.key]?.[item.optionLabel] ? { label: info?.[item.key]?.[item.optionLabel], value: info?.[item.key]?.["id"] } : null
				}
				else if (item.isMulti || item.type == "selectboxes") {
					rendervalue = info?.[item.key];
				}
				else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
					rendervalue = handleSelectValue(info?.[item.key], item.values);
				}
				else if (item.type == 'file') {
					rendervalue = typeof info?.[item.key] !== 'object' ? info?.[item.key] : '';
				}
				newData[item.key] = rendervalue;
			})
			setFormValues(newData)
			SetFieldValues(newData)
		}
	}, [data, filterFields])

	useEffect(() => {
		let fields: any = []
		dataFields &&
			dataFields.length &&
			dataFields?.length && dataFields?.map((field: any) => {
				let item = field &&
					field?.valuesJson && isJson(field.valuesJson)
					? JSON.parse(field.valuesJson)
					: {};
				if (field?.valuesJson) return fields.push(item);
			});
		setFilterFields(fields)
	}, [dataFields])
	const formdisabled = Object.keys(formerror).length === 0;
	return (
		<Form action="" className="d-flex flex-column justify-content-end h-100">
			<div className="live-preview hstack flex-wrap">
				{filterFields && filterFields.length
					? filterFields?.map((field: any) => {
						return (
							<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<div className="search-box">
									<FormBuilder
										placeholder={field.placeholder}
										name={field.key}
										onChange={(e: any) => onChange(e, field.key, field)}
										options={(field.values && field.values.reverse()) || []}
										label={field.label}
										type={field.type}
										value={formValues[field.key]}
										defaultValue={formValues[field.key]}
										resetValue={fieldsValues[field.key]}
										isEditState={true}
										actionButtons={isUpdate}
										Editable={true}
										rest={field}
										invalid={true}
										formValues={formValues}
										dataFields={filterFields}
										setdisabled={setdisabled}
										onSaveClick={() => onSaveClick(field.key)}
										setError={setError}
										formerror={formerror}
										isTextract={true}
									/>
								</div>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
				<Button
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
					disabled={isUpdate ? false : !allMandatoryFieldsFilled() || disabled || !formdisabled}
					onClick={() => btnAction(formValues)}
				>
					{btn_text}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;
