import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
	Button,
	Nav,
	NavItem,
	NavLink,
	Offcanvas,
	OffcanvasBody,
	Row,
	Col,
	TabContent,
	TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import RoleName from './Common/RoleName';
import PermissionsManagement from './Common/PermissionsManagement';
import Review from './Common/Review';
import {
	roleCreateRequest,
	permissionCreateRequest,
	roleListGetRequest,
	permissionsGetRequest,
} from 'store/role/action';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { toast } from 'react-toastify';

// Define the shape for role inputs.
interface CreateInputs {
	role: string;
}

interface CreateProps extends WithTranslation {
	show: boolean;
	onCloseClick: () => void;
}

// Define types for clarity.
interface Permission {
	id: string;
	value: any;
	type: string; // e.g., 'boolean' or 'dropdown'
	options?: string | { label: string; value: string }[];
	unassigned?: boolean | string;
	permissions?: Permission[]; // Nested permissions.
	// ...other fields like name, description, etc.
}

interface TreeNode {
	id: number | string;
	children?: TreeNode[];
	permissions?: Permission[];
	// ...other fields such as name, etc.
}

/**
 * Create Component
 * Implements a three–step wizard for role creation:
 *  Step 1: Enter Role Name.
 *  Step 2: Manage Permissions.
 *  Step 3: Review & Create.
 */
const Create: React.FC<CreateProps> = ({ show, onCloseClick, t }) => {
	// Redux dispatcher and state selectors.
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const permissionsdata = useSelector(
		(state: ApplicationState) => state.role.permissionsdata
	);

	// Local state variables.
	const [activeStep, setActiveStep] = useState<number>(1);
	const [createInputs, setCreateInputs] = useState<CreateInputs>({ role: '' });
	// "accessData" holds the permissions tree (ensure it remains an array).
	const [accessData, setAccessData] = useState<any[]>(
		Array.isArray(permissionsdata) ? permissionsdata : []
	);
	// "accessPermissions" holds the permission values selected by the user.
	const [accessPermissions, setAccessPermissions] = useState<any>({});
	// State to show loading indicator to prevent multiple clicks.
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// Fetch permissions on component mount.
	useEffect(() => {
		dispatch(permissionsGetRequest());
	}, [dispatch]);

	// Update accessData when permissionsdata changes.
	useEffect(() => {
		setAccessData(Array.isArray(permissionsdata) ? permissionsdata : []);
	}, [permissionsdata, dispatch]);

	/**
	 * Helper to parse the options for a dropdown permission.
	 * @param permission The permission object.
	 * @returns An array of option objects.
	 */
	const getOptions = (permission: Permission): { label: string; value: string }[] => {
		let options: { label: string; value: string }[] = [];
		if (typeof permission.options === 'string') {
			try {
				options = JSON.parse(permission.options);
			} catch (e) {
				options = [];
			}
		} else {
			options = permission.options || [];
		}
		return options;
	};

	/**
	 * Get the underlying option value for dropdown permissions.
	 * @param permission The permission object.
	 * @param currentValue The current selected value.
	 * @returns The value if found, otherwise the original currentValue.
	 */
	const getOptionValue = (permission: Permission, currentValue: any): any => {
		if (currentValue === null || currentValue === undefined) {
			return currentValue;
		}
		const options = getOptions(permission);
		const found = options.find(opt =>
			(opt.value ?? '').toString().toLowerCase() === currentValue.toString().toLowerCase()
		);
		return found ? found.value : currentValue;
	};

	/**
	 * Recursively traverse a node (TreeNode or Permission) to get all nested Permission objects.
	 * @param node The node to traverse.
	 * @returns An array of Permission objects.
	 */
	const traverseNode = (node: TreeNode | Permission): Permission[] => {
		let perms: Permission[] = [];
		if ('permissions' in node && Array.isArray(node.permissions)) {
			perms.push(...node.permissions);
			node.permissions.forEach(childPerm => {
				perms.push(...traverseNode(childPerm));
			});
		}
		if ('children' in node && Array.isArray(node.children)) {
			node.children.forEach(childNode => {
				perms.push(...traverseNode(childNode));
			});
		}
		return perms;
	};

	/**
	 * Build the payload from an array of top-level tree nodes.
	 * @param nodes Array of TreeNode objects.
	 * @returns Array of permission payload objects.
	 */
	const buildPayloadFromTree = (nodes: TreeNode[]): { permissionId: string; value: any; unassigned: boolean }[] => {
		let allPermissions: Permission[] = [];
		nodes.forEach(node => {
			allPermissions.push(...traverseNode(node));
		});
		return allPermissions.map(perm => {
			const value =
				perm.type === 'dropdown' ? getOptionValue(perm, perm.value) : perm.value;
			return {
				permissionId: perm.id,
				value: value,
				unassigned: perm.unassigned === true || perm.unassigned === "true",
			};
		});
	};

	/**
	 * Navigation function for the "Next" button.
	 */
	const nextStep = () => {
		if (activeStep === 1) {
			// Step 1: Move to Step 2 without creating the role.
			setActiveStep(2);
		} else if (activeStep === 2) {
			// Step 2: Proceed to review.
			setActiveStep(3);
		} else if (activeStep === 3) {
			// Final step: create the role and then map permissions.
			setIsLoading(true);
			const roleData = {
				attributes: [{ key: 'title', value: createInputs.role }],
			};
			// Handle successful role creation.
			const handleRoleSuccess = (body: any) => {
				// Build payload from accessData.
				const rolePermissionValue = buildPayloadFromTree(accessData);
				const permissionData = {
					title: body.title,
					rolePermissionValue,
				};
				// Handle successful permission mapping.
				const handlePermissionSuccess = (res: any) => {
					setIsLoading(false);
					onCloseClick();
					setActiveStep(1);
					dispatch(roleListGetRequest());
					setCreateInputs({ role: '' });
					toast.success(t('roles.role_created_successfully'), {
						position: 'top-center',
						hideProgressBar: true,
					});
				};
				// Handle errors in permission mapping.
				const handlePermissionError = (err: any) => {
					setIsLoading(false);
					toast.error(t('roles.role_permission_creation_failed'), {
						position: 'top-center',
						hideProgressBar: true,
					});
				};
				dispatch(
					permissionCreateRequest(permissionData, body.id, handlePermissionSuccess, handlePermissionError)
				);
			};
			// Handle role creation error.
			const handleRoleError = (err: any) => {
				setIsLoading(false);
				toast.error(t('roles.role_creation_failed'), {
					position: 'top-center',
					hideProgressBar: true,
				});
			};
			dispatch(roleCreateRequest(roleData, handleRoleSuccess, handleRoleError));
		}
	};

	/**
	 * Navigation function for the "Back" button.
	 */
	const backStep = () => {
		if (activeStep > 1) setActiveStep(activeStep - 1);
	};

	/**
	 * Cancel the wizard and reset the form.
	 */
	const cancelWizard = () => {
		onCloseClick();
		setActiveStep(1);
		setCreateInputs({ role: '' });
	};

	console.log("accessData>>", accessData);

	return (
		<Offcanvas
			direction="end"
			isOpen={show}
			toggle={cancelWizard}
			className="w-100 bg-light"
		>
			<OffcanvasBody>
				{/* Step Navigation Header */}
				<div className="mb-4">
					<Row className="justify-content-center">
						<Col lg={8}>
							<Nav pills className="nav-justified">
								<NavItem>
									<NavLink
										href="#"
										className={classnames({
											active: activeStep === 1,
											'bg-primary text-white': activeStep === 1,
										})}
									>
										{t('roles.role_name')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										href="#"
										className={classnames({
											active: activeStep === 2,
											'bg-primary text-white': activeStep === 2,
										})}
									>
										{t('roles.permissions')}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										href="#"
										className={classnames({
											active: activeStep === 3,
											'bg-primary text-white': activeStep === 3,
										})}
									>
										{t('roles.review')}
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
					</Row>
				</div>
				{/* Step Content */}
				<TabContent activeTab={activeStep}>
					<TabPane tabId={1}>
						<RoleName
							createInputs={createInputs}
							setCreateInputs={setCreateInputs}
							nextClicked={nextStep}
						/>
					</TabPane>
					<TabPane tabId={2}>
						<PermissionsManagement
							accessData={accessData}
							setAccessPermissions={setAccessPermissions}
							setAccessData={setAccessData}
							accessPermissions={accessPermissions}
						/>
					</TabPane>
					<TabPane tabId={3}>
						<Review
							createInputs={createInputs}
							accessData={accessData}
							CreatePermission={nextStep}
						/>
					</TabPane>
				</TabContent>
			</OffcanvasBody>
			{/* Wizard Footer Buttons */}
			<div className="offcanvas-footer bg-light border-top p-3 d-flex justify-content-between align-items-center">
				<div>
					{activeStep > 1 && activeStep < 4 && (
						<Button color="danger" onClick={backStep} className="me-2">
							{t('roles.back')}
						</Button>
					)}
					<Button color="danger" onClick={cancelWizard} className="me-2" outline>
						{t('roles.cancel')}
					</Button>
				</div>
				<Button
					color="success"
					onClick={nextStep}
					disabled={(activeStep === 1 && !createInputs.role) || isLoading}
				>
					{isLoading
						? t('roles.creating')
						: activeStep === 3
							? t('roles.create')
							: t('roles.next')}
				</Button>
			</div>
		</Offcanvas>
	);
};

export default withTranslation()(Create);
