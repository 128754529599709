import BreadCrumb from 'Components/Common/BreadCrumb';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Reports from './Reports';

const Main = (props: any) => {
	document.title = "DTS | Zilter - Reports";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("reports.listing.breadcrumb.reports")} pageTitle={props.t("reports.listing.breadcrumb.reports")} />
                    <Reports />
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Main);


