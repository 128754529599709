import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

interface EditModalProps {
    show?: boolean;
    onApplyClick: () => void;
    onCloseClick: () => void;
    onchange: (column: { accessor: string; }) => void
    toggleColumn: (column: { accessor: string; }) => void
    onClickmove: (column: { accessor: string; }) => void
    columns: [any];
    visibleColumns: [any];
    deSelectColumnall: () => void
    onChangeIndex: (column: any, index: any) => void
    tempcolumns: [any];
    leftFields: any;
    setTempColumns: (columns: any) => any;
    setLeftFields: (columns: any) => any;
    t: any;
}

const EditModal: React.FC<EditModalProps> = ({ leftFields, tempcolumns, setTempColumns, onChangeIndex, show, toggleColumn, onApplyClick, onCloseClick, columns, onchange, onClickmove, setLeftFields, deSelectColumnall, t }) => {

    const [column, setColumn] = useState<any>({});
    const [searchvalue, setSearchvalue] = useState<any>("");

    const onDrop = (index: any) => {
        onChangeIndex(column, index)
    };
    const onDrag = (column: any) => {
        setColumn(column)
    };

    const search = (value: any) => {
        let updatedList: any = [];
        tempcolumns && tempcolumns.length && tempcolumns.map((field: any) => {
            let item = field.valueJson;
            if (item?.key !== 'name' && item?.key !== 'action' && item?.key !== 'id' && item?.key !== 'checkbox', field?.accessor !== 'createdAt') {
                if (item?.label.toLowerCase().search(value.toLowerCase()) !== -1) updatedList.push(field)
            }
        })
        setLeftFields(updatedList)
        setSearchvalue(value);
    }

    return (
        <Modal backdrop="static" className="edit-column h-75" scrollable={true} size="xl" fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onCloseClick(); setTempColumns(columns) }}>
                <span className="m-0 me-2 text-light">
                    {t("common.table.choose_which_columns_you_see")}
                </span>
            </ModalHeader>
            <ModalBody className="p-5">
                <Row>
                    <Col lg={6} md={6}
                        onDragOver={(e) => e.preventDefault()}>
                        <h6 className="fw-bold mt-2 fs-14 mb-3">{t("common.table.select_colum_to_add")}</h6>
                        <div className=" px-1 pb-2">
                            <div className="search-box">
                                <Input
                                    type="text"
                                    size={14}
                                    className="search"
                                    placeholder={t("common.filter.search.search_field")}
                                    onChange={(e) => search(e.target.value)}
                                    value={searchvalue}
                                />
                                {searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => search("")}></i>}
                            </div>
                        </div>
                        <SimpleBar autoHide={false} className="simplebar-track-primary" style={{ maxHeight: "300px" }}>
                            <div className="vstack gap-2">
                                {
                                    leftFields.map((column: { accessor: any; show?: any; Header?: any; }, index: React.Key | null | undefined) => {
                                        if (column.accessor !== 'name' && column.accessor !== 'action' && column.accessor !== 'id' && column.accessor !== 'checkbox') {
                                            return (
                                                <div className="form-check form-check-primary fs-16" key={index}>
                                                    <Input type="checkbox" className="form-check-input" id={`checkbox-${column.accessor}`} checked={column.show} onClick={() => toggleColumn(column.accessor)} />
                                                    <Label className="form-check-label" htmlFor={`checkbox-${column.accessor}`}>
                                                        {column.Header}
                                                    </Label>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        </SimpleBar>
                    </Col>
                    <Col lg={6} md={6}>
                        <h6 className="fw-bold ms-3 mb-3">{t("common.table.selected_columns")} ({tempcolumns.filter((item: any) => item.show).length}) </h6>
                        <SimpleBar autoHide={false} className="simplebar-track-primary" style={{ maxHeight: "300px" }}
                            onDragOver={(e: any) => e.preventDefault()}>
                            <div className="vstack">
                                {
                                    tempcolumns.map((column, index) => {
                                        if (column.accessor == 'name') return <div className="mt-3">
                                            <Col md={12} key={index}>
                                                <Button size="md" disabled={true} color={"dark"} className="hstack form-control btn-soft-muted" outline={true}>
                                                    <span className="hstack align-center justify-content-start fs-16">{column.Header}</span>
                                                </Button>
                                            </Col>

                                            <Col md={12} key={index} className="hstack gap-1 text-secondary mt-2 mb-2 justify-content-center">
                                                <hr className="m-1 w-25 border border-1 dropdown-divider border-primary" /><span className="text-primary fs-16">{t("common.table.columns_above_are_freezed")} </span><hr className="m-1 w-25 dropdown-divider border border-1 border-primary" />
                                            </Col>
                                        </div>
                                        if (column.show) return <div className="pt-2" onDrop={() => { onDrop(index); }} onDrag={() => { onDrag(column); }}>
                                            <Col md={12} key={index} className="hstack gap-1 text-primary">
                                                <Button size="md" color={"btn-ghost-primary"} className="form-control justify-content-between hstack border border-opacity-25 cursor-grab" outline={true} draggable>
                                                    <span className="hstack align-items-center gap-2"><i className="bx bx-grid-vertical align-center justify-content-end fs-18 text-primary-emphasis" />{column.Header}</span>
                                                    <i className="hstack bx bx-x align-center justify-content-end fs-18" onClick={() => toggleColumn(column.accessor)}></i>
                                                </Button>
                                            </Col>
                                        </div>
                                    })
                                }
                            </div>
                        </SimpleBar>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className='hstack justify-content-start'>
                <div className='hstack justify-content-start gap-2'>
                    <Button
                        onClick={() => onApplyClick()}
                        color="primary"
                        className="btn-label">
                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                        {t("common.offcanvas.apply")}
                    </Button>
                    <Button
                        onClick={() => { onCloseClick(); setTempColumns(columns) }}
                        color="primary"
                        className="btn-soft-primary">
                        <i className="ri-layout-column-fill label-icon align-middle fs-16 me-2"></i>
                        {t("common.offcanvas.import_cancel")}
                    </Button>
                    <Button
                        onClick={() => deSelectColumnall()}
                        color="primary"
                        className="btn btn-ghost-primary waves-effect waves-primary">
                        {t("common.offcanvas.remove_all_columns")}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default withTranslation()(EditModal);