import { useEffect, useState } from "react";
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	Label,
	Row,
	UncontrolledDropdown,
} from "reactstrap";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import TableContainer from "../TableComponent";
import DeleteModal from "../Actions/DeleteModal";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { teamDelete, teamsListGetRequest } from "store/teams/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { withTranslation } from "react-i18next";
import AddDrawer from "../Actions/AddDrawer";
import EditDrawer from "../Actions/EditDrawer";
import AccessDenied from "pages/AccessDenied/Denied";
import ListingLoader from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { checkTheyOwn } from "utils";

interface SortConfig {
	[key: string]: {
		direction: 'asc' | 'desc';
	};
}

const TeamsTable = (props: any) => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
	const paginationDetails = useSelector((state: ApplicationState) => state.teams.pagination);
	const me = useSelector((state: ApplicationState) => state.teams.team);
	const loading = useSelector((state: ApplicationState) => state.teams.loading);
	const navigate = useNavigate();
	const [record, setrecord] = useState<any>({});
	const [columns, setColumns] = useState<any>([])
	const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
	const [isAdd, setIsAdd] = useState<boolean>(false);
	const [editRecord, setEditRecord] = useState<any>(null);
	const [pageSize, setPageSize] = useState<any | null>(10)
	const [confirmationStatus, setConfirmationStatus] = useState<any>("ALL")
	const [roleFilter, setRoleFilter] = useState<any>()
	const [sortConfig, setSortConfig] = useState<SortConfig>({});
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<string>("createdAt");
	const [sortOrder, setSortOrder] = useState<string>("desc");
	const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
	const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
	const [currentPage, setCurrentPage] = useState(0);
	const [viewAccess, setViewAccess] = useState<boolean>(false);
	const [create, setCreate] = useState<boolean>(false);
	const [edit, setEdit] = useState<any>(null);
	const [deleteaccess, setDeleteAccess] = useState<any>(null);
	const [pagination, setPagination]= useState<any>(null);

	const toggleIcon = (field: string) => {
		setSortConfig((prevState) => {
			const currentDirection = prevState[field]?.direction;
			const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
			return { ...prevState, [field]: { direction: newDirection } };
		});
	};
	
	useEffect(() => {
		let queryParams: any = new URLSearchParams({});
		queryParams.set('page', 0)
		queryParams.set('pageSize', pageSize)
		dispatch(teamsListGetRequest(queryParams.toString()));
	}, [])

	useEffect(() => {
		let columns: any = [];
		if (teamList && teamList.length > 0) {
			const keys = Object.keys(teamList[0])
			const fieldsCols: any =
				keys.map((fields: any, index: any) => {
					const isSorted = sortConfig[fields]?.direction;
					const isIconUp = isSorted === 'asc';
					if (fields == "id")
						return {
							Header: _.startCase(fields),
							accessor: fields,
							filterable: true,
							isSortable: false,
							className: "hoverable",
							disableFilters: true,
							show: false,
						};
					else if (fields == "name")
						return {
							Header: (
								<div className="cursor-pointer text-primary d-flex align-items-center" key={columns.id}
									onClick={(e) => {
										setCurrentPage(0);
										handleselected(fields, "column")
										e.stopPropagation();
										toggleIcon(fields);
									}}
									{...columns.isSortable ? columns.getSortByToggleProps() : {}}>
									{_.startCase(fields)}
									<span>
										<i
											className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}
										></i>
									</span>
								</div>
							),
							keys: { columns },
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
								const { name, email } = cellProps.row.original;
								return (
									<div style={{ minWidth: 400 }} className="d-flex gap-5 align-items-center justify-content-between">
										<div className="d-flex align-items-center gap-2">
											<div className="flex-grow-1 ms-3">
												<span
													className="cursor-pointer text-decoration-none text-ellipsis"
													onClick={() => {
														handleEditClick(cellProps.row.original.id);
													}}
												>
													{name || email}
												</span>
												<p className="text-muted fs-12 fw-light mb-0 text-decoration-none text-ellipsis">
													{email}
												</p>
											</div>
										</div>
										<div className="me-2">
											<ButtonGroup>
												<UncontrolledDropdown>
													<DropdownToggle
														color="primary"
														size="sm"
														title="Manage Team"
														className="btn-primary justfy-self-end hoverbutton ms-1"
													>
														<i className="ri-settings-2-fill label-icon align-middle"></i>
													</DropdownToggle>
													<DropdownMenu>
														{edit && (edit?.toLowerCase() == "all" || (edit?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																onClick={() => {
																	onClickrow(cellProps.row.original.id, index);
																	setEditRecord(cellProps.row.original.id);
																}}
															>
																{props.t("common.table.edit")}
															</DropdownItem>
														) : null}
														{deleteaccess && (deleteaccess?.toLowerCase() == "all" || (deleteaccess?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																disabled={
																	cellProps.row.original.id === me?.id ||
																	cellProps.row.original.role?.title === "Owner"
																}
																onClick={() => {
																	setPageSize(pageSize);
																	setCurrentPage(currentPage);
																	onClickrow(cellProps.row.original, index);
																	setisDeleteOpen(true);
																}}
															>
																{props.t("common.table.delete")}
															</DropdownItem>
														) : null}
													</DropdownMenu>
												</UncontrolledDropdown>
											</ButtonGroup>
										</div>
									</div>
								);
							},
						};
						else if (fields == "parentTeam")
							return {
								Header: (
									<div className="cursor-pointer text-primary d-flex align-items-center" key={columns.id}
										onClick={(e) => {
											setCurrentPage(0);
											handleselected(fields, "column")
											e.stopPropagation();
											toggleIcon(fields);
										}}
										{...columns.isSortable ? columns.getSortByToggleProps() : {}}>
										{_.startCase(fields)}
										<span>
											<i
												className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}
											></i>
										</span>
									</div>
								),
								keys: { columns },
								isSortable: true,
								accessor: fields,
								filterable: true,
								disableFilters: true,
								show: true,
								Cell: (cellProps: any) => {
									const { parentTeam } = cellProps.row.original;
									return (
										<div style={{ minWidth: 400 }} className="d-flex gap-5 align-items-center justify-content-between">
											<div className="d-flex align-items-center gap-2">
												<div className="flex-grow-1 ms-3">
													<span
														className="cursor-pointer text-decoration-none text-ellipsis"
													>
														{parentTeam?.name}
													</span>
												</div>
											</div>
										</div>
									);
								},
							};
					else if (fields == "createdAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
									setCurrentPage(0);
									setSortBy(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.createdAt).format('lll')}</div>;
							},
						};
					else if (fields == "updatedAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.updatedAt).format('lll')}</div>;
							},
						};
					else
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							className: "hoverable",
							show: true,
							Cell: (cellProps: any) => {
								switch (fields.type) {
									case "text":
									case "email":
									case "password":
									case "number":
									case "date":
										return (
											<div className="d-flex align-items-center text-primary" key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
											</div>
										);
									case "textarea":
										return (
											<div key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
												<textarea
													className="form-control"
													onClick={() => {
														onClickrow(cellProps.row.original, index);
													}}
													id={cellProps.row.original.name}
													name={cellProps.row.original.name}
													value={cellProps.cell.value || ""}
												></textarea>
											</div>
										);
									default:
										return <div key={index}>--</div>;
								}
							},
						};
				})
			columns.push.apply(columns, fieldsCols);
			setColumns(columns.filter((x: any) => x.Header !== "Id" && x.Header !== "Parent Team Id"));
		}
	}, [deleteaccess, edit, teamList]);

	useEffect(() => {
		let teamRole = userProfile?.role?.title;
		if (teamRole === "Owner") {
			setViewAccess(true)
			setCreate(true)
			setEdit("all")
			setDeleteAccess("all")
		}
		else if (subLevelPermissionsList && subLevelPermissionsList.teams) {
			let access = subLevelPermissionsList.teams;
			if (access && access?.length) {
				if (access && access.length) {
					access.map((item: any, index: any) => {
						if (item.name.toLowerCase() == 'view') {
							item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
						}
						if (item.name.toLowerCase() == 'create') {
							item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
						}
						if (item.name.toLowerCase() == 'edit') {
							item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.name)
						}
						if (item.name.toLowerCase() == 'delete') {
							item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.name)
						}
					})
				}
			}
			else {
				setViewAccess(false)
			}
		}
	}, [subLevelPermissionsList, userProfile])

	useEffect(() => {
		if (paginationDetails) {
			setPagination(paginationDetails);
		}
	}, [paginationDetails])

	const handleselected = (option: any, name: string) => {
		let queryParams: any = new URLSearchParams({});
		switch (name) {
			case 'next-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage + 1)
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage + 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				break;
			case 'previous-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage - 1)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage - 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				break;
			case 'page-number':
				setPageSize(pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', option);
				break;
			case 'page-size':
				let page = currentPage;
				page = 0
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				sortBy && queryParams.set('sortBy', sortBy);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				queryParams.set('page', page);
				queryParams.set('pageSize', option);
				break;
			case 'search':
				queryParams.set('search', option);
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			case 'clear-search':
				setSearchQuery('')
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			case 'confirmation-status':
				if (option == "ALL") {
					setRoleFilter(roleFilter);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					roleFilter && queryParams.set('roleId', roleFilter?.value);
					queryParams.set('status', `ALL`)
				}
				else {
					setRoleFilter(roleFilter);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					queryParams.set('status', option);
					roleFilter && queryParams.set('roleId', roleFilter?.value);
				}
				break;
			case 'role-filter':
				setConfirmationStatus(confirmationStatus);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('roleId', option);
				break;
			case 'column': //column-phone
				setSortBy(option);
				if (sortBy === option) {
					setSortOrder(sortOrder === "asc" ? "desc" : "asc");
				} else {
					setSortOrder("asc");
				}
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				searchQuery && queryParams.set('search', searchQuery);
				queryParams.set('sortBy', option);
				queryParams.set('sortOrder', sortOrder === "asc" ? "desc" : "asc");
				break;

			default:
				// handle default case if needed  column-confirmation
				break;
		}
		dispatch(teamsListGetRequest(queryParams.toString()));
	};

	const onClickrow = (data: any, index: any) => {
		setrecord(data);
	};

	const handleEditClick = (id: string) => {
		navigate(`/teams/${id}`);
	};

	const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
		let queryParams: any = new URLSearchParams({});
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });					
					queryParams.set('page', newPageNo);
					queryParams.set('pageSize', pagination?.size);
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });			
					queryParams.set('page', newPageNo);
					queryParams.set('pageSize', pagination?.size);
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });			
				queryParams.set('page', 0);
				queryParams.set('pageSize', pagination?.size);
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
				queryParams.set('page', newPageNo);
				queryParams.set('pageSize', pagination?.size);
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
				queryParams.set('page', newPageNo);
				queryParams.set('pageSize', pagination?.size);
                break;
        }
		dispatch(teamsListGetRequest(queryParams.toString()));
    };

	  // Handle PageSize Change
	const onChangePageSize = (value: any) => {		
		setPageSize(value)
		setPagination({ ...pagination, size: value, page: 0 })	 
		let queryParams: any = new URLSearchParams({});
		queryParams.set('page', 0);
		queryParams.set('pageSize',value);
		dispatch(teamsListGetRequest(queryParams.toString()));
    }

	return (
		<> {!authLoading ?
			viewAccess ? <div>
				<ToastContainer />
				<DeleteModal
					show={isDeleteOpen}
					onCloseClick={() => setisDeleteOpen(false)}
					record={record}
				/>
				<AddDrawer
					show={isAdd}
					onClose={() => setIsAdd(!isAdd)}
				/>
				<EditDrawer
					id={editRecord}
					show={!!editRecord}
					onClose={() => setEditRecord(null)}
				/>
				<Row className='hstack justify-content-between gap-3 pb-3'>
					<Col md={3} sm={12} xl={3} xxl={3} lg={3}>
						<div className="search-box">
							<InputGroup className='border rounded'>
								<ButtonGroup>
									<UncontrolledDropdown>
										<DropdownToggle tag="button" className="btn btn-light">
											All
										</DropdownToggle>
									</UncontrolledDropdown>
								</ButtonGroup>
								<Input className='border-0' placeholder={props.t("common.filter.search.search")} style={{ padding: '1.2rem 0.9rem' }}onChange={(e) => {
									setSearchQuery(e.target.value);
									handleselected(e.target.value, "search");
								}} value={searchQuery}></Input>
							</InputGroup>
							{searchQuery === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={(e) => {
									setSearchQuery("");
									handleselected("", "search");
								}}></i>}
						</div>
					</Col>
					<Col md={3} sm={12} xl={3} xxl={3} lg={3}>
						<div className='hstack gap-2 justify-content-end gap-2'>
							<div className='hstack gap-2'>
								<Button
									onClick={() => setIsAdd(true)}
									disabled={!create}
									color="primary"
									size="sm"
									className="btn-label">
									<i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
									{props.t("team.add_team")}
								</Button>
							</div>
						</div>
					</Col>
				</Row>
				<Card className="students-table pt-1  border-0 shadow-none">
					<CardBody className="table-card gridjs-border-none mt-n2 border-0">
						<TableContainer
							tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            thClass="border-bottom-1 table-soft-primary" 
                            columns={columns} 
                            data={teamList || []}
                            paginations={pagination}
                            props={props}
                            pageChanged={pageChanged}
                            onChangePageSize={onChangePageSize}
							loading={loading}
						/>
					</CardBody>
				</Card>
			</div>
				: <AccessDenied />
			: <ListingLoader
				columns={columns}
				tableClass="align-middle table-nowrap"
				theadClass="table-light text-muted"
				thClass="border-bottom-1 table-soft-primary" />
		}
		</>
	);
};
export default withTranslation()(TeamsTable);
