import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Input,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { convertCurrency, handleSelectValue, isJson } from "utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { currencyGetRequest } from "store/currency/action";
import { ApplicationState } from "store";
import currency from "../../../../../common/currency";


const PreviewModal: React.FC<any> = ({ recommendLoading, onRemove, disabled, handleButtonClick, props, link, createRecommend, show, onCloseClick, selectedlist, shortlistFields, isSubagent, courseUpdateChange, updateRow, setRecommendationName, recommendationName }: any) => {
    const dispatch: any = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(currencyGetRequest())
    }, [])

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };
    

    const filteredCurrencies = currency.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency_symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="xl"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
            {props.t("student.details.application.review_and_send")}
            </ModalHeader>
            <ModalBody>
                <div>
                    {!recommendLoading ?
                    <div className="vstack gap-2">
                        <div className="w-50">
                            <Label>
                                {props.t("common.modals.name")}
                            </Label><span className="fw-bold text-danger">{" "}*</span>
                            <Input type={"text"} className="form-control w-100" placeholder={props.t("common.filter.search.enter_name")} value={recommendationName} onChange={(e: any) => setRecommendationName(e.target.value)} />
                        </div>

                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        {shortlistFields?.length && shortlistFields.map((item: any) => {
                                            return <th>
                                                {item.label}
                                            </th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody id="task-list">
                                    {selectedlist && selectedlist.map((record: any, key: any) => {
                                        return <tr>
                                            <td>
                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(record, false)}
                                                >
                                                    <i className="ri-delete-bin-fill"></i>
                                                </button>
                                            </td>
                                            {
                                                shortlistFields && shortlistFields.length && shortlistFields.map((fields: any) => {
                                                    let rendervalue = record[fields.key];
                                                    let originalFee = parseFloat(record['course_fee']);
                                                    let originalCurrency = typeof record['currency'] === "string" ? record['currency']?.toUpperCase().match(/[A-Z]+/g)?.[0] : null;
                                                    if (fields.key === 'course_fee') {
                                                        return <td>
                                                            <Input defaulValue={originalFee?.toFixed(2)} value={originalFee || ''} placeholder="Enter Fee" onChange={(e: any) => { courseUpdateChange(e?.target?.value, record, 'course_fee') }} />
                                                        </td>
                                                    }
                                                    if (fields.key === 'currency') {
                                                        return <td>
                                                            <ButtonGroup>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        tag="button"
                                                                        className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary"
                                                                        style={{ width: '160px', color: 'inherit' }}
                                                                    >
                                                                        {originalCurrency} <i className="mdi mdi-chevron-down"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdownmenu-primary">
                                                                        <div className="position-relative p-2">
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Search"
                                                                                value={searchTerm}
                                                                                onChange={(event) => setSearchTerm(event.target.value)}
                                                                            />
                                                                            <span className="position-absolute end-0 top-50 translate-middle-y me-3">
                                                                                <i className="ri-search-line"></i>
                                                                            </span>
                                                                        </div>
                                                                        {filteredCurrencies?.map((currency: any, index: number) => (
                                                                            <DropdownItem
                                                                                key={index}
                                                                                onClick={(e) => courseUpdateChange(currency?.currency_code, record, 'currency')}
                                                                            >
                                                                                {currency?.name} {currency?.currency_code} ({currency?.currency_symbol})
                                                                            </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </ButtonGroup>
                                                        </td>
                                                    }
                                                    if (fields.tableRenderType == 'hyperlink' && record?.[fields.key] && record?.[fields.key] !== "NA") {
                                                        rendervalue = <div className='ms-3'>
                                                            <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(record[fields.key])} />
                                                        </div>
                                                    }
                                                    else if (fields.tableRenderType == 'tag') {
                                                        let value = record?.[fields.key]
                                                        let intakearray = value.split(",")
                                                        rendervalue = <div>
                                                            {intakearray?.length && intakearray.map((item: any) => {
                                                                return <div className='ms-0'>
                                                                    <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                    else if (fields?.key && fields?.optionLabel) {
                                                        rendervalue = record?.[fields.key]?.[fields.optionLabel] ? record?.[fields.key]?.[fields.optionLabel] : 'NA'
                                                    }
                                                    else if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                                        rendervalue = handleSelectValue(record[fields.key], fields.values);
                                                    }
                                                    return <td>{rendervalue}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div> : <DtsTablePlaceholder
                            rows={selectedlist.length} cols={5}
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                </div>
            </ModalBody>
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    {isSubagent ? null : <Button
                        className="btn btn-primary w-auto"
                        color={'primary'}
                        onClick={() => {
                            if (!isSubagent) {
                                createRecommend(false);
                            }
                            else {
                                createRecommend(false);
                            }
                        }}

                        disabled={disabled || !recommendationName}
                    >
                        {props.t("student.details.application.save_draft")}
                    </Button>}
                    <Button
                        className="btn btn-primary w-auto"
                        color={'success'}
                        onClick={() => {
                            if (!isSubagent) {
                                createRecommend(true);
                            }
                            else {
                                createRecommend(false);
                            }
                        }}

                        disabled={disabled || !recommendationName}
                    >
                        {isSubagent ? "Apply" : props.t("student.details.application.send_shortList")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("student.details.application.cancel")}
                    </Button>
                </div>
        </Modal>
    </>
    );
};

export default PreviewModal;
