import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';
import {
    NavLink,
    Input,
    Col,
    Row,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Spinner,
    Tooltip,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { setInvoceData } from "store/auth/action";
import { convertCurrency, handleSelectValue, isJson } from "utils";
import { ApplicationState } from "store";
import { channelGetRequest, invoicesStudentsList } from "store/channels/action";
import { applicationUpdateRequest } from "store/applications/action";
import Step1 from "./step1";
import { documentDownload } from "store/documents/action";
import moment from "moment";
import SimpleBar from "simplebar-react";
import SelectDropdown from "./SelectDropdown";
import { currencyGetRequest } from "store/currency/action";
import Step2 from "./step2";
import { withTranslation } from "react-i18next";
import Upload from "./Import/Upload";
import Mapping from "./Import/Mapping";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import Step3 from "./step3";
import Step4 from "./step4";
import { toast } from "react-toastify";
import axios from "axios";
import { InvoiceStudentUploadRequest } from "store/invoices/action";

interface PaymentDetail {
    paymentNo: string;
    commission: number;
    percentage: number;
}

const StudentsList: React.FC<any> = (props: any) => {
    const { id } = useParams()
    const { t } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const ChannelDetail = useSelector((state: ApplicationState) => state.channels.details)
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const students = useSelector((state: ApplicationState) => state.channels.invoicesstudents)
    const loading = useSelector((state: ApplicationState) => state.channels.loading)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [mainList, setMainList] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [updatedRow, setUpdatedRow] = useState<any>({});
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [assignRates, setAssignRates] = useState<any>(null)
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    const [parsedRates, setParsedRates] = useState<any>(null)
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [isImport, setIsImport] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<any>([])
    const [mappings, setMappings] = useState<any>('');
    const [finalMappings, setFinalMappings] = useState<any>([]);
    const [unMappedCount, setUnMappedCount] = useState<any>();
    const [disabled, setDisabled]= useState<boolean>(true);    
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [filter, setFilter]: any = useState(null);
    const [search, setSearch] = useState<string>("");    
    const [list, setList] = useState<any>('');
    const [selectedLstObj, setSectedListObj] = useState<any>([]);
    const [searchStatic, setStaticSearch]= useState<string>("");    
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [error, setError]: any = useState(null);
    const [errorTooltip, setErrorTooltip]: any = useState(false);
    const [columnMapping, setColumnMapping]= useState<any>({});
    const [fileId, setFileId]= useState<string>("");    
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(channelGetRequest(id))
        dispatch(invoicesStudentsList(id))
    }, [dispatch, id])

      // Handle PageSize Change
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value, page: 0 })
    }

    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };
    
        // Handle sort changes
    const handleSort = (columnName: any) => {
        setPagination({ ...pagination, page: 0 })
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            setSortOrder(sort);
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
        }
    };

    useEffect(() => {
        const parsedData: any = students && students.length ? students.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
            let options: any = []
            valuesJson?.commissions?.length &&
            valuesJson?.commissions.forEach((rule: any) => { // Changed from map() to forEach()
                if (rule?.ruleApplied && rule?.id === id) {
                    options.push({ label: rule?.ruleApplied?.name, value: rule?.ruleApplied?.id, rule: rule, channels: rule });
                }
            });   
            let option: any = options?.length ? options.find((obj: any) => obj?.value === valuesJson?.commission?.id || obj?.value === valuesJson?.channels?.ruleApplied?.id) : null
             
            let expected_commission: any = option?.rule?.paymentSchedule?.paymentDetails?.length ? 0 : parseFloat(option?.rule?.commission)
            let difference: any = option?.rule?.paymentSchedule?.paymentDetails?.length ? 0 : parseFloat(option?.rule?.commission)
         

            let values = {
                ...item,
                ...valuesJson,
                id: item.id,
                createdAt: item.createdAt,
                expected_commission: expected_commission,
                actual_commission: 0,
                difference: difference,
                option:option,
                channels: option?.channels
            };
            
            return values;
        }) : []


        let studentsList = parsedData && parsedData.length && parsedData.reverse()
        setMainList(studentsList)
    }, [students])


    const onUpdate = (id2: any, keyValue: any) => {
        const handleSuccess = (body: any) => {
            dispatch(invoicesStudentsList(id))
            if(activeArrowTab === 1) {
                setSelectedList([])
                setCheckedRows({})
            }
        }
        dispatch(applicationUpdateRequest(id2, keyValue, handleSuccess, () => { }))
    }

    useEffect(() => {
        if(!selectedList?.length) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }, [selectedList])

    const onhandleChange = (value: any, key: any, id: any, option?: any) => {
        let newData: any = []
        if(activeArrowTab === 1 ) {       
            setMainList((prevList: any) => {
                newData = [...prevList]; // Create a new array instead of modifying the original state directly. 
                let index = newData?.length && newData.findIndex((item: any) => item.id === id);
                if (index > -1) {
                    let difference = 0;
                    newData[index] = { ...newData[index], [key]: value };
                    if(key === 'settlement_type') {
                        newData[index] = { ...newData[index], settlementOption:option};
                    }
                    if (key === 'actual_commission') {
                        let expected = newData[index]?.option?.rule?.paymentSchedule?.paymentDetails?.length ? Number(newData[index]?.expected_commission) : Number(parseFloat(option?.rule?.commission)) || 0
                        let amount: any = expected ? expected?.toFixed(2) : 0;
                        difference = amount ? parseFloat(amount) - parseFloat(value ? value : 0) : 0;
                        newData[index] = { ...newData[index], difference: difference?.toFixed(2), [key]: value, expected_commission:amount };
                    }
                    setUpdatedRow(newData[index]);
                }
                return newData
            });
        }
        else {
            setSelectedList((prevList: any) => {
                newData = [...prevList]; // Create a new array instead of modifying the original state directly.
                let index = newData?.length && newData.findIndex((item: any) => item.id === id);
                if (index > -1) {
                    let difference = 0;
                    newData[index] = { ...newData[index], [key]: value };
                    if(key === 'settlement_type') { 
                        newData[index] = { ...newData[index], settlementOption:option};
                    }
                    if (key === 'actual_commission') {
                        let expected = newData[index]?.option?.rule?.paymentSchedule?.paymentDetails?.length ? Number(newData[index]?.expected_commission) : Number(parseFloat(option?.rule?.commission)) || 0
                        let amount: any = expected ? expected?.toFixed(2) : 0;
                        difference = amount ? parseFloat(amount) - parseFloat(value ? value : 0) : 0;
                        newData[index] = { ...newData[index], difference: difference?.toFixed(2), [key]: value, expected_commission:amount };
                    }
                    setUpdatedRow(newData[index]);
                }
                return newData
            });
        }
    }

    const paymentHandle = (values: any, key: any, id: any, option?: any) => {
        let newData: any = []
        if(activeArrowTab === 1 ) {
            setMainList((prevList: any) => {
                newData = [...prevList]; // Create a new array instead of modifying the original state directly.
                let paytotal: any = 0
                values.map((value: any, index: any) => {
                    paytotal = paytotal + value.commission
                })
                let index = newData?.length ? newData.findIndex((item: any) => item?.id === id) : -1;
                if (index > -1) {
                    let expected_commission: any = paytotal;
                    let difference = paytotal ? parseFloat(expected_commission) - parseFloat(newData[index]?.actual_commission) : 0;
                    newData[index] = { ...newData[index], itemselectedOption:values,  difference: difference == 0 ? 0 : difference?.toFixed(2), expected_commission:values?.length ? paytotal?.toFixed(2): 0  }
                    setUpdatedRow(newData[index])
                }
                return newData;
            });
        }
        else {
            setSelectedList((prevList: any) => {
                newData = [...prevList]; // Create a new array instead of modifying the original state directly.
                let paytotal: any = 0
                values.map((value: any, index: any) => {
                    paytotal = paytotal + value.commission
                })
                let index = newData?.length ? newData.findIndex((item: any) => item?.id === id) : -1;
                if (index > -1) {
                    let expected_commission: any = paytotal;
                    let difference = paytotal ? parseFloat(expected_commission) - parseFloat(newData[index]?.actual_commission) : 0;
                    newData[index] = { ...newData[index], itemselectedOption:values,  difference: difference == 0 ? 0 : difference?.toFixed(2), expected_commission:values?.length ? paytotal?.toFixed(2): 0  }
                    setUpdatedRow(newData[index])
                }
                return newData;
            });
        }
    }

    const handleRuleSelect = (obj: any, option: any, channels: any) => {
        if(activeArrowTab === 1 && !selectedList?.length) {
            let newList = mainList;
            let index = newList?.length ? newList.findIndex((item: any) => item.id === obj.id) : -1
            if (index > -1) {
                newList[index] = { ...newList[index], commission: option?.value, channels: channels }
                setMainList(newList)
                onUpdate(newList[index]?.id, { commission: option?.value })
                setUpdatedRow(newList[index])
            }
        }
        else {
            let newList = selectedList;
            let index = newList?.length ? newList.findIndex((item: any) => item.id === obj.id) : -1
            if (index > -1) {
                newList[index] = { ...newList[index], commission: option?.value, channels: channels }
                setSelectedList(newList)
                onUpdate(newList[index]?.id, { commission: option?.value })
                setUpdatedRow(newList[index])
            }
        }
    }

    const createStudents = () => {
        let details = ChannelDetail && ChannelDetail.valuesJson && isJson(ChannelDetail.valuesJson) ? JSON.parse(ChannelDetail.valuesJson) : {};
        let channel = { ChannelDetail, ...details, id: id }
        dispatch(setInvoceData(selectedList, channel))
        navigate("/channels/invoices/create-invoice")
    }

    useEffect(() => {
        dispatch(currencyGetRequest())
    }, [selectedCurrency])

    useEffect(() => {
        if (updatedCurrency) {
            setSelectedCurrency(updatedCurrency.selectedCurrency);
            setCurrencySymbol(updatedCurrency.selectedCurrencySymbol)
        }
    }, [updatedCurrency]);

    useEffect(() => {
        if (conversionRates) {
            setAssignRates(conversionRates)
        }
    }, [conversionRates])

    
    const checkExist = (item: any, value: string): boolean => {
        if (!item) return false;
    
        if (typeof item === "string") {
            return item.toLowerCase().includes(value);
        }
    
        if (typeof item === "object") {
            return Object.values(item).some((val) => checkExist(val, value));
        }
    
        return false;
    };

    useEffect(() => {
        if (assignRates && assignRates?.length > 0) {
            const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

            // Check if `targetCurrencyJson` exists and is a valid JSON string
            const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                ? JSON.parse(targetCurrencyJson)
                : {};

            // Set the parsed rates only if convertJson is valid
            setParsedRates(convertJson?.conversion_rates);
        }
    }, [assignRates]);


    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);

                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", documentKey); // Set the desired filename for the downloaded file

                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();

                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {

        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }

    
    // Table Data methods 
    // Decide updating list
    const execute = () => {
        let a = isEqual(selectedList, selectedLstObj)
        return !a
    }


    useEffect(() => {
        setList(selectedList)
        setSectedListObj(selectedList)
    }, [execute])

     useEffect(() => {
        if(selectedList?.length) {
            let newSelected: any = []
            selectedList.map((selected: any) => {
                let item =  mainList.find((item: any) => item?.id === selected?.id)
                if(item) {
                    newSelected.push(item)
                }
            })
            setSelectedList(newSelected)           
        }
    }, [mainList])
   

    
    
    useEffect(() => {
        if (searchStatic) {
            let value = searchStatic.replace(/\+/g, "").toLowerCase();    
            const updatedData = selectedList.filter((item: any) => checkExist(item, value));
            setList(updatedData);
        } else {
            setList(selectedList);
        }
    }, [searchStatic]);
    
    // Rendering Cell value
    const renderCell = (cellProps: any, fields: any) => {
        let itemdetails = { ...cellProps?.row?.original?.valuesJson, ...cellProps.row.original };
         let item = itemdetails
        let options: any = [];
        item?.commissions?.length && item?.commissions.map((rule: any) => {
            if (rule?.ruleApplied && rule?.id === id) {
                options.push({ label: rule?.ruleApplied?.name, value: rule?.ruleApplied?.id, rule: rule, channels: rule })
            }
        })
        let option: any = options?.length ? options.find((obj: any) => obj?.value === item?.commission?.id || obj?.value === item?.channels?.ruleApplied?.id) : null

        const paymentDetails = (option?.rule?.paymentSchedule?.paymentDetails as PaymentDetail[]) || [];

        const paymentOptions = paymentDetails?.map((pd: PaymentDetail, index: number) => ({
          label: `Payment ${index + 1}`, // or use pd.paymentNo if you want the label from the data
          value: pd.paymentNo,
          commission: pd.commission,
        }));

        let expected = item?.option?.rule?.paymentSchedule?.paymentDetails?.length ? item[fields.key] : parseFloat(item?.option?.rule?.commission)
        item = {...item, expected_commission: expected}
        let rendervalue = item[fields.key];
        const originalFee = parseFloat(cellProps.row?.original?.course_fee);
        const originalCurrency = typeof cellProps.row?.original?.currency === "string" ? cellProps.row?.original?.currency.toUpperCase().match(/[A-Z]+/g)?.[0] : null;
        if (fields.key === "commission_schedule") {
            return <ButtonGroup className="w-100">
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="button"
                        title={option && option.label ? option.label : 'Select Commission Rule...'}
                        className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary border-dark border-opacity-25"
                        style={{ width: 200, color: 'inherit' }}
                    >
                        <span className="text-nowrap text-truncate text-nowrap-ellipsis">{option && option.label ? option.label : 'Select Commission Rule...'}</span> <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdownmenu-primary border-dark border-opacity-50"
                    >
                        <SimpleBar style={{ maxHeight: 300, width: 250 }} >
                            {options && options.length ? options.map((option: any, index: number) => (
                                <DropdownItem
                                    key={index}
                                    onClick={(e) => handleRuleSelect(item, option, option?.channels)}
                                >
                                    {option ? option.label : ''}
                                </DropdownItem>
                            )) : null}
                        </SimpleBar>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ButtonGroup>
        }
        else if (fields.key === 'payment_no') {
            return <SelectDropdown
                title="Select"
                multi={true}
                options={paymentOptions}
                placeholder={'Select number of Payment..'}
                property={{}}
                values={item?.itemselectedOption}
                disabled={!paymentOptions?.length}
                onChange={(newValues: any) => { if(paymentOptions?.length) { paymentHandle(newValues, 'paymentnos', item.id, option)} }}
                isSearchable
            />
        }
        else if (fields.key === 'actual_commission') {
            return <Input value={item?.actual_commission} placeholder="Enter actual Commission" onChange={(e) => onhandleChange(e.target.value, fields?.key, item.id, option)} />
        }
        else if (fields.key === 'difference') {
            return rendervalue
        }
        else if (fields.key === 'expected_commission') {
            return item?.option?.rule?.paymentSchedule?.paymentDetails?.length ? rendervalue : parseFloat(item?.option?.rule?.commission)
        }
        else if (fields.key === "course_fee" && selectedCurrency) {
            if (
                !originalCurrency ||
                isNaN(originalFee) ||
                originalCurrency === selectedCurrency ||
                originalCurrency?.toLowerCase() === "na"
            ) {
                return (
                    <div
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                    >
                        <span>{rendervalue}</span>
                    </div>
                );
            }
            if (parsedRates) {
                const convertedFee = convertCurrency(originalFee, originalCurrency, selectedCurrency, parsedRates);
                return (
                    <div
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                    >
                        {currencySymbol} {convertedFee?.toFixed(2)}
                    </div>
                );
            }
        }
        else if (fields.key === "currency") {
            if (selectedCurrency && originalCurrency) {
                return (
                    <div
                        key={cellProps.column.id}
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                    >
                        {selectedCurrency}
                    </div>
                );
            } else {
                return (
                    <div
                        key={cellProps.column.id}
                        style={{ verticalAlign: 'middle' }}
                        className="align-items-center justify-content-between"
                    >
                        { }
                    </div>
                );
            }
        }
        else if (fields.tableRenderType === 'hyperlink' && item[fields.key] && item[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(item[fields.key])} />
            </div>
        }
        else if (fields.type === 'file' && item[fields.key] && item[fields.key] !== "NA") {
            return <div className='ms-3'>
                <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(item[fields.key], `${fields.key}_${item.id}`)} />
            </div>
        }
        else if (fields.isMulti || fields.type === 'selectboxes') {
            let value = item?.[fields.key]
            let arr = value && Array.isArray(value) && value.length && value.map((item: any) => {
                if (item?.value) return item.value
                else return item
            })
            return <div>
                {arr?.length && arr.map((item: any) => {
                    let label = handleSelectValue(item, fields.values);
                    return <div className='ms-0'>
                        <span className="badge bg-success">{label}</span>
                    </div>
                })}
            </div>
        }
        else if (fields.tableRenderType === 'tag') {
            let value = item?.[fields.key]
            let intakearray = value?.split(",")
            return <div>
                {intakearray?.length && intakearray.map((item: any) => {
                    return <div className='ms-0'>
                        <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                    </div>
                })}
            </div>
        }
        else if (fields?.key && fields?.optionLabel) {
            rendervalue = cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] ? cellProps?.row?.original?.[fields.key]?.[fields.optionLabel] : 'NA'
        }
        // else if (fields.type === 'select' || fields.type === 'radio' || fields.type === 'checboxes') {
        //     rendervalue = handleSelectValue(item[fields.key], fields.values);
        // }
        else if (fields.type === "datetime") {
            return <span className="text-ellipsis">{item[fields.key] ? moment(item[fields.key]).format('lll') :
                ''}</span>
        }
         return <div className="d-flex align-items-center justify-content-between w-100">                                      {
            fields.key === 'student' || fields.key === 'courses' || fields.key === 'institution_name' || fields.key === 'difference' || fields.key === 'expected_commission' ?
                <Row style={{ width: 300 }} className='justfy-content-start align-items-center'>
                    <Col md={12} lg={12} className={'hstack justify-content-between'}>
                        {rendervalue && typeof rendervalue !== 'object' ? rendervalue : null}
                    </Col>
                </Row>
                :
                <div className="w-100">
                    <FormBuilder
                        placeholder={fields.placeholder}
                        name={fields.key}
                        onChange={(e: any) => onhandleChange(e?.value || e, fields.key, item.id)}
                        options={fields.values && fields.values.reverse() || []}
                        type={fields.type}
                        value={rendervalue}
                        rows={fields.type==='textarea' ? 3 : null}
                        isEditState={true}
                        actionButtons={false}
                        defaultValue={rendervalue}
                        Editable={true}
                        rest={{ ...fields, isMulti: fields.type === "selectboxes" ? true : fields?.isMulti }}
                        invalid={true}
                        resetValue={rendervalue}
                    />
                </div>
        }
        </div>
    }

    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 4) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };
    

    const onMapping = async () => {
        let data = {
            "fileId": fileId,
            "columnMapping":columnMapping,
            "page": 0,
            "pageSize": 10
        }
        const handleSuccess = (body: any) => {
            let students = body?.data?.content
            const parsedData: any = students && students.length ? students.map((item: any) => {
                const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
                let options: any = []
                valuesJson?.commissions?.length &&
                valuesJson?.commissions.forEach((rule: any) => { // Changed from map() to forEach()
                    if (rule?.ruleApplied && rule?.id === id) {
                        options.push({ label: rule?.ruleApplied?.name, value: rule?.ruleApplied?.id, rule: rule, channels: rule });
                    }
                });   
                let option: any = options?.length ? options.find((obj: any) => obj?.value === valuesJson?.commission?.id || obj?.value === valuesJson?.channels?.ruleApplied?.id) : null
                 
                let expected_commission: any = option?.rule?.paymentSchedule?.paymentDetails?.length ? 0 : parseFloat(option?.rule?.commission)
                let difference: any = option?.rule?.paymentSchedule?.paymentDetails?.length ? 0 : parseFloat(option?.rule?.commission)
             
    
                let values = {
                    ...item,
                    ...valuesJson,
                    id: item.id,
                    createdAt: item.createdAt,
                    expected_commission: expected_commission,
                    actual_commission: 0,
                    difference: difference,
                    option:option,
                    channels: option?.channels
                };
                
                return values;
            }) : []
    
    
            let studentsList = parsedData && parsedData.length && parsedData.reverse()
            setSelectedList(studentsList)
        }
        const handleError = (error: any) => {

        }
        dispatch(InvoiceStudentUploadRequest(fileId, data, handleSuccess, handleError))
        // try {
        //     await axios.post(`https://api.qa.dts.student.zilter.io/applications/invoices/file-upload/${data?.fileId}`, data, config)
        //     .then((res: any) => { 
        //         if(res?.mappings)   {
        //             setMappings(res.mappings)    
        //             toggleArrowTab(activeArrowTab + 1);
        //         }   
        //         else {
        //             const message = () => toast(`An unexpected error occurred, please check uploaded file and try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        //             message();           
        //         }           
        //     })                    

        // }
        // catch (error) {
        //     console.error("Error downloading file:", error);
        //     const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        //     message();               
        // }

    }

    const nextClicked = (value: any) => {
        switch (value) {
            case 1:
                toggleArrowTab(activeArrowTab + 1);
                break;
            case 1.1:
                toggleArrowTab(1 + 1);
                onMapping()
                break;
            default: toggleArrowTab(activeArrowTab + 1);
        }

    };

    const backClicked = () => {
        switch (activeArrowTab) {
            case 1.1:
                toggleArrowTab(activeArrowTab - 1);
                break;
            case 2:
                toggleArrowTab(activeArrowTab - 1);
                // setSelectedList([])
                //setCheckedRows({})
                break;
            default: toggleArrowTab(activeArrowTab - 1);
        }
    };

    useEffect(() => {
        switch (activeArrowTab) {
            case 4:
                let exist = selectedList.some((item: any) => (!item.actual_commission || parseInt(item.actual_commission) <= 0))
                if(exist) {
                    setError('Please check actual commission is entered in all records')
                }
                else {
                    setError(null)
                }
                setDisabled(exist)
                break;
            case 1.1:
                // let exist = selectedList.some((item: any) => (!item.actual_commission || parseInt(item.actual_commission) <= 0))
                // if(exist) {
                //     setError('Please check actual commission is entered in all records')
                // }
                // else {
                //     setError(null)
                // }
                setDisabled(false)
                break;
        }
    }, [selectedList, activeArrowTab])

    const toggleTooltip = () => {
        if(error) {
            setErrorTooltip(true)
        }
    }

    const onUpload = async () => {
        const file = selectedFile && selectedFile.length ? selectedFile[0] : null;
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('model', 'commissions');
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                try {
                    await axios.post(`http://api.qa.dts.student.zilter.io/applications/invoices/column-mapping`, formData, config)
                    .then((res: any) => { 
                        if(res?.mappings)   {
                            setMappings(res.mappings)   
                            setFileId(res.fileId) 
                            toggleArrowTab(activeArrowTab + 1);
                        }   
                        else {
                            const message = () => toast(`An unexpected error occurred, please check uploaded file and try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                            message();           
                        }           
                    })                    

                }
                catch (error) {
                    console.error("Error downloading file:", error);
                    const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                    message();               
                }
            }
        toggleArrowTab(activeArrowTab + 0.1);
        setIsImport(false)
    }

    



    
        const clearFilter = () => {
            setSearch("")
            setSortBy("createdAt")
            setSortOrder("desc")
            setPagination({
                ...pagination,
                page: 0,
                first: true,
                last: false,
            })
        }
    
        
            // Apply filter changes
        const onFilter = (quickFilter: any, advanceFilter: any) => {
            setPagination({ ...pagination, page: 0 });
            const filter = {
                quick: quickFilter,
                advance: advanceFilter
            }
            setFilter(filter)
        }

        const onSearchHandle = (e: any) => {
            setPagination({ ...pagination, page: 0 });
            setSearch(e.target.value)
        }

        const goback = useCallback(() => {
            navigate(-1);
            }, [navigate]);

    return (<>
        {isImport && <Upload
            show={isImport}
            onCloseClick={() => setIsImport(false)}
            setselectedFile={setSelectedFile}
            selectedFile={selectedFile}
            onUpload={onUpload}
            props={props} />}
        <Row>
            <div className="hstack step-arrow-nav mb-4">
                <Row className="w-100 hstack justify-content-center">
                    <Col lg={8} className="hstack justify-content-between">
                        <Nav
                            className="w-100 nav-pills custom-nav nav-justified"
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="basic-info"
                                    className={classnames({
                                        active: activeArrowTab === 1,
                                        done: activeArrowTab <= 1 && activeArrowTab > 0,
                                    })}
                                >
                                    Student Selection
                                </NavLink>
                            </NavItem>
                            {
                                isImport || activeArrowTab === 1.1 ? <NavItem>
                                    <NavLink
                                        href="#"
                                        id="basic-info"
                                        className={classnames({
                                            active: activeArrowTab === 1.1,
                                            done: activeArrowTab <= 1.1 && activeArrowTab > 1,
                                        })}
                                    >
                                        Mapping
                                    </NavLink>
                                </NavItem> : null}
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="basic-info"
                                    className={classnames({
                                        active: activeArrowTab === 2,
                                        done: activeArrowTab <= 2 && activeArrowTab > 1,
                                    })}
                                >
                                    Commission
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="basic-info"
                                    className={classnames({
                                        active: activeArrowTab === 3,
                                        done: activeArrowTab <= 3 && activeArrowTab > 2,
                                    })}
                                >
                                    Settlement & Dispute
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="basic-info"
                                    className={classnames({
                                        active: activeArrowTab === 4,
                                        done: activeArrowTab <= 5 && activeArrowTab > 3,
                                    })}
                                >
                                    Review
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col>
                    <TabContent activeTab={activeArrowTab}>
                        <TabPane id="basic-info" tabId={1}>
                            {activeArrowTab === 1 && <Row>
                                <Col>
                                    <div>
                                        <Step1 setCheckedRows={setCheckedRows} checkedRows={checkedRows} setDisabled={setDisabled} setSelectedList={setSelectedList} selectedList={selectedList} etMainList={setMainList} onhandleChange={onhandleChange} paymentHandle={paymentHandle} handleRuleSelect={handleRuleSelect} mainList={mainList} loading={loading} renderCell={renderCell} updatedRow={updatedRow} setIsImport={setIsImport} onFilter={onFilter} clearFilter={clearFilter} filter={filter} pageChanged={pageChanged} pagination={pagination} onChangePageSize={onChangePageSize} handleSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} search={search} onSearchHandle={onSearchHandle} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="basic-info" tabId={1.1}>
                            {activeArrowTab === 1.1 && <Row>
                                <Col>
                                    <div>
                                        <Mapping setColumnMapping={setColumnMapping} columnMapping={columnMapping} mappings={mappings} loading={loading} setFinalMappings={setFinalMappings} setUnMappedCount={setUnMappedCount} unMappedCount={unMappedCount} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="basic-info" tabId={2}>
                            {activeArrowTab === 2 && <Row>
                                <Col>
                                    <div>
                                        <Step2  mainList={mainList} searchStatic={searchStatic} setStaticSearch={setStaticSearch} setList={setList} list={list} selectedLstObj={selectedLstObj} setSectedListObj={setSectedListObj} setSelectedList={setSelectedList} paymentHandle={paymentHandle}  onhandleChange={onhandleChange} handleRuleSelect={handleRuleSelect} selectedList={selectedList} loading={loading} renderCell={renderCell} updatedRow={updatedRow} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="basic-info" tabId={3}>
                            {activeArrowTab === 3 && <Row>
                                <Col>
                                    <div>
                                        <Step3  mainList={mainList} searchStatic={searchStatic} setStaticSearch={setStaticSearch} setList={setList} list={list} selectedLstObj={selectedLstObj} setSectedListObj={setSectedListObj} setSelectedList={setSelectedList} paymentHandle={paymentHandle}  onhandleChange={onhandleChange} handleRuleSelect={handleRuleSelect} selectedList={selectedList} loading={loading} renderCell={renderCell} updatedRow={updatedRow} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="basic-info" tabId={4}>
                            {activeArrowTab === 4 && <Row>
                                <Col>
                                    <div>
                                        <Step4  mainList={mainList} searchStatic={searchStatic} setStaticSearch={setStaticSearch} setList={setList} list={list} selectedLstObj={selectedLstObj} setSectedListObj={setSectedListObj} setSelectedList={setSelectedList} paymentHandle={paymentHandle}  onhandleChange={onhandleChange} handleRuleSelect={handleRuleSelect} selectedList={selectedList} loading={loading} renderCell={renderCell} updatedRow={updatedRow} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            <div className="offcanvas-footer bg-primary-subtle bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <div className="hstack gap-2">
                    {activeArrowTab > 1 && activeArrowTab <= 4 ? (
                        <Button
                            color="primary"
                            className="w-auto"
                            onClick={() => backClicked()}
                            outline={true}
                        >
                            {t('common.offcanvas.import_back')}
                        </Button>
                    ) : null}
                    <Link
                        to="#"
                        className="text-danger fw-bold w-auto"
                        onClick={() => { goback(); }}
                    >
                        {t('common.offcanvas.import_cancel')}
                    </Link>
                </div>

                <div className='hstack gap-2'>
                    <div                    
                        onMouseEnter={() => error && setErrorTooltip(true)}
                        onMouseLeave={() => setErrorTooltip(false)}
                    >
                    <Button
                        id='TooltipButton'
                        color="primary"
                        className="btn btn-primary w-auto" 
                        disabled={disabled}
                        onClick={() => activeArrowTab === 4 ? createStudents() : nextClicked(activeArrowTab)}
                    >
                        {loading ? <><Spinner size="sm" className="flex-shrink-0">Loading... </Spinner>
                            <span className="flex-grow-1 ms-2">
                                Processing Request...
                            </span> </> : <>{activeArrowTab === 4 ? t('common.offcanvas.import_finish') : t('common.offcanvas.import_next')}</>}

                    </Button>
                    </div>
                    {errorTooltip && ( // Show Tooltip only if condition is true
                        <Tooltip
                        isOpen={errorTooltip}
                        target="TooltipButton"
                        >
                        {error}
                        </Tooltip>
                    )}
                </div>
            </div>
        </Row>
    </>
    );
};

export default withTranslation()(StudentsList);
