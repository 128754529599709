import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Dropdown, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";

// Props for the SelectRange component
interface SelectRangeProps {
    value: number | string | null | undefined;
    highValue: number | string | null | undefined;
    placeholder?: string;
    onChange?: (minValue: string | null, maxValue: string | null) => void;
    t: any;
}

// Functional component that renders a dropdown with input range selection
const SelectRange: React.FC<SelectRangeProps> = ({ value, highValue, onChange, placeholder, t }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [minValue, setMinValue] = useState<string>('');
    const [maxValue, setMaxValue] = useState<string>('');
    const [isApplied, setIsApplied] = useState<boolean>(false);

    // Sync the state with props when the component mounts or when value changes
    useEffect(() => {
        const isValidValue = (v: any) => v === 0 || v === '0' || !!v;

        if (isValidValue(value) && isValidValue(highValue)) {
            setMinValue(String(value));
            setMaxValue(String(highValue));
            setIsApplied(true);
        } else {
            resetValues();
        }
    }, [value, highValue]);

    // Clears the input fields and resets the state
    const resetValues = () => {
        setMinValue('');
        setMaxValue('');
        setIsApplied(false);
    };

    // Handles clearing the input fields
    const handleClearInput = () => {
        resetValues();
        onChange && onChange(null, null);
    };

    // Applies the selected range and triggers the onChange event
    const handleApply = () => {
        onChange && onChange(minValue, maxValue);
        setIsOpen(false);
        setIsApplied(true);
    };

    // Toggles the dropdown open/close state
    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className="d-flex dts-select-range">
            <ButtonGroup className="w-100">
                <Dropdown className="w-100" autoClose="inside" isOpen={isOpen} toggle={toggleDropdown}>
                    <div className="d-flex">
                        <DropdownToggle tag="button" className="d-flex align-items-center justify-content-between btn btn-soft-primary w-100 gap-1 p-1">
                            <div className="fs-16 text-truncate">{placeholder}</div>
                            <i className="ri-arrow-down-s-line label-icon align-middle fs-16 fw-semibold ms-2"></i>
                        </DropdownToggle>
                        {isApplied && (
                            <Button
                                size="sm"
                                onClick={handleClearInput}
                                color="danger"
                                className="btn-icon-custom btn-soft-danger rounded-start-0"
                            >
                                <i className="ri-close-line label-icon align-middle fs-5 fw-semibold"></i>
                            </Button>
                        )}
                    </div>
                    <DropdownMenu className="dropdown-menu p-2 w-100">
                        <div className="d-flex flex-column p-2 gap-4">
                            <div className="d-flex flex-column gap-3">
                                <div>
                                    <Label className="form-label">Min Value</Label>
                                    <Input
                                        type="number"
                                        value={minValue}
                                        onChange={(e) => setMinValue(e.target.value)}
                                        placeholder="Enter min value"
                                    />
                                </div>
                                <div>
                                    <Label className="form-label">Max Value</Label>
                                    <Input
                                        type="number"
                                        value={maxValue}
                                        onChange={(e) => setMaxValue(e.target.value)}
                                        placeholder="Enter max value"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <Button
                                    onClick={handleApply}
                                    color="primary"
                                    disabled={!(minValue && maxValue && parseFloat(maxValue) >= parseFloat(minValue))}
                                >
                                    {t("common.offcanvas.apply")}
                                </Button>
                                <Button
                                    onClick={() => setIsOpen(false)}
                                    color="danger"
                                    className="btn-soft-danger"
                                >
                                    {t("common.offcanvas.import_cancel")}
                                </Button>
                            </div>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </ButtonGroup>
        </div>
    );
};

export default withTranslation()(SelectRange);