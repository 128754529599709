import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import { ApplicationState } from "store";
import { setInvoceData } from "store/auth/action";

const PreviewModal: React.FC<any> = ({loading, onRemove,disabled, props, show, onCloseClick, selectedlist }: any) => {  
    const {id} = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const studentDetails = useSelector((state: ApplicationState) => state.student.details);
    const navigate = useNavigate();
    
    const createInvoice = () => {
        let details: any= studentDetails && studentDetails.valuesJson ? studentDetails.valuesJson : {};
        let student = {...details, student_id: id, phone_number: details.mobile}  
        let productlist =   selectedlist && selectedlist.length && selectedlist.map((item: any) => {
            return { ...item.products, id: item.id, commission: item.amount}
        })  
        dispatch(setInvoceData(productlist, student))
        navigate("/students/invoices/create-invoice")
    }

    return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Review and Create
            </ModalHeader>
            <ModalBody>
              <div>
                    {!loading ? 
                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="task-list">
                                    {selectedlist && selectedlist.map((item: any, key: any) => {
                                      
                                        return <tr key={key}>
                                            <td>
                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(item, false)}
                                                            >
                                                    <i className="ri-delete-bin-fill"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <label >{item?.["products"]?.["name"]}</label>
                                            </td>
                                            <td>{typeof item["products"]?.["description"] !== 'object' ? item["products"]?.["description"] : ''}</td>
                                            <td>
                                                {typeof item?.["amount"] !== 'object' ? item?.["amount"] : ''}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            
                        </div>: <DtsTablePlaceholder 
                            rows={selectedlist.length} cols={5}                                     
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                    </div>
            </ModalBody>
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createInvoice()}
                        disabled={disabled}
                    >
                        {props.t("courses.create_invoice")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("student.details.application.cancel")}
                    </Button>
                </div>
        </Modal>
    </>
    );
};

export default withTranslation()(PreviewModal);
