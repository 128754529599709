export interface PaymentsMetadata {
	id: string,
	fieldName: string,
	dataType: string,
	options: string,
	createdBy: string,
	createdAt: string,
	updatedAt: string,
	deletedAt: string | null,
	model: string;
	valuesJson: any;
	permissionsJson: string;
}

export interface Payment {
	createdAt: string,
	createdBy: string,
	deletedAt: string,
	id: string,
	updatedAt: string,
	valuesJson: string
}

export interface Pagination {
	size: number,
	page: number,
	pages: number,
	total: number,
	elements: number,
	last: boolean
}

export enum PaymentActionTypes {
	GET_METADATA_REQUEST = "@@payment/GET_METADATA_REQUEST",
	GET_METADATA_SUCCESS = "@@payment/GET_METADATA_SUCCESS",
	GET_METADATA_ERROR = "@@payment/GET_METADATA_ERROR",
	GET_REQUEST = "@@payment/GET_REQUEST",
	GET_SUCCESS = "@@payment/GET_SUCCESS",
	GET_ERROR = "@@payment/GET_ERROR",
	GET_ALL_REQUEST = "@@payment/GET_ALL_REQUEST",
	GET_ALL_SUCCESS = "@@payment/GET_ALL_SUCCESS",
	GET_ALL_ERROR = "@@payment/GET_ALL_ERROR",
	PUT_REQUEST = "@@payment/PUT_REQUEST",
	PUT_SUCCESS = "@@payment/PUT_SUCCESS",
	PUT_ERROR = "@@payment/PUT_ERROR",
	DELETE_REQUEST = "@@payment/DELETE_REQUEST",
	DELETE_SUCCESS = "@@payment/DELETE_SUCCESS",
	DELETE_ERROR = "@@payment/DELETE_ERROR",
	POST_REQUEST = "@@payment/POST_REQUEST",
	POST_SUCCESS = "@@payment/POST_SUCCESS",
	POST_ERROR = "@@payment/POST_ERROR",
}

export interface PaymentState {
	readonly list: Payment[] | null;
	readonly details: Payment | null;
	readonly pagination: Pagination | null;
	readonly metaDataFields: PaymentsMetadata[];
	readonly hierarchysources: any;
	readonly loading: boolean;
	readonly dataLoading: boolean;
	readonly metaLoading: boolean;
}
