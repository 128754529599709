import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, ButtonGroup, Label, Badge, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { viewGetRequest } from 'store/views/action';
import Paginator from "Components/Common/Paginator";
import QuickFilter from '../../Filter/QuickFilter';
import AddEntryModal from './innerComponent/AddEntryModal';
import Recording from './Recording/Recording';
import { callsSentimentJSON } from 'store/calls/action';
import Sentiments from './Sentiments/Sentiment';

interface ReducerState {
    [key: string]: any;
}

interface FiltersData {
    filters: any[];
    sorts: any[];
}

const CALLSOUTCOMES = [
    { label: "Answered", value: "ANSWERED", color: "success" },
    { label: "Missed", value: "MISSED", color: "danger" },
    { label: "No Answer", value: "NO_ANSWER", color: "warning" },
    { label: "Voicemail Left", value: "VOICEMAIL_LEFT", color: "info" },
    { label: "Busy", value: "BUSY", color: "warning" },
    { label: "Wrong Number", value: "WRONG_NUMBER", color: "danger" },
    { label: "Declined", value: "DECLINED", color: "danger" },
    { label: "Unknown", value: "UNKNOWN", color: "info" },
]
const CALLSDIRECTIONS = [
    { label: "Inbound", value: "INBOUND", color: "primary" },
    { label: "Outbound", value: "OUTBOUND", color: "info" },
    { label: "Unknown", value: "UNKNOWN", color: "warning" },
]


const Listing = (props: any) => {
    const { viewId, getListAction, reducerState, model, createAction, parent, parentId, parentField, details, studentId, applicationId } = props;

    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ['metaDataFields', 'list', 'dataLoading', 'pagination', "metaLoading"]);
    const derivedState: any = useSelector(reducer);
    const { metaDataFields, list } = derivedState;
    let paginationDetails = derivedState.pagination;

    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const sentiment = useSelector((state: ApplicationState) => state.calls.sentiment);

    const [show, setShow] = useState(false);

    const [sentiments, setSentiments] = useState<any>(null)
    const [columns, setColumns] = useState<any>([])
    const [view, setView] = useState<any>([])
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [access, setAccess] = useState<any>([]);
    const [search, setSearch] = useState<string>("");
    const [metaData, setMetaData] = useState([]);
    const [ListData, setList] = useState([]);
    const [studentsJson, setstudentsJson] = useState<any>([]);
    const [communicate, setCommunicate] = useState<any>(null);
    const [fieldOptions, setFieldOptions] = useState<any>([])
    const [filter, setFilter]: any = useState(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [isCreateModal, setCreateOpen] = useState<boolean>(false);
    const [url, setUrl] = useState('')
    const [selectedSearchField, setSelectedSearchField]: any = useState({ value: 'all', label: 'All' });
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    // Handle PageSize Change
    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value, page: 0 })
    }


    useEffect(() => {
        if (sentiment) {
            const parsedObject = JSON.parse(sentiment.sentiment);
            setSentiments(parsedObject)
        }
    }, [sentiment])

    // Set access based on user role and permissions
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCommunicate("all")
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                setAccess(access)
            }
            let communicateaccess = subLevelPermissionsList?.communicate;
            if (communicateaccess && communicateaccess.length) {
                communicateaccess.map((item: any, index: any) => {
                    if (item.name.toLowerCase() == 'communicate') {
                        item.value == "" || item.value == "none" ? setCommunicate(null) : setCommunicate(item.value)
                    }

                })
            }
        }
    }, [subLevelPermissionsList, userProfile])

    // Update metadata fields
    useEffect(() => {
        setMetaData(metaDataFields);
    }, [metaDataFields]);

    useEffect(() => {
        if (parent && parentId && model != "applications") setUrl(`/${parent}/${parentId}/${model}`)
        else setUrl(`/${model}`)
    }, [])


    // Fetch list data with filters and pagination
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };

        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }

        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData: FiltersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            if (parent.length > 0) {
                filtersData.filters[0].parent = parent
            }
            dispatch(getListAction(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi, updatedCurrency, parentField])

    // Clear filter selections
    const clearFilter = () => {
        setSearch("")
        setSortBy("createdAt")
        setSortOrder("desc")
        setPagination({
            ...pagination,
            page: 0,
            first: true,
            last: false,
        })
    }

    // Handle search input change
    const onSearchHandle = (e: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(e.target.value)
    }

    // Apply filter changes
    const onFilter = (quickFilter: any, advanceFilter: any) => {
        setPagination({ ...pagination, page: 0 });
        const filter = {
            quick: quickFilter,
            advance: advanceFilter
        }
        setFilter(filter)
    }


    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };


    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])


    useEffect(() => {
        if (viewId) dispatch(viewGetRequest(viewId))
        else setView(null)
    }, [viewId])


    const execute = () => {
        let a = isEqual(list, studentsJson)
        return !a
    }

    // Update list data
    useEffect(() => {
        const parsedData: any = list && list.length ? list.map((item: any) => {
            const valuesJson = item.valuesJson;
            let values = { ...valuesJson, ...item }
            return values
        }) : []
        let data = parsedData && parsedData.length ? parsedData : []
        setList(data)
        setstudentsJson(list)
    }, [execute()])

    const onClickSentiment = (callsId: any) => {
        let queryParams: any = new URLSearchParams({});
        queryParams.set('id', callsId)
        queryParams.set('isRecording', false)
        dispatch(callsSentimentJSON(queryParams.toString()))
    }
    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            <>
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    toggle={() => setShow(false)}
                    backdrop={false}
                >
                    <OffcanvasHeader className="bg-light" toggle={() => setShow(false)}>
                        Conversational analytics
                    </OffcanvasHeader>
                    {sentiments ? (
                        <OffcanvasBody>
                            <Sentiments sentiments={sentiments} />
                        </OffcanvasBody>
                    ) : "Loading sentiment data..."}

                </Offcanvas>
            </>
            {isCreateModal && <AddEntryModal
                show={isCreateModal}
                onCloseClick={() => setCreateOpen(false)}
                dataFields={metaData}
                setTriggerApi={setTriggerApi}
                props={props}
                createAction={createAction}
                parent={parent}
                parentId={parentId}
                applicationId={applicationId}
                studentId={studentId}
            />}
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                            <Row className='hstack gap-3 justify-content-between'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <InputGroup className='border rounded'>
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-light">
                                                        {selectedSearchField?.label} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setSelectedSearchField({ value: 'all', label: 'All' })}>All</DropdownItem>
                                                        <DropdownItem divider />
                                                        {fieldOptions?.map((option: any) => <DropdownItem onClick={() => setSelectedSearchField(option)}>{option.label}</DropdownItem>)}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                            <Input className='border-0' placeholder={props.t("common.filter.search.search")} style={{ padding: '1.2rem 0.9rem' }} onChange={onSearchHandle} value={search}></Input>
                                        </InputGroup>
                                        {search === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => setSearch("")}></i>}
                                    </div>
                                </Col>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3} className='hstack justify-content-end'>
                                    <Button
                                        onClick={() => setCreateOpen(true)}
                                        color="primary"
                                        className="btn btn-primary btn-label">
                                        <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t("student.details.activities.log_call")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className=''>
                                {metaData && metaData.length ?
                                    <QuickFilter
                                        dataFields={metaData}
                                        columns={columns}
                                        translation={props}
                                        onFilter={onFilter}
                                        clearFilterParent={clearFilter}
                                        filter={filter}
                                        parentField={parentField}
                                        parentId={parentId}
                                    /> : ""}
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <Row>
                                        {ListData && ListData.map((calls: any) => {
                                            const callDetails = calls?.valuesJson;
                                            const createdAt = calls?.createdAt;
                                            const startTime = callDetails?.call_start_time
                                            const endTime = callDetails?.call_end_time;
                                            const duration = moment(endTime)?.diff(moment(startTime));
                                            const formattedDuration = moment.utc(duration).format('HH:mm:ss');
                                            const filteredOutcome = CALLSOUTCOMES.find((outcome => outcome.value?.toLowerCase() === callDetails?.call_outcome?.toLowerCase()))
                                            const filteredDirection = CALLSDIRECTIONS.find((direction => direction.value?.toLowerCase() === callDetails?.status?.toLowerCase()))

                                            return (
                                                <Col xxl={12} key={calls.id}> {/* Ensure each mapped element has a unique key */}
                                                    <Card>
                                                        <CardBody>
                                                            <div className='d-flex justify-content-between mb-0'>
                                                                <div className='d-flex gap-2 align-items-baseline'>
                                                                    <Label className='fs-15'>Logged call</Label>
                                                                    <div className='fs-14 text-muted'> by {callDetails?.created_by?.name ? callDetails?.created_by?.name : details.name}</div>
                                                                    {filteredDirection ? <Badge className='' color={filteredDirection?.color} pill>
                                                                        {filteredDirection?.label}
                                                                    </Badge> : <Badge className='' color="danger" pill>Unknown</Badge>}
                                                                    {callDetails?.provider === "ZILTERCALLS" ? <Badge className='' color="dark" pill>Mobile</Badge> : <Badge className='' color="dark" pill>Other</Badge>}
                                                                </div>
                                                                <div>
                                                                    {/* <small className='text-muted'>{moment(calls.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</small> */}
                                                                    <small className='text-muted'>{createdAt ? moment(createdAt).format('MMMM Do YYYY, h:mm:ss a') : moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                                                </div>
                                                            </div>
                                                            <div className='mt-0 d-flex gap-2 justify-content-between align-items-baseline'>
                                                                <div>
                                                                    <div className='d-flex gap-2 align-items-baseline'>
                                                                        <div className='fs-14 text-muted'> with {details && details?.valuesJson?.student
                                                                            ? (`${details?.valuesJson?.student?.first_name || ''} ${details?.valuesJson?.student?.last_name || ''}`.trim() || details?.valuesJson?.name)
                                                                            : (`${details?.valuesJson?.first_name || ''} ${details?.valuesJson?.last_name || ''}`.trim() || details?.valuesJson.name)
                                                                        }</div> {/* Assuming info is defined */}
                                                                        <Badge className='' color={filteredOutcome?.color} pill>{filteredOutcome?.label}</Badge>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Label>Call Duration: <span><small className='text-muted'>{formattedDuration ? formattedDuration : "Not Provided"}</small></span></Label>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <Label className='mt-2 fs-15'>{callDetails?.call_description ? callDetails?.call_description : "No Description"}</Label>
                                                                </div>
                                                                <div className='d-flex gap-3'>
                                                                    {calls.provider && calls.provider === "aws" ? (
                                                                        <div className='d-flex gap-2'>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setShow(true)
                                                                                    onClickSentiment(calls.id)
                                                                                }}
                                                                                color="success"
                                                                                className="btn btn-ghost-success waves-effect waves-light">
                                                                                Sentiments
                                                                            </Button>
                                                                            <Recording id={calls.id} />
                                                                        </div>
                                                                    ) : (
                                                                        null
                                                                    )}


                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })}


                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                                <div className="col-sm">
                                    <div className="text-muted">
                                        {props.t("common.pagination.showing")}
                                        <span className="fw-semibold ms-1">
                                            {ListData.length > 0 ? `${(pagination.offset ? pagination.offset : 0) + 1}-${Math.min((pagination.offset ? pagination.offset : 0) + pagination.size, pagination.elements)} ${props.t("common.pagination.of")} ${pagination.elements} ${props.t("common.pagination.results")}` : `0-0 of 0 ${props.t("common.pagination.results")}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                        currentPage={pagination?.page}
                                        pageChanged={(e: any) => pageChanged(e)}
                                        pageSize={pagination.size}
                                        isPageSizeChange={true}
                                        onChangeInSelect={(e: any) => onChangePageSize(e)}
                                        pagination={pagination}
                                        props={props}
                                    />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Listing);


