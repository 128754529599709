import React from 'react';

const UserAvatar = ({ firstName, img, className }:any) => {
    const getRandomColor = (letter: any) => {
        switch(letter) {
            case "A": return "#74d2e7";
            case "B": return "#48a9c5";
            case "C": return "#0085ad";
            case "D": return "#8db9ca";
            case "E": return "#4298b5";
            case "F": return "#005670";
            case "G": return "#00205b";
            case "H": return "#009f4d";
            case "I": return "#84bd00";
            case "J": return "#efdf00";
            case "K": return "#fe5000";
            case "L": return "#e4002b";
            case "M": return "#da1884";
            case "N": return "#a51890";
            case "O": return "#0077c8";
            case "P": return "#008eaa";
            case "Q": return "#3369e7";
            case "R": return "#8e43e7";
            case "S": return "#b84592";
            case "T": return "#ff4f81";
            case "U": return "#ff6c5f";
            case "V": return "#ffc168";
            case "W": return "#2dde98";
            case "X": return "#1cc7d0";
            case "Y": return "#003666";
            case "Z": return "#00aeff";  
            case "0": return "#74d2e7";
            case "1": return "#48a9c5";
            case "2": return "#0085ad";
            case "3": return "#8db9ca";
            case "4": return "#4298b5";
            case "5": return "#005670";
            case "6": return "#00205b";
            case "7": return "#009f4d";
            case "8": return "#84bd00";
            case "9": return "#efdf00";
        }
    }

    const getTextColor = (backgroundColor: any) => {
        // Determine the appropriate text color based on background color brightness
        // You can adjust the threshold value as needed
        // const threshold = 130;
        // const brightness = parseInt(backgroundColor.substring(1), 16);
        // return brightness > threshold ? '#000000' : '#ffffff';

        const red = Math.floor(Math.random() * 128); // Red component between 0 and 127
        const blue = Math.floor(Math.random() * 128); // Blue component between 0 and 127
        const green = Math.floor(Math.random() * 128); // Green component between 0 and 127
        return `rgba(${red}, ${green}, ${blue}, 1)`;
    };

    const renderAvatar = () => {
        if (img) {
            return <div  className={'avatar-xs border-0 rounded-circle acitivity-avatar fw-semibold rounded-circle img-thumbnail hstack justify-content-center '+ `${className}`}>
                       <img src={img} alt={firstName} /></div>;
        } else {
            const firstLetter = firstName ? firstName.charAt(0).toUpperCase(): "";
            const backgroundColor = getRandomColor(firstLetter); // Get random background color
           const textColor = getTextColor(backgroundColor); // Get text color based on background color

            const avatarStyle = {
                backgroundColor: backgroundColor, // Apply the random color as background
                color: "#ffffff"// Apply the text color based on background
            };
            return <div  className={'avatar-xs border-0 rounded-circle acitivity-avatar fw-semibold rounded-circle img-thumbnail hstack justify-content-center '+ `${className}`} style={avatarStyle}>
                        <span>{firstLetter}</span>
                    </div>;
        }
    };

    return (
        <div className="user-avatar">
            {renderAvatar()}
        </div>
    );
};

export default UserAvatar;