import { Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import BreadCrumb from "Components/Common/BreadCrumb";
import UserProfile from "./UserProfile";

const index = ({ t }: any) => {
  document.title = "DTS | Zilter";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("profile.listing.breadcrumb.profile")} pageTitle={t("profile.listing.breadcrumb.profile")} />
          <Row>
            <UserProfile />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(index);
