import BreadCrumb from 'Components/Common/BreadCrumb';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import RolesListing from './Roles';

const Roles = (props: any) => {
    document.title="DTS | Zilter - Roles";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("roles.listing.breadcrumb.roles")} pageTitle={props.t("roles.listing.breadcrumb.roles")} />
                    <RolesListing />
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Roles);


