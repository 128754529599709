import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Form,
    Button,
    Col,
    Row,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import _ from "lodash";
import FormLoader from "./Common/FormLoader";
import { validate } from "uuid";

const QuickFormComponent = ({ dataFields, setMainFieldsValues,metaLoading, btnAction, btn_text, APAId , data, btndisabled, xl, lg, md, sm, xs, BtnClassName, theme, key, commissionJson, tier, preFilledKey, pathParts, preFilledKeyValue}: any) => {
    const filterFields: any = [];   
    dataFields && dataFields.length && dataFields.map((field: any) => {           
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
        if (field.valuesJson) 
            if(item.key === 'months' || item.key === 'year')
            {
                item = {...item, validate: {required:true}}
            }
            return filterFields.push(item)
    })
    const [formValues, setFormValues] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [tableData , setTableData] = useState<any>([]);
    const [request, setRequest] = useState<any>([]);
    // const [data, setData] = useState<any>([])

    const onChange = (option: any, key: any, field: any) => {  
        if(field.type === "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
            setFieldValues({ ...formValues, [key]: option })
            setMainFieldsValues({ ...formValues, [key]: option })
        } 
        else if (typeof option === 'object') {       
            setFormValues({ ...formValues, [key]: option?.value || option })
            setFieldValues({ ...formValues, [key]: option?.value || option })
            setMainFieldsValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
            setFieldValues({ ...formValues, [key]: option })
            setMainFieldsValues({ ...formValues, [key]: option })
        }
    };

    useEffect(() => {  
        if(preFilledKey && pathParts.includes('create')) {
            let newValues = {...formValues,...preFilledKeyValue}
            setFormValues(newValues)
            let newlabelValue = {...fieldValues,...preFilledKeyValue}
            setFieldValues(newlabelValue)
            setMainFieldsValues(newlabelValue)
        } 
        else if(data) {
            let values=data;
            setFormValues({...values, ...preFilledKeyValue})
            let newlabelValue = {...values,...preFilledKeyValue}
            setFieldValues(newlabelValue)
            setMainFieldsValues(newlabelValue)
        }
    }, [data, preFilledKey, preFilledKeyValue])

    useEffect(() => {
        let newValues={...formValues}
        setRequest({...newValues, data: tableData})
    }, [formValues, tableData])
    

    useEffect(() => {
        if(tier) {
            if(commissionJson && formValues?.clone_from_basic) {
                 let newData = JSON.parse(JSON.stringify(commissionJson?.basic?.data))
                 let newFormValues = {...formValues,commissionable_period: commissionJson?.basic?.commissionable_period}
                 setFormValues(newFormValues)  
                 setFieldValues(newFormValues)   
                 setTableData(newData)
                 setRequest({...newFormValues , data: newData})
             }
             else if(data) {
                 let values=data;
                 setFormValues({...values,clone_from_basic: formValues?.clone_from_basic})
                 setFieldValues({...values,clone_from_basic: formValues?.clone_from_basic})   
                 if(data?.data?.length) {
                     let newData = _.cloneDeep(data?.data)
                     setTableData(newData)
                 }
             }
             else {
                 let newFormValues = {...formValues, clone_from_basic: formValues?.clone_from_basic, commissionable_period: null, multiple_payments: false}
                 setFormValues(newFormValues)      
                 setFieldValues(newFormValues) 
                 setTableData([])
             }
        }
    }, [formValues?.clone_from_basic])
               
    return (
        <div>
            {
                metaLoading ? <FormLoader rows={3} cols={2}/> :
                <Form action="" className="d-flex flex-column justify-content-end h-100">
                    <div className="live-preview vstack gap-2">
                        <Row>
                            {filterFields && filterFields.length ? filterFields.map((field: any) => {
                                let width = field.key === 'intake_type' ? 12 : xl?xl:12;
                                if(field.mainFields) return <Col xl={width} lg={width} md={width} sm={width} xs={width} className={field.type === 'checkbox' ? "hstack align-items-center" : ''}><FormBuilder
                                    placeholder={field.placeholder}
                                    name={field.key}
                                    onChange={(e: any) => onChange(e, field.key, field)}
                                    options={(field.values && field.values.reverse()) || []}
                                    label={field.label}
                                    type={field.type}
                                    value={formValues[field.key]}
                                    isEditState={true}
                                    actionButtons={false}
                                    defaultValue={formValues[field.key]}
                                    Editable={true}
                                    rest={field}
                                    invalid={true}
                                    formValues={formValues}
                                    dataFields={filterFields}
                                    setdisabled={setdisabled}
                                    setError={setError}
                                    formerror={formerror}
                                    resetValue={fieldValues[field.key]}
                                /></Col>
                            }): null}
                        </Row>
                    </div>
                </Form>
            }
        </div>
    );
};

export default QuickFormComponent;

