import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Badge } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { isJson } from 'utils';

interface ReducerState {
    [key: string]: any;
}

interface FiltersData {
    filters: any[];
    sorts: any[];
}


const Listing = (props: any) => {
    const { viewId, getListAction, reducerState, model, parent, parentId, parentField, applicationId, studentId } = props;

    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ['metaDataFields', 'list', 'dataLoading', 'pagination', "metaLoading"]);
    const derivedState: any = useSelector(reducer);
    const { metaDataFields, list, dataLoading, metaLoading } = derivedState;
    let paginationDetails = derivedState.pagination;
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [sortBy, setSortBy] = useState<any>("createdAt")
    const [sortOrder, setSortOrder] = useState<string>("desc")
    const [search, setSearch] = useState<string>("");
    const [metaData, setMetaData] = useState([]);
    const [ListData, setList] = useState([]);
    const [studentsJson, setstudentsJson] = useState<any>([]);
    const [fields, setFields] = useState<any>(null);
    const [filter, setFilter]: any = useState(null);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [selectedSearchField, setSelectedSearchField]: any = useState({ value: 'all', label: 'All' });
    const [pagination, setPagination] = useState<any>(
        {
            size: 50,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    // Update metadata fields
    useEffect(() => {
        setMetaData(metaDataFields);
    }, [metaDataFields]);

    // Fetch list data with filters and pagination
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }

        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData: FiltersData = {
                filters: [{
                    ...filter,
                    search: null
                }],
                sorts: [
                    {
                        "field": sortBy,
                        "order": sortOrder
                    }
                ]
            }

            // Add search filter
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: selectedSearchField?.value
                }
            }
            if (parent?.length > 0) {
                filtersData.filters[0].parent = parent;
            }
            dispatch(getListAction(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [filter, search, pagination?.page, pagination?.size, sortBy, sortOrder, triggerApi, updatedCurrency, parentField])

   
    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])


    const execute = () => {
        let a = isEqual(list, studentsJson)
        return !a
    }

    // Update list data
    useEffect(() => {
        const parsedData: any = list && list.length ? list.map((item: any) => {
            const valuesJson = item.valuesJson;
            let values = { ...valuesJson, ...item}
            return values
        }) : []
        let data = parsedData && parsedData.length ? parsedData : []
        setList(data)
        setstudentsJson(list)
    }, [execute()])

       // convert whatsapp style message to html
       const convertWhatsAppToHtml = (text: string): string => {
        if (!text) return "";
    
        return text
            .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Convert *bold* to <strong>
            .replace(/_(.*?)_/g, "<em>$1</em>") // Convert _italic_ to <em>
            .replace(/~(.*?)~/g, "<s>$1</s>") // Convert ~strikethrough~ to <s>
            .replace(/```(.*?)```/g, "<pre>$1</pre>") // Convert ```monospace``` to <pre>
            .replace(/^- (.*?)(?=\n|$)/gm, "<li>$1</li>") // Convert - bullet points to <li>
            .replace(/(?:^|\n)(<li>.*?<\/li>)(?:\n|$)/g, "<ul>$1</ul>") // Wrap <li> elements in <ul>
            .replace(/\n/g, "<br>"); // Convert newlines to <br>
    };


     // Render the main chat view
     const renderChatView = () => {
        if (!dataLoading) {
            return (
                <div className="d-flex flex-column gap-4">
                    {ListData.map((whatsapp: any, whatsappIndex: number) => {
                        const message = isJson(whatsapp.whats_app_json)
                        if (whatsapp?.from === 'user') {
                             if(message.type === 'chat') {
                            return (
                                <div key={whatsappIndex} className="direct-chat-msg right">
                                    <div className="direct-chat-info clearfix">
                                        <span className="direct-chat-name pull-right">{whatsapp.user.name}</span>
                                        <span className="direct-chat-timestamp pull-left">
                                            {moment.unix(message?.timestamp).format("MMM Do, h:mm a")}
                                        </span>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div
                                            className="w-100 shadow bg-light p-2 rounded-3"
                                            dangerouslySetInnerHTML={{ __html: convertWhatsAppToHtml(message?.body) }}
                                        ></div>
                                        <i className="ri-user-2-fill fs-1 text-muted" />
                                    </div>
                                </div>
                            );
                        }} else {
                            if(message.type === 'chat') {
                                let to = isJson(whatsapp?.student?.values_json)
                                return (
                                    <div key={whatsappIndex} className="direct-chat-msg">
                                        <div className="direct-chat-info clearfix">
                                            <span className="direct-chat-name pull-left">{to?.name}</span>
                                            <span className="direct-chat-timestamp pull-right">
                                                {moment.unix(message?.timestamp).format("MMM Do, h:mm a")}
                                            </span>
                                        </div>
                                        <div className="d-flex gap-3">
                                            <i className="ri-user-3-fill fs-1 text-muted" />
                                            <div
                                                className="w-100 shadow bg-light p-2 rounded-3"
                                                dangerouslySetInnerHTML={{ __html: convertWhatsAppToHtml(message?.body) }}
                                            ></div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        return null;
                    })}
                </div>
            );
        }

        return (
            <div className="chatboat-loader d-flex flex-column">
                <i className="ri-whatsapp-line display-1 shimmer"></i>
                <label>Loading messages...</label>
            </div>
        );
    };


    document.title = "DTS | Zilter";
    return (
        <React.Fragment>           
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="vstack gap-2">
                            <div className="dts-whatsapp">
                                <div className="row container d-flex justify-content-center">
                                    <div className="col-md-12">
                                        <div className="direct-chat direct-chat-warning position-relative">
                                            <div className="box-body">
                                                {renderChatView()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default withTranslation()(Listing);


