import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import Finance from 'pages/ChannelDetails/Finanace/Finance';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetInvoceData } from 'store/auth/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { channelGetRequest, channelMetadataGetRequest, channelUpdateRequest, invoicesStudentsList } from 'store/channels/action';
import Contracts from './Contract/Contracts';

const Channel = (props: any) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [create, setCreate] = useState<boolean>(false);

    const tabList = [
        {
            title: (props.t("common.tabs.finance")),
            component: Finance
        },
        {
            title: (props.t("common.tabs.contracts")),
            component: Contracts
        }
    ]

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const createStudents = (key: string) => {
        dispatch(ResetInvoceData())
        navigate(`/create-invoices/${key}/channel/${id}`)
    }

    useEffect(() => {
        dispatch(invoicesStudentsList(id))
    }, [])

    const createInvoice = () => {
        if (create) return <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle
                    tag="button"
                    className="btn btn-label btn-primary text-left d-flex align-items-center justify-content-between"
                // style={{ width: '200px' }}
                ><i className="ri-contacts-book-line label-icon align-middle fs-16 me-2"></i>
                    Create Invoice <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdownmenu-primary w-100">
                    <DropdownItem onClick={() => { createStudents('advance') }}>Advance</DropdownItem>
                    <DropdownItem onClick={() => { createStudents('quick') }}>Quick </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
        else return <Button
            color="primary"
            size='sm'
            disabled
            className="btn-label py-2">
            <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("channel.details.create_invoice")}
        </Button>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Section
                        detailsGetAction={channelGetRequest}
                        metaDatagetRequest={channelMetadataGetRequest}
                        updateAction={channelUpdateRequest}
                        model={"channels"}
                        reducerState={"channels"}
                        translater={"channel"}
                        label={"Channels"}
                        tabList={tabList}
                        createInvoice={createInvoice}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Channel);