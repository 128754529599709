import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { isJson } from "utils";

interface ReducerState {
    [key: string]: any;
}

const Info = (props: any) => {
    const { updateAction, translater, reducerState, detailsGetAction, model} = props;
    const { id, childId } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState: any) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer = useMemo(() => createReducerSelector(reducerState, ['metaDataFields', 'details', 'loading', 'metaLoading']), [reducerState]);

    const derivedState: any = useSelector(reducer);
    const { metaDataFields, details, loading, metaLoading } = derivedState;
    
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit]= useState<any>(false);

    useEffect(() => {
        if(details?.permissions) {
            setEdit(details?.permissions?.edit?.access)
        }
    }, [details])

    const onUpdate = (formValues: any) => {
        const handleSuccess = (body: any) => {
            dispatch(detailsGetAction(childId || id))
        }
        dispatch(updateAction(childId || id, formValues, handleSuccess, () => { }))
    }

    const handleViewAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => ( subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metaDataFields && metaDataFields.map((field: any) => {         
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson)  : {}            
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) :  true;  
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) :  true;                 
            if(isFieldviewaccess) {
                if(isFieldEditaccess) {                    
                    let newField = {...field, isEditAccess: true}
                    return newstatusMetaData.push(newField)
                } 
                return newstatusMetaData.push(field)   
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metaDataFields])

    return (
        <Row className="justify-content-start">            
            <Col md={model === "templates" ? 12 : 6} lg={model === "templates" ? 12 : 4}>                
                <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={details} formfor={model} isEditAccess={edit} />
            </Col>
        </Row>

    );
};

export default Info;
