import React, { useEffect, useState } from "react";
import { Input, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import config from "../../../config";
import google from '../../../assets/images/google-icon-logo-svgrepo-com.svg';
import microsoft from '../../../assets/images/microsoftSignin.svg';

const { link: { PRIVACY_POLICY } } = config;
interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}
interface Step1Props {
    toggleArrowTab: (e: any) => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    resendOTP: () => void;
    t: (key: string) => string;
    loading: boolean;
    isEditEmail: boolean;
    signUpGoogle: () => void;
    signUpMicrosoft: () => void;
}
const Step1: React.FC<Step1Props> = ({ t, inputs, setInputs, onCreate, loading, resendOTP, isEditEmail, signUpGoogle, signUpMicrosoft }: Step1Props) => {
    const [disabled, setDisabled] = useState(true)
    
    const buttonStyle: React.CSSProperties = {
        height: '40px',
        fontFamily: 'Nunito, sans-serif',
        fontSize: '15px',
        fontWeight: 500,
        color: '#2a5f8d',
        backgroundColor: '#fff',
        border: '1px solid #8c8c8c',
        padding: '3px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    };

    useEffect(() => {
        setDisabled(inputs.email !== "" && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email))
    }, [inputs.email])

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="border border-1 rounded-3 p-3 bg-light w-25">
                    <div className="text-center text-primary fs-20 fw-bolder">
                        <Label>
                            Signup
                        </Label>
                    </div>
                    <div className="hstack justify-content-center">
                        <Label className="form-label w-100" htmlFor="steparrow-gen-info-email-input">
                            {t("signup.email")}
                        </Label>
                    </div>
                    <div className="mb-3 justify-content-center">
                        <Input
                            type="text"
                            className="form-control w-100"
                            id="steparrow-gen-info-email-input"
                            placeholder={t("signup.enter_email")}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => { setInputs({ ...inputs, email: e.target.value }); }}
                        />
                        {inputs.email !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email) ? (
                            <div className="text-danger fs-14 fw-medium">
                                {t("signup.please_enter_valid_email_address")}
                            </div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <button
                            type="button"
                            className="w-100 btn btn-primary btn-icon waves-effect waves-light"
                            onClick={() => { isEditEmail ? resendOTP() : onCreate() }}
                            disabled={!disabled}
                        >
                            {loading ? <span className="d-flex align-items-center">
                                <span className="flex-grow-1 me-2">
                                    Loading...
                                </span>
                                <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                            </span> : t("signup.verify_email")}
                        </button>{" "}
                    </div>
                    <div className="mb-3 fs-16 fw-medium text-center justify-content-center">
                        {t("signup.or")}
                    </div>
                    <div className="d-flex gap-3 vstack justify-content-center align-items-center mb-3">
                        <div className="w-100" style={buttonStyle} onClick={signUpGoogle}>
                            <img src={google} alt="Google Sign Up" style={{ width: '20px', height: '20px', marginRight: "10px" }} />
                            {t("signup.continue_with_google")}
                        </div>
                        <div className="w-100" style={buttonStyle} onClick={signUpMicrosoft}>
                            <img src={microsoft} alt="Microsoft Sign Up" style={{ width: '20px', height: '20px', marginRight: "10px" }} />
                            {t("signup.signup_with_microsoft")}
                        </div>
                    </div>
                    <div>
                        {t("signup.disclaimer")}
                        <Link to={`${PRIVACY_POLICY}`} className="link-primary fw-bold" target="_blank" rel="noopener noreferrer">
                            &nbsp;{t("signup.privacy_policy")}&nbsp;
                            <FeatherIcon icon="external-link" className="icon-xs icon-dual-primary" />
                        </Link>
                    </div>
                </div>
            </div>
        </>

    );
};
export default Step1;