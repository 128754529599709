import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import TierSubCompoenent from './TierSubCompoenent';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { propertyListGetRequest } from 'store/properties/action';
import { ApplicationState } from 'store';
import { isJson } from 'utils';


const Tier = (props: any) => {    
    const { inputs,handleRemoveComponent } = props; 
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const metaData = useSelector((state: ApplicationState) => state.properties.list);
    const [count, setCount] = useState(1);
    const [tierJson, setTierJson] = useState({})
    const [selectedYears, setSelectedYears] = useState([])
    const [selectedMonths, setSelectedMonths] = useState([])
    const [selectRuleType, setSelectedRuleType] = useState<any>(
        {
            label: 'Intake wise',
            value: 'intakewise'
        })
    const [ruleTypeOption, setRuleTypeOption] = useState<any>(null)

    useEffect(() => {
        if (inputs?.tier) {
            const slabKeys = Object.keys(inputs.tier).filter(key => key.includes('slab'));
            setCount(slabKeys.length);
        }
        setTierJson(inputs?.tier)
    }, [inputs]);

    const ruleTypestaticOpt = [
        {
            label: 'Intake wise',
            value: 'intakewise'
        },
        {
            label: 'Academic Year(Aug - July)',
            value: 'academic_year(aug-july)'
        },
        {
            label: 'Calendar Year(Jan - Dec)',
            value: 'calendar_year(jan-dec)'
        }
    ]

    useEffect(() => {
        setSelectedMonths(inputs?.tier?.intake?.months)
        setSelectedYears(inputs?.tier?.intake?.year || inputs?.tier?.year)
        let options = ruleTypeOption || ruleTypestaticOpt;
        let ruletypeOption = options?.find((item: any) => item.value === inputs?.tier?.ruleType)
        setSelectedRuleType(ruletypeOption)
    }, [inputs])

     
    return (
        <React.Fragment>
            <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                <CardHeader className='hstack bg-primary-subtle justify-content-between'>
                    <Label className='fw-bold fs-20 text-primary'>Tier Wise</Label>
                    <button onClick={() => {props.setOpen(false); handleRemoveComponent('tier')}} className="btn btn-sm btn-soft-danger remove-list fs-12"                                                                                >
                        <i className="ri-delete-bin-fill"></i>{" "}
                    </button>{" "}
                </CardHeader>
                <CardBody>  
                    <Row className='p-1'>
                        <Col xl={6} md={6} lg={6}>
                            <Label className='fw-semibold fs-16 text-primary'>How will be rule applied?</Label>
                            <Select
                                placeholder={props.t("common.filter.select")}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                name="fields"
                                options={ruleTypestaticOpt}
                                value={selectRuleType}
                                onChange={setSelectedRuleType}
                            />
                        </Col>
                    </Row>                
                    {[...Array(count)].map((_, index) => (
                        <div>
                            <TierSubCompoenent dataFields={props.levels} setCount={setCount} count={count} key={index} number={index} setTierJson={setTierJson} tierJson={tierJson} onAddRule={props.onAddRule} inputs={inputs} selectedMonths={selectedMonths} selectedYears={selectedYears} selectRuleType={selectRuleType} />
                        </div>
                    ))}   
                   <Button color='primary' onClick={() => setCount(count + 1)}>Add</Button>                
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Tier);
