import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { withTranslation } from "react-i18next";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { teamDelete, teamsListGetRequest } from "store/teams/action";
import { ApplicationState } from "store";
import FeatherIcon from "feather-icons-react";

const DeleteModal = ({ t, props, show, onCloseClick, record }: any) => {
	const loading = useSelector((state: ApplicationState) => state.teams.loading);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const onDelete = () => {
        const handleSuccess = (body: any): void => {
            const queryParams: URLSearchParams = new URLSearchParams();
            queryParams.set('page', '0');
            queryParams.set('pageSize', '500');
            dispatch(teamsListGetRequest(queryParams.toString()));
            onCloseClick();
        }
        const handleError = (body: any): void => {};
        dispatch(teamDelete(record.id, handleSuccess, handleError));
    }

    return (
        <Modal id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
        <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
            {t("team.delete.delete_team")}
        </ModalHeader>
        <ModalBody className='text-center p-5'>
            <div className="d-flex flex-column gap-3 mt-1 justify-content-center align-items-center">     
                <FeatherIcon icon="trash-2" className="mb-1 text-danger display-2" style={{width:60, height: 60}} />                                   
                <h4 className="mb-2 mt-2">{record?.name}</h4>
               <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-danger" onClick={() => onDelete()} disabled={loading}>
                        {loading ? t("team.delete.btn_delete_loading") : t("team.delete.btn_delete")}
                    </Button>
                    <Button color="danger" onClick={() => onCloseClick()} disabled={loading} outline>
                        {t("team.delete.btn_cancel")}
                    </Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    ) as unknown as JSX.Element;
};

export default withTranslation()(DeleteModal);