import { Fragment } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { Row, Table } from "reactstrap";
import Paginator from "Components/Common/Paginator";

interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: string,
    theadClass?: string,
    thClass?: string,
    props: any
    paginations: any
    pageChanged: (page: any) => void
    onChangePageSize: (page: any) => void
}

const TableContainer = ({
    columns,
    data,
    tableClass,
    theadClass,
    thClass,
    paginations,
    props,
    pageChanged,
    onChangePageSize
}: TableContainerProps) => {
    const {
            getTableProps,
            headerGroups,
            page,
            prepareRow,
            setPageSize,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0, pageSize: paginations?.size
                },
                manualPagination: true, 
            },
            useGlobalFilter,
            useFilters,
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect
        );

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    return (
        <div>
            <Table responsive={true} hover {...getTableProps()} className={tableClass +'m-0'}>
                <thead  className={theadClass}>
                    {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                >
                                    <div title={column.Header}>
                                        {column.render("Header")}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    )) : null}
                </thead>
                <tbody>
                {page.map((row: any, index: any) => {
                    prepareRow(row);
                    return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                    {row.cells.map((cell: any) => {
                                        return <td key={cell.id} style={{ verticalAlign: 'middle', minWidth:100 }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    })}  
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("common.pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${(paginations.offset ? paginations.offset : 0) + 1}-${Math.min((paginations.offset ? paginations.offset : 0) + paginations.size, paginations.elements)} ${props.t("common.pagination.of")} ${paginations.elements} ${props.t("common.pagination.results")}` : `0-0 of 0 ${props.t("common.pagination.results")}`}
                                </span>
                            </div>
                        </div>
                <div className="col-sm-auto">
                    <Paginator total={paginations && paginations.elements ? paginations.elements : 0}
                        currentPage={paginations?.page}
                        pageChanged={(e: any) => pageChanged(e)}
                        pageSize={paginations.size}
                        isPageSizeChange={true}
                        onChangeInSelect={(e: any) => onChangeInSelect(e)}
                        pagination={paginations}
                        props={props}
                    />
                </div>
            </Row>
        </div>
    );
};

export default withTranslation()(TableContainer);