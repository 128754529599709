import React from "react";
import { Row, Col, Container } from "reactstrap";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Wizard from "pages/Signup/steps/Wizard";

interface SignUpProps {

}
  
const SignUp: React.FC<SignUpProps> = () => {  

    document.title = "SignUp | Zilter";
    return (
        <Container fluid>
            <ToastContainer />
            <Row className="hstack justify-content-center">
                <Col xl={12} lg={12} md={12} sm={11} xs={11}>
                    <Row>
                        <Wizard />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default SignUp;
