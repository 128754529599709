import React from 'react';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { subagentCreateRequest, subagentDelete, subagentGetRequest, subagentListGetRequest, subagentMetadataGetRequest } from 'store/subagents/action';

const Subagents = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <React.Fragment>
            <Listing 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={subagentListGetRequest}
                deleteAction={subagentDelete}
                detailsGetAction={subagentGetRequest}
                metaDatagetRequest={subagentMetadataGetRequest}
                createAction={subagentCreateRequest}
                model={"subagents"}
                reducerState={"subagents"}
                translater={"subagent"}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Subagents);


