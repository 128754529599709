import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from 'react-select'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Comments from "./Comments";
import SimpleBar from "simplebar-react";
import { noteCreateRequest, noteListGetRequest, notesMetadataGetRequest } from "store/notes/action";
import { isJson } from "utils";
import { ApplicationState } from "store";
import { taskCreateRequest } from "store/tasks/action";
import AddTaskEntry from '../Tasks/innerComponent/AddEntryModal'
import DtsCKEditor from "Components/Common/FormBuilder/DtsCKEditor";

interface FProps {
    props: any;
    notesModals: boolean;
    setIsNotesOpen: (event: boolean) => void;
    record: any,
    model: string,
    studentId: any,
    applicationId: any,
    userProfile: any,
    t: any
}

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];

const NotesModal: React.FC<FProps> = ({
    props,
    notesModals,
    setIsNotesOpen,
    record,
    model,
    studentId,
    applicationId,
    userProfile,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metaData = useSelector((state: ApplicationState) => state.notes.metaDataFields);
    const [newNote, setNewNote] = useState("");
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [notesMetadata, setNotesMetadata] = useState<any[]>([]);  // Using `any[]` as the type      
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [followupRequired, setFollowupRequired] = useState(false)
    const [transcribing, setTranscribing] = useState<"modalparent" | boolean>("modalparent");  // Set to 'parent' initially
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState(false);
    const [parentField, setParentField] = useState<any>({})
    const [parentFilter, setParentFilter] = useState<any>([])
    const [note, setNote] = useState(""); // State to store final transcript
    const [previousWords, setPreviousWords] = useState<string[]>([]);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({
        transcribing: transcribing === "modalparent",
        clearTranscriptOnListen: true,  // Clear transcript when microphone is turned on
        commands: [  // Define custom speech commands if needed
            {
                command: 'stop',
                callback: () => stopListening(),
            },
        ]
    });

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(notesMetadataGetRequest("notes", filters));
    }, [])


    useEffect(() => {
        if (model === 'students' && studentId) {
            notesFilter()
        }
        else if (model === 'applications' && studentId && applicationId) {
            notesFilter()
        }
    }, [studentId, applicationId, model])

    useEffect(() => {
        resetTranscript();
        let parent: any = {}
        let parentFilter: any = []
        const notesMetadata: any = [];
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : { ...field.valuesJson }
            if (field.valuesJson) return notesMetadata.push(item)
        })
        setNotesMetadata(notesMetadata)
        metaData && metaData.length && metaData.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if (field.key === "student") {
                parent = { ...field, ...item }
                setParentField({ ...field, ...item })
                let filter = {
                    "key": parent?.key,
                    "keyLabel": parent?.label,
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [studentId],
                    "valuesLabel": [],
                    "property": parent,
                    "quick": true,
                }
                setParentFilter([...parentFilter, filter])
            }
            else if (field.key === "applications") {
                parent = { ...field, ...item }
                setParentField({ ...field, ...item })
                let filter = {
                    "key": parent?.key,
                    "keyLabel": parent?.label,
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [applicationId],
                    "valuesLabel": [],
                    "property": parent,
                    "quick": true,
                }
                setParentFilter([...parentFilter, filter])
            }
        })
    }, [metaData, record])


    useEffect(() => {
        if (listening && transcript) {
          const words = transcript.trim().split(" "); // Convert transcript to words
    
          // Find new words by slicing from previous transcript length
          const newWords = words.slice(previousWords.length).join(" ").trim();
    
          if (newWords) {
            setNote((prevNote) => `${prevNote?prevNote:''} ${newWords?newWords:''}`.trim());
          }
    
          setPreviousWords(words); // Store current words for next comparison
        }
      }, [transcript, listening]);

      useEffect(() => {
        if(note) {
            console.log("newNote", newNote, "note", note)
            setNewNote(note)
        }
      }, [note])
      


    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("modalparent");  // Set transcribing to 'parent' when listening starts
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        setTranscribing(false);  // Stop transcribing when listening stops
        SpeechRecognition.stopListening();
        // Append transcript to existing newNote when listening stops
        // if (transcript) {
        //     handleNewNoteChange(transcript, true);
            resetTranscript();
        // }
    };

    const handleNewNoteChange = (
        input: React.ChangeEvent<HTMLTextAreaElement> | string,
        appendSpeech: boolean = false
    ) => {
        if(!listening){
            setNote(`${input?input:''}`.trim())
        }
    };

    const handleAddNoteTypeChange = (selectedOption: any) => {
        setSelectedAddNoteType(selectedOption);
        setAddShortNoteValue(selectedOption.label)
    };

    const notesFilter = () => {
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }
        const filtersData: any = {
            "filters": [
                {
                    quick: [],
                    advance: [],
                    search: null,
                    parent: parent
                }
            ],
            "sorts": [
                {
                    "field": "createdAt",
                    "order": "desc"
                }
            ]
        }
        dispatch(noteListGetRequest(filtersData));
    }

    
    console.log("newNote", newNote)

    const handleAddNote = () => {
        stopListening();
        setIsAddNoteButtonDisabled(true);
        const data =
        {
            student: studentId,
            application: applicationId,
            shortNote: addShortNoteValue,
            parent: "",
            note: newNote || transcript,
            assigned_by: userProfile?.id
        }
        const handleSuccess = (body: any): void => {
            createFollowUp()
            handleReset();
            notesFilter()
        };

        const handleError = (body: any): void => {
        };

        dispatch(noteCreateRequest(data, handleSuccess, handleError));
    };

    const handleReset = () => {
        setNewNote("");
        setSelectedAddNoteType(null)
        // setIsNotesOpen(false);
        setIsAddNoteButtonDisabled(false);
        resetTranscript();
        setAddShortNoteValue('')
    };

    const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
        if (typeof event === "boolean") {
            setIsChecked(event); // Update the checkbox state
            setFollowupRequired(event);
        } else {
            const checked = event.target.checked;
            setIsChecked(checked); // Update the checkbox state
            setFollowupRequired(checked);
        }
    };

    const createFollowUp = () => {
        if (followupRequired) {
            setTasksModals(true);
        }
    };

    const textEle = <div>
        <DtsCKEditor
            value={newNote || ""}
            onChange={(data: any) => {
                handleNewNoteChange(data)
            }}
            name={'body'}
            stopListening={stopListening}
            type={'texteditor'}
            actionButtons={false}
            defaultValue={newNote}
        />
    </div>

    const checkkboxEle = <div className="text-nowrap text-truncate text-nowrap-ellipsis form-check mb-0 align-self-center">
        <Input
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={handleFollowupCheck}
            id="formCheck6"
        />
        <Label className="form-check-label" htmlFor="formCheck6">
            {props.t("student.details.activities.follow_up_required")}
        </Label>
    </div>

    return (
        <>
            <AddTaskEntry
                show={tasksModals}
                onCloseClick={() => setTasksModals(false)}
                setTriggerApi={null}
                props={props}
                createAction={taskCreateRequest}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId}
            />
            {/* <Col lg={9}> */}
            <Modal className="w-75" id="modalForm" autoFocus={true} isOpen={notesModals} toggle={() => {
                resetTranscript();
                setIsNotesOpen(false);
            }} centered={true} size="lg">
                <ModalHeader className="p-3 bg-primary-subtle" toggle={() => {
                    resetTranscript();
                    setIsNotesOpen(false);
                    stopListening()
                    setNewNote('')
                    setNote('')
                }}>
                    <span>{t("student.modals.add_new_note")}</span>
                </ModalHeader>
                <ModalBody className="my-2 p-1">
                    <Row className="g-3 p-3">
                        <Col xs={12}>
                            {notesMetadata?.length > 0 &&
                                notesMetadata.map((item: any, index: number) => {
                                    if (item.type === "textarea") return (
                                        <>
                                            {textEle}
                                        </>
                                    )
                                })}
                        </Col>
                        <Col xs={12} className="mb-1 text-start">
                            <div className="d-flex justify-content-end gap-2">
                                {notesMetadata?.length > 0 &&
                                    notesMetadata.map((item: any, index: number) => {
                                        if (item.type === "checkbox") return (
                                            <>
                                                {checkkboxEle}
                                            </>
                                        )
                                    })}
                                <div style={{ minWidth: "250px" }}>
                                    {notesMetadata?.length > 0 &&
                                        notesMetadata.map((item: any, index: number) => {
                                            if (item.type === "select") return (
                                                <Select
                                                    key={index} // Unique key for the Select element
                                                    placeholder={t("common.filter.search.select_note_type")}
                                                    classNamePrefix="js-example-data-array"
                                                    isLoading={false}
                                                    options={item?.values} // Pass all options directly
                                                    value={selectedAddNoteType}
                                                    onChange={handleAddNoteTypeChange}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                />
                                            )
                                        })}
                                </div>
                                {listening ? (
                                    <Button
                                        color="danger"
                                        className="ms-2 btn-icon"
                                        outline
                                        type="button"
                                        onClick={stopListening}
                                    >
                                        <i className="ri-mic-off-line"></i>
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className="ms-2 btn-icon"
                                        outline
                                        type="button"
                                        onClick={startListening}
                                    >
                                        <i className="ri-mic-line"></i>
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    className="ms-2 btn btn-primary"
                                    disabled={
                                        isAddNoteButtonDisabled ||
                                        (!newNote || !selectedAddNoteType) &&
                                        (!transcript || !selectedAddNoteType)
                                    }
                                    onClick={handleAddNote}
                                >
                                    {props.t("student.details.activities.add_note")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleBar style={{ maxHeight: 300 }}>
                                <Comments props={props} record={record} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing} model={model} studentId={studentId} applicationId={applicationId} parentFilter={parentFilter} metaData={notesMetadata} details={record} notesFilter={notesFilter} />
                            </SimpleBar>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            {/* </Col> */}
        </>
    );
};

export default withTranslation()(NotesModal);
