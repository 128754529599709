/**
 * CustomNode.tsx
 *
 * This component defines a custom node for ReactFlow.
 * It renders a node with top and bottom handles for edges,
 * displays the node's label, and provides Add/Edit buttons.
 * If a node is marked as deleted, it shows a different style.
 */

import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { UncontrolledTooltip } from 'reactstrap';

interface CustomNodeProps {
	data: {
		label: string;
		nodeId: string;
		isDeleted?: boolean;
		onAdd: (node: any) => void;
		onEdit: (node: any) => void;
		onDelete: (node: any) => void;
	};
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
	// Create a unique tooltip ID based on the label.
	const tooltipId = `tooltip-${data.label.replace(/\s+/g, '-')}`;

	// Define the style for the node container.
	const containerStyle: React.CSSProperties = {
		minWidth: '150px',
		position: 'relative',
		backgroundColor: data.isDeleted ? '#d3d3d3' : undefined, // Light gray for deleted nodes.
		cursor: data.isDeleted ? 'not-allowed' : 'pointer',
	};

	return (
		<div
			id={tooltipId}
			className={`border p-2 rounded shadow-sm d-flex align-items-center justify-content-between ${data.isDeleted ? 'bg-danger-subtle border-danger' : 'bg-light border-dark'
				}`}
			style={containerStyle}
		>
			{/* Top handle for incoming edges */}
			<Handle
				type="target"
				position={Position.Top}
				id="target"
				style={{
					left: '50%',
					top: 0,
					background: '#555',
					width: '10px',
					height: '10px',
					borderRadius: '50%',
					transform: 'translate(-50%, -50%)',
					opacity: 0,
				}}
			/>

			<div className="d-flex gap-4 align-items-center justify-content-between w-100">
				<div
					className={`fw-bold text-truncate ${data.isDeleted ? 'text-danger' : 'text-dark'}`}
					style={{ maxWidth: '250px' }}
				>
					{data.label} {data.isDeleted ? '(Deleted)' : ''}
				</div>

				{/* Only render Add/Edit buttons if the node is not deleted */}
				{!data.isDeleted && (
					<div className="d-flex gap-2">
						<button
							type="button"
							className="btn btn-light p-0 border-0"
							title="Add Child"
							onClick={() => data.onAdd(data)}
						>
							<i className="ri-add-line text-success" style={{ fontSize: '20px' }}></i>
						</button>
						<button
							type="button"
							className="btn btn-light p-0 border-0"
							title="Edit"
							onClick={() => data.onEdit(data)}
						>
							<i className="ri-pencil-line text-primary" style={{ fontSize: '20px' }}></i>
						</button>
						<button
							type="button"
							className="btn btn-light p-0 border-0"
							title="Delete"
							onClick={() => data.onDelete(data)}
						>
							<i className="ri-delete-bin-line text-danger" style={{ fontSize: '20px' }}></i>
						</button>
					</div>
				)}
			</div>

			{/* Tooltip displaying the node label */}
			<UncontrolledTooltip placement="top" target={tooltipId}>
				{data.label}
			</UncontrolledTooltip>

			{/* Bottom handle for outgoing edges */}
			<Handle
				type="source"
				position={Position.Bottom}
				id="source"
				style={{
					left: '50%',
					bottom: 0,
					background: '#555',
					width: '10px',
					height: '10px',
					borderRadius: '50%',
					transform: 'translate(-50%, 50%)',
					opacity: 0,
				}}
			/>
		</div>
	);
};

export default CustomNode;
