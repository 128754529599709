import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { userGetRequest } from "store/auth/action";
import Comments from "./Comments";
import { noteCreateRequest, notesMetadataGetRequest, noteListGetRequest } from "store/notes/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { isJson } from "utils";
import { taskCreateRequest } from "store/tasks/action";
import AddTaskEntry from '../Tasks/innerComponent/AddEntryModal';
import DtsCKEditor from "Components/Common/FormBuilder/DtsCKEditor";

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];

const Notes = (props: any) => {
    let { id } = useParams();
    const { details, mainModel, studentId, applicationId } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metaData = useSelector((state: ApplicationState) => state.notes.metaDataFields);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [metadataLength, setMetadataLength] = useState<any>()
    const [transcribing, setTranscribing] = useState<"parent" | boolean>("parent");  // Set to 'parent' initially
    const [notesMetadata, setNotesMetadata] = useState<any[]>([]);  // Using `any[]` as the type
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [newNote, setNewNote] = useState("");
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [followupRequired, setFollowupRequired] = useState(false);
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState(false);
    const [parentField, setParentField] = useState<any>({})
    const [parentFilter, setParentFilter] = useState<any>([])
    const [note, setNote] = useState(""); // State to store final transcript
    const [previousWords, setPreviousWords] = useState<string[]>([]);


    useEffect(() => {
        let parent: any = {}
        let parentFilter: any = []
        const notesMetadata: any = [];
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : { ...field.valuesJson }
            if (field.valuesJson) return notesMetadata.push(item)
        })
        setNotesMetadata(notesMetadata)
        metaData && metaData.length && metaData.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if (field.key === "student") {
                parent = { ...field, ...item }
                setParentField({ ...field, ...item })
                let filter = {
                    "key": parent?.key,
                    "keyLabel": parent?.label,
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [studentId],
                    "valuesLabel": [],
                    "property": parent,
                    "quick": true,
                }
                setParentFilter([...parentFilter, filter])
            }
            else if (field.key === "application") {
                parent = { ...field, ...item }
                setParentField({ ...field, ...item })
                let filter = {
                    "key": parent?.key,
                    "keyLabel": parent?.label,
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [applicationId],
                    "valuesLabel": [],
                    "property": parent,
                    "quick": true,
                }
                setParentFilter([...parentFilter, filter])
            }
        })
    }, [metaData, details])

    const notesFilter = () => {
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }
        const filtersData: any = {
            "filters": [
                {
                    quick: [],
                    advance: [],
                    search: null,
                    parent: parent
                }
            ],
            "sorts": [
                {
                    "field": "createdAt",
                    "order": "desc"
                }
            ]
        }
        dispatch(noteListGetRequest(filtersData));
    }


    useEffect(() => {
        if (mainModel === 'students' && studentId) {
            notesFilter()
        }
        else if (mainModel === 'applications' && studentId && applicationId) {
            notesFilter()
        }
    }, [studentId, applicationId, mainModel])


    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition({
        transcribing: transcribing === "parent",  // Only enable transcribing when it's 'parent'
        clearTranscriptOnListen: true,  // Clear transcript when microphone is turned on
        commands: [  // Define custom speech commands if needed
            {
                command: 'stop',
                callback: () => stopListening(),
            },
        ]
    });

    useEffect(() => {
        setMetadataLength(metaData.length)
        resetTranscript();
        const filters = { pageSize: 500 }
        dispatch(notesMetadataGetRequest(filters));
        dispatch(userGetRequest(id));
    }, []);

    useEffect(() => {
        if (listening && transcript) {
          const words = transcript.trim().split(" "); // Convert transcript to words
    
          // Find new words by slicing from previous transcript length
          const newWords = words.slice(previousWords.length).join(" ").trim();
    
          if (newWords) {
            setNote((prevNote) => `${prevNote?prevNote:''} ${newWords?newWords:''}`.trim());
          }
    
          setPreviousWords(words); // Store current words for next comparison
        }
      }, [transcript, listening]);

      useEffect(() => {
        if(note) {
            setNewNote(note)
        }
      }, [note])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("parent");  // Set transcribing to 'parent' when listening starts
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        setTranscribing(false);  // Stop transcribing when listening stops
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleAddNoteTypeChange = (selectedOption: any) => {
        setSelectedAddNoteType(selectedOption);
        setAddShortNoteValue(selectedOption.label);
    };

    const handleNewNoteChange = (
        input: React.ChangeEvent<HTMLTextAreaElement> | string,
        appendSpeech: boolean = false
    ) => {
        if(!listening){
            setNote(`${input?input:''}`.trim())
        }
    };

    const handleReset = () => {
        setNewNote("");
        setSelectedAddNoteType(null);
        setIsAddNoteButtonDisabled(false);
        resetTranscript();
        setAddShortNoteValue("");
    };

    const handleAddNote = () => {
        stopListening();
        setIsAddNoteButtonDisabled(true);
        const data = {
            shortNote: addShortNoteValue,
            student: studentId,
            application: applicationId,
            parent: "",
            note: newNote
        };
        const handleSuccess = (body: any): void => {
            createFollowUp();
            handleReset();
            notesFilter()
        };

        const handleError = (body: any): void => {
            // Handle error here if needed
        };

        dispatch(noteCreateRequest(data, handleSuccess, handleError));
    };

    const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
        if (typeof event === "boolean") {
            setIsChecked(event);
            setFollowupRequired(event);
        } else {
            const checked = event.target.checked;
            setIsChecked(checked);
            setFollowupRequired(checked);
        }
    };

    const createFollowUp = () => {
        if (followupRequired) {
            setTasksModals(true);
        }
    };

    const textEle = <div>
        <DtsCKEditor
            value={newNote || ""}
            onChange={(data: any) => {
                handleNewNoteChange(data)
            }}
            name={'body'}
            stopListening={stopListening}
            type={'texteditor'}
            actionButtons={false}
            defaultValue={newNote}
        />
    </div>

    const checkkboxEle = <div className="form-check mb-0 align-self-center">
        <Input
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={handleFollowupCheck}
            id="formCheck6"
        />
        <Label className="form-check-label" htmlFor="formCheck6">
            {props.t("student.details.activities.follow_up_required")}
        </Label>
    </div>

    return (
        <React.Fragment>
            <AddTaskEntry
                show={tasksModals}
                onCloseClick={() => setTasksModals(false)}
                setTriggerApi={null}
                props={props}
                createAction={taskCreateRequest}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId}
                model={mainModel}
            />
            <Card>
                <CardBody>
                    <form className="mt-1">
                        <Row className="g-3">
                            <Col xs={12}>
                                {notesMetadata?.length > 0 &&
                                    notesMetadata.map((item: any, index: number) => {
                                        if (item.type === "textarea") return (
                                            <>
                                                {textEle}
                                            </>
                                        )
                                    })}
                            </Col>
                            <Col xs={12} className="mb-1 text-start">
                                <div className="d-flex justify-content-end gap-2">
                                    {notesMetadata?.length > 0 &&
                                        notesMetadata.map((item: any, index: number) => {
                                            if (item.type === "checkbox") return (
                                                <>
                                                    {checkkboxEle}
                                                </>
                                            )
                                        })}
                                    <div style={{ minWidth: "250px" }}>
                                        {notesMetadata?.length > 0 &&
                                            notesMetadata.map((item: any, index: number) => {
                                                if (item.type === "select") return (
                                                    <Select
                                                        key={index} // Unique key for the Select element
                                                        placeholder={props.t("common.filter.search.select_note_type")}
                                                        classNamePrefix="js-example-data-array"
                                                        isLoading={false}
                                                        options={item?.values} // Pass all options directly
                                                        value={selectedAddNoteType}
                                                        onChange={handleAddNoteTypeChange}
                                                        menuPlacement="auto"
                                                        menuPosition="fixed"
                                                    />
                                                )
                                            })}
                                    </div>
                                    {listening ? (
                                        <Button
                                            color="danger"
                                            className="ms-2 btn-icon"
                                            outline
                                            type="button"
                                            onClick={stopListening}
                                        >
                                            <i className="ri-mic-off-line"></i>
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            className="ms-2 btn-icon"
                                            outline
                                            type="button"
                                            onClick={startListening}
                                        >
                                            <i className="ri-mic-line"></i>
                                        </Button>
                                    )}
                                    <Button
                                        color="primary"
                                        className="ms-2 btn btn-primary"
                                        disabled={
                                            isAddNoteButtonDisabled ||
                                            (!newNote || !selectedAddNoteType) &&
                                            (!transcript || !selectedAddNoteType)
                                        }
                                        onClick={handleAddNote}
                                    >
                                        {props.t("student.details.activities.add_note")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                    <Comments
                        record={details}
                        noteType={noteType}
                        transcribing={transcribing}
                        setTranscribing={setTranscribing}
                        notesFilter={notesFilter}
                        model={mainModel}
                        parentField={parentField}
                        studentId={studentId}
                        applicationId={applicationId}
                        parentFilter={parentFilter}
                        metaData={notesMetadata}
                    />

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Notes);
