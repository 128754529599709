import { DocumentActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const documentGetRequest: AppThunk = (
    id: string,
    handleError?: (error?: any) => void,
    handleSuccess?: (blob: Blob) => void
) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        if (token) {
            headers.append("token", token);
        }
        dispatch({
            type: DocumentActionTypes.GET_DOCUMENT_REQUEST,
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/documents/${id}`, {
                method: "GET",
                headers: headers,
            });
            await responseHandler(
                response,
                dispatch,
                DocumentActionTypes.GET_DOCUMENT_SUCCESS,
                DocumentActionTypes.GET_DOCUMENT_ERROR,
                null,
                handleSuccess,
                handleError,
                "GET"
            );
        } catch (error) {
            console.error("Error fetching document:", error);
            handleError && handleError(error);
        }
    };
};

export const documentsMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.GET_DOCUMENTMETADATA_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        if (model) {
            headers.append('Properties-Model', model);
        }
        const searchQuery = createQueryLink(filterObject)
        try {
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, DocumentActionTypes.GET_DOCUMENTMETADATA_SUCCESS, DocumentActionTypes.GET_DOCUMENTMETADATA_ERROR, null, null, null, "GET");

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const documentUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.PUT_DOCUMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(
            `${DTS_API_STUDENT}/documents/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const error = {
            204: {
                "message": "Documents updated Successfully",
                "success": true
            },
            200: {
                "message": "Documents updated Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, DocumentActionTypes.PUT_DOCUMENT_SUCCESS, DocumentActionTypes.PUT_DOCUMENT_ERROR, error, handleSuccess, handleError);

    };
};

export const documentDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.DELETE_DOCUMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/documents/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Document deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Document deleted Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, DocumentActionTypes.DELETE_DOCUMENT_SUCCESS, DocumentActionTypes.DELETE_DOCUMENT_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: DocumentActionTypes.DELETE_DOCUMENT_ERROR
            });
            handleSuccess();
        }
    };
};
// ${DTS_API_STUDENT}/documents?page=${page}&pageSize=${size}

export const createdocumentQueryLink = (filterObject: any) => {
    const keys = filterObject ? Object.keys(filterObject) : [];
    const values = filterObject ? Object.values(filterObject) : [];
    let query = `${DTS_API_STUDENT}/documents?`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const documentListGetRequest: AppThunk = (filterObject, signal) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.GET_DOCUMENTS_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        let searchQuery = createdocumentQueryLink(filterObject)
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers,
            // body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if (typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: DocumentActionTypes.GET_DOCUMENTS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: DocumentActionTypes.GET_DOCUMENTS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const documentUploadRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.POST_DOCUMENT_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/documents`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "token": token
                },
                // body: JSON.stringify(data)
                body: data
            }
        );
        const body = await response.json();
        const { response: response_1, body: body_1 } = { response, body };
        if (!response_1.ok) {
            dispatch({
                type: DocumentActionTypes.POST_DOCUMENT_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: DocumentActionTypes.POST_DOCUMENT_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const profilePictureUploadRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.POST_PROFILEPICTURE_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/documents`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "token": token
                },
                // body: JSON.stringify(data)
                body: data
            }
        );
        const body = await response.json();
        const { response: response_1, body: body_1 } = { response, body };
        if (!response_1.ok) {
            dispatch({
                type: DocumentActionTypes.POST_PROFILEPICTURE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: DocumentActionTypes.POST_PROFILEPICTURE_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const documentDownload: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.DOWNLOAD_DOCUMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/documents/${id}`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR
            });
            handleSuccess();
        }
    };
};

export const downloadStudentsBulkTemplate: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();

        dispatch({
            type: DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Properties-Model', 'students');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/properties/download`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_ERROR
            });
            handleSuccess();
        }
    };
};

export const downloadApplicationsBulkTemplate: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Properties-Model', 'applications');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/properties/download`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_ERROR
            });
            handleSuccess();
        }
    };
};

export const downloadBulkUploadTemplate: AppThunk = (model, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: DocumentActionTypes.DOWNLOAD_BULKENTRY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Properties-Model', model);
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/properties/download`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_BULKENTRY_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: DocumentActionTypes.DOWNLOAD_BULKENTRY_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: DocumentActionTypes.DOWNLOAD_BULKENTRY_ERROR
            });
            handleSuccess();
        }
    };
};
