
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Row,
    Label,
    Button,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { userGetRequest, userUpdateRequest } from "store/user/action";
import { teamsListGetRequest } from "store/teams/action";
import UserAvatar from "Components/Common/UserAvtar/ListingUserAvatar";
import Select from "react-select";
import { toast } from "react-toastify";

const Profile = ({ t, user }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userDetails = useSelector((state: ApplicationState) => state.user.user);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const teamList = useSelector((state: ApplicationState) => state.teams.teams);
    const [phonenumber, setPhonumber] = useState<any>()
    const [teams, setTeams] = useState<any>([]);
    const [selectedTeams, setSelectedTeams] = useState<any>([]);

    useEffect(() => { if (user) dispatch(userGetRequest(user)) }, [user])

    useEffect(() => {
        dispatch(teamsListGetRequest());
    }, []);
    
    useEffect(() => {
        const teams = teamList?.map(team => {
            return {
                label: team?.name,
                value: team?.id
            }
        })
        setTeams(teams);

    }, [teamList])

    const onChange = (option: any, key: any) => {
        setPhonumber(option)
    };
     const handleUpdateInfo = () => {
        const attributes = [];
        if (userDetails?.phoneNumber !== phonenumber) {
          attributes.push({
            key: "phone_number",
            value: phonenumber
          });
        }
        attributes.push({
          key: "teams",
          value: selectedTeams?.map((team: any) => team.value).join(",") || ""
        });
    
        // Only dispatch if there are attributes to update
        if (attributes.length > 0) {
          const data = {
            attributes: attributes
          };
          const handleSuccess = (body: any): void => {
            // const message = () => toast(`User information updated successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            dispatch(userGetRequest(user))
          };
          const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
          };
          dispatch(userUpdateRequest(user, data, handleSuccess, handleError));
        }
      };

    const onSaveClick = async (type?:any, key?: any, value?: any) => {        
        handleUpdateInfo()
        // const handleSuccess = () => {
        //     dispatch(userGetRequest(user))
        // }
        // const handleError = () => {
            
        // }
        // dispatch(userUpdateRequest(user, dataToUpdate, handleSuccess, handleError))
    }

    useEffect(() => {
        if(userDetails) {
            setPhonumber(userDetails.phoneNumber)
            const selectedTeams = userDetails.teams?.map((team: any) => {
                return {
                    label: team?.name,
                    value: team?.id,
                }
            }) || [];
            setSelectedTeams(selectedTeams)
        }
    }, [userDetails])
    
    return (
        <React.Fragment>
            <div className="hstack mt-0 mb-4">
                <Row className="p-2 w-100 hstack gap-3 justify-items-center">
                    {/* <Col lg={12} className="vstack justify-content-between"> */}
                    <div className="vstack gap -1 justify-content-center">
                        <h4 className="">{t("userpreferences.profile")}</h4>
                        <p>{t("userpreferences.this_applies_across_zilter")}</p>
                    </div>
                    <div className="vstack gap-3 align-items-left">
                        <Label className="fw-bold fs-14">{t("userpreferences.profile_image")}</Label>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex position-relative gap-3">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                    {userDetails?.profilePic ? (
                                        <img
                                            src={userDetails.profilePic}
                                            className="rounded-circle avatar-sm img-thumbnail user-profile-image"
                                            alt="user-profile"
                                        />
                                    ) : (
                                        <>
                                        <UserAvatar className="fs-20 rounded-circle avatar-sm img-thumbnail user-profile-image" img={userDetails && userDetails.profilePic} firstName={userDetails && userDetails.name} />
                                        </>
                                    )}
                                </div>
                                <div className="mt-0">
                                    <Label className="mb-1 fs-16">{userDetails?.name}</Label>
                                    <div className="vstack gap-1 align-self-center flex-grow-1">
                                        <h6>{userDetails?.email}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-between">
                            <div className="w-50">
                                <FormBuilder
                                    disabled
                                    label={"Name"}
                                    type={"textfield"}
                                    value={userDetails?.name}
                                    name={"name"}
                                    placeholder={`Enter name`}
                                    className="flex-grow-1 me-3 mb-3"
                                    onChange={(value: any) => {

                                        // setvalue(value)
                                        //   setUserData({ ...userData, name: value })
                                    }}
                                    Editable={true}
                                />
                            </div>
                            <div className="w-50">
                                <FormBuilder
                                    disabled
                                    label={"Email"}
                                    type={"textfield"}
                                    value={userDetails?.email}
                                    name={"last_name"}
                                    placeholder={`Enter last name`}
                                    className="flex-grow-1 me-3 mb-3"
                                    Editable={false}
                                />
                            </div>
                            <div className="w-50">
                                <p>
                                    <FormBuilder
                                        disabled={!(userProfile?.role?.title === 'Owner')}
                                        label={"Main Phone"}
                                        type={"textfield"}
                                        defaultValue={phonenumber}
                                        value={phonenumber}
                                        onSaveClick={onSaveClick}
                                        onChange={(value: any) => onChange(value, 'phone_number')}
                                        name={"phone_number"}
                                        placeholder={`Enter Main number`}
                                        className="flex-grow-1 me-3 mb-3"
                                        Editable={false}
                                        // actionButtons={true}
                                    />
                                    {t("userpreferences.this_phone_number_used_for_contact")}
                                </p>
                                <div className="d-flex flex-column gap-1 mb-3">
                                    <Label htmlFor="exampleInputrounded" className="form-label">
                                        {t("users.invite.assign_teams")}
                                    </Label>
                                    <div className="w-100 select-element">
                                        <Select
                                            isMulti
                                            placeholder={t("users.invite.select_teams")}
                                            value={selectedTeams}
                                            onChange={setSelectedTeams}
                                            options={teams}
                                            classNamePrefix="js-example-data-array"
                                            isLoading={false}
                                        />
                                    </div>
                                </div>
                                <Button className='align-self-end cursor-pointer' color="primary" onClick={handleUpdateInfo}>{t("common.action_button.update_info")}</Button>
             
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Profile);
