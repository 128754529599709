import React, { useState, useRef } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../config"
import { useParams } from 'react-router'
import { userGetRequest } from 'store/auth/action'
const { DTS_API_DOMAIN, AWS_API } = config.api;

interface FProps {
    selectedFiles?: any;
    setselectedFiles?: (event: any) => void;
    setModalFile?:(event: boolean) => void;
    fileInfo?: any;
    setFileInfo?:  (event: any) => void;
    filterObject?: any;
    setData?: (event: any) => void;
    setPictureLoading?: (event: any) => void;
    setDataPreviewModal?:(event: boolean) => void;
}

const Upload: React.FC<FProps> = ({
    selectedFiles,
    setselectedFiles,
    setModalFile,
    fileInfo,
    setFileInfo,
    filterObject,
    setDataPreviewModal,
    setData,
    setPictureLoading}
) => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.auth.loading)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const inputRef = useRef<HTMLInputElement>(null)
    const [dragActive, setDragActive] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    
    const SubmitUpload = async (isTextract: boolean) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
        const studentId: any = id;
        setFileInfo && setFileInfo(file)
        if (file) {
                setUploading('started');
                const formData = new FormData();
                formData.append('file', file);
                setUploading('started');
                const config = {
                    onUploadProgress: function (progressEvent: any) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        setPercentage(percentCompleted)
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_DOMAIN}/users/uploadProfilePic`, formData, config)
                .then((res: any) => {                    
                    if(res == 'File uploaded successfully.') {
                        setPercentage(100)
                        setUploading('completed')
                        setModalFile && setModalFile(false) 
                        if(isTextract) {
                            setDataPreviewModal && setDataPreviewModal(true)
                            setPictureLoading && setPictureLoading(true)
                        } 
                        setselectedFiles && setselectedFiles([])
                        dispatch(userGetRequest());
                    }
                   
                })
        }
    }
    
    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New selectedFiles={selectedFiles} setselectedFiles={setselectedFiles ? setselectedFiles : () => {}} SubmitUpload={SubmitUpload} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload