import { useEffect, useState } from "react";
import {
    Label,
    Input,
    FormFeedback,
    Button,
    ButtonGroup,
    Spinner,
} from "reactstrap";
import Select from "react-select";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getFilterOptions, getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { createSelector } from 'reselect';
import { isJson } from "utils";

interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    isMulti?: boolean;
    loading?: boolean;
}

const DtsSelectInput: React.FC<DtsSelectInputProps> = ({loading, isMulti, setdisabled, onSaveClick, formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name, icon, iconPosition, errorText, actionButtons, readOnly, disabled }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [defaultoption, setDefaultOption] = useState()
    const [OptionsObject, setOptionsObject] = useState<any>({})
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const studentList = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [option, setOption] = useState({})
    const [options, setOptions] = useState([]);
    const [search, setsearch] = useState<string>("");
    const [filterObject, setFilterObject] = useState<any>({
        pageSize: 500
    });
    const [hover, setHover] = useState<any>(false)
    const [valid, setvalid] = useState<any>(false)    
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);
    const [errormsg, setErrormsg] = useState();
        const onhandleChange = (option: any) => {
        if(option.label == "Reset")
        {
            setOption({})
            onChange(option)
        }
        else if(rest && !rest?.ismulti && rest.returnValue && rest.returnValue === "label") 
        {
            let newoption = {label:option.label, value: option.label }
            setOption(option)
            onChange(newoption)
        }
        else {
            setOption(option)
            onChange(option)
        }
    }
    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                return false
            }
        }
        setErrormsg(undefined)
        return true
    }
    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])
    useEffect(() => {
        let newoptions: any = []
                if(rest && rest.returnValue && rest.returnValue === "label") 
            {
                let labeloptions: any = studentList && studentList.length && studentList.map((item: any) => {
                   let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
                    let label = rest?.optionLabel ? rest?.optionLabel : "name";
                    let value = valueJson[label] ? valueJson[label] : valueJson;
                    return { label: valueJson[label], value: item.id }
                })
                let newoptions = handleduplicates(labeloptions)
                setOptions(newoptions)
                setOptionsObject({...OptionsObject,[rest.model]: newoptions })
            }
            else {    
                let options: any = studentList && studentList.length && studentList.map((item: any) => {
                    let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
                    let label = rest?.optionLabel ? rest?.optionLabel : "name";
                    return { label: valueJson[label], value: item.id }
                })        
                setOptions(options)
                setOptionsObject({...OptionsObject,[rest.model]: options})
            }
            newoptions && newoptions.map((item: any) =>{
                if(item.value === defaultValue) { 
                    setDefaultOption(item)
                }
            })
        }, [studentList, rest])
    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])

    // useEffect(() => {
    //     if (rest && rest.model && parent != rest.key) {
    //         setParent(rest.key)
    //         dispatch(getOptions(rest.model, filterObject))
    //     }
    // }, [rest])

    const handletypetext = (
        inputValue: string
    ) => {
                const filters = {...filterObject,
            search: inputValue
        }
        setTimeout(() => {
            dispatch(getOptions(rest.model, filters));
        }, 500);
        if(rest && rest.returnValue && rest.returnValue === "label") 
            {
                let labeloptions: any = studentList && studentList.length && studentList.map((item: any) => {
                  
                    let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
                  
                    let label = rest?.optionLabel ? rest?.optionLabel : "name";
                    // let value = valueJson[label] ? valueJson[label] : valueJson;
                    // if(typeof value === 'string') return { label: value, value: item.id } 
                    // else return { label: value[label], value: item.id }
                    return { label: valueJson[label], value: item.id }
                })
                let newoptions = handleduplicates(labeloptions)
                setOptions(newoptions)
                setOptionsObject({...OptionsObject,[rest.model]: newoptions})
            }
            else {    
                let options: any = studentList && studentList.length && studentList.map((item: any) => {
      
                    let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
                    let label = rest?.optionLabel ? rest?.optionLabel : "name";
              
                    return { label: valueJson[label], value: item.id }
                })        
                setOptions(options)
                setOptionsObject({...OptionsObject,[rest.model]: options})
            }
    };

    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }
    const handleduplicates = (options: any) => {
        const seenLabels = new Set();
        const uniqueOptions: any= [];
        options && options.length && options.forEach((option: any) => {
            if (!seenLabels.has(option.label)) {
                seenLabels.add(option.label);
                uniqueOptions.push(option);
            }
        });
        return uniqueOptions;
    }

    useEffect(() => {       
        if(rest && rest.returnValue && rest.returnValue === "label") 
        {
            let labeloptions: any = studentList && studentList.length && studentList.map((item: any) => {
             
                let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
             
                let label = rest?.optionLabel ? rest?.optionLabel : "name";
                let value = valueJson[label] ? valueJson[label] : valueJson;
                // if(typeof value === 'string') return { label: value, value: item.id } 
                //     else return { label: value[label], value: item.id }
                    return { label: valueJson[label], value: item.id }
            })
            let newoptions = handleduplicates(labeloptions)
            setOptions(newoptions)
            setOptionsObject({...OptionsObject,[rest.model]: newoptions})
        }
        else {    
            let options: any = studentList && studentList.length && studentList.map((item: any) => {
    
                let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
                let label = rest?.optionLabel ? rest?.optionLabel : "name";
       
                return { label: valueJson[label], value: item.id }
            })        
            setOptions(options)
            setOptionsObject({...OptionsObject,[rest.model]: options})
        }
    }, [studentList])
    
    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className='hstack justify-content-between'>
                {props.children}
                {props.isSelected && props.value !== undefined && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };

      const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    // color: '#495057',
                    fontWeight: 900,
                    // border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 3,
                    boxShadow: 'none',
                    '&:hover': {
                    // border: '0.5px solid lightgrey',
                    },
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          borderRadius: 4,
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }
    const onFocus = () => {
        setOptions([])
        if (rest && rest.model) {
            dispatch(getOptions(rest.model, filterObject))
        }
    }
    return (
         <div> 
         {
             actionButtons ?
             <div>
                 { hover ?
                 <div>
                     {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                     <div className="d-flex border rounded-3 align-items-center">
                        <Select {...rest} 
                            onFocus={() =>onFocus()}
                            isMulti={rest?.ismulti}
                            styles={customStyles}
                            defaultOptions
                            value={value}
                            components={{ Option: CustomOption }}
                            className='form-select-sm p-0 border-0' 
                            type={type}
                            defaultValue={defaultoption}
                            isOptionDisabled={(option: any) => option.label == 'Loading...'}
                            options={optionLoading ? [{ label: 'Loading...', value: null }] : options || []}
                            id={name}
                            placeholder={placeholder ? placeholder : `Select ${rest.label}`}
                            onChange={(selectedOption: any) => onhandleChange(selectedOption)}
                            readOnly={readOnly}
                            disabled={disabled}
                            // onInputChange={(inputValue: any) => handletypetext(inputValue)}
                        ></Select>
                            <ButtonGroup>
                                <Button onClick={(e) => { e.preventDefault(); setHover(false); onhandleChange(defaultValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                <Button onClick={(e) => {onCheckClick(e)}} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                            </ButtonGroup>
                    </div> 
                     {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}                     
                 </div>
                 : <div>
                     {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}              
                     <div className="hstack justify-content-between">
                         <div className="fs-14 fw-medium form-control border-0 text-nowrap text-truncate text-nowrap-ellipsis" >
                             {defaultValue}
                         </div>
                         {
                            readOnly ? null : <Button disabled={loading} onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary" id={`btn${name}`}> 
                            {loading ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-edit-2-fill" /> } 
                        </Button>}
                     </div>
                 </div>
                 }
             </div>
         :            
             <div>
                 {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                 <div className="search-box">
                    <Select {...rest} 
                        isMulti={rest?.ismulti}
                        styles={customStyles}
                        onFocus={() =>onFocus()}
                        defaultOptions
                        components={{ Option: CustomOption }}
                        className='form-select-sm p-0'   
                        value={value}
                        type={type}
                        defaultValue={defaultoption}
                        isOptionDisabled={(option: any) => option.label == 'Loading...'}
                        options={optionLoading ? [{ label: 'Loading...', value: null }] : options || []}
                        id={name}
                        placeholder={placeholder ? placeholder : `Select ${rest.label}`}
                        onChange={(selectedOption: any) => onhandleChange(selectedOption)}
                        readOnly={readOnly}
                        disabled={disabled}
                        // onInputChange={(inputValue: any) => handletypetext(inputValue)}
                        >
                    </Select>
                    {value ? <i className="ri-close-circle-fill search-icon cursor-pointer" style={{right:40, left:'auto'}} onClick={() => onhandleChange({label: "Reset", value: ""})}></i> : null}
                </div>
                 {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
             </div>
         }
         </div>
    );
};

export default DtsSelectInput;
