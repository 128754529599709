import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Table } from "reactstrap";
import { Link } from 'react-router-dom';
import { Action } from "redux";
import { ApplicationState } from "store";
import { reportDetailGetRequest } from "store/report/action";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import Paginator from "Components/Common/PaginatorFix";
import processFilterDateType from "helpers/filter_datetime";
import moment from "moment";

interface ReportProps {
  config: any;
  detailReportData: any;
  setDetailReportData: (detailReportData: any) => void;
  t: (key: string) => string;
}

const ReportDetails: React.FC<ReportProps> = ({
  t,
  config,
  detailReportData,
  setDetailReportData,
}) => {
  const [reportConfig, setReportConfig]: any = useState();
  const [fieldsError, setFieldsError]: any = useState(false);
  const [pagination, setPagination]: any = useState(10);
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const generatedReport = useSelector(
    (state: ApplicationState) => state.report.generated_report_detail
  );
  const loading = useSelector(
    (state: ApplicationState) => state.report.generated_report_detail_loading
  );
  const paginationResponse = useSelector(
    (state: ApplicationState) => state.report.pagination_detail
  );

  useEffect(() => {
    setFieldsError(false);
    if (detailReportData) {
      if (detailReportData?.key === "all") {
        if (config?.fields?.length) {
          const newConfig = { ...config };
          newConfig.filters = config?.filters.filter(
            (filter: any) => !filter?.detail_field
          );
          newConfig.filter_condition = "1";
          newConfig.chart_type = "table";
          newConfig.columns = config.fields;
          newConfig.limit = 10;
          newConfig.page = 1;
          setReportConfig(newConfig);
        } else {
          setFieldsError(true);
        }
      } else {
        if (config?.fields?.length) {
          const newConfig = { ...config };
          newConfig.filters = config?.filters.filter(
            (filter: any) => !filter?.detail_field
          );
          if (!detailReportData?.all) {
            newConfig?.filters.push(detailReportData);
          }
          newConfig.filter_condition = "1";
          newConfig.chart_type = "table";
          newConfig.columns = config.fields;
          newConfig.limit = 10;
          newConfig.page = 1;
          setReportConfig(newConfig);
        } else {
          setFieldsError(true);
        }
      }
    }
  }, [config, detailReportData, dispatch]);

  useEffect(() => {
    if (reportConfig) {
      const updatedConfig = {
        ...reportConfig,
        filters: processFilterDateType(reportConfig.filters),
      };
      dispatch(reportDetailGetRequest(updatedConfig));
    }
  }, [reportConfig, dispatch]);

  useEffect(() => {
    setPagination(paginationResponse);
  }, [paginationResponse]);

  const pageChanged = (pageVal: any) => {
    const { pages, page } = pagination;
    const lastPage = pages;
    let newPageNo = 0;
    switch (pageVal) {
      case "prev":
        if (page >= 1) {
          newPageNo = page - 1;
          setPagination({ ...pagination, page: newPageNo });
          setReportConfig({ ...reportConfig, page: newPageNo });
        }
        break;
      case "next":
        if (page < lastPage) {
          newPageNo = page + 1;
          setPagination({ ...pagination, page: newPageNo });
          setReportConfig({ ...reportConfig, page: newPageNo });
        }
        break;
      case "first":
        newPageNo = 1;
        setPagination({ ...pagination, page: 1 });
        setReportConfig({ ...reportConfig, page: 1 });
        break;
      case "last":
        newPageNo = lastPage;
        setPagination({ ...pagination, page: newPageNo });
        setReportConfig({ ...reportConfig, page: newPageNo });
        break;
      default:
        newPageNo = pageVal;
        setPagination({ ...pagination, page: newPageNo });
        setReportConfig({ ...reportConfig, page: newPageNo });
        break;
    }
  };

  const onChangePageSize = (event: any) => {
    setPagination({ ...pagination, size: event.target.value });
    setReportConfig({ ...reportConfig, limit: event.target.value });
  };

  return (
    <Modal
      size="xl"
      isOpen={!!detailReportData}
      toggle={() => setDetailReportData(null)}
      className="modal-fullscreen"
    >
      <div className="d-flex flex-column gap-4 h-100">
        <div className="d-flex justify-content-between align-items-center p-3 bg-primary">
          <h5 className="modal-title text-white">Report Details</h5>
          <Button
            size="sm"
            color="primary"
            type="button"
            onClick={() => setDetailReportData(null)}
            aria-label="Close"
          >
            <i className="text-light ri-close-line fs-4 cursor-pointer"></i>
          </Button>
        </div>
        <div className="h-100 p-3">
          {loading ? (
            <DtsTablePlaceholder
              columns={reportConfig?.columns}
              tableClass="align-middle table-nowrap"
              thClass="border-bottom-1 table-soft-primary"
            />
          ) : fieldsError ? (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <h4 className="text-danger">
                No fields available to display. Please add the required fields to populate the table.
              </h4>
            </div>
          ) : (
            <div className="d-flex flex-column gap-2" style={{ height: "calc(100% - 140px)" }}>
              <Row className="align-items-center g-3 text-center text-sm-start">
                <div className="col-sm">
                  <div className="text-muted">
                    <span className="fw-semibold ms-1">
                      {pagination
                        ? `${pagination.offset + 1}-${Math.min(
                            pagination.offset + pagination.size,
                            pagination.elements
                          )} of ${pagination.elements} results`
                        : `0-0 of 0 results`}
                    </span>
                  </div>
                </div>
                <div className="col-sm-auto">
                  <Paginator
                    total={pagination && pagination.elements ? pagination.elements : 0}
                    currentPage={pagination?.page}
                    pageChanged={(e: any) => pageChanged(e)}
                    pageSize={pagination?.size}
                    isPageSizeChange={true}
                    onChangeInSelect={(e: any) => onChangePageSize(e)}
                    pagination={pagination}
                  />
                </div>
              </Row>
              <div className="table-responsive overflow-auto bg-white">
                <Table className="align-middle table-nowrap">
                  <thead className="table-light text-muted position-sticky top-0">
                    <tr>
                      {reportConfig?.columns.map((column: any, idx: number) => (
                        <th key={idx} scope="col" className="border-bottom-1 table-soft-primary fw-bold">
                          {column.label ? column.label : column.key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {generatedReport?.data?.map((row: any, rowIndex: number) => (
                      // Make the entire row clickable for the primary record.
                      <tr key={rowIndex}>
                        {reportConfig?.columns.map((column: any, colIndex: number) => {
                          let cellValue: any;
                          // If primary, the value is directly on the row.
                          if (column?.data_source?.primary) {
                            cellValue = row[column?.key];
                          } else {
                            // For foreign columns, the value is nested under the secondary table name.
                            cellValue =
                              row[column?.data_source?.value] &&
                              row[column?.data_source?.value][column?.key]
                                ? row[column?.data_source?.value][column?.key]
                                : null;
                          }
                          // If this column is of type datasetselect/treeselect and a foreign id exists,
                          // render the cell value as a clickable link.
                          if (colIndex === 0 && !column?.model && column?.data_source?.primary && row["record_id"]) {
                            return (
                              <td key={colIndex} className="align-middle fw-semibold">
                                <Link to={"/"+column?.data_source?.value+"/" + row["record_id"]} className="text-ellipsis cursor-pointer" target="_blank" rel="noopener noreferrer">
                                  {cellValue}
                                </Link>
                              </td>
                            );
                          } else if (
                            (column?.type === "datasetselect" || column?.type === "treeselect") &&
                            row[column?.key + "_id"]
                          ) {
                            return (
                              <td key={colIndex} className="align-middle fw-semibold">
                                <Link to={"/sources/" + row[column?.key + "_id"]} className="text-ellipsis cursor-pointer" target="_blank" rel="noopener noreferrer">
                                  {cellValue}
                                </Link>
                              </td>
                            );
                          } else {
                            return (
                              <td key={colIndex} className="align-middle fw-semibold">
                                {column?.type === "datetime"
                                  ? moment(cellValue).format("lll")
                                  : cellValue}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Row className="align-items-center g-3 text-center text-sm-start">
                <div className="col-sm">
                  <div className="text-muted">
                    <span className="fw-semibold ms-1">
                      {pagination
                        ? `${pagination.offset + 1}-${Math.min(
                            pagination.offset + pagination.size,
                            pagination.elements
                          )} of ${pagination.elements} results`
                        : `0-0 of 0 results`}
                    </span>
                  </div>
                </div>
                <div className="col-sm-auto">
                  <Paginator
                    total={pagination && pagination.elements ? pagination.elements : 0}
                    currentPage={pagination?.page}
                    pageChanged={(e: any) => pageChanged(e)}
                    pageSize={pagination?.size}
                    isPageSizeChange={true}
                    onChangeInSelect={(e: any) => onChangePageSize(e)}
                    pagination={pagination}
                  />
                </div>
              </Row>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(ReportDetails);
