import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroup } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import SideMenuItem from './SideMenuItem';
import PermissionDetails from './PermissionDetails';

export interface MenuItem {
	id: string | number;
	name: string;
	children: any[]; // You can refine this type as needed.
	permissions: any[];
}

interface PermissionsManagementProps extends WithTranslation {
	accessData: MenuItem[]; // The full permissions tree.
	setAccessPermissions: (data: any) => void; // Update parent's permission lookup.
	setAccessData: (data: any) => void; // Update parent's full tree.
	accessPermissions: any; // Current permission values.
	preAccessPermissions?: any;
}

/**
 * updateTree
 * Recursively traverses the tree and updates the permission whose id matches.
 * @param items The tree array.
 * @param id The permission id to update.
 * @param newValue The new value to assign.
 * @returns Updated tree.
 */
const updateTree = (items: any[], id: string, newValue: any): any[] => {
	return items.map(item => {
		if (item.id === id) {
			return { ...item, value: newValue };
		}
		let updatedPermissions = item.permissions;
		if (Array.isArray(item.permissions)) {
			updatedPermissions = updateTree(item.permissions, id, newValue);
		}
		let updatedChildren = item.children;
		if (Array.isArray(item.children)) {
			updatedChildren = updateTree(item.children, id, newValue);
		}
		return { ...item, permissions: updatedPermissions, children: updatedChildren };
	});
};

/**
 * PermissionsManagement Component
 * Renders a side–menu tree (left) and a permission details panel (right).
 * When a permission is changed, it updates both the local lookup (permissionValues)
 * and the full tree using updateTree.
 */
const PermissionsManagement: React.FC<PermissionsManagementProps> = ({
	accessData,
	setAccessPermissions,
	setAccessData,
	preAccessPermissions,
	t,
}) => {
	// Ensure accessData is an array.
	const menuItems: MenuItem[] = Array.isArray(accessData) ? accessData : [];

	// Local state for currently selected menu item.
	const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
	// Local state for permission lookup values.
	const [permissionValues, setPermissionValues] = useState<{ [id: string]: any }>(
		preAccessPermissions || {}
	);

	// Update permissionValues when preAccessPermissions changes.
	useEffect(() => {
		setPermissionValues(preAccessPermissions || {});
	}, [preAccessPermissions]);

	console.log("permissionValues>>>", permissionValues);

	// Set default selected item if not already set.
	useEffect(() => {
		if (menuItems.length > 0 && !selectedItem) {
			setSelectedItem(menuItems[0]);
		}
	}, [menuItems, selectedItem]);

	// Update parent's lookup state when permissionValues changes.
	useEffect(() => {
		setAccessPermissions(permissionValues);
	}, [permissionValues, setAccessPermissions]);

	/**
	 * handlePermissionChange:
	 * - Update local permissionValues.
	 * - Update the full tree (accessData) using updateTree.
	 */
	const handlePermissionChange = (id: string, value: any) => {
		setPermissionValues(prev => ({ ...prev, [id]: value }));
		setAccessData((prevData: any[]) => updateTree(prevData, id, value));
	};

	const handleMenuSelect = (item: MenuItem | any) => {
		setSelectedItem(item);
	};

	return (
		<Row>
			{/* Left: Side Menu */}
			<Col md={3} className="border-end" style={{ minHeight: '70vh' }}>
				<div className="p-3">
					<h5 className="mb-3 text-primary">{t('roles.menu')}</h5>
					{menuItems && menuItems.length ? (
						<ListGroup>
							{menuItems.map(item => (
								<SideMenuItem
									key={item.id}
									item={item}
									onSelect={handleMenuSelect}
									selectedItemId={selectedItem ? selectedItem.id : null}
								/>
							))}
						</ListGroup>
					) : (
						<div>{t('roles.no_menu_items_available')}</div>
					)}
				</div>
			</Col>
			{/* Right: Permission Details */}
			<Col md={9}>
				<div className="p-3">
					{selectedItem ? (
						<PermissionDetails
							item={selectedItem}
							permissionValues={permissionValues}
							onPermissionChange={handlePermissionChange}
						/>
					) : (
						<div className="text-center text-muted">
							{t('roles.please_select_a_menu_item_to_view_its_permissions')}
						</div>
					)}
				</div>
			</Col>
		</Row>
	);
};

export default withTranslation()(PermissionsManagement);
