import { useEffect, useState } from "react";
import {
    Form,
    Button,
    Col,
    Row,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import DynamicTable from "./DynamicTable";
import _ from "lodash";
import FormLoader from "./FormLoader";

const QuickFormComponent = ({ dataFields, btnAction, btn_text, APAId , data, btndisabled, xl, lg, md, sm, xs, BtnClassName, theme, key, commissionJson, tier, preFilledKey, pathParts, preFilledKeyValue, metaLoading, onlyLevelFields}: any) => {
    const filterFields: any = [];   
   
    dataFields && dataFields.length && dataFields.map((field: any) => {           
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
    
        if (field.valuesJson) return filterFields.push(item)
    })
    const [formValues, setFormValues] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [tableData , setTableData] = useState<any>({});
    const [request, setRequest] = useState<any>([]);

    const onChange = (option: any, key: any, field: any) => { 
        if(key.includes('multiple_payments')) {
            let newKey = `${key}_data`;
            let period =key.split('_multiple_payments')[0]+'_commissionable_period'
            setFormValues((prevValues: any) => ({
                ...prevValues,
                [key]: option,
                [period]: null 
              }));
            setFieldValues({ ...formValues, [key]: option, [period]: null })
            if(!option) {                
                onChangeData([], newKey)
            }
        }
        else if(field.type === "selectboxes" || field.isMulti) {   
            // setFormValues({ ...formValues, [key]: option })
            setFormValues((prevValues: any) => ({
                ...prevValues,
                [key]: option,
              }));
            setFieldValues({ ...formValues, [key]: option })
        } 
        else if (typeof option === 'object') {       
            // setFormValues({ ...formValues, [key]: option?.value || option })
            setFormValues((prevValues: any) => ({
                ...prevValues,
                [key]: option?.value || option }));
            setFieldValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            // setFormValues({ ...formValues, [key]: option })
            setFormValues((prevValues: any) => ({
                ...prevValues,
                [key]: option }));
            setFieldValues({ ...formValues, [key]: option })
        }
    };
    const allMandatoryFieldsFilled = () => {
        for (const field of filterFields) {
            if (field && field.quickCreate && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {  
        if(preFilledKey && pathParts.includes('create')) {
            let newValues = {...formValues,...preFilledKeyValue}
            setFormValues(newValues)
            let newlabelValue = {...fieldValues,...preFilledKeyValue}
            setFieldValues(newlabelValue)
        } 
        else if(data) {
            let values=data;
            setFormValues({...values, ...preFilledKeyValue})
            let newlabelValue = {...values,...preFilledKeyValue}
            setFieldValues(newlabelValue)
            const filteredData = Object.keys(data)
            .filter((key: any) => key.endsWith("_multiple_payments_data"))
            .reduce((obj: any, key: any) => {
                obj[key] = structuredClone(data[key]); // Ensure a deep copy
                return obj;
            }, {});
            setTableData(filteredData); 
        }
    }, [data, preFilledKey, preFilledKeyValue])

    const formdisabled = Object.keys(formerror).length === 0;

    useEffect(() => {
        let newValues={...formValues}
        setRequest({...newValues, ...tableData})
    }, [formValues, tableData])
    

    useEffect(() => {
        if(tier) {
            if(commissionJson && formValues?.clone_from_basic) {
                const filteredData = Object.keys(commissionJson?.basic)
                 .filter((key: any) => key.endsWith("_multiple_payments_data"))
                 .reduce((obj: any, key: any) => {
                    obj[key] =  commissionJson?.basic[key] && commissionJson?.basic[key]?.length ? structuredClone(commissionJson?.basic[key]) : []; // Ensure a deep copy
                    return obj;
                 }, {});
                 let newData = JSON.parse(JSON.stringify(filteredData))
                 let newFormValues = { ...formValues, ...commissionJson?.basic}
                 setFormValues(newFormValues)  
                 setFieldValues(newFormValues)   
                 setTableData(newData)
                 setRequest({...newFormValues , ...newData})
             }
             else if(data) {
                 let values=data;
                 setFormValues({...values,clone_from_basic: formValues?.clone_from_basic})
                 setFieldValues({...values,clone_from_basic: formValues?.clone_from_basic})   
                 const filteredData = Object.keys(data)
                 .filter((key: any) => key.endsWith("_multiple_payments_data"))
                 .reduce((obj: any, key: any) => {
                     obj[key] = structuredClone(data[key]); // Ensure a deep copy
                     return obj;
                 }, {});
                 setTableData(filteredData); 
             }
             else {
                 let newFormValues = {...formValues, clone_from_basic: formValues?.clone_from_basic, commissionable_period: null, multiple_payments: false}
                 setFormValues(newFormValues)      
                 setFieldValues(newFormValues) 
                 setTableData({})
             }
        }
    }, [formValues?.clone_from_basic])

    const onChangeData = (data: any, key: any) => {
        setTableData({...tableData, [key]: data})
    }

    const handleTable = (value: any, levelkey : any, key: any, type: any) => {
        let commissionable_period = `${type}_commissionable_period`
        if(value){
            return <Row>
                    {
                        <DynamicTable key={key} paymentsCount={formValues?.number_of_payments} setFormValues={setFormValues} formValues={formValues} setdisabled={setdisabled}
                        period={formValues?.[commissionable_period] || 'year'} setTableData={(data: any) => onChangeData(data, key)} dataValues={data?.data ? [...data?.data] : []} tableData={tableData?.[key] || []}
                        />
                    }
                </Row>
        }
        else return null
    }

    const handleSelect = (value: any, levelkey : any, field: any, type: any) => {
        if(value){
            return <Col xl={12} lg={12} md={12} sm={12} xs={12} className={field.type === 'checkbox' ? "hstack align-items-center" : ''}><FormBuilder
            placeholder={field.placeholder}
            name={field.key}
            onChange={(e: any) => onChange(e, field.key, field)}
            options={field.values || []}
            label={field.label}
            type={field.type}
            value={formValues[field.key] || 'year'}
            isEditState={true}
            actionButtons={false}
            defaultValue={formValues[field.key] || 'year'}
            Editable={true}
            rest={field}
            invalid={true}
            formValues={formValues}
            dataFields={filterFields}
            setdisabled={setdisabled}
            setError={setError}
            formerror={formerror}
            resetValue={fieldValues[field.key] || 'year'}
    /></Col>
        }
        else return null
    }
            
    return (
        <div> 
            {
                metaLoading ? <FormLoader rows={5} cols={2} btn_text={btn_text} /> :
                <Form action="" className="d-flex flex-column justify-content-end h-100">
                    <div className="live-preview vstack gap-2">
                        <Row>
                            {filterFields && filterFields.length ? filterFields.map((field: any, index: any) => { 
                                let width = field.key === 'notes' || field.key === 'channelId' || field.key === 'instituteId' ||  field.key === 'subagentId' || field.key === 'clone_from_basic' ? 12 : xl?xl:12;
                                    if(field.quickCreate) { 
                                        if(field.type === 'select')   {
                                            if(formValues?.[filterFields[index - 1]?.key])  return handleSelect(formValues?.[filterFields[index - 1]?.key], filterFields[index - 1]?.key, field, filterFields[index - 2]?.key)
                                            else return null
                                        } 
                                        else if(field.key === 'clone_from_basic')   {
                                            return <Col xl={width} lg={width} md={width} sm={width} xs={width} className={field.type === 'checkbox' ? "hstack align-items-center" : ''}><Button
                                                color={'primary'}
                                                size="sm"
                                                className={ "my-3 btn btn-primary w-auto"}
                                                onClick={() => onChange(!formValues?.[field.key], field.key, field)}
                                            >
                                                {field.label}
                                            </Button></Col>
                                        } 
                                        else if(field.type === 'table')   {
                                            if(formValues?.[filterFields[index - 2]?.key])  return handleTable(formValues?.[filterFields[index - 2]?.key], filterFields[index - 2]?.key, field.key, filterFields[index - 3]?.key)
                                            else return null
                                        } 
                                        else return <Col xl={width} lg={width} md={width} sm={width} xs={width} className={field.type === 'checkbox' ? "hstack align-items-center" : ''}><FormBuilder
                                            placeholder={field.placeholder}
                                            name={field.key}
                                            onChange={(e: any) => onChange(e, field.key, field)}
                                            options={(field.values && field.values.reverse()) || []}
                                            label={field.label}
                                            type={field.type}
                                            value={formValues[field.key]}
                                            isEditState={true}
                                            actionButtons={false}
                                            defaultValue={formValues[field.key]}
                                            Editable={true}
                                            rest={field}
                                            invalid={true}
                                            formValues={formValues}
                                            dataFields={filterFields}
                                            setdisabled={setdisabled}
                                            setError={setError}
                                            formerror={formerror}
                                            resetValue={fieldValues[field.key]}
                                    /></Col>
                                }
                            }): null}
                        </Row>
                    </div>
                    <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
                        <Button
                            color={theme ? theme : ''}
                            className={ BtnClassName ? BtnClassName : "btn btn-success w-100"}
                            disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || btndisabled}
                            onClick={() => btnAction(request)}
                        >
                            {btn_text}
                        </Button>
                    </div>
                </Form>
            }
        </div>
    );
};

export default QuickFormComponent;

