import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Label, Modal, UncontrolledAlert } from "reactstrap";
import { ApplicationState } from "store";
import { setResponse } from "store/auth/action";
import { roleListGetRequest, roleNameListRequest } from "store/role/action";
import { userInviteRequest, usersListGetRequest } from "store/user/action";
import { teamsListGetRequest } from "store/teams/action";

interface InviteUsersProps {
  inviteUser: boolean;
  setInviteUser: (event: boolean) => void;
  usersQueryParams: any;
  t?: any;
}
export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}
const InviteUser: React.FC<InviteUsersProps> = ({
  inviteUser,
  setInviteUser,
  usersQueryParams,
  t
}) => {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const roleList = useSelector((state: ApplicationState) => state.role.list);
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
  const [roles, setRoles] = useState<any[]>([])
  const [names, setNames] = useState<any[]>([]);
  const [emails, setEmails] = useState<any[]>([]);
  const [role, setRole] = useState<any>("");
  const [selectedRole, setSelectedRole] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inviteeName, setInviteeName] = useState("")
  const [isSendInviteDisabled, setIsSendInviteDisabled] = useState<boolean>(false);
  const [teams, setTeams] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState<any>([]);

  useEffect(() => {
    dispatch(roleListGetRequest());
    dispatch(teamsListGetRequest());
  }, []);
  
  useEffect(() => {
    const roleValues: any = [];
    roleList && roleList.filter((item: any) => item.title !== "Owner").map((item: any) => {
      roleValues.push({ value: item.id, label: item.title });
    });
    setRoles(roleValues);
  }, [roleList]);

  useEffect(() => {
      const teams = teamList?.map(team => {
          return {
              label: team?.name,
              value: team?.id
          }
      })
      setTeams(teams);

  }, [teamList])

  const handleSelectChange = (newValue: any) => {
    setEmails(newValue);
  };

  const handleNameChange = (newValue: any) => {
    setNames(newValue);
  };

  const handleRoleSearch = (option: any, name: string) => {
    let queryParams: any = new URLSearchParams({});
    switch (name) {
      case 'searchRole':
        if (option) {
          queryParams.set('search', option.toString());
        }
        break;
    }
    dispatch(roleNameListRequest(queryParams.toString()));
  }
  const handleInputChange = (inputValue: any) => {
    handleRoleSearch(inputValue, "searchRole");
  };

  const handleRoleSelect = (sortBy: any) => {
    setRole(selectedRole);
    setSelectedRole(sortBy.value);
    setMenuIsOpen(false);
  };
  const onSendInvite = (): void => {
    setIsSendInviteDisabled(true)
    const nameArray = names.map(item => item.value).join(',')
    const nameList = JSON.stringify(nameArray);
    const emailArray = emails.map(item => item.value).join(',');
    const emailList = JSON.stringify(emailArray);
    const data = {
      "attributes": [
        {
          "key": "name",
          "value": nameList.slice(1, -1)
        },
        {
          "key": "email",
          "value": emailList.slice(1, -1)
        },
        {
          "key": "role_Id",
          "value": selectedRole
        },
        {
          "key": "teams",
          "value": selectedTeams?.map((team: any) => team.value).join(",") || ""
        }
      ]
    };
    const handleSuccess = (body: any): void => {
      handleClear()
      dispatch(usersListGetRequest());
      const message = () => toast(`Invite sent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
      message();
      dispatch(setResponse(body));
    };
    const handleError = (body: any): void => {
      setIsSendInviteDisabled(false)
      const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
      message();
    };

    dispatch(userInviteRequest(data, handleSuccess, handleError));
  };
  const filterSuggestions = (inputValue: string) => {
    return emails.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<SuggestionOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSuggestions(inputValue));
      }, 1000);
    });

  const handleClear = () => {
    setIsSendInviteDisabled(false)
    setInviteUser(false);
    setSelectedRole("");
    setInviteeName("");
    setNames([])
    setEmails([])
    setSelectedTeams([])
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      color: 'auto',
      fontWeight: 900,
      border: state.isFocused ? '0.5px solid #2A5F8D' : 'auto',
      borderRadius: 8,
      // backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover': {
        border: '0.5px solid #2A5F8D',
      },
    }),

    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: "#2A5F8D",
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "white",
    }),
  }

  const equality = names.length !== emails.length;

  return (
    <>
      <Modal
        id="loginModals"
        tabIndex={1}
        isOpen={inviteUser}
        centered
      >
        <Card className="mb-0 card-light">
          <CardBody>
            <div className="bg-light">
              <h5>{t("users.invite.invite_user")}</h5>
            </div>
            <hr />
            <div className="d-flex align-items-center">
              <div className="w-100">
              {equality && selectedRole ?
                  <>
                    <UncontrolledAlert color="danger" className="mb-1">
                      <Label className="text-danger text-center">{t("users.invite.count_error_message")}</Label>
                    </UncontrolledAlert>

                  </> : null}
                <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                  {t("users.invite.invitee_name")}<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    <AsyncCreatableSelect
                      onChange={handleNameChange}
                      isMulti={true}
                      isClearable={true}
                      styles={customStyles}
                      loadOptions={promiseOptions}
                      placeholder={t("users.invite.enter_invitee_name")}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                  {t("users.invite.invitee_email")}<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    <AsyncCreatableSelect
                      onChange={handleSelectChange}
                      isMulti={true}
                      isClearable={true}
                      options={emails}
                      styles={customStyles}
                      loadOptions={promiseOptions}
                      placeholder={t("users.invite.enter_invitee_email")}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <Label htmlFor="exampleInputrounded" className="form-label">
                  {t("users.invite.assign_role")}<span className="text-danger"> *</span>
                  </Label>
                  <div className="w-100 select-element">
                    {/* <Col lg={12}> */}
                    <Select
                      placeholder={t("users.invite.select_role")}
                      value={role.label}
                      onInputChange={handleInputChange}
                      onChange={handleRoleSelect}
                      options={roles}
                      classNamePrefix="js-example-data-array"
                      isLoading={false}
                      menuIsOpen={menuIsOpen}
                      onMenuOpen={() => setMenuIsOpen(true)}
                      onMenuClose={() => setMenuIsOpen(false)}
                    />
                    {/* </Col> */}

                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                    <Label htmlFor="exampleInputrounded" className="form-label">
                      {t("users.invite.assign_teams")}
                    </Label>
                    <div className="w-100 select-element">
                        <Select
                            isMulti
                            placeholder={t("users.invite.select_teams")}
                            value={selectedTeams}
                            onChange={setSelectedTeams}
                            options={teams}
                            classNamePrefix="js-example-data-array"
                            isLoading={false}
                        />
                    </div>
                </div>
              </div>
            </div>
          </CardBody>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  className="btn-sm"
                  color="danger"
                  outline
                  onClick={() => {
                    handleClear()
                    setInviteUser(false);
                  }}
                >
                  {t("users.invite.cancel")}
                </Button>
              </div>
              <div>
                <Button
                  className="btn-sm"
                  color="success"
                  onClick={onSendInvite}
                  disabled={isSendInviteDisabled || (names.length !== emails.length) || (names.length === 0 || emails.length === 0 || !selectedRole)}
                >
                  {t("users.invite.send_invite")}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default withTranslation()(InviteUser);
