import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { invoiceCreateRequest, invoiceDelete, invoiceGetRequest, invoiceListGetRequest, invoicesMetadataGetRequest, invoiceUpdateRequest } from 'store/invoices/action';
import { paymentCreateRequest, paymentsMetadataGetRequest } from 'store/payments/action';
import PaymentDrawer from './Actions/PaymentDrawer';
import AddPaymentDrawer from './Actions/AddPaymentDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';

const Invoice = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen, defaultFilter, hideFilter } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const paymentMetadata = useSelector((state: ApplicationState) => state.payments.metaDataFields);
    const [record, setRecord] = useState<any>(null);
    const [isViewPayments, setIsViewPayments] = useState<boolean>(false);
    const [isAddPayment, setIsAddPayment] = useState<boolean>(false);


    useEffect(() => {
        dispatch(paymentsMetadataGetRequest("payments", { pageSize: 500 }));
    }, []);

    const onViewPayments = (data: any) => {
        setRecord(data);
        setIsViewPayments(true);
    }

    const onAddPayments = (data: any) => {
        setRecord(data);
        setIsAddPayment(true);
    }

    const updatedPaymentMetadata = paymentMetadata.filter((item: any) => {
        try {
            const parsed = JSON.parse(item.valuesJson);
            return parsed.key !== "invoice";
        } catch (error) {
            // If parsing fails, decide how to handle it (for now, we'll keep the item)
            return true;
        }
    });
    const extraActions = [{
        clickHandler: onViewPayments,
        btn_type: "btn-soft-info",
        btn_icon: "ri-list-check-2",
        btn_name: "View Payments",
    }, {
        clickHandler: onAddPayments,
        btn_type: "btn-soft-success",
        btn_icon: "ri-play-list-add-fill",
        btn_name: "Add Payment",
    }]
    return (
        <React.Fragment>
            {isViewPayments && <PaymentDrawer
                show={isViewPayments}
                onClose={() => setIsViewPayments(false)}
                data={record}
            />}
            {isAddPayment && <AddPaymentDrawer
                show={isAddPayment}
                onCloseClick={() => setIsAddPayment(false)}
                dataFields={updatedPaymentMetadata}
                props={props}
                createAction={paymentCreateRequest}
                invoice={record?.id}
            />}
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={invoiceListGetRequest}
                deleteAction={invoiceDelete}
                detailsGetAction={invoiceGetRequest}
                metaDatagetRequest={invoicesMetadataGetRequest}
                createAction={invoiceCreateRequest}
                updateAction={invoiceUpdateRequest}
                model={"invoices"}
                reducerState={"invoices"}
                translater={"invoice"}
                isEditDrawer={true}
                isViewDrawer={true}
                extraActions={extraActions}
                defaultFilter={defaultFilter}
                hideFilter={hideFilter}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Invoice);


