import { Modal, ModalBody } from "reactstrap";

/**
 * DeleteModal Component
 * Renders a modal for confirming role deletion.
 */
const DeleteModal = ({ show, onCloseClick, onDelete, t }: any) => {
	return (
		<Modal
			id="roleDeleteModel"
			isOpen={show}
			toggle={() => {
				onCloseClick();
			}}
			centered
		>
			<ModalBody className="text-center p-5">
				<div className="mt-2 text-center">
					<i className="ri-delete-bin-5-line display-5 text-danger"></i>
					<div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
						<h4>{t("roles.delete_disclaimer1")}</h4>
						<p className="text-muted mx-4 mb-0">
							{t("roles.delete_disclaimer2")}
						</p>
					</div>
				</div>
				<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
					<button
						type="button"
						className="btn w-sm btn-light"
						onClick={() => onCloseClick()}
					>
						{t("roles.close")}
					</button>
					<button
						type="button"
						className="btn w-sm btn-danger"
						onClick={() => onDelete()}
					>
						{t("roles.delete")}
					</button>
				</div>
			</ModalBody>
		</Modal>
	) as unknown as JSX.Element;
};

export default DeleteModal;
