import { useEffect, useState } from "react";
import {
	CardBody,
	Row,
	Col,
	Card,
	Container,
	Input,
	Label,
	Table,
	Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";
//redux
import { useDispatch, useSelector } from "react-redux";
import PreviewModal from "./PreviewModal";
import { companyListGetRequest } from "store/companies/action";
import { ApplicationState } from "store";
import { ToastContainer } from "react-toastify";
import { isJson } from "utils";
import { bankListGetRequest } from "store/banks/action";

const InvoiceCreate = () => {
	const navigate = useNavigate();
	const dispatch: any = useDispatch();
	const companyList = useSelector((state: ApplicationState) => state.companies.list);
	const banks = useSelector((state: ApplicationState) => state.banks.list);

	const [companyOptions, setCompanyOptions] = useState<any>([]);
	const [companies, setCompanies] = useState<any>({});
	const [formValues, setFormValues] = useState<any>({
		terms: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.",
		notes: "Notes all about payment Details"
	});
	const [campanyobject, setcampanyobject] = useState<any>(null);
	const [bankOptions, setBankOptions] = useState<any>(null);
	const [bankobject, setbankobject] = useState<any>(null);
	const [banksList, seBanksList] = useState<any>(null)
	const [previewModal, setpreview] = useState<any>(false)
	const [ItemList, setItemList] = useState<any>([]);
	const [student, setstudent] = useState<any>({});
	const [currency, setCurrency] = useState<any>(null)

	useEffect(() => {
		if (companyList && companyList.length) {
			let options: any = []
			companyList.map((item: any, index: any) => {
				const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
				let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
				let option = { label: values.name, value: values.id, ...values, company_email: values.email }
				options.push(option)
			})
			setCompanyOptions(options)
			setCompanies(options)
		}
	}, [companyList])

	const currencyoptions: any = [
		{ value: "AED", label: "AED (د.إ)", currency: "د.إ" },
		{ value: "AFN", label: "AFN", currency: "AFN" },
		{ value: "ALL", label: "ALL (Lek)", currency: "Lek" },
		{ value: "AMD", label: "AMD", currency: "AMD" },
		{ value: "ANG", label: "ANG (ƒ)", currency: "ƒ" },
		{ value: "AOA", label: "AOA (Kz)", currency: "Kz" },
		{ value: "ARS", label: "ARS ($)", currency: "$" },
		{ value: "AUD", label: "AUD ($)", currency: "$" },
		{ value: "AWG", label: "AWG (ƒ)", currency: "ƒ" },
		{ value: "AZN", label: "AZN (ман)", currency: "ман" },
		{ value: "BAM", label: "BAM (KM)", currency: "KM" },
		{ value: "BBD", label: "BBD ($)", currency: "$" },
		{ value: "BDT", label: "BDT (Tk)", currency: "Tk" },
		{ value: "BGN", label: "BGN (лв)", currency: "лв" },
		{ value: "BHD", label: "BHD", currency: "BHD" },
		{ value: "BIF", label: "BIF", currency: "BIF" },
		{ value: "BMD", label: "BMD ($)", currency: "$" },
		{ value: "BND", label: "BND ($)", currency: "$" },
		{ value: "BOB", label: "BOB ($b)", currency: "$b" },
		{ value: "BOV", label: "BOV", currency: "BOV" },
		{ value: "BRL", label: "BRL (R$)", currency: "R$" },
		{ value: "BSD", label: "BSD ($)", currency: "$" },
		{ value: "BTN", label: "BTN", currency: "BTN" },
		{ value: "BWP", label: "BWP (P)", currency: "P" },
		{ value: "BYN", label: "BYN (Br)", currency: "Br" },
		{ value: "BZD", label: "BZD (BZ$)", currency: "BZ$" },
		{ value: "CAD", label: "CAD ($)", currency: "$" },
		{ value: "CDF", label: "CDF", currency: "CDF" },
		{ value: "CHE", label: "CHE", currency: "CHE" },
		{ value: "CHF", label: "CHF", currency: "CHF" },
		{ value: "CHW", label: "CHW", currency: "CHW" },
		{ value: "CLF", label: "CLF", currency: "CLF" },
		{ value: "CLP", label: "CLP ($)", currency: "$" },
		{ value: "CNY", label: "CNY (¥)", currency: "¥" },
		{ value: "COP", label: "COP (p.)", currency: "p." },
		{ value: "COU", label: "COU", currency: "COU" },
		{ value: "CRC", label: "CRC (₡)", currency: "₡" },
		{ value: "CUC", label: "CUC", currency: "CUC" },
		{ value: "CUP", label: "CUP (₱)", currency: "₱" },
		{ value: "CVE", label: "CVE", currency: "CVE" },
		{ value: "CZK", label: "CZK (Kč)", currency: "Kč" },
		{ value: "DJF", label: "DJF (CHF)", currency: "CHF" },
		{ value: "DKK", label: "DKK (kr)", currency: "kr" },
		{ value: "DOP", label: "DOP (RD$)", currency: "RD$" },
		{ value: "DZD", label: "DZD", currency: "DZD" },
		{ value: "EGP", label: "EGP (E£)", currency: "E£" },
		{ value: "ERN", label: "ERN", currency: "ERN" },
		{ value: "ETB", label: "ETB", currency: "ETB" },
		{ value: "EUR", label: "EUR (€)", currency: "€" },
		{ value: "FJD", label: "FJD ($)", currency: "$" },
		{ value: "FKP", label: "FKP (£)", currency: "£" },
		{ value: "GBP", label: "GBP (£)", currency: "£" },
		{ value: "GEL", label: "GEL", currency: "GEL" },
		{ value: "GHS", label: "GHS (GH¢)", currency: "GH¢" },
		{ value: "GIP", label: "GIP (£)", currency: "£" },
		{ value: "GMD", label: "GMD", currency: "GMD" },
		{ value: "GNF", label: "GNF", currency: "GNF" },
		{ value: "GTQ", label: "GTQ (Q)", currency: "Q" },
		{ value: "GYD", label: "GYD ($)", currency: "$" },
		{ value: "HKD", label: "HKD (HK$)", currency: "HK$" },
		{ value: "HNL", label: "HNL (L)", currency: "L" },
		{ value: "HTG", label: "HTG", currency: "HTG" },
		{ value: "HUF", label: "HUF (Ft)", currency: "Ft" },
		{ value: "IDR", label: "IDR (Rp)", currency: "Rp" },
		{ value: "ILS", label: "ILS (₪)", currency: "₪" },
		{ value: "INR", label: "INR (Rs)", currency: "Rs" },
		{ value: "IQD", label: "IQD", currency: "IQD" },
		{ value: "IRR", label: "IRR", currency: "IRR" },
		{ value: "ISK", label: "ISK (kr)", currency: "kr" },
		{ value: "JMD", label: "JMD (J$)", currency: "J$" },
		{ value: "JOD", label: "JOD", currency: "JOD" },
		{ value: "JPY", label: "JPY (¥)", currency: "¥" },
		{ value: "KES", label: "KES (KSh)", currency: "KSh" },
		{ value: "KGS", label: "KGS (лв)", currency: "лв" },
		{ value: "KHR", label: "KHR (៛)", currency: "៛" },
		{ value: "KMF", label: "KMF", currency: "KMF" },
		{ value: "KPW", label: "KPW (₩)", currency: "₩" },
		{ value: "KRW", label: "KRW (₩)", currency: "₩" },
		{ value: "KWD", label: "KWD (ك)", currency: "ك" },
		{ value: "KYD", label: "KYD ($)", currency: "$" },
		{ value: "KZT", label: "KZT (лв)", currency: "лв" },
		{ value: "LAK", label: "LAK (₭)", currency: "₭" },
		{ value: "LBP", label: "LBP (£)", currency: "£" },
		{ value: "LKR", label: "LKR (Rs)", currency: "Rs" },
		{ value: "LRD", label: "LRD ($)", currency: "$" },
		{ value: "LSL", label: "LSL", currency: "LSL" },
		{ value: "LYD", label: "LYD (LD)", currency: "LD" },
		{ value: "MAD", label: "MAD", currency: "MAD" },
		{ value: "MDL", label: "MDL", currency: "MDL" },
		{ value: "MGA", label: "MGA", currency: "MGA" },
		{ value: "MKD", label: "MKD (ден)", currency: "ден" },
		{ value: "MMK", label: "MMK", currency: "MMK" },
		{ value: "MNT", label: "MNT (₮)", currency: "₮" }
	]

	const dateFormat = () => {
		let d = new Date(),
			months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
	};

	const [date, setDate] = useState(dateFormat());

	const dateformate = (e: any) => {
		const date = e.toString().split(" ");
		const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
		setDate(joinDate);
		setFormValues({ ...formValues, invoice_date: joinDate })
	};

	const handleselected = async (option: any, str: any) => {
		switch (str) {
			case 'campany': setcampanyobject(option);
				let newcompany: any = {}
				companies.map((item: any) => {
					if (item.id == option.value) {
						newcompany = item;
					}
				})
				let filtersData = {
					parent : 
					{
						model : "companies", id: newcompany.id
					}
				}
				await dispatch(bankListGetRequest(filtersData, 0, 100));
				let companyDetails = { company_registrationNo: newcompany.registration_number, company_name: newcompany.name, company_id: newcompany.id, company_website: newcompany.website, company_city: newcompany.city, company_country: newcompany.country, company_email: newcompany.email, company_phone: newcompany.phone_number, company_postal_code: newcompany.postal_code, company_state: newcompany.state, company_address: newcompany.address }
				setFormValues({ ...formValues, ...companyDetails })

				break
			case 'currency': setCurrency(option)
				setFormValues({ ...formValues, currency: option })
				break
			case 'bank': setbankobject(option)
				let newBank = { bank_id: option.value, account_number: option.account_number, account_name: option.account_name || option.name, ifsc: option.ifsc, bank_name: option.bank_name }
				setFormValues({ ...formValues, ...newBank })
				break
			default: return
		}
	}

	const toggle = () => {
		setpreview(!previewModal)
	}

	useEffect(() => {
		seBanksList(banks)
	}, [banks])

	useEffect(() => {
		dispatch(companyListGetRequest({}, 0, 500))
		let ItemList: any = localStorage.getItem('invoiceItems');
		let student: any = localStorage.getItem('invoiceCustomer');
		setstudent(JSON.parse(student))
		let initstudent = JSON.parse(student);
		let items = JSON.parse(ItemList);
		let newList = items.map((item: any, index: any) => {
			if (item?.commissionPercentage?.includes("%") || item?.taxType == 'percent') {
				let taxpercent = item?.commissionPercentage?.replace("%", "")
				let itemAmount = taxpercent ? (parseFloat(item.commission) + ((parseFloat(item.commission)) * (parseFloat(taxpercent) / 100))) : parseFloat(item.commission);
				let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: 'percent' }
				return newitem;
			}
			else {
				let tax = item?.commissionPercentage?.replace("%", "")
				let itemAmount = tax ? (parseFloat(item.commission) + (parseFloat(tax))) : parseFloat(item.commission);
				let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: tax, taxType: 'fixed' }
				return newitem;
			}
		});
		let newstudentDetails = { student_id: initstudent.student_id, student_name: initstudent.name, student_phone: initstudent.phone_number, student_short_name: initstudent.student_short_name, student_city: initstudent.city, student_email: initstudent.email, student_state: initstudent.state, student_address: initstudent.address }
		let newformValues = { ...formValues, ...newstudentDetails }
		setItemList(newList)
		valuesChanged(newList, newformValues)
	}, [])

	const handleTax = (item: any, totaltax: any) => {
		let taxAmount = 0;
		let taxpercent = item?.commissionPercentage?.replace("%", "")
		if (item.taxType == 'percent') {
			taxAmount = taxpercent ? totaltax + (parseFloat(item.commission)) * (parseFloat(taxpercent) / 100) : 0;
			return taxAmount;
		}
		else return totaltax + parseFloat(taxpercent)
	}

	const valuesChanged = (newList: any, innitialvalues?: any) => {
		let invoice_amount = 0;
		let totaltax = 0;
		let subtotal = 0;
		let newValues: any = {}
		newList && newList.length && newList.map((item: any, index: any) => {
			invoice_amount = invoice_amount + parseFloat(item.itemAmount);
			subtotal = subtotal + parseFloat(item.commission);
			totaltax = item?.commissionPercentage ? handleTax(item, totaltax) : totaltax;
		});
		if (innitialvalues) {
			let balanceDue = innitialvalues.paid_amount ? invoice_amount - innitialvalues.paid_amount : invoice_amount;
			newValues = { ...innitialvalues, invoice_amount: invoice_amount ? invoice_amount.toFixed(2) : 0, balanceDue: balanceDue ? balanceDue.toFixed(2): 0, subtotal: subtotal ? subtotal.toFixed(2): 0, totaltax: totaltax ? totaltax.toFixed(2): 0}
		}
		else {
			let balanceDue = formValues.paid_amount ? invoice_amount - formValues.paid_amount : invoice_amount;
			newValues = { ...formValues, invoice_amount: invoice_amount ? invoice_amount.toFixed(2) : 0, balanceDue: balanceDue ? balanceDue.toFixed(2): 0, subtotal: subtotal ? subtotal.toFixed(2): 0, totaltax: totaltax ? totaltax.toFixed(2): 0}
		}
		setFormValues(newValues)
	}

	useEffect(() => {
		if(formValues.paid_amount) {
			valuesChanged(ItemList)
		}
	}, [formValues.paid_amount])

	const onDelete = (id: any) => {
		let newList = ItemList.filter((item: any) => item.id !== id);
		setItemList(newList)
		valuesChanged(newList)
	}

	const onChangeCommission = (value: any, id: any) => {
		let newList = ItemList.map((item: any, index: any) => {
			if (id === item.id) {
				if (item?.taxType === 'percent') {
					let taxpercent = item?.commissionPercentage?.replace("%", "")
					let newdetails = { ...item, commission: value }
					let itemAmount = taxpercent ? handleTaxInclusion(newdetails, taxpercent, item.taxType) : parseFloat(value);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: 'percent', commission: value }
					return newitem;
				}
				else {
					let taxpercent = item?.commissionPercentage?.replace("%", "")
					let newdetails = { ...item, commission: value }
					let itemAmount = taxpercent ? handleTaxInclusion(newdetails, taxpercent, item.taxType) : parseFloat(value);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: 'fixed', commission: value }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setItemList(newList);
		valuesChanged(newList)
	}

	const onChangeitemtax = (value: any, taxType: string, id: any,) => {
		let newList = ItemList.map((item: any, index: any) => {
			if (id === item.id) {
				if (taxType == 'percent') {
					let taxpercent = value
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
				else {
					let taxpercent = value
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setItemList(newList);
		valuesChanged(newList)
	}

	const handleTaxInclusion = (item: any, taxpercent: any, taxType: any) => {
		let amount = 0;
		if (taxType == 'percent') {
			if (!item.taxIncluded) amount = (parseFloat(item.commission) + ((parseFloat(item.commission)) * (parseFloat(taxpercent) / 100)))
			else amount = (parseFloat(item.commission) - ((parseFloat(item.commission)) * (parseFloat(taxpercent) / 100)))
		}
		else {
			if (!item.taxIncluded) amount = (parseFloat(item.commission) + (parseFloat(taxpercent)));
			else amount = (parseFloat(item.commission) - (parseFloat(taxpercent)));
		}
		return amount;
	}

	const onChangeitemtaxtype = (taxType: string, id: any,) => {
		let newList = ItemList.map((item: any, index: any) => {
			if (id === item.id) {
				if (taxType == 'percent') {
					let taxpercent = item?.commissionPercentage?.replace("%", "")
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
				else {
					let taxpercent = item?.commissionPercentage?.replace("%", "")
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, commissionPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setItemList(newList);
		valuesChanged(newList)
	}

	useEffect(() => {
		let newbankoptions: any = [];
		if (banksList && banksList.length) {
			banksList.map((item: any) => {
				const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
				let option = { value: item.id, label: valuesJson.bank_name, ...valuesJson }
				newbankoptions.push(option)
			})
			setBankOptions(newbankoptions)
		}
	}, [banksList])

	const goBack = () => {
		navigate(-1);
	};

	
	const onChangedPaidAmount = (value: any) => {
		if(!value) {
			setFormValues({ ...formValues, paidAmount: value });
			valuesChanged(ItemList, {...formValues, paidAmount: 0})
		}
		else {
			setFormValues({ ...formValues, paidAmount: value }); 
		}
	  }

	return (
		<div className="page-content">
			<ToastContainer />
			<Container fluid>
				<BreadCrumb title="Create Invoice" pageTitle="Invoices" />
				{previewModal &&
					<PreviewModal
						show={previewModal}
						onCloseClick={() => setpreview(false)}
						formValues={formValues}
						ontoggle={toggle}
						setFormValues={setFormValues}
						ItemList={ItemList}
					/>}
				<Row>
					<Col>
						<div className="hstack gap-2 justify-content-start d-print-none mt-4">
							<Button
								onClick={() => goBack()}
								color="primary"
								className="btn-sm"	>
								Go back
							</Button>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xxl={9}>
						<Card>
							<CardBody className="border-bottom border-bottom-dashed p-4">
								<Row>
									<Col lg={4}>
										<div className="vstack gap-1">
											<Label for="invoicenoInput">Company<span className="fw-bold text-danger">{" "}*</span></Label>
											<Select
												defaultOptions
												className='border border-dark border-opacity-25 rounded-2'
												placeholder={"select company"}
												value={campanyobject}
												onChange={(option: any) => handleselected(option, 'campany')}
												options={companyOptions}
												name="choices-single-default"
												id="idStatus"
											></Select>
										</div>										
										<div className="profile-user mx-auto  mb-3 mt-2">
											<Input
												id="profile-img-file-input"
												type="file"
												className="profile-img-file-input"
											/>
											<Label for="profile-img-file-input" className="d-block">
												<span
													className="border border-dashed d-flex align-items-center justify-content-center rounded"
													style={{ height: "100px", width: "100px" }}
												>{formValues.logo ?
													<img
														src={formValues.logo}
														className="card-logo card-logo-dark user-profile-image img-fluid"
														alt="logo dark"
														width={100}
														height={100}
													/>
													: null}
												</span>
											</Label>
										</div>
										<div>
											<div>
												<Label for="companyAddress">Address</Label>
											</div>
											<div className="mb-2">
												<Input
													type="textarea"
													className="form-control bg-light border-0"
													id="companyAddress"
													rows={3}
													value={formValues.company_address}
													onChange={(e) => setFormValues({ ...formValues, company_address: e.target.value })}
													placeholder="Company Address"
												/>
												<div className="invalid-feedback">
													Please enter a address
												</div>
											</div>
											<div>
												<Label for="companyAddress">Postal Code</Label>
												<Input
													type="text"
													className="form-control bg-light border-0"
													id="companyaddpostalcode"
													minLength={5}
													maxLength={6}
													name="postalcode"
													value={formValues?.company_postal_code || ""}
													onChange={(e) => setFormValues({ ...formValues, company_postal_code: e.target.value })}
													placeholder="Enter Postal Code"
												/>

											</div>
										</div>
									</Col>
									<Col lg={4} className="ms-auto">
										<div className="hstack justify-content-end">
											<div className="vstack gap-1">
												<Label for="invoicenoInput">currency<span className="fw-bold text-danger">{" "}*</span></Label>
												<Select
													// isMulti
													defaultOptions
													className='border border-dark border-opacity-25 rounded-2 w-75 mb-2'
													placeholder={"select currency"}
													value={currency}
													onChange={(option: any) => handleselected(option, 'currency')}
													options={currencyoptions}
													name="choices-single-default"
													id="idStatus"
												></Select>
											</div>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Registration Number</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="registrationNumber"
												name="registration"
												value={formValues.company_registrationNo || ""}
												onChange={(e) => setFormValues({ ...formValues, company_registrationNo: e.target.value })}
												maxLength={12}
												placeholder="Legal Registration No"
											/>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Email</Label>
											<Input
												type="email"
												className="form-control bg-light border-0"
												id="companyEmail"
												name="email"
												value={formValues.company_email || ""}
												onChange={(e) => setFormValues({ ...formValues, company_email: e.target.value })}
												placeholder="Email Address"
											/>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Website</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="companyWebsite"
												name="website"
												value={formValues.company_website || ""}
												onChange={(e) => setFormValues({ ...formValues, company_website: e.target.value })}
												placeholder="Website"
											/>
										</div>
										<div>
											<Label for="companyAddress">Contact Number</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												data-plugin="cleave-phone"
												id="compnayContactno"
												name="contact"
												value={formValues.company_phone || ""}
												onChange={(e) => setFormValues({ ...formValues, company_phone: e.target.value })}
												placeholder="Contact No"
											/>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardBody className="p-4 border-top border-top-dashed hstack justify-content-between">
								<Row className="w-100 hstack justify-content-between">
									<Col md={5} lg={5} sm={5} xl={5}>
										<div>
											<Label
												for="billingName"
												className="text-muted text-uppercase fw-semibold"
											>
												Billing Address
											</Label>
										</div>
										<div className="mb-2">
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="billingName"
												name="name"
												value={formValues.student_name || ""}
												onChange={(e) => setFormValues({ ...formValues, student_name: e.target.value })}
												placeholder="Name"
											/>

										</div>
										<div className="mb-2">
											<Input
												type="textarea"
												className="form-control bg-light border-0"
												id="billingAddress"
												name="billing_address"
												value={formValues.student_address || ""}
												onChange={(e) => setFormValues({ ...formValues, student_address: e.target.value })}
												rows={3}
												placeholder="Address"
											/>

										</div>
										<div className="mb-2">
											<Input
												type="text"
												className="form-control bg-light border-0"
												data-plugin="cleave-phone"
												id="billingPhoneno"
												name="billing_phone"
												value={formValues.student_phone || ""}
												onChange={(e) => setFormValues({ ...formValues, student_phone: e.target.value })}
												placeholder="(123)456-7890"
											/>

										</div>
										<div className="mb-3">
											<Input
												type="text"
												className="form-control bg-light border-0"
												name="email"
												value={formValues.student_email || ""}
												onChange={(e) => setFormValues({ ...formValues, student_email: e.target.value })}
												placeholder="Email"
											/>

										</div>
										<div className="mb-3">
											<Input
												type="text"
												className="form-control bg-light border-0"
												name="billing_taxno"
												value={formValues.student_taxNumber || ""}
												onChange={(e) => setFormValues({ ...formValues, student_taxNumber: e.target.value })}
												placeholder="Tax Number"
											/>

										</div>
									</Col>
									<Col md={5} lg={5} sm={5} xl={5} className="align-self-start">
										<div>
											<Label for="invoicenoInput">Invoice No<span className="fw-bold text-danger">{" "}*</span></Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="invoicenoInput"
												name="invoiceId"
												value={formValues.invoiceNo || ""}

												// 
												onChange={(e) => setFormValues({ ...formValues, invoiceNo: e.target.value })}
												placeholder="Invoice No"
											/>
										</div>
										<div>
											<Label for="date-field">Invoice Date<span className="fw-bold text-danger">{" "}*</span></Label>
											<Flatpickr
												name="date"
												id="date-field"
												className="form-control bg-light"
												placeholder="Select a date"
												options={{
													altInput: true,
													altFormat: "d M, Y",
													dateFormat: "d M, Y",
												}}
												onChange={(e) =>
													dateformate(e)
												}
												value={formValues.invoice_date || ""}
											/>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardBody className="p-4">
								<div className="table-responsive">
									<Table className="invoice-table table-borderless table-nowrap mb-0">
										<thead className="align-middle">
											<tr className="table-active">
												<th scope="col" style={{ width: "50px" }}>
													#
												</th>
												<th scope="col">Student Details</th>
												<th scope="col" style={{ width: "120px" }}>
													<div className="d-flex currency-select input-light align-items-center text-center ">
														Commission
													</div>
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "200px" }}
												>
													Tax
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "150px" }}
												>
													Amount
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "105px" }}
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody id="newlink">
											{ItemList && ItemList.length ? ItemList.map((item: any, index: any) => {
												return <tr id="1" className="product">
													<th scope="row" className="product-id">
														{index + 1}
													</th>
													<td className="text-start">
														<div className="mb-2">
															{item.name}
														</div>
														{item.description}<br />
													</td>
													<td className="justify-content-center">
														<Input
															type="number"
															className="form-control product-price bg-light border-0"
															placeholder="0.00"
															id="productRate-1"
															value={item.commission}
															onWheel={(e: any) => e.target.blur()} // Disable mouse wheel change
															onChange={(e) => onChangeCommission(e.target.value, item.id)}
														/>
													</td>
													<td className="text-center">
														<div className="mb-2 hstack align-items-center gap-2">
															<div className="hstack  w-100">
																{
																	item.taxType == 'percent' ?
																		<div className="position-relative w-100">
																			<Input
																				type="text"
																				className="form-control bg-light border-0"
																				id="companyEmail"
																				name="email"
																				value={item.commissionPercentage ? `${item.commissionPercentage}` : ""}
																				onChange={(e: any) => onChangeitemtax(e.target.value, 'percent', item.id)}
																				placeholder="0"
																			/>
																			<div className="position-absolute" style={{ right: 5, top: 7 }}>
																				<div className="hstack gap-1">
																					<span>
																						%
																					</span>
																				</div>
																			</div>
																		</div>
																		:
																		<div className="position-relative w-100">
																			<Input
																				type="text"
																				className="form-control bg-light border-0 ps-5"
																				id="companyEmail"
																				name="email"
																				value={item.commissionPercentage ? `${item.commissionPercentage}` : ""}

																				onChange={(e: any) => onChangeitemtax(e.target.value, 'fixed', item.id)}
																				placeholder="0"
																			/>
																			<div className="position-absolute" style={{ left: 10, top: 7 }}>
																				<div className="hstack gap-1">
																					<span>
																						{currency?.currency}
																					</span>
																				</div>
																			</div>
																		</div>
																}
																<button className="btn btn-sm btn-soft-dark remove-list hstack align-items-center" style={{ height: 35 }} onClick={() => onChangeitemtaxtype(item.taxType === "percent" ? "fixed" : "percent", item.id)}>
																	<i className="ri-repeat-fill fs-14"></i>
																</button>
															</div>
														</div>
													</td>
													<td className="text-end">
														<div className="position-relative w-100">
															<Input
																type="text"
																className="form-control bg-light border-0 product-line-price ps-5"
																id="productPrice-1"
																placeholder={`0.00`}
																value={item.itemAmount || ""}
																readOnly
															/>
															<div className="position-absolute" style={{ left: 10, top: 7 }}>
																<div className="hstack gap-1">
																	<span>
																		{currency?.currency}
																	</span>
																</div>
															</div>
														</div>
													</td>
													<td className="product-removal text-center">
														<button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onDelete(item.id)}>
															<i className="ri-delete-bin-fill"></i>
														</button>  {" "}
													</td>
												</tr>
											}) : null}

										</tbody>
									</Table>
								</div>
								<Row className="mt-3 justify-content-between">
									<Col className="mt-5" lg={5} md={5} xl={5}>
										<Label>NOTES<span className="fw-bold text-danger">{" "}*</span></Label>
										<Select
											defaultOptions
											className='border border-dark border-opacity-25 rounded-2'
											placeholder={"Select Bank"}
											value={bankobject}
											onChange={(option: any) => handleselected(option, 'bank')}
											options={bankOptions || []}
											name="choices-single-default"
											id="idStatus"
										></Select>
										<div className="mb-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardholderName"
												placeholder="Bank Name"
												value={formValues.bank_name || ""}
												onChange={(e) => setFormValues({ ...formValues, bank_name: e.target.value })}
											/>
										</div>
										<div className="mb-2 mt-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardNumber"
												placeholder="Account name"
												value={formValues.account_name || formValues.name || ""}
												onChange={(e) => setFormValues({ ...formValues, account_name: e.target.value })}
											/>
										</div>
										<div className="mb-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardNumber"
												placeholder="Account number"
												value={formValues.account_number || ""}
												onChange={(e) => setFormValues({ ...formValues, account_number: e.target.value })}
											/>
										</div>
										<div>
											<Input
												className="form-control  bg-light border-0"
												type="text"
												id="amountTotalPay"
												placeholder="IFSC code"
												value={formValues.ifsc || ""}
												onChange={(e) => setFormValues({ ...formValues, ifsc: e.target.value })}
											/>
										</div>
									</Col>
									<Col lg={5} md={5} xl={5} className="mt-5">
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Subtotal</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="subtotal"
													name="subtotal"
													value={formValues.subtotal || ""}
													maxLength={12}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 8, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.currency}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Tax</Label>
											<div className="position-relative  hstack  w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="companyEmail"
													name="email"
													value={formValues.totaltax ? `${formValues.totaltax}` : ""}

													// onChange={(e: any) => onChangetax(e.target.value, 'fixed' )}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.currency}
														</span>
													</div>
												</div>
											</div>

										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Total</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="total"
													name="total"
													value={formValues.invoice_amount || ""}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.currency}
														</span>
													</div>
												</div>
											</div>

										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Amount Paid</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="paid_amount"
													name="paid_amount"
													value={formValues.paid_amount || ""}
													placeholder="0"
													onChange={(e: any) => {onChangedPaidAmount(e.target.value)}}
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.currency}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Balance Due</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="companyEmail"
													name="email"
													value={formValues.balanceDue || ""}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.currency}
														</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={12}>
										<div className="mt-4">
											<Label
												for="exampleFormControlTextarea1"
												className="form-label text-muted text-uppercase fw-semibold"
											>
												TERMS
											</Label>
											<Input
												type="textarea"
												className="form-control alert alert-info"
												id="exampleFormControlTextarea1"
												placeholder="Terms"
												rows={3}
												onChange={(e: any) => setFormValues({ ...formValues, terms: e.target.value })}
												value={formValues.terms}
												defaultValue="All accounts are to be paid within 7 days from receipt of
													invoice. To be paid by cheque or credit card or direct
													payment online. If account is not paid within 7 days the
													credits details supplied as confirmation of work
													undertaken will be charged the agreed quoted fee noted
													above."
											/>
										</div>
									</Col>
								</Row>
								<div className="hstack gap-2 justify-content-end d-print-none mt-4">
									<button
										onClick={() => setpreview(true)}
										className="btn btn-primary"
										disabled={!formValues.invoice_date || !formValues.invoiceNo || !ItemList.length || !formValues.bank_name || !formValues.company_name || !formValues.currency}
									>
										<i className="ri-article-line align-bottom me-1"></i> Preview
										Invoice
									</button>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default InvoiceCreate;
