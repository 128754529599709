import React, { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Table, UncontrolledDropdown } from "reactstrap";
import SimpleBar from "simplebar-react";

interface TableContainerProps {
    columns?: any;
    data?: any;
    updateRow: any;
    onUpdate: any;
    tableClass: string,
    theadClass: string,
    thClass: string,
    importJson: any
}

const TableContainer = ({
    columns,
    data,
    updateRow,
    onUpdate,
    tableClass,
    theadClass,
    thClass,
    importJson
}: TableContainerProps) => {
    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: 500
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const [search, setSearch] = useState('')
    const [focusId, setFocusId] = useState('')
    const [isDropdownClick, setIsDropdownClick] = useState(false);
    return (
        <div>
            <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                <thead className={theadClass}>
                    {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                >
                                    <div title={column.Header}>
                                        {column.render("Header")}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    )) : null}
                </thead>
                <tbody>
                    {page.map((row: any, index: any) => {
                        prepareRow(row);
                        const options = focusId === row.original?.id && search ? row.original.suggestions.filter((item: any) => item?.label?.toLowerCase()?.includes(search)) : row?.original?.suggestions;
                        const currentValue = updateRow?.id === row.original?.id ? updateRow?.field : row.original?.field;
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                    {row.cells.map((cell: any) => {
                                        if (cell.column.id === 'suggestions') {
                                            return <td style={{ width: 250 }}>
                                                <ButtonGroup className="w-100"
                                                    onFocus={() => setFocusId(row.original?.id)}
                                                    onBlur={() => {
                                                        if (!isDropdownClick) {
                                                            setFocusId('');
                                                            setSearch('');
                                                        }
                                                        setIsDropdownClick(false);
                                                    }}
                                                >
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            tag="button"
                                                            title={currentValue && currentValue.name ? currentValue.name : 'Select Zilter field to Map...'}
                                                            className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary border-dark border-opacity-25"
                                                            style={{ width: 250, color: 'inherit' }}
                                                        >
                                                            <span className="text-nowrap text-truncate text-nowrap-ellipsis">{currentValue && currentValue.name ? currentValue.name : 'Select Zilter field to Map...'}</span> <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdownmenu-primary border-dark border-opacity-50">
                                                            {row.original.options?.length > 5 ? <div className="search-box">
                                                                <Input
                                                                    type="text"
                                                                    size={14}
                                                                    className="search py-1"
                                                                    placeholder="Search for..."
                                                                    onChange={(e) => setSearch(e.target.value)}
                                                                    value={search}
                                                                />
                                                                <i className="ri-search-line search-icon"></i>{" "}
                                                            </div> : null}
                                                            <SimpleBar style={{ maxHeight: 300, width: 250 }} >
                                                                <DropdownItem
                                                                    key={index}
                                                                    onMouseDown={() => setIsDropdownClick(true)}
                                                                    onClick={(e) => { onUpdate(row.original, null, "field", row.original.id); setSearch(''); }}
                                                                >
                                                                    {'Select...'}
                                                                </DropdownItem>
                                                                {options && options.length ? options.map((option: any, index: number) => (
                                                                    <DropdownItem
                                                                        key={index}
                                                                        title={option ? option.name : ''}
                                                                        onMouseDown={() => setIsDropdownClick(true)}
                                                                        onClick={(e) => { onUpdate(row.original, option, "field", row.original.id); setSearch('');}}
                                                                    >
                                                                        {option ? option.name : ''}
                                                                    </DropdownItem>
                                                                )) : null}
                                                            </SimpleBar>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </ButtonGroup>
                                            </td>
                                        }
                                        else return <td key={cell.id} style={{ verticalAlign: 'middle', minWidth: 100 }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    })}
                                   
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(TableContainer);