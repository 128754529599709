import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "./DtsResponsiveForm";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { isJson } from "utils";
import { tasksMetadataGetRequest, taskUpdateRequest } from "store/tasks/action";

const Info = (props: any) => {
    const { show, onCloseClick, taskDetails, model, setTriggerApi } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const metaDataFields = useSelector((state: ApplicationState) => state.tasks.metaDataFields);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);

    useEffect(() => {
        dispatch(tasksMetadataGetRequest({ pageSize: 100 }))
    }, [])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const onUpdate = (formValues: any) => {
        let assigned_by = userProfile?.id;
        let newValues = { ...formValues, assigned_by: assigned_by, assigned_to: formValues?.assigned_to?.value || formValues?.assigned_to }
        const handleSuccess = (body: any) => {
            onCloseClick()
            setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
        dispatch(taskUpdateRequest(taskDetails?.id, newValues, handleSuccess, () => { }))
    }

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }

    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metaDataFields && metaDataFields.map((field: any) => {
            let fieldData = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
            if (isFieldviewaccess && (fieldData.key !== 'assigned_by' && fieldData.key !== 'application' && fieldData.key !== 'student')) {
                if (isFieldEditaccess) {
                    let newField = { ...field, isEditAccess: true }
                    return newstatusMetaData.push(newField)
                }
                return newstatusMetaData.push(field)
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metaDataFields])

    return (
        <React.Fragment>
            {taskDetails && taskDetails.id ?
                <Modal id="createTask" isOpen={show} modalClassName="zoomIn" centered tabIndex={-1} toggle={() => onCloseClick()}>
                    <ModalHeader toggle={() => onCloseClick()} className="p-3 bg-primary-subtle">
                        {"Edit Task"}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="justify-content-start">
                            <Col md={12} lg={12}>
                                <Form sm={12} lg={12} md={12} xs={12} xl={12} dataFields={statusMetaData} btnAction={onUpdate} showbtn={true} btn_text={'Update Task'} data={taskDetails} formfor={model} isEditAccess={edit} />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                : null}
        </React.Fragment >

    );
};

export default Info;
