import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Container,
    Breadcrumb,
    Card,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { userGetRequest } from 'store/user/action';
import UserProfile from './UserProfile';
import { ToastContainer } from 'react-toastify';

const UserDetailsModal = ({t}: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user.user);
    const [userData, setUserData] = useState<any>()

    useEffect(() => {
        if (id) 
        {
            dispatch(userGetRequest(id))
        }
    }, [id])

    useEffect(() => {
        if(user){
            setUserData(user)
        }
    }, [user])



    const goBack = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="page-content">
                
                <ToastContainer />
                <Container fluid>
                    <Breadcrumb title="Users" pageTitle="User Profile" />
                    <div className='w-100 mx-3'>
                        <Card className='px-3'>
                            <div className='d-flex'>
                                <div>
                                    <Button color="primary" className="btn-sm m-2 w-auto hstack btn-ghost-primary align-items-center fw-bold fs-16" onClick={() => goBack()}>
                                        <FeatherIcon icon="arrow-left" className="icon-xs" />&nbsp;{t("users.back_to_all_users")}
                                    </Button>
                                </div>
                                <div className="w-auto hstack text-muted align-items-center fw-semibold fs-16">
                                    {userData && userData.name ? `( ${userData.name} )` : null}
                                </div>
                            </div>

                            <UserProfile />
                        </Card>
                    </div>
                    <div>




                    </div>
                </Container>
            </div>
        </>
    );
};

export default withTranslation()(UserDetailsModal);