import React, { useCallback, useEffect, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Col, Input, Label, Row, UncontrolledAccordion } from 'reactstrap';
import conditionsJson from "../../../Components/Common/Filter/conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "../../../Components/Common/SelectTree/SelectTree";
import ConditionalDefaultValue from "./ConditionalDefaultValue";
import { isJson } from "utils";
import DtsDataSelectInput from "./DtsDataSelectInput";
import { withTranslation } from "react-i18next";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

const Conditional = ({setEditInputs, EditInputs, fieldJson,model, setFieldJson, dataFields, t }: any) => {  
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [isConditional, setIsConditional] = useState<boolean>(false);
    // State Variables
    const [selectedProperty, setSelectedProperty] = useState<any>({});
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<PropertyProps[]>([]);
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);
    const [displaystatus, setDisplaystatus] = useState<any>({});
    const [newConditionalObj, setNewConditionObj] = useState<any>(EditInputs);

    const handleselected = (option: any, name: string, ) => {
        switch(name) {
            case 'show': setDisplaystatus(option)
                setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, show: option.value}})
                setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, show: option.value}})
                break
            case 'condition': setSelectedCondition(option)       
                setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, condition: option.value}})
                setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, condition: option.value}})
                break
            case 'value':         
                setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, value: option.value}})
                setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, value: option.value}})
                break
        }
    }

    const displayoption =[
        {
            value: true,
            label: 'Show'
        },
        {
            value: false,
            label: 'Hide'
        }
    ]

    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj?.length) {
            if(selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj.map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj.map((option: any) => {
                    const optionsJson = isJson(option.valuesJson);
                    return { label: optionsJson[selectedProperty?.valueJson?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = JSON.parse(node.valuesJson);
      
        // Create the base transformed node
        const transformedNode: TransformedNode = {
          label: values.name,
          value: node.id,
          createdBy: node.createdBy,
          createdAt: node.createdAt,
          updatedAt: node.updatedAt,
          deletedAt: node.deletedAt,
          parentId: node.parentId,
          parent: node.parent,
        };
      
        // Recursively transform children if they exist and are not empty
        if (node.children && node.children.length > 0) {
          transformedNode.children = node.children.map(transformTree);
        }
      
        return transformedNode;
    }

    // Handle property selection
    const onSelectProperty = (property: PropertyProps, key?: any) => {
        if (property) {
            setSelectedProperty(property);
            const conditionOptions = conditionsJson[property?.valueJson?.type as keyof typeof conditionsJson];
            if(conditionOptions) {
                setConditions(conditionOptions);
                if(key == "default") {                          
                    if(EditInputs.conditional.condition) {
                        conditionOptions?.length && conditionOptions.map((item) => {
                            if(item.value ==  EditInputs?.conditional?.condition) setSelectedCondition(item) 
                        })    
                    }  }
                else {
                    setSelectedCondition(conditionOptions[0]); // Set the first condition by default
                    setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, when: property?.value, property: property, condition: conditionOptions[0].value}})
                    setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, when: property?.value, property: property, condition: conditionOptions[0].value}})    
                }
            }
            else {
                setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, when: property?.value, property: property}})
                setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, when: property?.value, property: property}})
            }
            resetValueStates();
        }
    };

    useEffect(() => {        
        if(values && values.length ) {
            setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, values: values}})
            setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, values: values}})
        }
    }, [values])

    useEffect(() => {         
        setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, value: value}})
        setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, value: value}})
    }, [value])

    useEffect(() => {        
        setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, highValue: highValue}})
        setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, highValue: highValue}})
    }, [highValue])


    useEffect(() => {   
        setValues(newConditionalObj.conditional?.values)
    }, [newConditionalObj.conditional?.values])

    useEffect(() => {   
        setValue(newConditionalObj.conditional?.value)
    }, [newConditionalObj.conditional?.value,])

    useEffect(() => {    
        setHighValue(newConditionalObj.conditional?.highValue)
    }, [newConditionalObj.conditional?.highValue])
    

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        if (selectedProperty?.valueJson?.model) {
            dispatch(getOptions(selectedProperty.valueJson.model, {}));
        }
    };

    // Render input field based on property type
    const renderValueInput = () => {
        switch (selectedProperty?.valueJson?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={options}
                        defaultValue={newConditionalObj?.conditional?.values}
                        onChange={(options: any) => setValues(options)}
                        value={values}
                        loading={optionsLoading}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'radio':
            case 'selectboxes':
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        defaultValue={newConditionalObj?.conditional?.values}
                        options={selectedProperty?.valueJson?.values}
                        onChange={(options: any) => setValues(options)}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={[]}
                        onChange={(newValue: any) => setValues(newValue)}
                        value={values}
                    />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: value ? value : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => setValue(e.target.value)}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        options={options}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                    />
                );
            default:
                return null;
        }
    };
    const onChange = (value: any) => {
        setIsConditional(value)
        setEditInputs({...EditInputs, conditional:{...EditInputs.conditional, isConditional: value}})
        setFieldJson({...fieldJson, conditional:{...EditInputs.conditional, isConditional: value}})
    }
    
    useEffect(() => {       
        if(newConditionalObj?.conditional?.property) setSelectedProperty(newConditionalObj?.conditional?.property)
        if(newConditionalObj?.conditional) setIsConditional(EditInputs?.conditional?.isConditional)
        if(newConditionalObj?.conditional?.show) {           
            setDisplaystatus( {
                value: true,
                label: 'Show'
            })
        }
        else {
            setDisplaystatus(
                {
                    value: false,
                    label: 'Hide'
                })
        } 
    }, [newConditionalObj?.conditional?.isConditional,EditInputs?.conditional?.show])
    return (

        <div>
        <React.Fragment>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">    
                        {EditInputs.label}
                </Col>
            </Row>
            <UncontrolledAccordion toggle={()=>{}} defaultOpen={["default"]}>
                <AccordionItem className='border border-1'>
                    <AccordionHeader targetId={'default'}  className='hstack justify-content-around'>
                        {t("properties.rules.display_default_value")}
                    </AccordionHeader>
                    <AccordionBody accordionId={'default'} className='p-0 vstack gap-2'>
                        <ConditionalDefaultValue                            
                            setEditInputs={setEditInputs}
                            EditInputs={EditInputs}
                            fieldJson={fieldJson}
                            setFieldJson={setFieldJson}
                            model={model}
                        />
                    </AccordionBody>
                </AccordionItem>
                {/* <AccordionItem className='border border-1'>
                    <AccordionHeader targetId={'dispaly'}  className='hstack justify-content-around'>
                        Display Field Conditionally
                    </AccordionHeader>
                    <AccordionBody accordionId={'dispaly'} className='p-0 vstack gap-2'>                        
                        <div className="gap-2 p-3">
                            <div className="hstack align-content-center gap-1 mt-1">
                                <div className='hstack my-2 justify-content-around'>                        
                                        <div className="form-check-primary border-primary form-switch align-items-stretch">
                                            <Input className="form-check-input border border-primary fs-12" type="checkbox" role="switch"
                                                onChange={(e : any) => onChange(e.target.checked)}
                                                checked={isConditional} />
                                                <div className='fw-bold fs-14 vstack align-self-center'>
                                                <h6>Show this field conditionally.</h6>
                                        </div>
                                    </div>                        
                                </div>
                            </div> 
                            {isConditional ? <div>
                                <div>
                                    <h6>Display status<span className="fw-bold text-danger">{" "}*</span></h6>                           
                                    <Select
                                        defaultOptions
                                        placeholder={'Select property type'}
                                        className="fs-16 fw-medium"
                                        value={displaystatus}
                                        onChange={(option : any) => handleselected(option, 'show')}
                                        options={displayoption}
                                        name="choices-single-default"
                                    ></Select>
                                </div>            
                                <div>
                                    <DtsDataSelectInput
                                        placeholder={'Select Field name'}
                                        onChange={(option : any, key: any) => onSelectProperty(option, key)}
                                        label={'When'}
                                        value={selectedProperty}
                                        rest={{...fieldJson, model: EditInputs?.objectType}}
                                        model={{value: EditInputs?.objectType}}
                                        disabled={!model}
                                        defaultValue={EditInputs.conditional?.when}
                                    />
                                </div>              
                                <div>
                                    {selectedProperty && (
                                        <div className="vstack gap-2 my-2">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable
                                                name="conditions"
                                                options={conditions}
                                                onChange={(option : any) => handleselected(option, 'condition')}
                                                value={selectedCondition}
                                            />
                                            <div className="vstack gap-2 my-1">
                                                {renderValueInput()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> : null}
                        </div>
                    </AccordionBody>
                </AccordionItem> */}
                {/* <AccordionItem className='border border-1'>
                    <AccordionHeader targetId={'filter'}  className='hstack justify-content-around'>
                        Display Conditionally Filtered DropDown Values
                    </AccordionHeader>
                    <AccordionBody accordionId={'filter'} className='p-0 vstack gap-2'>
                        <ConditionalFilter                            
                            setEditInputs={setEditInputs}
                            EditInputs={EditInputs}
                            fieldJson={fieldJson}
                            setFieldJson={setFieldJson}
                            model={model}
                        />
                    </AccordionBody>
                </AccordionItem> */}
            </UncontrolledAccordion>
        </React.Fragment>
        </div>
    );
};

export default withTranslation()(Conditional);
