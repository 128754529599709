import {
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
} from "reactstrap";
import QuickForm from "Components/Common/FormBuilder/QuickForm";

const UpdateView = ({ props, show, onCloseClick, dataFields, onUpdate, data }: any) => {
    return (
        <Modal backdrop={'static'} id={'staticBackdrop'} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 Modal-header-dark d-flex flex-row justify-content-between align-items-center" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2">
                    {props.t("student.modals.update_view")}
                </span>
            </ModalHeader>
            <ModalBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0">
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={dataFields} btnAction={onUpdate} btn_text={'Update Entry'} formfor="students" data={data} />
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default UpdateView;

