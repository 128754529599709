import React, { useEffect, useState, useCallback } from 'react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Button, Col, Input, Label, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { propertyBulkUpdateRequest } from 'store/properties/action';
import { withTranslation } from 'react-i18next';

interface ParsedProperty {
    id: string;
    key: string;
    label: string;
    profileCompleteness: boolean;
}

interface StudentFieldsProps {
    props: any;
    parsedProperties: ParsedProperty[];
}

const StudentFields = ({ props, parsedProperties }: StudentFieldsProps) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [unselectedProperties, setUnselectedProperties] = useState<ParsedProperty[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<ParsedProperty[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [initialSelected, setInitialSelected] = useState<ParsedProperty[]>([]);
    const [initialUnselected, setInitialUnselected] = useState<ParsedProperty[]>([]);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const selected = parsedProperties.filter(property => property.profileCompleteness === true);
        const unselected = parsedProperties.filter(property => property.profileCompleteness !== true);

        setSelectedProperties(selected);
        setUnselectedProperties(unselected);
        setInitialSelected(selected);
        setInitialUnselected(unselected);
        setLoading(false);
    }, [parsedProperties]);

    // Check if there are any changes between current and initial states
    const checkIfModified = useCallback(() => {
        const isSelectedEqual = JSON.stringify(initialSelected) === JSON.stringify(selectedProperties);
        const isUnselectedEqual = JSON.stringify(initialUnselected) === JSON.stringify(unselectedProperties);
        setIsModified(!isSelectedEqual || !isUnselectedEqual);
    }, [initialSelected, initialUnselected, selectedProperties, unselectedProperties]);

    // Run checkIfModified on property state changes
    useEffect(() => {
        checkIfModified();
    }, [selectedProperties, unselectedProperties, checkIfModified]);

    const handleCheckboxChange = (property: ParsedProperty, checked: boolean) => {
        if (checked) {
            setUnselectedProperties(prev => prev.filter(item => item.key !== property.key));
            setSelectedProperties(prev => [...prev, property]);
        } else {
            setSelectedProperties(prev => prev.filter(item => item.key !== property.key));
            setUnselectedProperties(prev => [...prev, property]);
        }
    };

    const handleSelectAll = () => {
        if (unselectedProperties.length > 0) {
            setSelectedProperties(prev => [...prev, ...unselectedProperties]);
            setUnselectedProperties([]);
        } else {
            setSelectedProperties([]);
            setUnselectedProperties(parsedProperties);
        }
    };

    const handleDiscardChanges = () => {
        setUnselectedProperties(initialUnselected);
        setSelectedProperties(initialSelected);
        setSearchTerm('');
    };

    const handleApplyChanges = () => {
        if (selectedProperties.length !== 0) {
            const incompleteProperties = selectedProperties.filter(property => property.profileCompleteness !== true);
            if (incompleteProperties.length !== 0) {
                const selectedKeys = incompleteProperties.map(property => property.id);
                const idsParam = selectedKeys.join(',');
                const data = {
                    ids: idsParam,
                    properties: {
                        profileCompleteness: true
                    }
                };
                dispatch(propertyBulkUpdateRequest(data, () => { }, () => { }));
            }
        }
        if (unselectedProperties.length !== 0) {
            const completeProperties = unselectedProperties.filter(property => property.profileCompleteness === true);
            if (completeProperties.length !== 0) {
                const selectedKeys = completeProperties.map(property => property.id);
                const idsParam = selectedKeys.join(',');
                const data = {
                    ids: idsParam,
                    properties: {
                        profileCompleteness: false
                    }
                };
                dispatch(propertyBulkUpdateRequest(data, () => { }, () => { }));
            }
        }
        // Update initial state to current state after applying changes
        setInitialSelected(selectedProperties);
        setInitialUnselected(unselectedProperties);
        setIsModified(false);
    };

    // Filter properties based on search term
    const filteredUnselectedProperties = unselectedProperties.filter(property =>
        property.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredSelectedProperties = selectedProperties.filter(property =>
        property.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <div className='d-flex gap-2 justify-content-between align-items-baseline'>
                <div className='d-flex align-items-baseline gap-2'>
                    <div className="search-box">
                        <Input
                            type="text"
                            placeholder={props.t("userpreferences.search_fields")}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm === "" ? (
                            <i className="mb-2 ri-search-line search-icon"></i>
                        ) : (
                            <i className="ri-close-circle-fill search-icon cursor-pointer" onClick={() => setSearchTerm('')}></i>
                        )}
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <Label className='mb-3 fs-16'>
                        {filteredSelectedProperties.length === 0 ? 'No' : filteredSelectedProperties.length} {props.t("userpreferences.fields_selected")}
                    </Label>
                    <Button onClick={handleSelectAll} color="primary" className="btn-sm mb-3">
                        {unselectedProperties.length > 0 ? `${props.t("userpreferences.select_all")}` : `${props.t("userpreferences.deselect_all")}`}
                    </Button>
                    <Button onClick={handleDiscardChanges} color="danger" className="btn-sm mb-3" disabled={!isModified}>
                        {props.t("userpreferences.discard_changes")}
                    </Button>
                    <Button onClick={handleApplyChanges} color="success" className="btn-sm mb-3" disabled={!isModified}>
                        {props.t("userpreferences.apply_changes")}
                    </Button>
                </div>

            </div>
            {filteredUnselectedProperties.length > 0 || filteredSelectedProperties.length > 0 ? (
                <Row>
                    {/* Unselected Properties */}
                    <Col xl={6} lg={6} sm={6}>
                        <div className=''>
                            {filteredUnselectedProperties.length === 0 ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', minHeight: '100px' }}>
                                    <Label className='fs-18'>{props.t("userpreferences.all_fields_selected")}</Label>
                                </div>
                            ) : (
                                <ListGroup flush>
                                    <SimpleBar autoHide={true} className="simplebar-track-light px-3" style={{ maxHeight: "500px" }}>
                                        {filteredUnselectedProperties.map((property) => (
                                            <ListGroupItem tag="label" key={property.key}>
                                                <div className='d-flex gap-4' key={property.key}>
                                                    <div>
                                                        <Input
                                                            className="form-check-input mx-1"
                                                            type="checkbox"
                                                            id={property.key}
                                                            name={property.key}
                                                            onChange={(e) => handleCheckboxChange(property, e.target.checked)}
                                                        />
                                                    </div>
                                                    <div className='ms-2'>
                                                        {property.label}
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </SimpleBar>
                                </ListGroup>
                            )}
                        </div>
                    </Col>
                    {/* Selected Properties */}
                    <Col xl={6} lg={6} sm={6}>
                        <div className=''>
                            {filteredSelectedProperties.length === 0 ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', minHeight: '100px' }}>
                                    <Label className='fs-18'>{props.t("userpreferences.no_fields_selected")}</Label>
                                </div>
                            ) : (
                                <ListGroup flush>
                                    <SimpleBar autoHide={true} className="simplebar-track-light pe-2" style={{ maxHeight: "500px" }}>
                                        {filteredSelectedProperties.map((property) => (
                                            <ListGroupItem tag="label" key={property.key}>
                                                <div className='d-flex gap-3'>
                                                    <div>
                                                        <Input
                                                            className="form-check-input mx-1"
                                                            type="checkbox"
                                                            id={property.key}
                                                            name={property.key}
                                                            checked
                                                            onChange={(e) => handleCheckboxChange(property, e.target.checked)}
                                                        />
                                                    </div>
                                                    <div className='ms-2'>
                                                        {property.label}
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </SimpleBar>
                                </ListGroup>
                            )}
                        </div>
                    </Col>
                </Row>
            ) : (
                <div className='d-flex justify-content-center align-items-center'>
              <Spinner color="primary"> Loading... </Spinner>
                </div>
  
            )}

        </>
    );
};

export default withTranslation()(StudentFields);
