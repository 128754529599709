import { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import { withTranslation } from "react-i18next";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { checkTheyOwn } from "utils";

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    setisDeleteOpen,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    pageSize,
    archive,  
    handleSort,
    sortOrder,
    sortBy,
    access,
    props,
    setrecord,
    loading
}: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0
            },
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

    const [deleteaccess, setDeleteAccess] = useState<any>(null);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setDeleteAccess(false)
        }
    }, [access, userProfile])


    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    return (
        <div>
        {loading ?
           <DtsTablePlaceholder 
            columns={columns} 
            theadClass={theadClass}     
            tableClass="align-middle table-nowrap"
            thClass="border-bottom-1 table-soft-primary"
        />
            : 
        <div>
        {!loading && page && page.length ? 
            <Table hover {...getTableProps()} className={tableClass + ' m-0'}>
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={column.isSortable ? () => handleSort(column.id): ()=> {}} className={column.isSortable ?  "cursor-pointer": ""}>
                                        {column.render("Header")}
                                        {column.isSortable ? sortBy === column.id && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        ): null}
                                    </div>     
                                </th>
                            ))}
                           { archive ? null :
                            <th className={thClass + " fw-bold"}>
                                Action
                            </th>}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        let they_own = checkTheyOwn(row.original, userProfile?.id)
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{verticalAlign: 'middle'}}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    {
                                            archive ? null :
                                            <td>
                                                {
                                                    row.original.createdBy?.name === 'Zilter' || row.original.createdBy === 'Zilter' ?  <ul className="hstack gap-1 p-0 m-0"> 
                                                    <button disabled={true} className="btn btn-sm btn-soft-danger remove-list" onClick={() => { setrecord(row.original); setisDeleteOpen(true) }}>
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button>
                                                </ul> 
                                                    :
                                                    <ul className="hstack gap-1 p-0 m-0"> 
                                                        <button disabled={!(deleteaccess && (deleteaccess?.toLowerCase() === "all" || (deleteaccess?.toLowerCase() === "they_own" && they_own)))} className="btn btn-sm btn-soft-danger remove-list" onClick={() => { setrecord(row.original); setisDeleteOpen(true) }}>
                                                            <i className="ri-delete-bin-fill"></i>
                                                        </button>
                                                    </ul>}
                                            </td>            
                                    }
                                </tr>
                            </Fragment>
                        );
                    })}
                   
                </tbody>
            </Table>: <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">{props.t("common.message.no_data_found")}</span></div>}
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">
                        {props.t("common.pagination.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div>   
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagesList={pagesList}
                        pagination={{...pagination, size: pageSize}}
                        props={props}
                     />
                </div>
            </Row>
        </div>}
        </div>
    );
};

export default withTranslation()(TableContainer);