import { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { checkTheyOwn } from "utils";
interface TableContainerProps {
    columns?: any;
    data?: any;
    isGlobalFilter?: any;
    isAddOptions?: any;
    divClassName?: any;
    tableClassName?: any;
    theadClassName?: any,
    tableClass?: any;
    theadClass?: any;
    isBordered?: boolean;
    isAddUserList?: any;
    handleOrderClicks?: any;
    handleUserClick?: any;
    handleCustomerClick?: any;
    isAddCustList?: any;
    customPageSize?: any;
    className?: any;
    customPageSizeOptions?: any;
    iscustomPageSize?: boolean;
    SearchPlaceholder: string;
    isProductsFilter?: boolean;
    isPageSizeChange?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;  
    pagination: any; 
    pageSize: any;
    pagesList: any;
    setisDeleteOpen: (e: any) => void;
    setrecord: (e: any) => void;
    pageChanged: (e: any) => void;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    isIconUp: boolean;    
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    loading: any;
    access: any;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    tableClass,
    theadClass,
    trClass,
    thClass,
    setisDeleteOpen,
    setrecord,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    pageSize,
    handleSort,
    sortOrder,
    sortBy,
    props,
    access,
    loading
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            // initialState: {
            //     pageIndex: 0, selectedRowIds: 0, sortBy: [
            //         {
            //             desc: true,
            //         },
            //     ],
            // },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );   
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile); 
    
    const [deleteaccess, setDeleteAccess] = useState<any>(null);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setDeleteAccess(false)
        }
    }, [access, userProfile])


    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };
    return (
        <div>
        {loading ?
           <DtsTablePlaceholder 
           columns={columns} 
           theadClass={theadClass}     
           tableClass="align-middle table-nowrap"
           thClass="border-bottom-1 table-soft-primary"
       />
            : 
        <div>
            <Table hover {...getTableProps()} className={tableClass + ' m-0'}>
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={() => handleSort(column.id)}>
                                        {column.render("Header")}
                                        {sortBy === 'title' && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        )}
                                    </div>                                   
                                </th>
                            ))}
                            <th className={thClass + " fw-bold"} >
                                {props.t('roles.actions')}
                            </th>
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        let they_own = checkTheyOwn(row.original, userProfile?.id)
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{verticalAlign: 'middle'}}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                        {row.original.title == 'Owner' ? null :
                                            <li className="list-inline-item" title="Delete">
                                                {deleteaccess && (deleteaccess?.toLowerCase() === "all" || (deleteaccess?.toLowerCase() === "they_own" && they_own)) ?
                                                    <button className="btn btn-sm btn-soft-danger remove-list" disabled={row.original.title == 'Owner' ? true : false}
                                                    onClick={() => { const LeadData = row.original; setrecord(LeadData); setisDeleteOpen(true) }}>
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button> 
                                                :  <button disabled className="btn btn-sm btn-soft-danger remove-list"
                                                        onClick={() => { const LeadData = row.original; setrecord(LeadData); setisDeleteOpen(true) }}>
                                                            <i className="ri-delete-bin-fill"></i>
                                                        </button> }
                                            </li>
                                        }
                                        </ul>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">
                        {props.t("common.pagination.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div> 
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagesList={pagesList}
                        pagination={pagination}
                        props={props}
                     />
                </div>
            </Row>
        </div>}
        </div>
    );
};

export default TableContainer;