import React, { useEffect } from "react";
import { invoicesMetadataGetRequest} from 'store/invoices/action';
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import Invoices from "pages/Invoices/Invoices";

const InvoicesTab: React.FC = () => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(invoicesMetadataGetRequest("invoices", filters));
    }, [])
    const filter = {
            "quick": [],
            "advance": [],
            "search": {"term": id, "field": "all"}
    };
    return (
        <Invoices
            defaultFilter={filter}
            hideFilter={true}
        />
    );
};

export default InvoicesTab;
