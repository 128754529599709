import React from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Col,
    Button,
} from "reactstrap";
import Listing from 'Components/Common/Views/Listing';
import { paymentDelete, paymentGetRequest, paymentListGetRequest, paymentsMetadataGetRequest } from 'store/payments/action';
interface PreviewModalProps {
    show?: boolean;
    onClose: () => void;
    data: any;
}

const PaymentDrawer: React.FC<PreviewModalProps> = ({ show, onClose, data }: any) => {
    const filter = {
            "quick": [
                {
                    "key": "invoice",
                    "keyLabel": "Invoice",
                    "condition": "IN",
                    "conditionLabel": "is any of",
                    "values": [
                        data?.id
                    ],
                    "quick": true
                }
            ],
            "advance": [],
    };
    return (<>
        {
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasExample"
                toggle={onClose}
                className="w-50"
            >
                <OffcanvasHeader className="bg-light" toggle={onClose}>
                    {data?.channel_name}
                </OffcanvasHeader>
                <form action="" className="d-flex flex-column justify-content-end h-100">
                    <OffcanvasBody>
                        <Col xxl={12} md={12} lg={12}>
                            <div className="text-center">
                                <h5 className="mt-1 mb-1">Invoice No: {data?.invoice_number}</h5>
                            </div>
                        </Col>
                        <div>
                            <Listing 
                                getListAction={paymentListGetRequest}
                                deleteAction={paymentDelete}
                                detailsGetAction={paymentGetRequest}
                                metaDatagetRequest={paymentsMetadataGetRequest}
                                model={"payments"}
                                reducerState={"payments"}
                                translater={"payment"}
                                hideFilter={true}
                                defaultFilter={filter}
                            />
                        </div>
                    </OffcanvasBody>
                    <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                        <Button
                            className="btn btn-light w-100"
                            onClick={() => onClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Offcanvas>
        }
    </>
    );
};

export default PaymentDrawer;
