import React, { useState } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";

const CallSelectionModel = ({ props, show, onCloseClick, record, onCallClicked, isCallLoading }: any) => {
    const [method, setMethod] = useState<any>("1")
    const [modal_tooltip, setmodal_tooltip] = useState<boolean>(false);

    function tog_tooltip() {
        setmodal_tooltip(!modal_tooltip);
    }


    return (record && (record?.id || record?.mobile || record?.student?.mobile) ?
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <i className="ri-phone-line display-5 text-primary"></i>
                    <h4 className="mb-2 mt-2">{props.t("common.modals.select_calling_method")}</h4>
                    <div className="form-check form-radio-primary mb-3">
                        <Input
                            className="form-check-input"
                            type="radio"
                            id="mobileRadio"
                            name="formradiocolor1"
                            onChange={() => setMethod("1")}
                            checked={method === "1"}
                        />
                        <Label className="form-check-label" for="mobileRadio">
                            Mobile
                        </Label>
                    </div>
                    <div className="form-check form-radio-primary mb-3">
                        <Input
                            className="form-check-input"
                            type="radio"
                            id="voipRadio"
                            name="formradiocolor1"
                            onChange={() => setMethod("2")}
                            checked={method === "2"}
                        />
                        <Label className="form-check-label" for="voipRadio">
                            VOIP
                        </Label>
                    </div>

                    <div className="hstack gap-2 justify-content-center">
                        <Button color="primary" disabled={isCallLoading} onClick={() => onCallClicked(record, method)}>
                            {isCallLoading ? props.t("common.action_button.calling") : props.t("common.action_button.call")}
                        </Button>
                        <Button color="primary" onClick={() => onCloseClick()} outline>
                            {props.t("common.action_button.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default CallSelectionModel;