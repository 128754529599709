import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FileViewer from 'react-file-viewer';
import { documentDownload } from 'store/documents/action';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { Spinner, Alert, Button } from 'reactstrap';

interface FileInfo {
  documentKey: string;
  documentExtension: string;
  documentName: string;
}

interface FilePreviewProps {
  fileInfo: FileInfo;
}

const FilePreview: React.FC<FilePreviewProps> = ({ fileInfo }) => {
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const fileType: string = fileInfo.documentExtension.toLowerCase();

  // Function to fetch the file preview
  const fetchFile = () => {
    // Edge-case: Missing or invalid file key
    if (!fileInfo?.documentKey) {
      setError('Invalid file information provided.');
      setLoading(false);
      return;
    }
    
    setLoading(true);
    setError(null);

    dispatch(
      documentDownload(
        fileInfo.documentKey,
        async (response: Response) => {
          try {
            // Ensure the response is valid
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const fileURL = window.URL.createObjectURL(blob);
            setPreviewUrl(fileURL);
          } catch (err) {
            console.error('Error processing file blob:', err);
            setError('Error processing file. Please try again.');
          }
          setLoading(false);
        },
        () => {
          console.error('Failed to download file for preview');
          setError('Failed to download file for preview.');
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchFile();

    return () => {
      // Cleanup: Revoke object URL to avoid memory leaks
      if (previewUrl) {
        window.URL.revokeObjectURL(previewUrl);
      }
    };
  }, [fileInfo, dispatch]);

  // Loading UI using Bootstrap/Reactstrap components
  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '400px' }}>
        <Spinner size="lg" type="grow" color='primary'> Loading... </Spinner>
        <h4 className="mt-3 text-muted">Loading preview...</h4>
      </div>
    );
  }

  // Error UI with a retry button
  if (error) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '400px' }}>
        <Alert color="danger" className="text-center">
          {error}
        </Alert>
        <Button color="primary" onClick={fetchFile}>
          Retry
        </Button>
      </div>
    );
  }

  // Render the file preview using FileViewer
  return (
    <div style={{ height: '400px', width: '100%' }}>
      <FileViewer
        fileType={fileType}
        filePath={previewUrl!}
        onError={(err: any) => {
          console.error('FileViewer error:', err);
          setError('Error displaying file.');
        }}
      />
    </div>
  );
};

export default FilePreview;
